import * as AccountApi from 'signin-app/api/account-api';
import makeLocaleSetup, {type SupportedLanguages} from 'web-app/shared/locale-setup';
import LocalStorageService from 'web-app/services/local-storage';
import PlatformHelper from 'web-app/helpers/platform-helper';

const LOCALE_KEY = 'locale';

const getLocaleFromURL = (): SupportedLanguages | null => {
    const urlParams = new URLSearchParams(window.location.search);
    const locale = urlParams.get(LOCALE_KEY);

    if (!locale) {
        return null;
    }

    return locale as SupportedLanguages;
};

const canUseCacheStorage = 'caches' in window;

const getLocaleFromCacheStorage = (): Promise<SupportedLanguages | null | undefined> => {
    return new Promise(resolve => {
        if (canUseCacheStorage) {
            window.caches.open(LOCALE_KEY).then(cache => {
                cache.match(LOCALE_KEY).then(response => {
                    if (!response) {
                        resolve(null);
                    } else {
                        response.json().then(body => {
                            const localeFromCaches = body && body[LOCALE_KEY];
                            if (localeFromCaches) {
                                resolve(localeFromCaches as SupportedLanguages);
                            } else {
                                resolve(null);
                            }
                        });
                    }
                });
            });
        } else {
            resolve(null);
        }
    });
};

const saveLocaleToCacheStorage = (locale: SupportedLanguages | undefined | null) => {
    return new Promise<void>(resolve => {
        if (canUseCacheStorage) {
            window.caches.open(LOCALE_KEY).then(cache => {
                cache.put(LOCALE_KEY, new Response(JSON.stringify({[LOCALE_KEY]: locale}))).then(resolve);
            });
        } else {
            resolve();
        }
    });
};

// `userLanguage` and `browserLanguage` are specific for older IE and Opera versions,
// they return the language of the browser application or the operating system's user interface.
// `navigator.language` should be used when possible.
type OlderBrowserNavigator = Navigator & {userLanguage?: string; browserLanguage?: string};

const getDeviceLocale = (): SupportedLanguages => {
    const compatibleNavigator = navigator as OlderBrowserNavigator;
    const deviceLanguage =
        compatibleNavigator.language || compatibleNavigator.browserLanguage || compatibleNavigator.userLanguage || '';
    return deviceLanguage.replace('-', '_') as SupportedLanguages;
};

const localeSetup = () => {
    // 1. Try to get a locale from URL (search params).
    // 2. If there's not a locale in the URL, check localStorage or cacheStorage(only iOS Safari),
    //    the locale may be already saved.
    // 3. If no locale is found, use the device locale (the language of the browser or OS).
    // 4. If the previous steps failed, get the locale from backend(from the device location).

    const locale =
        getLocaleFromURL() ||
        (LocalStorageService.getFromLocalStorage(LOCALE_KEY) as SupportedLanguages) ||
        getDeviceLocale();

    if (PlatformHelper.isSafariMobile()) {
        // iOS Safari doesn't share localStorage, sessionStorage, cookies, etc., with standalone apps(PWA on homescreen),
        // therefore we cannot share 'locale' which normally comes from URL Search params and then is saved to localStorage.
        // The only workaround is to use CacheStorage API, which is shared.
        // The CacheStorage API is not supported by older browsers and should be used only as a fallback for iOS Safari.
        return () =>
            new Promise<void>(resolve => {
                getLocaleFromCacheStorage().then(localeFromCaches => {
                    const savedLocale = locale || localeFromCaches;
                    makeLocaleSetup(AccountApi.getLocale, savedLocale)().then(() => {
                        saveLocaleToCacheStorage(savedLocale).then(resolve);
                    });
                });
            });
    } else {
        return makeLocaleSetup(AccountApi.getLocale, locale);
    }
};

export default localeSetup();
