import {Record} from 'immutable';

import {makeReducer, match} from 'web-app/util/redux';
import {hasValue} from '@famly/stat_ts-utils_has-value';

import * as Actions from './actions';

export class ChildCheckInState extends Record<{
    readonly selectedPickupRelationId: string;
    readonly pickupPersonName: string;
    readonly selectedPickupHours: number | undefined;
    readonly selectedPickupMinutes: number | undefined;
    readonly isCheckingIn: boolean;
    readonly addingNewRelation: boolean;
    readonly forceShowPickupScreen: boolean;
}>({
    selectedPickupRelationId: '',
    pickupPersonName: '',
    selectedPickupHours: undefined,
    selectedPickupMinutes: undefined,
    isCheckingIn: false,
    addingNewRelation: false,
    forceShowPickupScreen: false,
}) {}

export default makeReducer<ChildCheckInState>(
    [
        match(Actions.selectPickupRelation, (state, {payload}) =>
            state.merge({
                selectedPickupRelationId: payload.relationId,
            }),
        ),
        match(Actions.selectPickupHours, (state, {payload}) =>
            state.merge({
                selectedPickupHours: payload.hours,
            }),
        ),
        match(Actions.selectPickupMinutes, (state, {payload}) =>
            state.merge({
                selectedPickupMinutes: payload.minutes,
            }),
        ),
        match(Actions.initPickupTime, (state, {payload}) =>
            state.merge({
                selectedPickupHours: payload.hours,
                selectedPickupMinutes: payload.minutes,
            }),
        ),
        match(Actions.checkInChild, state =>
            state.merge({
                isCheckingIn: true,
            }),
        ),
        match(Actions.checkInChildSuccess, state =>
            state.merge({
                isCheckingIn: false,
            }),
        ),
        match(Actions.checkInChildFailed, state =>
            state.merge({
                isCheckingIn: false,
            }),
        ),
        match(Actions.resetPickup, () => new ChildCheckInState()),
        match(Actions.addNewPickupRelation, (state, {payload}) => {
            return state.merge({
                addingNewRelation: payload.addNew,
                selectedPickupRelationId: '',
                /**
                 * Here, if undefined is passed it means that no changes are desired, so we're keeping the current value.
                 * If an empty string is passed, it means that the value should be reset.
                 */
                pickupPersonName: hasValue(payload.pickupPersonName)
                    ? payload.pickupPersonName
                    : state.pickupPersonName,
            });
        }),
        match(Actions.showPickupInfo, state =>
            state.merge({
                forceShowPickupScreen: true,
            }),
        ),
    ],
    new ChildCheckInState(),
);
