import {ofType} from 'redux-observable';
import {of, merge} from 'rxjs';
import {mergeMap, flatMap, catchError, map} from 'rxjs/operators';
import i18next from 'i18next';

import {combineEpics} from 'web-app/util/redux-observable';
import ChildApi from 'signin-app/api/child-api';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import translate from 'signin-app/helpers/translate';
import {childName as getChildName, requirePickupInfo, childRelations} from 'signin-app/child/selectors';
import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {pickupFirstName} from 'signin-app/child/check-out/selectors';
import {type SignInEpic} from 'signin-app/redux/types';
import {TrackingEvents} from 'web-app/util/famlytics/events';
import {track} from 'signin-app/util/famlytics';
import {hasValue} from '@famly/stat_ts-utils_has-value';

import * as Actions from './actions';
import * as Selectors from './selectors';

const checkInChild: SignInEpic = (action$, state$) =>
    action$.pipe(
        ofType(Actions.checkInChild.type),
        mergeMap(({payload}: ReturnType<typeof Actions.checkInChild.action>) => {
            const {
                childId,
                pickupRelationId,
                selectedHours,
                selectedMinutes,
                newPickupRelationName,
                successMessage,
                groupId,
                pin,
                goHomeWithChildId,
                isCheckedIn,
            } = payload.options;

            const state = state$.value;
            const childName = getChildName(state, {id: childId, childId});
            const requirePickup = requirePickupInfo(state, {childId});

            const relationsIds = childRelations(state).map(relation => relation.loginId);

            if (requirePickup && !goHomeWithChildId && !pickupRelationId && !newPickupRelationName) {
                return merge(
                    of(GlobalEventActions.updateError.action(translate('chooseWhoWillPickUp', {childName}))),
                    of(Actions.checkInChildFailed.action('pickupRelationId is not selected')),
                );
            }

            if (requirePickup && !goHomeWithChildId && (selectedHours === undefined || selectedMinutes === undefined)) {
                return merge(
                    of(GlobalEventActions.updateError.action(translate('chooseWhenWillBePickedUp', {childName}))),
                    of(Actions.checkInChildFailed.action('pickupRelationId is not selected')),
                );
            }

            const minutesWithZero = `0${selectedMinutes}`.slice(-2);
            const pickupTime =
                selectedHours !== undefined && selectedMinutes !== undefined
                    ? `${selectedHours}:${minutesWithZero}`
                    : '';

            const pickupName = newPickupRelationName
                ? newPickupRelationName
                : pickupFirstName(state, {
                      child: {
                          pickupRelationId,
                          childId,
                          id: childId,
                      },
                  });

            return ChildApi.checkIn({
                childId,
                pickupRelationId,
                pickupTime,
                newPickupRelationName,
                groupId: groupId ? groupId : undefined,
                pin,
                goHomeWithChildId,
            }).pipe(
                flatMap(response => {
                    track(TrackingEvents.SIGNIN_SCREEN_CHILD_CHECKED_IN, {
                        openedFrom: 'Sign in screen',
                        pickupTimeSelected: hasValue(pickupTime),
                        pickupPersonSelected: hasValue(response.pickupRelationId),
                        usedPinCode: hasValue(pin) && pin.length > 0,
                        editing: isCheckedIn,
                        checkingInOutPersonType: checkingInOutPersonType(response.checkinLoginId, relationsIds),
                    });

                    return [
                        Actions.checkInChildSuccess.action(response, pickupName),
                        GlobalEventActions.updateSuccess.action(successMessage),
                    ];
                }),
                catchError(e => {
                    const errorMessage = e.error ? e.error : i18next.t('somethingWentWrong');
                    return merge(
                        of(GlobalEventActions.updateError.action(errorMessage)),
                        of(Actions.checkInChildFailed.action(e)),
                    );
                }),
            );
        }),
    );

const onSelectedHoursChanged = (action$, state$) =>
    action$.pipe(
        ofType(Actions.selectPickupHours.type),
        map(({payload}: ReturnType<typeof Actions.selectPickupHours.action>) => {
            const timeOptions = Selectors.pickupTimeOptions(state$.value);
            const minutesMin = Math.min(...(timeOptions.find(time => time.hour === payload.hours)?.minutes ?? []));
            return Actions.selectPickupMinutes.action(minutesMin);
        }),
    );

export const checkingInOutPersonType = (checkinLoginId, relationsIds) => {
    return relationsIds.includes(checkinLoginId) ? 'parent' : 'employee';
};

export default combineEpics<Action<any>, Action<any>, RootState>(checkInChild, onSelectedHoursChanged);
