import React from 'react';

import {useDataProps, type DataProps} from 'modern-famly/components/util';

import {StatusPill, type StatusPillProps} from '../status-pill';
import {ListItem, type ListItemProps} from './list-item';
import {useListContext} from './list-context';

export type ListItemStatusPillProps = Pick<
    StatusPillProps,
    'text' | 'icon' | 'variant' | 'showDot' | 'severity' | 'size'
> &
    DataProps &
    Pick<ListItemProps, 'displayFrom'>;

export const ListItemStatusPill = (props: ListItemStatusPillProps) => {
    const dataProps = useDataProps(props);
    const {device} = useListContext();

    return (
        <ListItem displayFrom={props.displayFrom}>
            <StatusPill
                text={props.text}
                severity={props.severity}
                size={props.size ?? (device === 'mobile' ? 'compact' : 'regular')}
                showDot={props.showDot}
                icon={props.icon}
                variant={props.variant}
                {...dataProps}
            />
        </ListItem>
    );
};
