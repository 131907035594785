/* eslint-disable no-restricted-imports */
import React from 'react';
import MuiDialog from '@mui/material/Dialog';
import {useDataProps, useAriaProps} from '@famly/stat_ts-utils_data-aria-props';

/**
 * Base component for implementing modern famly dialogs.
 *
 * Can take an arbitrary element as children, but it is recommended to use the other dialogs elements defined
 * in this same project
 *
 * @param open tells if the dialog should be shown or not
 */
export const Dialog = ({open, children, ...rest}: React.PropsWithChildren<{open: boolean} & DataProps>) => {
    const dataAttributes = useDataProps(rest);
    const ariaAttributes = useAriaProps(rest);

    return (
        <MuiDialog
            open={open}
            {...dataAttributes}
            {...ariaAttributes}
            PaperProps={{
                sx: theme => ({
                    width: {
                        base: '100%',
                        mobileLandscape: '500px',
                    },
                    margin: {
                        base: 0,
                        mobileLandscape: undefined,
                    },
                    padding: 0,
                    borderRadius: {base: '16px 16px 0 0', mobileLandscape: '16px'},
                    boxShadow: theme.modernFamlyTheme?.elevation[4],
                    position: {
                        base: 'fixed',
                        mobileLandscape: undefined,
                    },
                    bottom: {
                        base: 0,
                        mobileLandscape: 'inherit',
                    },
                    maxHeight: '90%',
                }),
            }}
        >
            {children}
        </MuiDialog>
    );
};
