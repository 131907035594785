/**
 * These colors will not vary between themes and for convenience they're defined here
 */
export const fixedPalette: FixedPalette = {
    // Blue colors
    b50: 'hsla(210, 95%, 98%, 1)',
    b100: 'hsla(210, 95%, 96%, 1)',
    b200: 'hsla(210, 95%, 90%, 1)',
    b300: 'hsla(210, 95%, 65%, 1)',
    b400: 'hsla(210, 95%, 48%, 1)',
    b500: 'hsla(210, 95%, 25%, 1)',

    // Green colors
    g50: 'hsla(155, 73%, 98%, 1)',
    g100: 'hsla(155, 73%, 96%, 1)',
    g200: 'hsla(155, 73%, 85%, 1)',
    g300: 'hsla(155, 73%, 50%, 1)',
    g400: 'hsla(155, 73%, 40%, 1)',
    g500: 'hsla(155, 73%, 20%, 1)',

    // Orange colors
    o50: 'hsla(25, 95%, 98%, 1)',
    o100: 'hsla(25, 95%, 96%, 1)',
    o200: 'hsla(25, 95%, 90%, 1)',
    o300: 'hsla(25, 95%, 70%, 1)',
    o400: 'hsla(25, 95%, 55%, 1)',
    o500: 'hsla(25, 95%, 25%, 1)',

    // Yellow colors
    y50: 'hsla(43, 95%, 98%, 1)',
    y100: 'hsla(43, 95%, 96%, 1)',
    y200: 'hsla(43, 95%, 90%, 1)',
    y300: 'hsla(43, 95%, 70%, 1)',
    y400: 'hsla(43, 95%, 58%, 1)',
    y500: 'hsla(43, 95%, 20%, 1)',

    // Red colors
    r50: 'hsla(0, 90%, 98%, 1)',
    r100: 'hsla(0, 90%, 96%, 1)',
    r200: 'hsla(0, 90%, 90%, 1)',
    r300: 'hsla(0, 90%, 70%, 1)',
    r400: 'hsla(0, 90%, 40%, 1)',
    r500: 'hsla(0, 90%, 20%, 1)',

    // Neutral colors
    n0: 'hsla(0, 0%, 100%, 1)',
    n25: 'hsla(240, 12%, 99%, 1)',
    n50: 'hsla(240, 12%, 98%, 1)',
    n75: 'hsla(240, 12%, 95%, 1)',
    n100: 'hsla(240, 12%, 90%, 1)',
    n200: 'hsla(240, 12%, 80%, 1)',
    n300: 'hsla(240, 12%, 45%, 1)',
    n400: 'hsla(240, 12%, 20%, 1)',
    n500: 'hsla(240, 12%, 1%, 1)',
};
