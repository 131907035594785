import {hasValue} from '@famly/stat_ts-utils_has-value';
import {exhaustiveCheck} from '@famly/stat_ts-utils_exhaustive-check';

export {
    /**
     * @deprecated import `hasValue` directly from '@famly/stat_ts-utils_has-value
     */
    hasValue,

    /**
     * @deprecated import `exhaustiveCheck` directly from '@famly/stat_ts-utils_exhaustive-check
     */
    exhaustiveCheck,
};

export type NonEmptyArray<T> = [T, ...T[]];

export function isNonEmpty<T>(arr: T[] | NonEmptyArray<T>): arr is NonEmptyArray<T> {
    return arr.length > 0;
}
