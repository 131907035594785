/* eslint-disable default-case */
/* eslint-disable consistent-return */
import React from 'react';
import styled, {css} from 'styled-components';

import Tooltip, {type TooltipProps} from 'web-app/react/components/tooltip/tooltip';
import Link, {BlockLink} from 'web-app/react/components/link/link';
import {Body, Caption} from 'web-app/react/components/text/text';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {s2} from 'web-app/styleguide/spacing';

const StyledOverlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: ${props => props.theme.blackScrim};
    height: 100%;
`;

const StyledChildPreview = styled.li`
    display: flex;
    align-items: center;
    img {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        margin-right: 8px;
    }
    & + & {
        margin-top: 8px;
    }
`;

const StyledDescription = styled.div`
    display: inline-block;
`;

const HoverStyle = css`
    display: flex;
    align-items: center;
    margin: 0 -10px;
    padding: 0 10px;
    width: 100%;
    color: inherit;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

const StyledLink = styled(Link)`
    ${HoverStyle}
`;

const StyledHover = styled.div`
    cursor: pointer;
    ${HoverStyle}
`;

const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 100%;
    position: relative;

    display: flex;
`;

interface StyledImageContainerProps {
    size?: number;
    bordered?: boolean;
    count?: number;
    overlap?: boolean;
    shadow?: boolean;
    onClick?: () => void;
}

export const StyledImageContainer = styled('div')<StyledImageContainerProps>`
    ${props =>
        props.size
            ? ''
            : css`
                  height: 100%;
                  width: 100%;
              `}

    width: ${props => (props.size ? `${props.size}px` : 'initial')};
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;
    ${props => {
        if (props.bordered) {
            const width = props.overlap ? 2 : 1;
            const color = props.overlap ? props.theme.invertedText : props.theme.delimiter;
            const border = css<StyledImageContainerProps>`
                border: ${width}px solid ${color};
            `;

            return props.size
                ? css`
                      position: relative;
                      :after {
                          content: '';
                          border-radius: 50%;
                          position: absolute;
                          top: 0;
                          left: 0;
                          right: 0;
                          bottom: 0;
                          ${border}
                      }
                  `
                : border;
        }
    }}

    ${props => {
        if (!props.shadow) {
            return '';
        }

        const width = props.overlap ? 2 : 1;
        const color = props.overlap ? props.theme.invertedText : props.theme.delimiter;

        return css`
            box-shadow: 0 0 ${width}px 0px ${color} inset, 0 0 ${width}px 0px ${color};
        `;
    }};

    ${props =>
        props.overlap
            ? css`
                  ${hasValue(props.size) && hasValue(props.count)
                      ? css`
                            /**
                             * Stacking the images using negative margin is preferable as applying 'transform: translateX(...)'
                             * doesn't affect the container of the laid out images, which leaves blank space after the images.
                             */
                            margin-left: -${props.count ? props.size / 2 - props.theme.spacing.spacingMap[s2.spacingType] : 0}px;
                        `
                      : css`
                            transform: translateX(${props.count ? props.count * -50 : 0}%);
                        `}
              `
            : css`
                  :not(:first-child) {
                      margin-left: 8px;
                  }
              `}

    ${props =>
        props.onClick
            ? css`
                  cursor: pointer;
              `
            : ''};

    @media not print {
        /* Fixes layout bug in mobile safari causing the border-radius not to be enforced. See: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
        -webkit-mask-image: -webkit-radial-gradient(white, black);
    }
`;

export const StyledImg = styled('img')<{size?: number; mute?: boolean}>`
    max-height: 100%;
    min-width: 100%;
    height: ${props => (props.size ? `${props.size}px` : '100%')};
    width: ${props => (props.size ? `${props.size}px` : 'auto')};
    ${props =>
        props.mute &&
        css`
            opacity: 0.3;
            filter: grayscale(100%);
        `}
`;

const StyledHiddenCount = styled(Body)`
    position: absolute;
`;

const StyledContainerLink = styled(BlockLink)`
    & + & {
        margin-left: 8px;
    }
`;

type Image = {
    name: string;
    image?: string;
    flair?: string;
    mute?: boolean;
} & (
    | {
          onClick?: () => void;
      }
    | {
          link?: string;
      }
);

export type PlaceholderType = 'default' | 'room' | 'institution';

export interface ProfileImageProps {
    mute?: boolean;
    bordered?: boolean;
    addLinksToShownImages?: boolean;
    overlap?: boolean;
    shadow?: boolean;
    allowContainerAsLink?: boolean;
    src?: string | Image[] | null;
    placeholderSrc?: string;
    placeholderType?: PlaceholderType;
    className?: string;
    limitCount?: number;
    tooltipPlacement?: ProfileImageTooltipComposerProps['tooltipPlacement'];
    size?: number;
    tooltipAll?: boolean;

    /**
     * Controls the tooltip displaying remaining items when hovering the small "overflow element"
     * inserted when passing an array to `src` that has more items than `limitCount` specifies,
     *
     * The default value is `true`.
     */
    showTooltipOnOverflowingItems?: boolean;
}

const emtpyTypeImageSrc = (placeholderType: PlaceholderType) => {
    switch (placeholderType) {
        case 'institution':
            return 'img/empty-states/institution.svg';
        case 'room':
            return 'img/empty-states/room.svg';
        case 'default':
        default:
            return 'img/empty-states/profile-image.svg';
    }
};

const ProfileImage = (props: ProfileImageProps) => {
    const {
        tooltipPlacement,
        src,
        mute,
        bordered,
        shadow,
        className,
        limitCount,
        size,
        overlap = true,
        allowContainerAsLink = true,
        addLinksToShownImages,
        tooltipAll,
        placeholderSrc,
        placeholderType = 'default',
        showTooltipOnOverflowingItems = true,
    } = props;

    const emptyImageSrc = placeholderSrc || emtpyTypeImageSrc(placeholderType);

    if (!Array.isArray(src)) {
        return (
            <StyledImageContainer
                className={className}
                shadow={shadow}
                bordered={bordered}
                overlap={overlap}
                size={size}
            >
                <StyledImg src={src || emptyImageSrc} mute={mute} size={size} />
            </StyledImageContainer>
        );
    } else if (src.length === 1) {
        const content = (
            <ProfileImageTooltipComposer items={src} tooltipPlacement={tooltipPlacement} emptyImageSrc={emptyImageSrc}>
                <StyledImageContainer
                    className={className}
                    bordered={bordered}
                    overlap={overlap}
                    size={size}
                    onClick={'onClick' in src[0] ? src[0].onClick : undefined}
                >
                    <StyledImg src={src[0].image || emptyImageSrc} mute={mute} size={size} />
                </StyledImageContainer>
            </ProfileImageTooltipComposer>
        );

        const first = src[0]!;

        if ('link' in first && first.link && allowContainerAsLink) {
            return <Link href={first.link}>{content}</Link>;
        }
        return content;
    }

    const limit = limitCount !== undefined ? limitCount : 1;
    const lastShown = src[limit - 1];

    const sliceEnd = limit < src.length ? limit - 1 : limit;

    return (
        <StyledContainer className={className}>
            {src.slice(0, sliceEnd).map((source, i) => {
                let content = (
                    <StyledImageContainer
                        key={i}
                        bordered={bordered}
                        overlap={overlap}
                        size={size}
                        count={i}
                        onClick={addLinksToShownImages && 'onClick' in source ? source.onClick : undefined}
                    >
                        <StyledImg
                            src={source.image ? source.image : emptyImageSrc}
                            mute={mute}
                            size={size}
                            onClick={'onClick' in source ? source.onClick : undefined}
                        />
                    </StyledImageContainer>
                );

                if (tooltipAll) {
                    content = (
                        <ProfileImageTooltipComposer
                            key={i}
                            items={source}
                            tooltipPlacement={tooltipPlacement}
                            emptyImageSrc={emptyImageSrc}
                        >
                            {content}
                        </ProfileImageTooltipComposer>
                    );
                }

                if ('link' in source && source.link && addLinksToShownImages) {
                    return (
                        <StyledContainerLink key={i} href={source.link}>
                            {content}
                        </StyledContainerLink>
                    );
                }

                return content;
            })}
            {limit < src.length && (
                <ProfileImageTooltipComposer
                    items={src.slice(limit - 1)}
                    tooltipPlacement={tooltipPlacement}
                    emptyImageSrc={emptyImageSrc}
                    disabled={!showTooltipOnOverflowingItems}
                >
                    <StyledImageContainer bordered={bordered} count={limit - 1} overlap={overlap} size={size}>
                        <StyledOverlay>
                            <StyledImg
                                src={lastShown.image ? lastShown.image : emptyImageSrc}
                                mute={true}
                                size={size}
                            />
                            <StyledHiddenCount color={'invertedText'}>{`+${src.length - limit + 1}`}</StyledHiddenCount>
                        </StyledOverlay>
                    </StyledImageContainer>
                </ProfileImageTooltipComposer>
            )}
        </StyledContainer>
    );
};

export default ProfileImage;

interface ProfileImageTooltipComposerProps {
    tooltipPlacement?: TooltipProps['placement'];
    items: Image | Image[];
    emptyImageSrc?: string;
    disabled?: boolean;
}
export const ProfileImageTooltipComposer: React.FC<
    React.PropsWithChildren<ProfileImageTooltipComposerProps>
> = props => {
    const {items, children, tooltipPlacement, emptyImageSrc, disabled} = props;
    return (
        <Tooltip
            placement={tooltipPlacement}
            overlay={
                Array.isArray(items) ? (
                    <StyledUl>
                        {items.map((item, i) => (
                            <ProfileImageTooltip key={i} item={item} emptyImageSrc={emptyImageSrc} />
                        ))}
                    </StyledUl>
                ) : (
                    <ProfileImageTooltip item={items} emptyImageSrc={emptyImageSrc} />
                )
            }
            // Only applying the `visible` prop specifically when `disabled`
            // is passed. Setting `visible` to e.g. `undefined` is interpreted as `false`
            // by `rc-tooltip`.
            {...(disabled ? {visible: false} : {})}
        >
            {children}
        </Tooltip>
    );
};

const StyledUl = styled.ul`
    max-height: 80vh;
    overflow: auto;
    padding-right: ${props => props.theme.mf.spacing(5)} !important;

    &::-webkit-scrollbar {
        background: transparent;
        height: 8px;
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border: none;
        box-shadow: none;
        background: ${props => props.theme.mf.colorPalette.n400};
        border-radius: 6px;
        min-height: 8px;
    }

    scrollbar-color: ${props => props.theme.mf.colorPalette.n400} transparent;
    scrollbar-width: thin;
`;

const InnerContentImg = styled.img<{mute?: boolean}>`
    ${props =>
        props.mute
            ? css`
                  filter: grayscale(100%);
              `
            : ''}
`;

interface ProfileImageTooltipProps {
    item: Image;
    emptyImageSrc?: string;
}

const InnerContent: React.FC<React.PropsWithChildren<ProfileImageTooltipProps>> = props => {
    const {item, emptyImageSrc} = props;
    return (
        <>
            <InnerContentImg src={item.image ? item.image : emptyImageSrc} alt={item.name} mute={item.mute} />
            <StyledDescription>
                <Body color={'invertedText'}>{item.name}</Body>
                {item.flair ? <Caption color={'invertedText'}>{item.flair}</Caption> : null}
            </StyledDescription>
        </>
    );
};

const ProfileImageTooltip: React.FC<React.PropsWithChildren<ProfileImageTooltipProps>> = ({item, emptyImageSrc}) => {
    let content;

    if ('link' in item && item.link) {
        content = (
            <StyledLink href={item.link} onClick={e => e.stopPropagation()}>
                <InnerContent item={item} emptyImageSrc={emptyImageSrc} />
            </StyledLink>
        );
    } else if ('onClick' in item && item.onClick) {
        content = (
            <StyledHover onClick={item.onClick}>
                <InnerContent item={item} emptyImageSrc={emptyImageSrc} />
            </StyledHover>
        );
    } else {
        content = <InnerContent item={item} emptyImageSrc={emptyImageSrc} />;
    }

    return (
        <StyledChildPreview onClick={'onClick' in item && item.onClick ? item.onClick : undefined}>
            {content}
        </StyledChildPreview>
    );
};
