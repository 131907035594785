import i18next from 'i18next';
import {StatusPill} from 'modern-famly';
import React from 'react';
import styled from 'styled-components';

import {createBox} from '@famly/mf_layout_box';

interface RoomsStatusPillsProps {
    fromAnotherRoom?: boolean;
    inAnotherRoom?: boolean;
    baseGroupName?: string;
    currentGroupName?: string;
}

const PillEllipsis = styled(createBox({}))`
    p {
        width: 110px;
        text-overflow: ellipsis;
    }
`;

export const RoomStatusPills = ({
    fromAnotherRoom,
    inAnotherRoom,
    baseGroupName,
    currentGroupName,
}: RoomsStatusPillsProps) => {
    if (fromAnotherRoom) {
        return (
            <PillEllipsis>
                <StatusPill
                    size="compact"
                    text={i18next.t('group_tag.fromAnotherRoom', {baseRoomName: baseGroupName})}
                    severity="info"
                />
            </PillEllipsis>
        );
    }

    if (inAnotherRoom) {
        return (
            <PillEllipsis>
                <StatusPill
                    size="compact"
                    text={i18next.t('group_tag.inAnotherRoom', {
                        roomName: currentGroupName,
                    })}
                    severity="info"
                />
            </PillEllipsis>
        );
    }
    return null;
};
