/**
 * This is an internal component that all remaining buttons build upon. It exposes props
 * that should not appear in the public interface but allow us to easily create new and
 * more specific button variants.
 */
import React from 'react';
import MUIButton, {type ButtonProps as MUIButtonProps, buttonClasses} from '@mui/material/Button';
import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import {type Theme, type Components, styled} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {adjustAlpha} from '@famly/mf_colors';
import {extractSystemStyles, handlePassthroughSx, type AlignmentProps} from '@famly/mf_system_system-props';

import {type DataProps, useDataProps} from 'modern-famly/components/util';
import {Icon, type IconName, type IconProps} from 'modern-famly/components/data-display/icon';

type ButtonIconProps = IconName | Pick<IconProps, 'name' | 'filled'>;

interface ButtonProps {
    /**
     * Whether the button is disabled
     */
    isDisabled?: boolean;

    /**
     * Callback fired when button is clicked
     */
    onClick?: MUIButtonProps['onClick'];

    /**
     * The button size. Possible values are 'regular' and 'compact'
     */
    size?: MUIButtonProps['size'];

    /**
     * Whether the button should take up the available space in its container
     */
    fullWidth?: MUIButtonProps['fullWidth'];

    /**
     * Classic button element type attribute.
     * Possible values are "button" | "submit" | "reset"
     */
    type?: MUIButtonProps['type'];

    /**
     * Whether to show a loading indicator.
     *
     * As default, it's placed on top of the text.
     *
     * When the an `icon` is provided,
     * the loading indicator will be displayed in place of the icon.
     */
    isLoading?: boolean;

    /**
     * The button's ref
     */
    ref?: MUIButtonProps['ref'];

    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx?: MUIButtonProps['sx'];

    /**
     * Element placed after the `text` label.
     */
    endIcon?: MUIButtonProps['endIcon'];

    /**
     *
     */
    children?: React.ReactNode;

    /**
     * The button variants. Possible values are 'primary', 'secondary', 'tertiary', 'transparent', 'upsell', 'critical' and 'critical-transparent'
     */
    variant?: MUIButtonProps['variant'];

    /**
     * The label of the button;
     */
    text?: string;

    /**
     * The icon name to display on the left side of the button
     */
    icon?: ButtonIconProps;

    /**
     * The aria label
     */
    'aria-label'?: string;

    /**
     * The form id to use this button with.
     *
     * You can pass a form id here to use a submit type button for a form from outside the <form /> tags
     *
     * https://stackoverflow.com/a/12567605/3643533
     */
    form?: string;
}

export type AnchorProps =
    | {
          /**
           * The URL to link to when the button is clicked.
           * If defined, an `a` element will be used as the root node.
           */
          href?: undefined;
      }
    | {
          /**
           * The URL to link to when the button is clicked.
           * If defined, an `a` element will be used as the root node.
           */
          href: string;

          /**
           * Where to display the linked URL
           */
          target?: HTMLAnchorElement['target'];
      };

export type ButtonBaseProps = ButtonProps & DataProps & AnchorProps & AlignmentProps;

export const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonBaseProps>(
    ({text, onClick, isDisabled, variant, size, fullWidth, type, icon, isLoading, sx, endIcon, form, ...rest}, ref) => {
        const dataProps = useDataProps(rest);
        const anchorProps = rest.href ? {href: rest.href, target: rest.target} : undefined;
        const isIconOnlyButton = hasValue(icon) && !hasValue(text);

        const iconSize = getIconSize(size, isIconOnlyButton);

        const commonProps = {
            onClick,
            disabled: isDisabled || isLoading,
            startIcon: icon ? getStartIcon(icon, isLoading, iconSize) : undefined,
            size,
            fullWidth,
            type,
            ref,
            form,
            ['aria-label']: rest['aria-label'],
        };

        const {sx: systemSx} = extractSystemStyles(rest);

        if (isIconOnlyButton) {
            return (
                <MUIButton
                    variant={variant}
                    sx={theme => ({
                        ...systemSx(theme),
                        borderRadius: '50% !important',
                        width: size === 'compact' ? '36px !important' : '48px !important',
                        minWidth: 'unset',

                        [`.${buttonClasses.startIcon}`]: {
                            margin: `${theme.modernFamlyTheme.spacing(0)}`,
                        },
                    })}
                    {...commonProps}
                    {...anchorProps}
                    {...dataProps}
                />
            );
        }

        return (
            <MUIButton
                variant={variant}
                endIcon={endIcon}
                sx={[...handlePassthroughSx(sx), ...handlePassthroughSx(systemSx)]}
                {...commonProps}
                {...anchorProps}
                {...dataProps}
            >
                {text}
                <LoadingOverlay isLoading={isLoading && !hasValue(icon)} />
            </MUIButton>
        );
    },
);

const getStartIcon = (buttonIconProps: ButtonIconProps, isLoading: ButtonBaseProps['isLoading'], iconSize: number) => {
    if (isLoading) {
        return (
            <Stack padding={0.5} justifyContent="center" alignItems="center">
                <CircularProgress size={20} />
            </Stack>
        );
    } else {
        const iconProps: IconProps = typeof buttonIconProps === 'string' ? {name: buttonIconProps} : buttonIconProps;

        return <Icon {...iconProps} size={iconSize} />;
    }
};

const getIconSize = (size: ButtonBaseProps['size'], isIconOnlyButton: boolean) => {
    switch (true) {
        case isIconOnlyButton:
            return 22;
        case size === 'compact':
            return 18;
        case size === 'regular':
            return 24;
        default:
            return 24;
    }
};

const LoadingOverlayContainer = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
`;

const LoadingOverlay = ({isLoading}: {isLoading: ButtonBaseProps['isLoading']}) => {
    return <LoadingOverlayContainer>{isLoading ? <CircularProgress size={24} /> : null}</LoadingOverlayContainer>;
};

/**
 * MUI Theming
 */
export const ButtonThemeConfiguration: Components<Theme>['MuiButton'] = {
    defaultProps: {
        variant: 'primary',
        size: 'regular',
    },
    styleOverrides: {
        // The styles here apply to all button instances
        root: ({theme}) => {
            const palette = theme.modernFamlyTheme.colorPalette;
            return {
                textTransform: 'none',
                boxSizing: 'border-box',
                borderRadius: '8px',
                fontWeight: '400',
                lineHeight: '22px',
                fontVariantLigatures: 'no-contextual',
                whiteSpace: 'nowrap',

                '&:disabled': {
                    backgroundColor: `${palette.n75}`,
                    color: `${adjustAlpha(0.6, palette.n300)}`,
                },

                [`.${buttonClasses.startIcon}`]: {
                    marginRight: theme.modernFamlyTheme.spacing(1),
                },

                [`.${circularProgressClasses.root}`]: {
                    color: theme.modernFamlyTheme.colorPalette.n300,
                },
            };
        },
    },
    variants: [
        /**
         * COLORS
         */
        {
            props: {variant: 'primary'},
            style: ({theme}) => {
                const palette = theme.modernFamlyTheme.colorPalette;

                return {
                    backgroundColor: palette.p400,
                    color: palette.n0,

                    '&:hover': {
                        backgroundColor: palette.p300,
                    },

                    '&:active': {
                        backgroundColor: palette.p500,
                    },

                    [`.${circularProgressClasses.root}`]: {
                        color: palette.p400,
                    },
                };
            },
        },
        {
            props: {variant: 'secondary'},
            style: ({theme}) => {
                const palette = theme.modernFamlyTheme.colorPalette;

                return {
                    backgroundColor: palette.p100,
                    color: palette.p500,

                    '&:hover,&:active': {
                        backgroundColor: palette.p200,
                    },

                    [`.${circularProgressClasses.root}`]: {
                        color: palette.p500,
                    },
                };
            },
        },
        {
            props: {variant: 'tertiary'},
            style: ({theme}) => {
                const palette = theme.modernFamlyTheme.colorPalette;

                return {
                    border: `1px solid ${palette.n200}`,
                    backgroundColor: palette.n0,
                    color: palette.n400,

                    '&:hover,&:active': {
                        borderColor: palette.n200,
                        backgroundColor: palette.n75,
                    },

                    [`.${circularProgressClasses.root}`]: {
                        color: palette.n400,
                    },
                };
            },
        },
        {
            props: {variant: 'critical'},
            style: ({theme}) => {
                const palette = theme.modernFamlyTheme.colorPalette;
                return {
                    backgroundColor: palette.r200,
                    color: palette.r500,

                    '&:hover,&:active': {
                        backgroundColor: palette.r300,
                        color: palette.n0,
                    },

                    [`.${circularProgressClasses.root}`]: {
                        color: palette.r500,
                    },
                };
            },
        },
        {
            props: {variant: 'transparent'},
            style: ({theme}) => {
                const palette = theme.modernFamlyTheme.colorPalette;

                return {
                    backgroundColor: 'transparent',
                    color: palette.n400,

                    '&:hover': {
                        backgroundColor: palette.n75,
                    },

                    '&:active': {
                        backgroundColor: palette.n100,
                    },

                    [`.${circularProgressClasses.root}`]: {
                        color: palette.n400,
                    },
                };
            },
        },
        {
            props: {variant: 'critical-transparent'},
            style: ({theme}) => {
                const palette = theme.modernFamlyTheme.colorPalette;

                return {
                    backgroundColor: 'transparent',
                    color: palette.r400,

                    '&:hover': {
                        backgroundColor: palette.r300,
                        color: palette.n0,
                    },

                    '&:active': {
                        backgroundColor: palette.r400,
                        color: palette.n0,
                    },

                    [`.${circularProgressClasses.root}`]: {
                        color: palette.r400,
                    },
                };
            },
        },
        {
            props: {variant: 'upsell'},
            style: ({theme}) => {
                const palette = theme.modernFamlyTheme.colorPalette;

                return {
                    backgroundColor: palette.o400,
                    color: palette.n0,

                    '&:hover': {
                        backgroundColor: palette.o300,
                        color: palette.n0,
                    },

                    '&:active': {
                        backgroundColor: palette.o500,
                        color: palette.n0,
                    },

                    [`.${circularProgressClasses.root}`]: {
                        color: palette.o400,
                    },
                };
            },
        },

        /**
         * SIZES
         */
        {
            props: {size: 'regular'},
            style: ({theme}) => ({
                fontSize: theme.typography.body.fontSize,
                padding: theme.modernFamlyTheme.spacing(0, 4),
                height: '48px',
            }),
        },
        {
            props: {size: 'compact'},
            style: ({theme}) => ({
                fontSize: theme.typography['body-small'].fontSize,
                padding: theme.modernFamlyTheme.spacing(0, 3),
                height: '36px',
            }),
        },
    ],
};

/**
 * Module agmentation
 */
declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        outlined: false;
        contained: false;
        text: false;

        primary: true;
        secondary: true;
        tertiary: true;
        transparent: true;
        critical: true;
        'critical-transparent': true;
        upsell: true;
    }

    export interface ButtonPropsSizeOverrides {
        small: false;
        medium: false;
        large: false;

        regular: true;
        compact: true;
    }
}
