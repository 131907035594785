import React, {useMemo} from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import styled from 'styled-components';
import {StandaloneCheckbox} from 'modern-famly';
import {EmptyState} from 'modern-famly';
import {useDispatch} from 'react-redux';

import {Text} from '@famly/mf_data-display_text';
import {Stack} from '@famly/mf_layout_stack';
import {Box, createBox} from '@famly/mf_layout_box';
import {useBreakpoints} from '@famly/mf_util_breakpoints';
import ChildrenEntity from 'signin-app/entities/children';
import translate from 'signin-app/helpers/translate';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import PersonButton from 'signin-app/components/person-button';
import Header from 'signin-app/components/header';
import * as PrivateRouteSelectors from 'signin-app/components/private-route/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import SignedInChildrenEmptyState from 'signin-app/resources/img/signed-in-children.svg';
import {StyledPageContainer} from 'signin-app/components/containers';
import {GroupGrid} from 'signin-app/components/group-grid';

import {toggleWhoPicksUp} from './actions';
import {showSignedIn} from './selectors';
import {useElementDimensions, useGetPages} from './hooks';
import {SwipeableView} from './swipe-view';

const StyledEmptyStateContainer = styled(
    createBox({
        margin: '0 auto',
    }),
)`
    p,
    h6 {
        color: ${props => props.theme.mf.colorPalette.n400};
    }
`;

const ShowOnlySignedInChildrenToggle = () => {
    const shouldShowPickupTimes = useTypedSelector(showSignedIn);
    const dispatch = useDispatch();

    const handleClick = React.useCallback(() => {
        dispatch(toggleWhoPicksUp);
    }, [dispatch]);

    return (
        <Stack
            onClick={handleClick}
            backgroundColor="n75"
            borderRadius="12px"
            direction="row"
            alignItems="center"
            alignSelf="flex-end"
            cursor="pointer"
            pl={4}
            pr={2}
            py={1}
        >
            <Text variant="body" color="n400">
                {translate('showPickupTimes')}
            </Text>
            <StandaloneCheckbox size="compact" checked={shouldShowPickupTimes} />
        </Stack>
    );
};

type GroupRouteProps = RouteComponentProps<{groupId: string}>;

export const Group: React.FC<GroupRouteProps> = props => {
    const childrenList = useTypedSelector(state =>
        ChildrenEntity.selectors.getByGroupedId(state, {referenceId: props.match.params.groupId}),
    );
    const {isTabletPortraitAndLarger} = useBreakpoints();
    const groupName = useTypedSelector(state => GroupsSelectors.getGroupName(state, {id: props.match.params.groupId}));
    const isFetching = useTypedSelector(PrivateRouteSelectors.isPrefetching);
    const shouldShowSignedIn = useTypedSelector(state => showSignedIn(state));
    const checkedInChildren = childrenList.filter(child => child.checkedIn);
    const childrenToRender = shouldShowSignedIn ? checkedInChildren : childrenList;
    const isEmptyState = useMemo(
        () => checkedInChildren.size === 0 && shouldShowSignedIn,
        [checkedInChildren, shouldShowSignedIn],
    );

    const {ref: swipeAreaRef, dimensions} = useElementDimensions();

    const {pages} = useGetPages({
        items: childrenToRender,
        width: dimensions.width,
        height: dimensions.height,
    });

    if (isFetching) {
        return <Spinner centered margined />;
    }

    return (
        <StyledPageContainer>
            <Header
                centerElement={
                    <Text flex={1} variant="h4" color="n400" textAlign={{base: 'start', tabletPortrait: 'center'}}>
                        {groupName}
                    </Text>
                }
                // On mobile we show the toggle in the page contents
                rightElement={isTabletPortraitAndLarger && <ShowOnlySignedInChildrenToggle />}
            />
            <Stack flexDirection="column" mt={6} gap={8} height="100%">
                {!isTabletPortraitAndLarger && (
                    <Stack mb={3} justifyContent="end">
                        <ShowOnlySignedInChildrenToggle />
                    </Stack>
                )}
                {!isEmptyState ? (
                    <Box ref={swipeAreaRef} height="100%">
                        {/* key={Date.now()} to force a re-render when the pages change */}
                        <SwipeableView key={Date.now()}>
                            {pages.map(({items, pageId}) => (
                                <GroupGrid key={pageId}>
                                    {items.map(child => {
                                        const childLink = child.checkedIn
                                            ? `/child/${child.id}/checkout`
                                            : `/child/${child.id}/checkin`;
                                        return (
                                            <PersonButton
                                                key={child.id}
                                                linkTo={childLink}
                                                person={child}
                                                hide={shouldShowSignedIn && !child.checkedIn ? true : undefined}
                                            />
                                        );
                                    })}
                                </GroupGrid>
                            ))}
                        </SwipeableView>
                    </Box>
                ) : (
                    <Stack flexWrap="wrap" gap={4}>
                        <StyledEmptyStateContainer>
                            <EmptyState
                                fullWidth
                                imageUrl={SignedInChildrenEmptyState}
                                heading={translate('signedinChildrenEmptyStateHeading')}
                                body={translate('signedinChildrenEmptyStateBody')}
                            />
                        </StyledEmptyStateContainer>
                    </Stack>
                )}
            </Stack>
        </StyledPageContainer>
    );
};
