import React from 'react';
import styled from 'styled-components';

import {Text} from '@famly/mf_data-display_text';
import {createStack} from '@famly/mf_layout_stack';
import {useBreakpoints} from '@famly/mf_util_breakpoints';
import {PersonImage, type ImageSize, IMAGE_PROPS} from 'signin-app/components/person-image';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

export const StyledTextContainer = styled(createStack({}))`
    * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const StyledStack = styled(
    createStack({
        alignItems: 'center',
        cursor: 'pointer',
        overflowX: 'hidden',
        direction: 'column',
    }),
)<{
    selected?: boolean;
    customBorderColor?: string;
    customBackgroundColor?: string;
}>`
    border: 2px solid ${props => (props.selected ? props.customBorderColor : 'transparent')};
    background-color: ${props => (props.selected ? props.customBackgroundColor : 'transparent')};
`;

export const SelectablePersonButton: React.FC<{
    image: string | undefined;
    initials: string;
    name: string;
    selected?: boolean;
    onClick?: VoidFunction;
    dataE2eClass?: string;
    size?: ImageSize;
}> = ({image, initials, name, selected, onClick, dataE2eClass, size}) => {
    const {isTabletPortraitAndLarger} = useBreakpoints();
    const p300 = useCustomWhitelabelColor('p300');
    const p200 = useCustomWhitelabelColor('p200');

    // This makes sure the text doesn't go out of the Stack container,
    // but allows it to be fullwidth despite the padding on the main container
    const maxTextWidth = React.useMemo(() => {
        if (size) {
            const width = parseInt(IMAGE_PROPS[size].size.replace('px', ''), 10);
            return isTabletPortraitAndLarger ? `${width + 24 * 2 - 4}px` : `${width + 12 * 2 - 4}px`;
        }
        return '100%';
    }, [size, isTabletPortraitAndLarger]);

    return (
        <StyledStack
            px={{base: 3, tabletPortrait: 6}}
            py={{base: 2, tabletPortrait: 3}}
            borderRadius={{base: 3, tabletPortrait: 4}}
            maxWidth={size ? IMAGE_PROPS[size].size : '100%'}
            onClick={onClick}
            selected={selected}
            customBorderColor={p300}
            customBackgroundColor={p200}
            data-e2e-class={dataE2eClass}
        >
            <PersonImage image={image} initials={initials} size={size} />
            <StyledTextContainer>
                <Text
                    variant={size === 'sm' || size === 'md' ? 'h6' : 'h5'}
                    color="n400"
                    emphasized
                    mt={{base: 0.5, tabletPortrait: 1.5}}
                    maxWidth={maxTextWidth}
                >
                    {name}
                </Text>
            </StyledTextContainer>
        </StyledStack>
    );
};
