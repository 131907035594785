import {type Theme as MuiTheme} from '@mui/material/styles';
import {type AllSystemCSSProperties, type SxProps} from '@mui/system/styleFunctionSx';

import * as ResponsiveValue from './responsive-value';

const alignmentRelatedSystemProps = [
    'm',
    'margin',
    'mb',
    'marginBottom',
    'ml',
    'marginLeft',
    'mr',
    'marginRight',
    'mt',
    'marginTop',
    'mx',
    'marginX',
    'my',
    'marginY',
    'bottom',
    'left',
    'position',
    'right',
    'top',
    'alignSelf',
    'flex',
    'flexGrow',
    'flexShrink',
    'flexBasis',
] as const;

/**
 * System props that we want to support in Modern Famly.
 *
 * This list can be expanded over time.
 */
const supportedSystemProps = [
    'cursor',
    'displayPrint',
    'display',
    'alignContent',
    'alignItems',
    'flexDirection',
    'flexWrap',
    'justifyContent',
    'justifyItems',
    'order',
    'gap',
    'columnGap',
    'rowGap',
    'gridColumn',
    'gridRow',
    'gridAutoFlow',
    'gridAutoColumns',
    'gridAutoRows',
    'gridTemplateColumns',
    'gridTemplateRows',
    'gridTemplateAreas',
    'gridArea',
    'height',
    'maxHeight',
    'maxWidth',
    'minHeight',
    'minWidth',
    'width',
    'boxSizing',
    'overflow',
    'overflowX',
    'overflowY',
    'p',
    'padding',
    'pb',
    'paddingBottom',
    'pl',
    'paddingLeft',
    'pr',
    'paddingRight',
    'pt',
    'paddingTop',
    'px',
    'paddingX',
    'py',
    'paddingY',
    'pointerEvents',
    'textAlign',
    'textDecoration',
    'textDecorationLine',
    'textTransform',
    'textUnderlineOffset',
    'zIndex',
    'borderStyle',
    'borderTopStyle',
    'borderRightStyle',
    'borderBottomStyle',
    'borderLeftStyle',
    'borderWidth',
    'borderTopWidth',
    'borderRightWidth',
    'borderBottomWidth',
    'borderLeftWidth',
    'borderRadius',
    'whiteSpace',
    'opacity',
    ...alignmentRelatedSystemProps,
] as const;

export type AlignmentRelatedSystemProps = typeof alignmentRelatedSystemProps[number];

/**
 * A string literal union of all the supported system props
 */
type SupportedSystemProps = typeof supportedSystemProps[number] | AlignmentRelatedSystemProps;

/**
 * Props that don't satisfy MUI's system props, but that we want to expose on our "layout"
 * components.
 */
type InternalSystemProps = {
    color?: ColorKey;
    backgroundColor?: ColorKey;
    elevation?: ElevationOption;

    /**
     * Adds a 1px solid border in the specified color
     */
    border?: ColorKey;

    /**
     * Adds a 1px solid top border in the specified color
     */
    borderTop?: ColorKey;

    /**
     * Adds a 1px solid right border in the specified color
     */
    borderRight?: ColorKey;

    /**
     * Adds a 1px solid bottom border in the specified color
     */
    borderBottom?: ColorKey;

    /**
     * Adds a 1px solid left border in the specified color
     */
    borderLeft?: ColorKey;

    /**
     * Sets the border color
     */
    borderColor?: ColorKey;

    /**
     * Sets the top border color
     */
    borderTopColor?: ColorKey;

    /**
     * Sets the right border color
     */
    borderRightColor?: ColorKey;

    /**
     * Sets the bottom border color
     */
    borderBottomColor?: ColorKey;

    /**
     * Sets the left border color
     */
    borderLeftColor?: ColorKey;
};

/**
 * System props that we support from MUI.
 */
type MuiSystemProps = {
    [K in SupportedSystemProps]?: Exclude<AllSystemCSSProperties[K], Array<any>>;
};

type MuiAlignmentRelatedSystemProps = {
    [K in AlignmentRelatedSystemProps]?: Exclude<AllSystemCSSProperties[K], Array<any>>;
};

type NonResponsiveSystemProps = InternalSystemProps & MuiSystemProps;

/**
 * A combination of all the system props supported, both internal and from MUI. These are all responsive.
 */
export type SystemProps = {
    [PropName in keyof NonResponsiveSystemProps]: ResponsiveValue.ResponsiveValue<NonResponsiveSystemProps[PropName]>;
};

export type AlignmentProps = {
    [PropName in keyof MuiAlignmentRelatedSystemProps]: ResponsiveValue.ResponsiveValue<
        MuiAlignmentRelatedSystemProps[PropName]
    >;
};

export type WithSystemProps<T> = T & SystemProps;

/**
 * Extracts supported system props from an object
 *
 * @param props The props object
 *
 * @returns An `sx` object that can be passed on to a MUI component and a `nonSystemProps` object containing the non-system props
 */
export const extractSystemStyles = <T extends Record<string, any>>(props: T) => {
    const _props = {...props};

    /**
     * First, we extract the internal system props manually.
     */
    const {
        color,
        backgroundColor,
        elevation,
        border,
        borderTop,
        borderRight,
        borderBottom,
        borderLeft,
        borderColor,
        borderTopColor,
        borderRightColor,
        borderBottomColor,
        borderLeftColor,
        ...rest
    } = _props;

    /**
     * Here we split the non-internal props into supported MUI system props and non-system props.
     */
    const nonSystemProps: Record<string, any> = {};
    const muiSystemProps: {
        [Key in SupportedSystemProps]?: ResponsiveValue.ResponsiveValue<AllSystemCSSProperties[Key]>;
    } = {};

    Object.keys(rest).forEach(propKey => {
        if (!isSupportedSystemProp(propKey)) {
            nonSystemProps[propKey] = rest[propKey];
            return;
        }

        if (rest[propKey] === null || rest[propKey] === undefined) {
            return;
        }

        (muiSystemProps[propKey] as any) = rest[propKey];
    });

    /**
     * We build the `sx` function from the extracted internal system props and supported MUI system props
     */
    const sx: SxProps<MuiTheme> = (theme: MuiTheme) => ({
        backgroundColor: ResponsiveValue.map(backgroundColor, getColor(theme)),
        color: ResponsiveValue.map(color, getColor(theme)),
        boxShadow: ResponsiveValue.map(elevation, getElevation(theme)),
        border: ResponsiveValue.map(border, colorKey => {
            return `1px solid ${getColor(theme)(colorKey)}`;
        }),
        borderTop: ResponsiveValue.map(borderTop, colorKey => {
            return `1px solid ${getColor(theme)(colorKey)}`;
        }),
        borderRight: ResponsiveValue.map(borderRight, colorKey => {
            return `1px solid ${getColor(theme)(colorKey)}`;
        }),
        borderBottom: ResponsiveValue.map(borderBottom, colorKey => {
            return `1px solid ${getColor(theme)(colorKey)}`;
        }),
        borderLeft: ResponsiveValue.map(borderLeft, colorKey => {
            return `1px solid ${getColor(theme)(colorKey)}`;
        }),
        borderColor: ResponsiveValue.map(borderColor, getColor(theme)),
        borderTopColor: ResponsiveValue.map(borderTopColor, getColor(theme)),
        borderRightColor: ResponsiveValue.map(borderRightColor, getColor(theme)),
        borderBottomColor: ResponsiveValue.map(borderBottomColor, getColor(theme)),
        borderLeftColor: ResponsiveValue.map(borderLeftColor, getColor(theme)),

        // We take advantage of the fact that MUI's system props are directly translatable to
        // the `sx` syntax.
        ...muiSystemProps,
    });

    /**
     * Lastly we return the `sx` function and the non system props.
     */
    return {sx, nonSystemProps};
};

const isSupportedSystemProp = (candidate: any): candidate is SupportedSystemProps => {
    return supportedSystemProps.includes(candidate);
};

const getColor = (theme: MuiTheme) => (color: ColorKey) => {
    return theme.modernFamlyTheme.colorPalette[color];
};

const getElevation = (theme: MuiTheme) => (elevation: ElevationOption) => {
    return theme.modernFamlyTheme.elevation[elevation];
};

export const handlePassthroughSx = (sx: SxProps<MuiTheme> | undefined) => {
    return Array.isArray(sx) ? sx : [sx];
};
