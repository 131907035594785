import type {Components, Theme} from '@mui/material/styles';
import {adjustAlpha} from '@famly/mf_colors';

const trackColor = (theme: Theme, checked: boolean | undefined, disabled: boolean | undefined) => {
    if (!checked) {
        return theme.modernFamlyTheme.colorPalette.n100;
    }

    if (disabled) {
        return theme.modernFamlyTheme.colorPalette.n200;
    }

    return theme.modernFamlyTheme.colorPalette.p400;
};

const thumbColor = (theme: Theme, checked: boolean | undefined, disabled: boolean | undefined) => {
    if (checked) {
        return theme.modernFamlyTheme.colorPalette.n0;
    }

    if (disabled) {
        return theme.modernFamlyTheme.colorPalette.n200;
    }

    return theme.modernFamlyTheme.colorPalette.n300;
};

export const SwitchThemeConfiguration: Components<Theme>['MuiSwitch'] = {
    styleOverrides: {
        root: ({theme, ownerState: {checked, disabled}}) => ({
            padding: '8px',

            // Increases the size of the "moving" part of the switch on mousedown
            'input[type="checkbox"]:active + .MuiSwitch-thumb:not(.MuiSwitch-disabled)': !disabled
                ? {
                      width: 20,
                      height: 20,
                      margin: 0,
                      padding: 2,
                  }
                : undefined,

            // Changes color of the ripple effect
            '& .MuiTouchRipple-root': {
                color: theme.modernFamlyTheme.colorPalette[checked ? 'p300' : 'n300'],
            },
        }),

        switchBase: ({theme}) => ({
            ':hover': {
                backgroundColor: adjustAlpha(0.1, theme.modernFamlyTheme.colorPalette.n300),
            },
            '&.Mui-checked:hover': {
                backgroundColor: adjustAlpha(0.1, theme.modernFamlyTheme.colorPalette.p300),
            },
        }),

        track: ({theme, ownerState: {checked, disabled}}) => ({
            backgroundColor: `${trackColor(theme, checked, disabled)} !important`,
            opacity: `1 !important`,
            borderRadius: 22 / 2,
            border: checked ? undefined : `2px solid ${theme.modernFamlyTheme.colorPalette.n200}`,
            boxSizing: 'border-box',
        }),

        // The "moving" part of the switch
        thumb: ({theme, ownerState: {checked, disabled}}) => ({
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
            background: thumbColor(theme, checked, disabled),

            // Sets background image (the checkmark or the "dash")
            backgroundImage: checked
                ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                      trackColor(theme, checked, disabled),
                  )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
                : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                      theme.modernFamlyTheme.colorPalette.n50,
                  )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            boxSizing: 'border-box',
        }),
    },
};
