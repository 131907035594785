declare const cordova: any;
import React from 'react';
import styled, {css} from 'styled-components';

import PlatformHelper from 'web-app/helpers/platform-helper';
import {Base, type BaseProps} from 'web-app/react/components/layout/layout';
import {addHover} from 'web-app/styleguide/utils';

import {useExternalLinkWarningDialog} from './use-external-link-warning-dialog';
import {openWindow, isLinkExternal} from './helpers';

const openLinkInWindow = (href: string | undefined) => {
    openWindow(href, '_system', 'location=no');
};

const _blank = '_blank';
const noopener = 'noopener noreferrer';

export interface LinkProps extends BaseProps {
    id?: string;
    href?: string;
    rel?: string;

    // Auto detect whether or not href is external or internal and sets target to correct value
    autoAdjustHref?: boolean;

    // Makes sure that file permissions are correct on android devices before opening a file link
    isFileLink?: boolean;
    onClick?: ((event: any) => boolean | void) | ((event: any) => Promise<boolean | void>);
    className?: string;
    inheritColor?: boolean;
    target?: '_self' | '_blank';

    wordBreak?: CSSStyleDeclaration['wordBreak'];
    underline?: boolean;
    showExternalLinkWarning?: boolean;
}

const StyledLink = styled(({...rest}) => <Base as="a" {...rest} />)<LinkProps>`
    color: ${props => props.theme.analogue2};

    ${(props: LinkProps) =>
        props.inheritColor
            ? css`
                  color: inherit;
              `
            : ''}

    /* Introduced to override 'pointer-events: none' in checkbox/radiobutton label */
    pointer-events: auto;

    ${(props: LinkProps) =>
        props.wordBreak
            ? css`
                  word-break: ${props.wordBreak};
              `
            : ''}

    ${props =>
        props.underline
            ? addHover(css`
                  &:hover {
                      text-decoration: underline;
                  }
              `)
            : ''}
`;

const Link: React.FC<React.PropsWithChildren<LinkProps>> = props => {
    const {isFileLink, autoAdjustHref, ...attributes} = props;

    const showExternalLinkWarningDialog = useExternalLinkWarningDialog();

    let href = props.href;
    let target = props.target;
    let rel = props.rel;

    if (autoAdjustHref) {
        // URLs beginning with /api are served from the api and should open in a new window (PDFs and similar)
        const isExternal = isLinkExternal(href);

        if (href && !href.startsWith('#')) {
            if (isExternal && !target) {
                target = _blank;
            } else if (!isExternal) {
                href = `#${href}`;
            }
        }
    }

    const targetIsBlank = target === _blank;
    if (!rel && targetIsBlank) {
        rel = noopener;
    }

    const isFileLinkAndAndroid = isFileLink && PlatformHelper.isAndroid();

    if ((PlatformHelper.isTouch() && !targetIsBlank) || PlatformHelper.isMobileApp() || isFileLinkAndAndroid) {
        attributes.onClick = async (e: any) => {
            if (props.onClick) {
                const clickValue = await props.onClick(e);

                if (clickValue === false) {
                    return;
                }
            }

            if (props.showExternalLinkWarning && href && isLinkExternal(href)) {
                const shouldNavigate = await showExternalLinkWarningDialog(href);

                if (!shouldNavigate) {
                    return;
                }
            }

            if (PlatformHelper.isIos() && targetIsBlank && href) {
                cordova.InAppBrowser.open(href, '_system', 'location=no');
            } else if (isFileLinkAndAndroid) {
                e.preventDefault();
                if (targetIsBlank) {
                    openLinkInWindow(href);
                } else if (href) {
                    window.location.hash = href;
                }
            } else if (targetIsBlank && href) {
                e.preventDefault();
                openLinkInWindow(href);
            } else if (href) {
                window.location.hash = href;
            }
        };
    }

    if (props.showExternalLinkWarning && href && isLinkExternal(href)) {
        attributes.onClick = async (e: any) => {
            if (props.onClick) {
                const clickValue = await props.onClick(e);

                if (clickValue === false) {
                    return;
                }
            }

            e.preventDefault();

            const shouldNavigate = await showExternalLinkWarningDialog(href);

            if (shouldNavigate) {
                window.open(href, target, rel);
            }
        };
    }

    return (
        <StyledLink {...attributes} href={href} target={target} rel={rel}>
            {props.children}
        </StyledLink>
    );
};

export default Link;

export const BlockLink = styled(Link)`
    display: block;
`;
