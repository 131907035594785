/* eslint-disable no-restricted-imports */
import React, {createContext, useContext, useState, type ReactNode} from 'react';
import {type ModalProps} from '@famly/mf_modals-dialogs_modal';
import {extractAriaProps, extractDataProps} from 'modern-famly/components/util';

import {toDefault} from 'web-app/util/to-default';

const Dialog = React.lazy(() => import('@famly/mf_modals-dialogs_dialog').then(toDefault('Dialog')));
const Modal = React.lazy(() => import('@famly/mf_modals-dialogs_modal').then(toDefault('Modal')));

type CommonItem = {
    /** Unique identifier for the modal/dialog */
    id: string;

    /** React element to render */
    element: React.ReactElement;
} & DataProps;

type ModalItem = CommonItem & {
    type: 'modal';

    /** size to be passed to the modal */
    size: ModalProps['size'];
};

type DialogItem = CommonItem & {
    type: 'dialog';
};

type ModalDialogItem = ModalItem | DialogItem;

type ModalDialogContextType = {
    /** Array of active modal/dialog components */
    modalDialogs: ModalDialogItem[];

    /** Function to display a new modal/dialog */
    showModalDialog: (modalDialog: ModalDialogItem) => void;

    /** Function to remove a modal/dialog by ID */
    hideModalDialog: (id: string) => void;
};

const ModalDialogContext = createContext<ModalDialogContextType | undefined>(undefined);

/**
 * Provider component that manages modal/dialog state
 *
 * @param children - React children nodes
 *
 * @example
 * <ModalDialogProvider>
 *   <App />
 * </ModalDialogProvider>
 */
export const ModalDialogProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const [modalDialogs, setModalDialogs] = useState<ModalDialogItem[]>([]);

    const showModalDialog = React.useCallback((modalDialog: ModalDialogItem) => {
        setModalDialogs(prev => [...prev, modalDialog]);
    }, []);

    const hideModalDialog = React.useCallback((id: string) => {
        setModalDialogs(prev => prev.filter(modalDialog => modalDialog.id !== id));
    }, []);

    return (
        <ModalDialogContext.Provider
            value={React.useMemo(
                () => ({modalDialogs, showModalDialog, hideModalDialog}),
                [hideModalDialog, modalDialogs, showModalDialog],
            )}
        >
            {children}
            {modalDialogs.map(modalDialog => (
                <React.Suspense key={modalDialog.id} fallback={null}>
                    {modalDialog.type === 'dialog' ? (
                        <Dialog open {...extractDataProps(modalDialog)} {...extractAriaProps(modalDialog)}>
                            {modalDialog.element}
                        </Dialog>
                    ) : (
                        <Modal
                            open
                            size={modalDialog.size}
                            {...extractDataProps(modalDialog)}
                            {...extractAriaProps(modalDialog)}
                        >
                            {modalDialog.element}
                        </Modal>
                    )}
                </React.Suspense>
            ))}
        </ModalDialogContext.Provider>
    );
};
/**
 * Hook to access modal dialog context
 *
 * @returns {ModalDialogContextType} Modal dialog context
 * @throws Error if used outside provider
 *
 * @example
 * const { showModalDialog, hideModalDialog } = useModalsDialogsContext()
 */
export const useModalsDialogsContext = (): ModalDialogContextType => {
    const context = useContext(ModalDialogContext);
    if (!context) {
        throw new Error('useModalsDialogsContext must be used within a ModalProvider');
    }
    return context;
};
