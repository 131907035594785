import {Subject, type Observable} from 'rxjs';
import i18next from 'i18next';
import {from} from 'rxjs/observable/from';

import {isBadNetworkError} from 'web-app/util/url';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {addErrorToErrorLog} from 'web-app/react/routes/error-log/helpers';

import {verifyRequestUUID} from './common';
import {RestClientNetworkError} from './rest-client-network-error';
import * as Constants from './constants';

interface RestClientError {
    responseData?: {
        errorCode?: number;
    };
}

export const ErrorSubject = new Subject<RestClientError>();

interface RequestHeaders {
    headers: {[x: string]: string};
    requestUUID: string;
}

export interface IAPIHelper {
    getRequestHeaders: (authenticated?: boolean) => RequestHeaders;
    getApiUrlByKey: (key: string) => string;
    setApiConfig: (apiKey: string) => void;
    apiUrl: string;
    baseUrl: string;
    apiKeys: string[];
}

interface SentryService {
    captureMessage: (message: string, data: object) => void;
}

export type OnErrorConfig = {
    checkConnection?: boolean;
    url?: string;
    error?: any;
};

type OnError = (message: string, onErrorConfig?: OnErrorConfig) => void;

interface RequestOptions {
    overrideApiKey?: string;
    customApiUrl?: string;
    suppressErrors?: boolean;
    [x: string]: any;
}

// eslint-disable-next-line no-restricted-syntax
enum HTTPMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

interface RequestData {
    [x: string]: any;
}

interface RequestConfig {
    endpoint: string;
    data?: RequestData;
    method: keyof typeof HTTPMethods;
    options?: RequestOptions;
    authenticated?: boolean;
}

interface FetchOptions {
    requestUUID: string;
    timeout: number;
    method: keyof typeof HTTPMethods;
    headers: Headers;
    body?: string;
    dataType: string;
    ignoredErrors?: number[];
    [x: string]: any;
}

interface FetchResponse {
    responseData: any;
}

type RequestObservable = (endpoint: string, data?: RequestData, options?: Partial<FetchOptions>) => Observable<any>;
type AuthenticatedDispatchRequestCreator = (requestConfig: RequestConfig) => Promise<any>;
type DispatchRequestCreator = (endpoint: string, data?: RequestData, options?: Partial<FetchOptions>) => Promise<any>;

export type FetchAugmenter<T> = (
    url: URL,
    fetchOptions: FetchOptions,
) => (
    augmentor: (
        url: URL,
        fetchOptions: FetchOptions,
        callbacks?: {onSuccess?: (response: T) => void; onError?: (e: Error) => void},
    ) => Promise<T>,
) => Promise<T>;

const noOpAugment: FetchAugmenter<any> = (url, fetchOptions) => fetcher => fetcher(url, fetchOptions);

const createDispatchRequest =
    (
        APIHelper: IAPIHelper,
        sentryService: SentryService,
        onError: OnError,
        ignoredErrorCodes: number[] = [],
        fetchAugment: FetchAugmenter<any> = noOpAugment,
    ): AuthenticatedDispatchRequestCreator =>
    ({endpoint, data, method, authenticated, options}) => {
        const {headers: requestHeaders, requestUUID} = APIHelper.getRequestHeaders(authenticated);

        const {
            overrideApiKey,
            customApiUrl,
            suppressErrors,
            ignoredErrors = [],
            ...otherOptions
        } = options || ({} as RequestOptions);

        const apiUrl = customApiUrl || (overrideApiKey ? APIHelper.getApiUrlByKey(overrideApiKey) : APIHelper.apiUrl);
        const url = `${apiUrl}/${endpoint}`;

        const isGet = method === 'GET';

        const headers = new Headers(requestHeaders);
        headers.append('Content-Type', 'application/json');

        const logErrorToSentry = (
            url: string,
            error: any,
            queryData: object | undefined,
            settings: FetchOptions,
            sentryService: SentryService,
        ) => {
            if ((error?.responseData?.log ?? true) === true) {
                const cleanedUrl = cleanUrlForLogging(url);
                const {responseData} = error;
                const errorCodeText = hasValue(responseData?.errorCode) ? ` - ${responseData.errorCode}` : '';
                const fingerprint = hasValue(responseData?.errorCode)
                    ? [cleanedUrl, responseData.errorCode]
                    : [cleanedUrl];
                sentryService.captureMessage(`${error.statusText} - ${cleanedUrl}${errorCodeText}`, {
                    fingerprint,
                    extra: {
                        requestUUID: settings.requestUUID,
                        type: settings.method,
                        url,
                        path: cleanedUrl,
                        data: queryData,
                        status: error.status,
                        error: error.statusText,
                        response: responseData,
                    },
                });
            }
        };

        const onRequestUUIDError = (requestUUID: string, responseUUID: string, identifier: string) =>
            sentryService.captureMessage('Famly has detected what seems to be an unsafe caching layer', {
                fingerprint: ['1542636641'],
                extra: {
                    identifier,
                    responseUUID,
                    requestUUID,
                },
                level: 'warning',
            });

        const urlObject: URL = new URL(url, APIHelper.baseUrl);

        if (isGet && data) {
            Object.keys(data).forEach(
                key => key !== 'uuid' && data[key] !== null && urlObject.searchParams.append(key, data[key]),
            );
        }

        const body = isGet ? undefined : JSON.stringify(data);

        const settings = {
            requestUUID,
            timeout: 60 * 1000,
            ...otherOptions,
            method,
            headers,
            body,
            dataType: 'json',
        };

        return fetchAugment(
            urlObject,
            settings,
        )((url, settings, augmentCallbacks) => {
            const run = (attemptNumber: number) => {
                return fetchWithTimeout(url, settings)
                    .then(response => {
                        // There is no reason to verify requestUUID headers if the response failed
                        if (response.ok) {
                            const responseUUID = response.headers.get(Constants.Headers.XFamlyRequestUuid);
                            verifyRequestUUID(requestUUID, responseUUID, url, onRequestUUIDError);
                        }

                        const baseResponse = {
                            ok: response.ok,
                            status: response.status,
                            statusText: response.statusText,
                        };

                        // Handle "204 - No Content" status.
                        if (response.status === 204) {
                            return baseResponse;
                        }

                        // Convert the response from stream to readable json
                        return response.json().then((responseData: Response) => ({
                            ...baseResponse,
                            responseData,
                        }));
                    })
                    .then(response => {
                        // Since http error responses (status 400 and above) doesn't go to the catch of a promise we force it here
                        if (!response.ok) {
                            throw response;
                        }
                        augmentCallbacks?.onSuccess?.(response);
                        return response.responseData;
                    })
                    .catch(error => {
                        // In an attempt to bypass the issue we're seeing requests to core randomly failing,
                        // we automatically retry failed requests up to three times when running Cypress tests.
                        // See: https://famlyapp.atlassian.net/browse/TAIL-113 for more info
                        if (
                            window.Cypress &&
                            (error.status === 500 || error instanceof SyntaxError) &&
                            attemptNumber < 3
                        ) {
                            console.warn(`Request to "${url}" failed with 500 in Cypress test - retrying.`);
                            return new Promise(resolve => setTimeout(() => resolve(run(attemptNumber + 1)), 2000));
                        }

                        console.error('Error when calling URL:', urlObject);
                        console.error('The error was:', error);

                        // Put the error in the global error stream so you can do global error handling
                        ErrorSubject.next(error);

                        augmentCallbacks?.onError?.(error);

                        // The error code is set in responses from core & famlyapi
                        const errorCode = error.errorCode || (error.responseData && error.responseData.errorCode);

                        if (!(suppressErrors || ignoredErrors.includes(errorCode))) {
                            globalEventOnError(error, onError, urlObject.href);
                        }

                        // Fetch returns an error object if there is a network error, otherwise the response from the http error
                        if (error instanceof Error) {
                            addErrorToErrorLog({
                                type: 'network-error',
                                name: error.name,
                                message: error.message,
                                restRoute: urlObject.pathname,
                            });

                            throw error;
                        } else {
                            const responseData = error.responseData;
                            addErrorToErrorLog({
                                type: 'rest-error',
                                code: `${errorCode}`,
                                name: 'REST error',
                                message: responseData?.error,
                                statusCode: responseData?.statusCode,
                                restRoute: urlObject.pathname,
                            });

                            if (!ignoredErrorCodes.includes(errorCode)) {
                                logErrorToSentry(urlObject.href, error, data, settings, sentryService);
                            }

                            const path = cleanUrlForLogging(urlObject.href);
                            const statusCode = error?.status ?? 'Unknown';

                            throw new RestClientNetworkError(
                                `Network error (${statusCode}) when calling ${path}: ${errorCode}`,
                                error?.responseData,
                            );
                        }
                    });
            };

            return run(1);
        });
    };

export const cleanUrlForLogging = (url: string) => {
    const [cleanedUrl] = url
        // We replace all UUIDs and ULIDs with a placeholder to get the same fingerprint for all errors
        .replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g, '{uuid}')
        .replace(/[0-7][0-9A-HJKMNP-TV-Z]{25}/, '{ulid}')
        // We also remove the domain and api prefix
        .replace(/https?:\/\/[^/]+(?:\/api)?/gi, '')
        .split('?');

    return cleanedUrl;
};

declare global {
    interface Window {
        /**
         * Cypress adds its own property during tests
         * Documented here: https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
         */
        Cypress?: any;
    }
}

const globalEventOnError = (error: Error | FetchResponse, onError: OnError, url?: string) => {
    if (error instanceof SyntaxError) {
        // If the backend respons with a non-JSON response, we show a better-looking error message.
        // Otherwise error message would be something like "Unexpected token < at position 0"
        onError(i18next.t('network.specialCaseErrors.nonJSONResponse'), {
            checkConnection: false,
            url,
            error,
        });
    } else if (error instanceof Error) {
        if (isBadNetworkError(error)) {
            onError(i18next.t('network.specialCaseErrors.badNetworkError'), {
                checkConnection: false,
                url,
                error,
            });
        } else {
            // Fetch returns an error object if there is a network error, otherwise the response from the http error
            onError(i18next.t('network.checkConnection'), {checkConnection: true, url, error});
        }
    } else {
        onError(error.responseData.error);
    }
};

const sanitizeGetData = (data?: RequestData) => {
    if (data) {
        return Object.keys(data).reduce<RequestData>((newObject, current) => {
            const currentValue = data[current];
            if (currentValue !== '' && currentValue !== undefined) {
                newObject[current] = currentValue;
            }
            return newObject;
        }, {});
    }

    return {};
};

const fetchWithTimeout = (url: string | URL, allOptions: FetchOptions) => {
    const {timeout, ...options} = allOptions;

    const controller = new AbortController();
    const signal = controller.signal;

    const timer = setTimeout(() => {
        return controller.abort(new Error('Fetch request aborted due to timeout'));
    }, timeout);

    // So. Apparently finally is not part of the fetch spec since it is not part of the promise spec
    // To the get Edge to work with finally we need to wrap the fetch in a Promise that has the promise finally polyfill
    const promise = new Promise<any>((resolve, reject) =>
        fetch(url as string, {
            signal,
            credentials: 'same-origin',
            ...(options as RequestInit),
        }).then(resolve, reject),
    );

    return promise.finally(() => {
        return clearTimeout(timer);
    });
};

export const createRestClient = (
    APIHelper: IAPIHelper,
    onError: OnError,
    sentryService: SentryService,
    ignoredErrorCodes: number[] = [],
    fetchAugment?: FetchAugmenter<any>,
) => {
    const dispatchRequest = createDispatchRequest(APIHelper, sentryService, onError, ignoredErrorCodes, fetchAugment);

    const dispatchToAllAPIs = ({endpoint, data, method, options, authenticated}: RequestConfig) => {
        const {stopErrorCode, ...otherOptions} = options || ({} as RequestOptions);

        const dispatchToAPIs = (apiKeys: string[]): Promise<any> => {
            const [apiKey, ...extraKeys] = apiKeys;
            return dispatchRequest({
                endpoint,
                data,
                method,
                options: {
                    timeout: 15 * 1000,
                    ...otherOptions,
                    suppressErrors: true,
                    overrideApiKey: apiKey,
                },
                authenticated,
            })
                .then(data => {
                    APIHelper.setApiConfig(apiKey);
                    return data;
                })
                .catch(e => {
                    const errorCode = e.errorCode || (e.responseData && e.responseData.errorCode);
                    if (ignoredErrorCodes.includes(errorCode)) {
                        throw e;
                    }
                    if (stopErrorCode && e.errorCode === stopErrorCode && extraKeys[0]) {
                        if (!otherOptions.suppressErrors) {
                            onError(e.error);
                        }
                        throw e;
                    } else if (extraKeys[0]) {
                        return dispatchToAPIs(extraKeys);
                    } else if (!otherOptions.suppressErrors) {
                        if (e.statusCode === 0) {
                            onError(i18next.t('network.checkConnection'), {
                                checkConnection: true,
                                url: endpoint,
                                error: e,
                            });
                        } else if (e.error) {
                            onError(e.error);
                        }
                    }
                    throw e;
                });
        };

        return dispatchToAPIs(APIHelper.apiKeys);
    };

    const dispatchAuthenticatedRequest: AuthenticatedDispatchRequestCreator = ({endpoint, data, method, options}) => {
        return dispatchRequest({
            options,
            endpoint,
            data,
            method,
            authenticated: true,
        });
    };

    const dispatchAuthenticatedToAllAPIs: AuthenticatedDispatchRequestCreator = ({endpoint, data, method, options}) => {
        return dispatchToAllAPIs({
            endpoint,
            data,
            method,
            options,
            authenticated: true,
        });
    };

    const post: DispatchRequestCreator = (endpoint, data, options) => {
        return dispatchRequest({
            options,
            endpoint,
            data,
            method: 'POST',
        });
    };

    const postAuthenticated: DispatchRequestCreator = (endpoint, data, options) => {
        return dispatchAuthenticatedRequest({
            options,
            endpoint,
            data,
            method: 'POST',
        });
    };

    const postObservable: RequestObservable = (endpoint, data, options) => {
        return from(post(endpoint, data, options));
    };

    const postAuthenticatedObservable: RequestObservable = (endpoint, data, options) => {
        return from(postAuthenticated(endpoint, data, options));
    };

    const postObservableToAllAPIs = (endpoint, data, options?: any) => {
        return from(postToAllAPIs(endpoint, data, options));
    };

    const postToAllAPIs: DispatchRequestCreator = (endpoint, data, options) => {
        return dispatchToAllAPIs({
            endpoint,
            data,
            options,
            method: 'POST',
        });
    };

    const postAuthenticatedToAllAPIs: DispatchRequestCreator = (endpoint, data, options) => {
        return dispatchAuthenticatedToAllAPIs({
            endpoint,
            data,
            options,
            method: 'POST',
        });
    };

    const putAuthenticated: DispatchRequestCreator = (endpoint, data, options) => {
        return dispatchAuthenticatedRequest({
            options,
            endpoint,
            data,
            method: 'PUT',
        });
    };

    const putAuthenticatedObservable: RequestObservable = (endpoint, data, options) => {
        return from(putAuthenticated(endpoint, data, options));
    };

    const deleteAuthenticated: DispatchRequestCreator = (endpoint, data, options) => {
        return dispatchAuthenticatedRequest({
            options,
            endpoint,
            data,
            method: 'DELETE',
        });
    };

    const deleteAuthenticatedObservable: RequestObservable = (endpoint, data, options) => {
        return from(deleteAuthenticated(endpoint, data, options));
    };

    const getRequest: DispatchRequestCreator = (endpoint, data, options) => {
        return dispatchRequest({
            options,
            endpoint,
            data: sanitizeGetData(data),
            method: 'GET',
        });
    };

    const getAuthenticated: DispatchRequestCreator = (endpoint: string, data?: object, options?: RequestOptions) => {
        return dispatchAuthenticatedRequest({
            options,
            endpoint,
            data: sanitizeGetData(data),
            method: 'GET',
        });
    };

    const getObservable: RequestObservable = (endpoint, data, options) => {
        return from(getRequest(endpoint, data, options));
    };

    const getAuthenticatedObservable: RequestObservable = (endpoint, data, options) => {
        return from(getAuthenticated(endpoint, data, options));
    };

    const dispatchAuthenticatedRequestObservable = ({endpoint, data, method, options}: RequestConfig) => {
        return from(
            dispatchAuthenticatedRequest({
                options,
                endpoint,
                data,
                method,
            }),
        );
    };

    return {
        post,
        postAuthenticated,
        postObservable,
        postAuthenticatedObservable,
        postToAllAPIs,
        postAuthenticatedToAllAPIs,
        postObservableToAllAPIs,
        putAuthenticated,
        putAuthenticatedObservable,
        deleteAuthenticated,
        deleteAuthenticatedObservable,
        getRequest,
        getAuthenticated,
        getObservable,
        getAuthenticatedObservable,
        dispatchAuthenticatedRequestObservable,
        ErrorSubject,
    };
};
