import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {Text} from '@famly/mf_data-display_text';
import {createStack, type StackProps} from '@famly/mf_layout_stack';
import Spinner from 'web-app/react/components/loading/spinner/spinner';

export const PageBodyContainer = styled(
    createStack({
        flexDirection: 'column',
        pb: '162px', // value from designs
    }),
)``;

export const BottomBarContainer = ({children}) => {
    return <StyledBottomBarContainer>{children}</StyledBottomBarContainer>;
};

export const StyledBottomBarContainer = styled(
    createStack({
        position: 'fixed',
        left: '0',
        right: '0',
        bottom: '0',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: '24px',
        gap: '16px',
        backgroundColor: 'n25',
    }),
)`
    z-index: 100;
`;

const StyledButton = styled(
    createStack({
        alignItems: 'center',
        justifyContent: 'center',
        height: '64px',
        borderRadius: '16px',
        cursor: 'pointer',
        overflow: 'hidden',
        flex: 1,
        borderColor: 'n200',
        borderWidth: '1px',
        borderStyle: 'solid',
    }),
)``;

const StyledConfirmButton = styled(StyledButton)<{
    disabled?: boolean;
    isOnlyButton?: boolean;
}>`
    border: none;
    color: ${props => (props.disabled ? props.theme.mf.colorPalette.n200 : props.theme.mf.colorPalette.n0)};
`;

const StyledLink = styled(Link)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export type ConfirmButtonTypes = 'signIn' | 'signOut';

interface ConfirmButtonProps extends BaseButtonProps {
    type: ConfirmButtonTypes;
    onClick?: VoidFunction;
    isLoading?: boolean;
    disabled?: boolean;
    isOnlyButton?: boolean;
}

interface BaseButtonProps {
    text: string;
    dataE2EId?: string;
}

interface ButtonProps extends BaseButtonProps {
    link: string;
    compact?: boolean;
}

interface OnClickButtonProps extends BaseButtonProps {
    borderColor?: StackProps['borderColor'];
    backgroundColor?: StackProps['backgroundColor'];
    color?: StackProps['color'];
    onClick?: VoidFunction;
    compact?: boolean;
}

export const ConfirmButton: React.FC<ConfirmButtonProps> = props => {
    const {text, type, onClick, isLoading, disabled, dataE2EId, isOnlyButton} = props;

    const handleOnClick = React.useCallback(() => {
        if (!disabled && onClick) {
            onClick();
        }
    }, [disabled, onClick]);

    const bgColour = React.useMemo(() => {
        if (disabled) {
            return 'n75';
        }
        if (type === 'signIn') {
            return 'g400';
        }
        return 'r400';
    }, [disabled, type]);

    return (
        <StyledConfirmButton
            onClick={handleOnClick}
            disabled={disabled}
            data-e2e-id={dataE2EId}
            isOnlyButton={isOnlyButton}
            backgroundColor={bgColour}
        >
            {isLoading ? (
                <Spinner color="white" centered />
            ) : (
                <Text variant="h5" textAlign="center" color={disabled ? 'n300' : 'n0'}>
                    {text}
                </Text>
            )}
        </StyledConfirmButton>
    );
};

const ButtonWithBackground = styled(StyledButton)`
    border: 1px solid ${props => props.theme.mf.colorPalette.n200};
`;

export const ActionButton: React.FC<ButtonProps> = props => {
    const {text, link, compact} = props;

    return (
        <ButtonWithBackground backgroundColor="n0">
            <StyledLink to={link}>
                <Text variant={compact ? 'body' : 'h5'} color="n400" textAlign="center">
                    {text}
                </Text>
            </StyledLink>
        </ButtonWithBackground>
    );
};

export const ActionOnClickButton = ({
    text,
    onClick,
    backgroundColor = 'n0',
    color = 'n400',
    borderColor = 'n200',
    compact,
    dataE2EId,
}: OnClickButtonProps) => {
    return (
        <StyledButton
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            onClick={onClick}
            data-e2e-id={dataE2EId}
        >
            <Text variant={compact ? 'body' : 'h5'} color={color} textAlign="center">
                {text}
            </Text>
        </StyledButton>
    );
};
