import {BBMontessoriTheme as BBMontessoriMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const BBMontessoriChromeColor = '#4E738A';
const BBMontessoriPrimary = BBMontessoriChromeColor;
const BBMontessoriActive = hexToRGBA(BBMontessoriChromeColor, 0.8);
const BBMontessoriBackgroundHover = hexToRGBA(BBMontessoriChromeColor, 0.04);

const BBMontessoriTheme = BaseTheme.merge({
    title: 'Montessori by Busy Bees',
    chromeColor: BBMontessoriChromeColor,
    primary: BBMontessoriPrimary,
    active: BBMontessoriActive,
    backgroundHover: BBMontessoriBackgroundHover,
    mf: BBMontessoriMFTheme,
});

export default BBMontessoriTheme;
