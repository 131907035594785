import React from 'react';
import {Icon} from 'modern-famly';
import styled from 'styled-components';

import {Text} from '@famly/mf_data-display_text';
import {Box} from '@famly/mf_layout_box';
import {Stack} from '@famly/mf_layout_stack';
import Tooltip from 'web-app/react/components/tooltip/tooltip';
import translate from 'signin-app/helpers/translate';

const StyledTooltip = styled(Tooltip)`
    max-width: max-content;
    opacity: 1;

    .rc-tooltip-inner {
        background-color: ${props => props.theme.mf.colorPalette.n25};
    }

    &&&.rc-tooltip-placement-top .rc-tooltip-arrow,
    &&&.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
    &&&.rc-tooltip-placement-topRight .rc-tooltip-arrow {
        border-top-color: ${props => props.theme.mf.colorPalette.n25};
    }

    &&&.rc-tooltip-placement-right .rc-tooltip-arrow,
    &&&.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
    &&&.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
        border-right-color: ${props => props.theme.mf.colorPalette.n25};
    }

    &&&.rc-tooltip-placement-left .rc-tooltip-arrow,
    &&&.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
    &&&.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
        border-left-color: ${props => props.theme.mf.colorPalette.n25};
    }

    &&&.rc-tooltip-placement-bottom .rc-tooltip-arrow,
    &&&.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
    &&&.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
        border-bottom-color: ${props => props.theme.mf.colorPalette.n25};
    }
`;

const Content = () => {
    return (
        <Box backgroundColor="n25" color="n400">
            <Text variant="body">
                <strong>{translate('passwordHintLine1')}</strong>
                <br />
                {translate('passwordHintLine2')}
            </Text>
        </Box>
    );
};

export const PasswordTooltip = () => {
    return (
        <StyledTooltip overlay={<Content />} trigger={['click']} showArrow placement="right">
            <Stack
                position="absolute"
                justifyContent="center"
                alignItems="center"
                right={0}
                top={0}
                width="36px"
                height="36px"
                cursor="pointer"
            >
                <Icon name="help" filled size={24} color="n0" />
            </Stack>
        </StyledTooltip>
    );
};
