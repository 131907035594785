import LocalStorageService from 'web-app/services/local-storage';
import {hasValue} from '@famly/stat_ts-utils_has-value';

export const DebugLocalStorageFlags = {
    FAMLYTICS: {
        flag: 'FAMLYTICS',
        title: 'Toggle Famlytics Debugging on/off',
        description:
            'Toggle this on, to see all Famlytics events printed to the console. Make sure the feature "ENABLE_ANALYTICS" is enabled as well.',
    },
    SHOW_UPCOMING_FEATURES_SETTING: {
        flag: 'SHOW_UPCOMING_FEATURES_SETTING',
        title: 'Toggle "Upcoming feature" settings section on/off',
        description: 'Toggle this on to show the "Upcoming features" tab on the settings page.',
    },
    ENABLE_INTERCOM_TEST_ENVIRONMENT: {
        flag: 'ENABLE_INTERCOM_TEST_ENVIRONMENT',
        title: 'Connect to Intercom test environment',
        description: 'Connect to Intercom test environment for debugging/dev purposes.',
    },
    SHOW_AUTO_LOGOUT_STATS: {
        flag: 'SHOW_AUTO_LOGOUT_STATS',
        title: 'Auto logout V2 status',
        description:
            'Toggle this to get an overlay that shows all details for auto logout. Auto logout feature and V2 needs to be enabled.',
    },
    EDIT_MODAL_PAYMENT_IMPORT: {
        flag: 'EDIT_MODAL_PAYMENT_IMPORT',
        title: 'Enables editing Ready & Incomplete payment rows in the Payments Import',
        description: 'Toggle this on to see the edit modal when clicking on Incomplete Payment Rows.',
    },
    ENABLE_IMAGICK_IMAGE_PROCESSING: {
        flag: 'ENABLE_IMAGICK_IMAGE_PROCESSING',
        title: 'Enable Imagick image processing',
        description: 'Toggle this on to use Imagick for image processing instead of GD (in core).',
    },
    ENABLE_XERO_PAYMENTS: {
        flag: 'ENABLE_XERO_PAYMENTS',
        title: 'Enables Xero payments functionality',
        description: 'Toggle this on to see the Xero payments article link',
    },
    TRACKING_VISUALIZATION: {
        flag: 'TRACKING_VISUALIZATION',
        title: 'Enable tracking visualization',
        description:
            'You can see tracking events for elements, see searchable live feed of events, etc. Make sure the feature "ENABLE_ANALYTICS" is enabled as well.',
    },
    SHOW_DEFAULT_FTE: {
        flag: 'SHOW_DEFAULT_FTE',
        title: 'Show default fte',
        description: 'Toggle this to show default fte.',
    },
    ENABLE_FORM_BUILDER_DEBUG: {
        flag: 'ENABLE_FORM_BUILDER_DEBUG',
        title: 'Enable form builder debug info',
        description: 'Toggle this on to access the form builder debug info',
    },
    FORM_BUILDER_DEBUG_INFO: {
        flag: 'FORM_BUILDER_DEBUG_INFO',
        title: 'Adds debug info to the form builder state (for e2e)',
        description: 'Toggle this to add debug info to the form builder state (for e2e)',
    },
    SHOW_FINANCE_DEBUGGING_BUTTON: {
        flag: 'SHOW_FINANCE_DEBUGGING_BUTTON',
        title: 'Show finance debugging button',
        description: 'Show finance debugging button to access Finance debugging page.',
    },
    SHOW_BULK_PLAN_DELETE: {
        flag: 'SHOW_BULK_PLAN_DELETE',
        title: 'Show Bulk Plan delete option',
        description: 'Toggle this to show the bulk delete latest plan in plans page',
    },
    TRACE_ALL_TRANSACTIONS: {
        flag: 'TRACE_ALL_TRANSACTIONS',
        title: '[Sentry] Trace all transactions',
        description: 'Turn this on to have all transactions traced in Sentry.',
    },
    SENTRY_ENABLE_REPLAY: {
        flag: 'ENABLE_REPLAY',
        title: '[Sentry] Enable Replay',
        description: 'Turn this on to enable Sentry replay.',
    },
    SENTRY_DIAGNOSTIC_MODE: {
        flag: 'SENTRY_DIAGNOSTIC_MODE',
        title: '[Sentry] Diagnostic mode',
        description:
            "Turn this on to enable Sentry diagnostic mode. This will bypass the filtering we've setup in sentry-setup.ts.",
    },
    ENABLE_SCS_ONBOARDING: {
        flag: 'ENABLE_SCS_ONBOARDING',
        title: 'Enables Sales-to-CS onboarding flow',
    },
    SHOW_BULK_BILLING_PROFILE_UPDATE: {
        flag: 'SHOW_BULK_BILLING_PROFILE_UPDATE',
        title: 'Show Bulk Billing Profile Update in the Plans page',
        description: 'Used to handle bulk billing profile update in the Plans page',
    },
    FAMLY_HOME_CATEGORY_ONBOARDING: {
        flag: 'FAMLY_HOME_CATEGORY_ONBOARDING',
        title: 'Show Category Onboarding widget in Famly Home',
        description: 'Used to display the work in progress widget that will be in Famly Home',
    },
    FINANCE_POWER_PAGE: {
        flag: 'FINANCE_POWER_PAGE',
        title: 'Use the new finance power page',
        description: 'Use the new finance power page instead of finance overview',
    },
    FINANCE_POWER_PAGE_ENABLE_NEXT_INVOICE_WIDGETS: {
        flag: 'FINANCE_POWER_PAGE_ENABLE_NEXT_INVOICE_WIDGETS',
        title: 'Enable next invoice widgets in the billing hub',
    },
    FINANCE_POWER_PAGE_USE_NEXT_INVOICE_WIDGETS_WITH_POLLING: {
        flag: 'FINANCE_POWER_PAGE_USE_NEXT_INVOICE_WIDGETS_WITH_POLLING',
        title: 'Use next invoice widgets with polling',
        description: 'Toggle this to use the new implementation of next invoice widgets with polling',
    },
    POWER_PAGE_NEEDS_YOUR_ATTENTION_WIDGET_EMPTY_STATE: {
        flag: 'POWER_PAGE_NEEDS_YOUR_ATTENTION_WIDGET_EMPTY_STATE',
        title: 'Enable "Needs your attention widget" empty state',
    },
    MOBILE_APP_PACKAGE_PURCHASE_MAGIC_LINK_REDIRECT: {
        flag: 'MOBILE_APP_PACKAGE_PURCHASE_MAGIC_LINK_REDIRECT',
        title: 'When trying to purchase a package on the mobile app, redirect the user to the browser with a magic link',
    },
    MEAL_PLANS_DND: {
        flag: 'MEAL_PLANS_DND',
        title: 'Enable dnd of food items in meal plans',
    },
    BULK_ADD_ATTENDANCE: {
        flag: 'BULK_ADD_ATTENDANCE',
        title: 'Show the bulk add attendance modal',
    },
    SHOW_STAT_FORM_DEBUG: {
        flag: 'SHOW_STAT_FORM_DEBUG',
        title: 'Enable stat form debug information',
    },
    SMS_NOTIFICATIONS: {
        flag: 'SMS_NOTIFICATIONS',
        title: 'Enable SMS notifications in Newsfeed and Messages',
    },
    SHOW_PURCHASES_NEW_DESIGN_ON_MOBILE: {
        flag: 'SHOW_PURCHASES_NEW_DESIGN_ON_MOBILE',
        title: 'Show new design for purchases on mobile and automatic pricing',
    },
    MF_LISTS_MANAGE_CHILDREN: {
        flag: 'MF_LISTS_MANAGE_CHILDREN',
        title: 'Enable manage children MF List implementation',
    },
    NEW_REPORT_CREATE_FLOW: {
        flag: 'NEW_REPORT_CREATE_FLOW',
        title: 'Enables the new "Create report" flow on the new reports overview and the new reporting overview page.',
    },
    PRINT_CHILD_PROFILE: {
        flag: 'PRINT_CHILD_PROFILE',
        title: 'Enable child profile printing',
    },
    PLAN_RULES: {
        flag: 'PLAN_RULES',
        title: 'Enable plan rules',
    },
    SIMPLIFIED_US_BILLING_PLAN: {
        flag: 'SIMPLIFIED_US_BILLING_PLAN',
        title: 'Enable simplified US billing plan',
    },
    CHILD_PROFILE_COLLECTION_PASSWORD: {
        flag: 'CHILD_PROFILE_COLLECTION_PASSWORD',
        title: 'Enable child collection passwords',
    },
    ENABLE_SAVING_EMPTY_BILLING_PLAN: {
        flag: 'ENABLE_SAVING_EMPTY_BILLING_PLAN',
        title: 'Enable saving of empty billing plans',
        description: 'Enable saving empty billing plans as a way to make breaks in billing.',
    },
    LIMITED_CONTACT_ROLE: {
        flag: 'LIMITED_CONTACT_ROLE',
        title: 'Enable Limited Contact Role toggle',
        description: 'Shows the Limited Contact Role toggle in the child profile contacts form.',
    },
    BILL_PAYER_ENABLE_LEGACY_CSV_REPORT: {
        flag: 'BILL_PAYER_ENABLE_LEGACY_CSV_REPORT',
        title: 'Enables the legacy PHP-based CSV report for bill payers',
        description:
            'While testing the new BillPayer report, it might be useful to have the old one available as well for testing. This enables the legacy report.',
    },
    ENABLE_STAFF_HOURS_V2: {
        flag: 'ENABLE_STAFF_HOURS_V2',
        title: 'Enables the new Staff Hours Approval page',
        description:
            'While developing the new staff hours approval page, it is useful to create a new staff hours page to not create any conflicts. This enables the new staff hours page.',
    },
    CHILL_BILL_EDIT_PLAN: {
        flag: 'CHILL_BILL_EDIT_PLAN',
        title: 'Enable editing of billing plans in Chill Bill',
        description: 'Enables the editing of billing plans in Chill Bill (aka Simplified US billing plan)',
    },
    CHILL_BILL_EDIT_PLAN_TESTING_INFORMATION: {
        flag: 'CHILL_BILL_EDIT_PLAN_TESTING_INFORMATION',
        title: 'Enable extra testing information in Chill Bill edit plan view.',
        description: 'Shows extra information in tool tips in the edit view of Chill Bill.',
    },
    ENABLE_AUDIO_FILE_UPLOAD: {
        flag: 'ENABLE_AUDIO_FILE_UPLOAD',
        title: 'Enable audio file upload',
        description: 'Enable audio file upload',
    },
} satisfies {
    [flagId: string]: {
        flag: string;
        title: string;
        description?: string;
    };
};

/**
 * I grew tired of having to import DebugLocalStorageFlags to check if a flag is enabled
 * so I added an overload that allows you to simply look up by the union of keys in DebugLocalStorageFlags
 */
interface CheckIfFlagIsToggled {
    (flag: string): boolean;
    (flag: keyof typeof DebugLocalStorageFlags): boolean;
}
export const checkIfFlagIsToggled: CheckIfFlagIsToggled = flag => {
    const lookup = DebugLocalStorageFlags[flag];
    if (hasValue(lookup)) {
        return Boolean(LocalStorageService.getFromLocalStorage(lookup.flag));
    }
    return Boolean(LocalStorageService.getFromLocalStorage(flag));
};
