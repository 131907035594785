import {type SupportedLanguage, useModernFamlyContext} from './modern-famly-provider';

/**
 * All keys that we need translated.
 */
export type TranslationKey =
    | 'DropdownButton.close'
    | 'FormControl.clear'
    | 'Toast.errorCode'
    | 'DropdownPill.more'
    | 'DropdownPill.selected'
    | 'PlaygroundBox.enabled'
    | 'PlaygroundBox.disabled'
    | 'PlaygroundBox.optIn'
    | 'PlaygroundBox.optOut'
    | 'PlaygroundBox.readMore'
    | 'PlaygroundBox.readLess'
    | 'FilterAutocomplete.selected'
    | 'FilterAutocomplete.clearAll'
    | 'FilterAutocomplete.selectAll'
    | 'FilterAutocomplete.emptyResults'
    | 'FilterAutocomplete.placeholder'
    | 'FavoriteButton.addFavorite'
    | 'FavoriteButton.removeFavorite'
    | 'BulkActionBar.selectedCount'
    | 'BulkActionBar.deselectAll'
    | 'ListDrawer.defaultCloseButtonText'
    | 'MobileListRow.more'
    | 'Dialog.cancel'
    | 'Dialog.confirm'
    | 'Modal.cancel'
    | 'Modal.confirm';

export type Translations = {[key in TranslationKey]: string};
export type Locales = {[key in SupportedLanguage]: Translations};

/**
 * Gets a translated string by its key
 */
export const useTranslation = (translationKey: TranslationKey, ...values: string[]) => {
    const {locale} = useModernFamlyContext();

    return buildMessage(locales[locale][translationKey], ...values);
};

export const danish: Translations = {
    'DropdownButton.close': 'Luk',
    'FormControl.clear': 'Ryd',
    'Toast.errorCode': 'Fejlkode',
    'DropdownPill.more': 'og {0} mere',
    'DropdownPill.selected': '{0} valgte',
    'PlaygroundBox.enabled': 'Aktiveret',
    'PlaygroundBox.disabled': 'Diaktiveret',
    'PlaygroundBox.optIn': 'Opt in',
    'PlaygroundBox.optOut': 'Opt out',
    'PlaygroundBox.readMore': 'Read more',
    'PlaygroundBox.readLess': 'Read less',
    'FilterAutocomplete.selected': '{0} valgte',
    'FilterAutocomplete.clearAll': 'Ryd alle',
    'FilterAutocomplete.selectAll': 'Vælg alle',
    'FilterAutocomplete.emptyResults': 'Ingen der matcher',
    'FilterAutocomplete.placeholder': 'Søg',
    'FavoriteButton.addFavorite': 'Tilføj favorit',
    'FavoriteButton.removeFavorite': 'Fjern favorit',
    'BulkActionBar.selectedCount': '{0} valgt',
    'BulkActionBar.deselectAll': 'Fravælg alle',
    'ListDrawer.defaultCloseButtonText': 'Luk',
    'MobileListRow.more': 'Mere',
    'Dialog.cancel': 'Fortryd',
    'Dialog.confirm': 'Bekræft',
    'Modal.cancel': 'Fortryd',
    'Modal.confirm': 'Bekræft',
};

export const english: Translations = {
    'DropdownButton.close': 'Close',
    'FormControl.clear': 'Clear',
    'Toast.errorCode': 'Error code',
    'DropdownPill.more': 'and {0} more',
    'DropdownPill.selected': '{0} selected',
    'PlaygroundBox.enabled': 'Enabled',
    'PlaygroundBox.disabled': 'Disabled',
    'PlaygroundBox.optIn': 'Opt in',
    'PlaygroundBox.optOut': 'Opt out',
    'PlaygroundBox.readMore': 'Read more',
    'PlaygroundBox.readLess': 'Read less',
    'FilterAutocomplete.selected': '{0} selected',
    'FilterAutocomplete.clearAll': 'Clear All',
    'FilterAutocomplete.selectAll': 'Select All',
    'FilterAutocomplete.emptyResults': 'No items matching',
    'FilterAutocomplete.placeholder': 'Search',
    'FavoriteButton.addFavorite': 'Add favorite',
    'FavoriteButton.removeFavorite': 'Remove favorite',
    'BulkActionBar.selectedCount': '{0} selected',
    'BulkActionBar.deselectAll': 'Deselect all',
    'ListDrawer.defaultCloseButtonText': 'Close',
    'MobileListRow.more': 'More',
    'Dialog.cancel': 'Cancel',
    'Dialog.confirm': 'Confirm',
    'Modal.cancel': 'Cancel',
    'Modal.confirm': 'Confirm',
};

export const german: Translations = {
    'DropdownButton.close': 'Schließen',
    'FormControl.clear': 'Löschen',
    'Toast.errorCode': 'Fehlercode',
    'DropdownPill.more': 'und {0} mehr',
    'DropdownPill.selected': '{0} ausgewählt',
    'PlaygroundBox.enabled': 'Aktiviert',
    'PlaygroundBox.disabled': 'Deaktiviert',
    'PlaygroundBox.optIn': 'Opt in',
    'PlaygroundBox.optOut': 'Opt out',
    'PlaygroundBox.readMore': 'Read more',
    'PlaygroundBox.readLess': 'Read less',
    'FilterAutocomplete.selected': '{0} ausgewählt',
    'FilterAutocomplete.clearAll': 'Alle löschen',
    'FilterAutocomplete.selectAll': 'Alle auswählen',
    'FilterAutocomplete.emptyResults': 'Keine passenden Einträge',
    'FilterAutocomplete.placeholder': 'Suchen',
    'FavoriteButton.addFavorite': 'Favorit hinzufügen',
    'FavoriteButton.removeFavorite': 'Favorit entfernen',
    'BulkActionBar.selectedCount': '{0} ausgewählt',
    'BulkActionBar.deselectAll': 'Alle abwählen',
    'ListDrawer.defaultCloseButtonText': 'Schließen',
    'MobileListRow.more': 'Mehr',
    'Dialog.cancel': 'Abbrechen',
    'Dialog.confirm': 'Bestätigen',
    'Modal.cancel': 'Abbrechen',
    'Modal.confirm': 'Bestätigen',
};

export const spanish: Translations = {
    'DropdownButton.close': 'Cerrar',
    'FormControl.clear': 'Claro',
    'Toast.errorCode': 'Código de error',
    'DropdownPill.more': 'y {0} más',
    'DropdownPill.selected': '{0} seleccionados',
    'PlaygroundBox.enabled': 'Activado',
    'PlaygroundBox.disabled': 'Desactivado',
    'PlaygroundBox.optIn': 'Opt in',
    'PlaygroundBox.optOut': 'Opt out',
    'PlaygroundBox.readMore': 'Read more',
    'PlaygroundBox.readLess': 'Read less',
    'FilterAutocomplete.selected': '{0} seleccionados',
    'FilterAutocomplete.clearAll': 'Clear All',
    'FilterAutocomplete.selectAll': 'Select All',
    'FilterAutocomplete.emptyResults': 'No items matching',
    'FilterAutocomplete.placeholder': 'Search',
    'FavoriteButton.addFavorite': 'Añadir favorito',
    'FavoriteButton.removeFavorite': 'Eliminar favorito',
    'BulkActionBar.selectedCount': '{0} seleccionados',
    'BulkActionBar.deselectAll': 'Deseleccionar todo',
    'ListDrawer.defaultCloseButtonText': 'Cerrar',
    'MobileListRow.more': 'Más',
    'Dialog.cancel': 'Cancelar',
    'Dialog.confirm': 'Confirmar',
    'Modal.cancel': 'Cancelar',
    'Modal.confirm': 'Confirmar',
};

export const norwegian: Translations = {
    'DropdownButton.close': 'Lukk',
    'FormControl.clear': 'Klar',
    'Toast.errorCode': 'Feilkode',
    'DropdownPill.more': 'og {0} til',
    'DropdownPill.selected': '{0} valgt',
    'PlaygroundBox.enabled': 'Aktivert',
    'PlaygroundBox.disabled': 'Deaktivert',
    'PlaygroundBox.optIn': 'Opt in',
    'PlaygroundBox.optOut': 'Opt out',
    'PlaygroundBox.readMore': 'Read more',
    'PlaygroundBox.readLess': 'Read less',
    'FilterAutocomplete.selected': '{0} valgt',
    'FilterAutocomplete.clearAll': 'Clear All',
    'FilterAutocomplete.selectAll': 'Select All',
    'FilterAutocomplete.emptyResults': 'No items matching',
    'FilterAutocomplete.placeholder': 'Search',
    'FavoriteButton.addFavorite': 'Legg til favoritt',
    'FavoriteButton.removeFavorite': 'Fjern favoritt',
    'BulkActionBar.selectedCount': '{0} valgt',
    'BulkActionBar.deselectAll': 'Fjern alle',
    'ListDrawer.defaultCloseButtonText': 'Lukk',
    'MobileListRow.more': 'Mer',
    'Dialog.cancel': 'Avbryt',
    'Dialog.confirm': 'Bekrefte',
    'Modal.cancel': 'Avbryt',
    'Modal.confirm': 'Bekrefte',
};

const locales: Locales = {
    daDK: danish,
    enUS: english,
    deDE: german,
    deCH: german,
    nbNO: norwegian,
    esES: spanish,
    esUS: spanish,
    enGB: english,
};

const buildMessage = (str: string, ...values: string[]) =>
    str.replace(/{(\d)}/g, (_, firstCapturedGroup) => values[Number(firstCapturedGroup)]);
