import {getHSLAChannels} from './get-hsla-channels';

/**
 * Adjusts the alpha channel of the given HSLA color
 *
 * @param newAlpha The desired alpahe value
 * @param hsla The HSLA color to adjust
 * @returns A new HSLA color with the alpha channel adjusted
 */
export const adjustAlpha = (newAlpha: number, hsla: HSLA): HSLA => {
    const [h, s, l] = getHSLAChannels(hsla);

    return `hsla(${h}, ${s}%, ${l}%, ${newAlpha})`;
};
