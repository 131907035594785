import React, {type MouseEventHandler, type ChangeEvent} from 'react';
import {useDispatch} from 'react-redux';
import styled, {css} from 'styled-components';
import {Icon} from 'modern-famly';
import {Button} from 'modern-famly';

import {Text} from '@famly/mf_data-display_text';
import {createStack, Stack} from '@famly/mf_layout_stack';
import {createBox, Box} from '@famly/mf_layout_box';
import {useTypedSelector} from 'signin-app/components/hooks';
import translate from 'signin-app/helpers/translate';
import {StyledStack} from 'signin-app/child/check-in/going-home-with';
import {PersonImage, type ImageSize} from 'signin-app/components/person-image';
import {getInitials} from 'signin-app/util/get-initials';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

import * as Selectors from './selectors';
import * as Actions from './actions';

const ENTER_KEY_CODE = 13;

const StyledOverlay = styled(createStack({flexDirection: 'column'}))`
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: grey;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    // From designs:
    background-color: rgba(253, 253, 253, 0.92);
    backdrop-filter: blur(3px);
`;

export const StyledTextContainer = styled(
    createStack({
        alignItems: 'center',
        maxWidth: {base: '80px', tabletPortrait: '96px'},
        minHeight: {base: '28px', tabletPortrait: '32px'},
        mt: {base: 0.5, tabletPortrait: 1.5},
        justifyContent: 'center',
    }),
)<{shouldWrap?: boolean}>`
    justify-content: center;
    ${props =>
        props.shouldWrap
            ? css`
                  text-align: center;
              `
            : css`
                  * {
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                  }
              `}
`;

const SelectedStack = styled(createBox({}))<{
    selected?: boolean;
    customBorderColor?: string;
    customBackgroundColor?: string;
}>`
    border-radius: 16px;
    border: 2px solid ${props => (props.selected ? props.customBorderColor : 'transparent')};
    background-color: ${props => (props.selected ? props.customBackgroundColor : 'transparent')};
`;

const useAddPickupPerson = () => {
    const adding = useTypedSelector(Selectors.addingNewRelation);
    const pickupName = useTypedSelector(Selectors.getPickupPersonName);

    const dispatch = useDispatch();

    const handleShowPickupPersonField = React.useCallback(() => {
        dispatch(Actions.addNewPickupRelation.action(true));
    }, [dispatch]);

    const handleHidePickupPersonField = React.useCallback(
        (newPickupName?: string) => {
            if (hasValue(newPickupName)) {
                dispatch(Actions.addNewPickupRelation.action(false, newPickupName));
            } else {
                dispatch(Actions.addNewPickupRelation.action(false));
            }
        },
        [dispatch],
    );

    return {
        adding,
        pickupName,
        handleShowPickupPersonField,
        handleHidePickupPersonField,
    };
};

export const AddPickupPerson: React.FC<{childName: string; isExistingRelationSelected: boolean; size?: ImageSize}> = ({
    childName,
    isExistingRelationSelected,
    size,
}) => {
    const {adding, pickupName, handleShowPickupPersonField, handleHidePickupPersonField} = useAddPickupPerson();
    const p300 = useCustomWhitelabelColor('p300');
    const p200 = useCustomWhitelabelColor('p200');

    const handleAddingDone = React.useCallback(
        (newPickupName: string) => {
            handleHidePickupPersonField(newPickupName);
        },
        [handleHidePickupPersonField],
    );

    const initials = React.useMemo(() => (pickupName ? getInitials(pickupName) : ''), [pickupName]);

    return (
        <>
            {adding ? (
                <AddPickupPersonOverlay initialName={pickupName} childName={childName} onDone={handleAddingDone} />
            ) : null}
            <Stack onClick={handleShowPickupPersonField} cursor="pointer" data-e2e-id="custom-pickup-person-container">
                {pickupName ? (
                    <SelectedStack
                        px={{base: 3, tabletPortrait: 6}}
                        py={{base: 2, tabletPortrait: 3}}
                        flexDirection="column"
                        selected={!isExistingRelationSelected}
                        customBorderColor={p300}
                        customBackgroundColor={p200}
                    >
                        <PersonImage image="" initials={initials} size={size} />
                        <StyledTextContainer>
                            <Text
                                variant={size === 'sm' || size === 'md' ? 'h6' : 'h5'}
                                color="n400"
                                emphasized
                                data-e2e-id="custom-pickup-person-text"
                            >
                                {pickupName}
                            </Text>
                        </StyledTextContainer>
                    </SelectedStack>
                ) : (
                    <Stack
                        px={{base: 3, tabletPortrait: 6}}
                        py={{base: 2, tabletPortrait: 3}}
                        flexDirection="column"
                        data-e2e-id="add-custom-pickup-person"
                    >
                        <StyledStack>
                            <Icon name="add" color="n300" size={36} />
                        </StyledStack>
                        <StyledTextContainer shouldWrap={true}>
                            <Text variant="body-small" color="n400" emphasized>
                                {translate('addPickupPerson')}
                            </Text>
                        </StyledTextContainer>
                    </Stack>
                )}
            </Stack>
        </>
    );
};

const StyledInput = styled.input`
    box-sizing: border-box;
    height: 96px;
    width: 100%;
    padding: 0 16px 0px 68px;
    border: 2px solid ${props => props.theme.mf.colorPalette.n200};
    border-radius: 16px;
    margin-top: 8px;
    font-size: 32px;
    &:focus {
        outline: none;
        border-color: ${props => props.theme.mf.colorPalette.p400};
    }
`;

const ButtonWrapper = styled(createBox({}))`
    button {
        box-sizing: border-box;
        height: 96px;
        min-width: 192px;
        border-radius: 16px;
        margin-top: 8px;
        font-size: 24px;
    }
`;

const AddPickupPersonOverlay = ({
    initialName,
    childName,
    onDone,
}: {
    initialName: string;
    childName: string;
    onDone: (pickupName: string) => void;
}) => {
    const [pickupPersonName, setPickupPersonName] = React.useState(initialName);

    const handleClose = React.useCallback(() => {
        onDone(initialName);
    }, [initialName, onDone]);

    const handleDone: MouseEventHandler<HTMLButtonElement> = React.useCallback(
        e => {
            e?.stopPropagation?.();
            onDone(pickupPersonName);
        },
        [onDone, pickupPersonName],
    );

    const handleKeyDown = React.useCallback(
        e => {
            if (e.keyCode === ENTER_KEY_CODE) {
                onDone(pickupPersonName);
            }
        },
        [onDone, pickupPersonName],
    );

    const handlePickupPersonNameChange = React.useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setPickupPersonName(e.target.value);
        },
        [setPickupPersonName],
    );

    return (
        <StyledOverlay onClick={handleClose} data-e2e-id="custom-pickup-person-overlay">
            <Box width="80%">
                <Text variant="h6" emphasized>
                    {translate('whoIsPickingUp', {childName})}
                </Text>
                <Stack
                    spacing={2}
                    justifyContent="center"
                    width="100%"
                    direction={{base: 'column', tabletPortrait: 'row'}}
                >
                    <Stack position="relative" flex={1}>
                        <Stack position="absolute" alignItems="center" top="8px" bottom={0} ml={4}>
                            <Icon name="person_filled" size={48} />
                        </Stack>
                        <StyledInput
                            onClick={e => e.stopPropagation()} // Don't trigger overlay click event
                            onKeyDown={handleKeyDown}
                            onChange={handlePickupPersonNameChange}
                            value={pickupPersonName}
                            data-e2e-id="add-pickup-person-input"
                        />
                    </Stack>
                    <ButtonWrapper>
                        <Button
                            text="Done"
                            fullWidth
                            onClick={handleDone}
                            data-e2e-id="add-custom-pickup-person-done-button"
                        />
                    </ButtonWrapper>
                </Stack>
            </Box>
        </StyledOverlay>
    );
};
