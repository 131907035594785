import React from 'react';

import {type TextProps} from '@famly/mf_data-display_text';
import {Text} from '@famly/mf_data-display_text';
import {Stack, type StackProps} from '@famly/mf_layout_stack';

type InitalsProps = {
    compact?: boolean;
    initials: string;
    wrapperProps?: StackProps;
    textProps?: TextProps;
};

export const Initials = ({compact, initials, wrapperProps, textProps}: InitalsProps) => {
    return (
        <Stack
            width="100%"
            height="100%"
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            backgroundColor="b100"
            {...wrapperProps}
        >
            <Text variant={compact ? 'h5' : 'h2'} color="b400" {...textProps}>
                {initials}
            </Text>
        </Stack>
    );
};
