/* eslint-disable no-restricted-imports */
import React from 'react';
import MuiDialogContentText from '@mui/material/DialogContentText';
import {useIsScrollable} from '@famly/stat_react-utils_is-scrollable';
import {type ButtonProps} from 'modern-famly';
import {
    DialogButtons,
    DialogCloseButton,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@famly/mf_modals-dialogs_dialog';

export type GenericDialogProps = {
    /**
     * Translated title of the dialog
     */
    title: string;

    /**
     * Translated description of the dialog
     */
    description: string;

    /**
     * Translated cancel button label of the dialog. Defaults to 'Cancel'.
     */
    cancelText?: string;

    /**
     * Translated confirm button label of the dialog. Defaults to 'Confirm'.
     */
    confirmText?: string;

    /**
     * Variant of the Dialog to be shown. The variant is passed the CTA button. Defaults to 'primary'
     */
    variant?: Extract<ButtonProps['variant'], 'primary' | 'critical'>;

    /**
     * Callback to handle when the user clicks either 'Cancel' or 'x' button. Use this to control
     * the `open` state
     */
    onClose: Callback<void>;

    /**
     * Callback to handle when the user clicks 'Confirm' button. Use this to handle confirmation
     */
    onConfirm: Callback<void>;
} & DataProps;

const dialogTitleId = 'dialog-title';
const dialogDescriptionId = 'dialog-description';

/**
 * Generic dialog component for implementing simple modern famly dialogs with just text to be displayed.
 *
 * Should also be taken as lead example on how to create custom dialogs.
 */
export const GenericDialog = ({
    title,
    description,

    cancelText,
    confirmText,
    variant = 'primary',

    onClose,
    onConfirm,
}: GenericDialogProps) => {
    const {ref: scrollableContentRef, isScrollable} = useIsScrollable();
    const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);
    return (
        <>
            <DialogHeader>
                <DialogTitle id={dialogTitleId} title={title} />
                <DialogCloseButton onClose={onClose} />
            </DialogHeader>
            <DialogContent
                ref={scrollableContentRef}
                onScroll={() => {
                    if (scrollableContentRef.current) {
                        const {scrollHeight, clientHeight, scrollTop} = scrollableContentRef.current;
                        setIsScrolledToBottom(Math.abs(clientHeight - (scrollHeight - scrollTop)) < 10);
                    }
                }}
            >
                <MuiDialogContentText
                    id={dialogDescriptionId}
                    sx={theme => ({
                        color: theme.modernFamlyTheme.colorPalette.n400,
                    })}
                >
                    {description}
                </MuiDialogContentText>
            </DialogContent>
            <DialogFooter>
                <DialogButtons
                    isScrollable={isScrollable}
                    isScrolledToBottom={isScrolledToBottom}
                    cancelText={cancelText}
                    confirmText={confirmText}
                    variant={variant}
                    onClose={onClose}
                    onConfirm={onConfirm}
                />
            </DialogFooter>
        </>
    );
};

export default GenericDialog;
