import {matchPath} from 'react-router-dom';

import InstitutionBehaviors from 'web-app/behaviors/institution-behavior-ids';
import {type ITheme} from 'web-app/styleguide/themes/model';
import * as Breakpoints from 'web-app/styleguide/breakpoint-helpers';
import {type NavigationContext} from 'web-app/react/contexts/navigation';
import RouteConfig from 'web-app/react/routes/route-config';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import * as NonEmptyArray from 'web-app/util/non-empty-array';

import {
    type NavigationGroup,
    type NavigationLink,
    NavigationLinkIds,
    type NavigationGroupIds,
    hasNavigationLinks,
    type CustomEntity,
    type InstitutionForNavigationLink,
    type OrganizationForNavigationLink,
} from './types';
import {type Entity} from './entity-selector/use-entity';
import {getNavigationLinkRouteConfig} from './config/navigation-link';
import {
    ENABLE_SAFEGUARDING_PAYLOAD_KEY,
    ENABLE_MEDICATION_FORMS_PAYLOAD_KEY,
    ENABLE_IMMUNIZATION_PAYLOAD_KEY,
    ENABLE_HEADCOUNTS_PAYLOAD_KEY,
} from './constants';

/**
 * Returns the behaviorId controlling whether the given NavigationLinkId should be shown.
 */
export const getBehaviorForNavigationLinkId = (navigationLinkId: NavigationLinkIds) => {
    switch (navigationLinkId) {
        case NavigationLinkIds.MEAL_PLANS_V3:
            return InstitutionBehaviors.ShowMealPlanningApp;
        default:
            return null;
    }
};

export const getTabBarHeight = (theme: ITheme) =>
    Breakpoints.isTabletPortraitAndLarger() && theme.informationArchitectureConfiguration.tabBarHeightTablet
        ? theme.informationArchitectureConfiguration.tabBarHeightTablet
        : theme.informationArchitectureConfiguration.tabBarHeightMobile;

export const doPathsMatch = (currentPath: string, targetPath: string) => {
    // https://reactrouter.com/web/api/matchPath
    const match = matchPath(currentPath, {
        path: targetPath,
        exact: true,
        strict: false,
    });
    return match?.isExact ?? false;
};

/**
 * Recursively test if `navigationLinkId` is part of the given `navigationLink`. Note that this does not
 * take sub pages into account.
 *
 * @param navigationLinkId
 * @param navigationLink
 */
export const isPartOfNavigationLink = (
    navigationLinkId: NavigationLinkIds,
    navigationLink: NavigationLink,
): boolean => {
    if (navigationLink.id === navigationLinkId) {
        return true;
    }

    if (navigationLink.subNavigationLinks.length === 0) {
        return false;
    }

    return navigationLink.subNavigationLinks.some(subNavigationLink =>
        isPartOfNavigationLink(navigationLinkId, subNavigationLink),
    );
};

/**
 * Recursively test if a `navigationLinkId` is part of the given `navigationGroup`
 *
 * @param navigationLinkId
 * @param navigationGroup
 * @returns
 */
export const isPartOfNavigationGroup = (navigationLinkId: NavigationLinkIds, navigationGroup: NavigationGroup) => {
    if (!hasNavigationLinks(navigationGroup)) {
        return false;
    }

    return navigationGroup.navigationLinks.some(navigationLink =>
        isPartOfNavigationLink(navigationLinkId, navigationLink),
    );
};

/**
 * Recursively test if a `navigationLinkId` is a sub page of the given `navigationLink`
 */
export const isSubPageOfNavigationLink = (
    navigationLinkId: NavigationLinkIds,
    navigationLink: NavigationLink,
): boolean => {
    if (navigationLink.subPages?.some(subNavigationLinkId => subNavigationLinkId === navigationLinkId)) {
        return true;
    }

    if (navigationLink.subNavigationLinks.length === 0) {
        return false;
    }

    return navigationLink.subNavigationLinks.some(subNavigationLink =>
        isSubPageOfNavigationLink(navigationLinkId, subNavigationLink),
    );
};

/**
 * Determine whether the given `navigationLink` has sub navigation links
 */
export const hasSubNavigationLinks = (navigationLink: NavigationLink): boolean => {
    return navigationLink.subNavigationLinks.length > 0;
};

/**
 * The type of the arguments given to the `generateUrl` function
 */
type TransitionArgs = Parameters<NavigationContext['generateUrl']>;

const noAccessEmberIdent =
    `${RouteConfig.account.emberIdent}.${RouteConfig.account.subRoutes.institution.emberIdent}.${RouteConfig.account.subRoutes.institution.subRoutes.noAccess.emberIdent}` as const;

/**
 * Creates a URL for the given navigation link based on the given entity. If the given entity is an institution
 * and doesn't have access to the navigation link it returns the URL for the no-acccess, otherwise it returns null.
 *
 * @param navigationLink The navigation link to create the URL for
 * @param entity The entity to generate create the URL for
 * @param config An optional configuration object with the possibility to force the function to create entity specific urls
 */
export const createUrlForEntity = (
    navigationLink: NavigationLink,
    entity: Entity,
    config = {ignoreTopLevelRoute: false},
): TransitionArgs | null => {
    const routeConfig = getNavigationLinkRouteConfig(navigationLink.id);

    if (routeConfig.topLevelRoute && !config.ignoreTopLevelRoute) {
        return [routeConfig.topLevelRoute.emberRoute];
    }

    switch (entity.type) {
        case 'custom-entity': {
            if (!routeConfig.customEntityRoutes) {
                return null;
            }

            const targetRouteConfig = routeConfig.customEntityRoutes.find(customEntityRoute => {
                return customEntityRoute.id === entity.id;
            });

            if (!targetRouteConfig) {
                return null;
            }

            return [targetRouteConfig.emberRoute];
        }
        case 'organization': {
            const hasAccess = navigationLink.organizations.some(
                organization => organization.organizationId === entity.id,
            );

            if (!hasAccess || !routeConfig.organizationRoute) {
                return null;
            }

            return [routeConfig.organizationRoute.emberRoute, entity.id];
        }
        case 'institution': {
            const hasAccess = institutionHasAccessToNavigationLink(entity.id, navigationLink);

            return hasAccess && routeConfig.institutionRoute
                ? [routeConfig.institutionRoute.emberRoute, entity.id]
                : [
                      noAccessEmberIdent,
                      entity.id,
                      {
                          queryParams: {navigationLinkId: navigationLink.id},
                      },
                  ];
        }
        default:
            return null;
    }
};

const safeGuardingNavigationLinkIds = [
    NavigationLinkIds.ACCIDENTS_INCIDENTS,
    NavigationLinkIds.MEDICATION_FORMS,
    NavigationLinkIds.HEAD_COUNT,
    NavigationLinkIds.IMMUNIZATION,
];

const payloadKeysMap = {
    [NavigationLinkIds.ACCIDENTS_INCIDENTS]: ENABLE_SAFEGUARDING_PAYLOAD_KEY,
    [NavigationLinkIds.MEDICATION_FORMS]: ENABLE_MEDICATION_FORMS_PAYLOAD_KEY,
    [NavigationLinkIds.IMMUNIZATION]: ENABLE_IMMUNIZATION_PAYLOAD_KEY,
    [NavigationLinkIds.HEAD_COUNT]: ENABLE_HEADCOUNTS_PAYLOAD_KEY,
};

/**
 * Checks if the institution with the given institution id has given to a navigation link
 *
 * This includes some special handling of the safeguarding links as their behaviors are
 * structured differently from all of the other navigation links.
 */
export const institutionHasAccessToNavigationLink = (
    institutionId: string,
    navigationLink: NavigationLink,
): boolean => {
    if (safeGuardingNavigationLinkIds.includes(navigationLink.id)) {
        const payloadForInstitution = navigationLink.institutions.find(
            institution => institution.institutionId === institutionId,
        )?.payload;

        if (!payloadForInstitution) {
            return false;
        }

        const payloadKey = payloadKeysMap[navigationLink.id];

        return payloadForInstitution[payloadKey] === true;
    }

    return navigationLink.institutions.some(institution => institution.institutionId === institutionId);
};

/**
 * Determines if the given NavigationLink is valid
 */
export const isNavigationLinkValid = (navigationLink: NavigationLink) => {
    // Some NavigationLinks don't have any entities attached to them but are still valid for parents
    if (parentSpecificNavigationLinkIds.includes(navigationLink.id)) {
        return true;
    }

    const customEntities = navigationLink.customEntities ?? [];

    return (
        navigationLink.institutions.length > 0 || navigationLink.organizations.length > 0 || customEntities.length > 0
    );
};

const parentSpecificNavigationLinkIds = [
    NavigationLinkIds.BILL_PAYER,
    NavigationLinkIds.BILL_PAYERS,
    NavigationLinkIds.LESSON_PLANS_PARENT,
];
/**
 * Creates a NavigationLink based on the passed configuration
 */
export const createNavigationLink = (config: {
    id: NavigationLinkIds;
    entities?: {
        institutions?: InstitutionForNavigationLink[];
        organizations?: OrganizationForNavigationLink[];
        /**
         * @deprecated Refrain from using custom entities if possible. They cause a lot of hassle.
         */
        custom?: CustomEntity[];
    };
    subNavigationLinks?: Array<NavigationLink | null>;
    subPages?: NavigationLinkIds[];
    isRemovable?: boolean;
}): NavigationLink => {
    const subNavigationLinks = (config.subNavigationLinks ?? []).filter(hasValue).filter(isNavigationLinkValid);

    return {
        id: config.id,
        institutions: config.entities?.institutions ?? [],
        organizations: config.entities?.organizations ?? [],
        customEntities: config.entities?.custom,
        subNavigationLinks,
        subPages: config.subPages ?? [],
        isRemovable: config.isRemovable ?? false,
    };
};

/**
 * Creates a NavigationGroup based on the passed configuration
 *
 * @returns the created NavigationGroup or null if none of the passed `navigationLinks` are valid
 */
export const createNavigationGroupWithNavigationLinks = (config: {
    id: NavigationGroupIds;
    navigationLinks: Array<NavigationLink | null>;
    hideNavigationLinks?: boolean;
}): NavigationGroup | null => {
    const navigationLinks = config.navigationLinks.filter(hasValue).filter(isNavigationLinkValid);

    if (!NonEmptyArray.isNonEmpty(navigationLinks)) {
        return null;
    }

    return {
        type: 'WithNavigationLinks',
        id: config.id,
        navigationLinks,
        hideNavigationLinks: config.hideNavigationLinks,
    };
};
