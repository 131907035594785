import React from 'react';
import {useMediaQuery} from './use-media-query';

/**
 * Returns a dictionary of breakpoint names and booleans indicating whether that breakpoint is currently in use.
 *
 * @example
 * ```
 * const {
 *     isBase,
 *     isMobileLandscapeAndLarger,
 *     isTabletPortraitAndLarger,
 *     isTabletLandscapeAndLarger,
 *     isLaptopAndLarger
 * } = useBreakpoints();
 * ```
 */
export const useBreakpoints = () => {
    const isBase = useMediaQuery(breakpoints => breakpoints.up('base'));
    const isMobileLandscapeAndLarger = useMediaQuery(breakpoints => breakpoints.up('mobileLandscape'));
    const isTabletPortraitAndLarger = useMediaQuery(breakpoints => breakpoints.up('tabletPortrait'));
    const isTabletLandscapeAndLarger = useMediaQuery(breakpoints => breakpoints.up('tabletLandscape'));
    const isLaptopAndLarger = useMediaQuery(breakpoints => breakpoints.up('laptop'));

    return React.useMemo(
        () => ({
            isBase,
            isMobileLandscapeAndLarger,
            isTabletPortraitAndLarger,
            isTabletLandscapeAndLarger,
            isLaptopAndLarger,
        }),
        [isBase, isMobileLandscapeAndLarger, isTabletPortraitAndLarger, isTabletLandscapeAndLarger, isLaptopAndLarger],
    );
};
