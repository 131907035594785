/**
 * Gets the different channels of a given HSLA color
 *
 * @param hsla The HSLA color to get the channels from
 * @returns A tuple representing hue, saturation, luminosity and alpha respectively
 */
export const getHSLAChannels = (hsla: HSLA): [number, number, number, number] => {
    return (
        hsla
            // Remove the surrounding syntax
            .replace(`hsla(`, '')
            .replace(')', '')

            // Get rid of the percentage signs
            .replace(/%/g, '')

            // Split by comma to get string representations of values
            .split(',')

            // Map the values to numbers and convince the compiler that we're only returning four numbers
            .map(parseFloat) as [number, number, number, number]
    );
};
