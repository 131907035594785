import React from 'react';

import {extractAriaProps} from './extract-aria-props';
import {type extractProps} from './extract-props';

/**
 * Utility hook to extract all aria-attributes that are added to components
 */
export const useAriaProps = <P extends Dictionary<string, any>>(
    props: P,
): ReturnType<typeof extractProps<P, 'aria'>> => {
    return React.useMemo(() => {
        return extractAriaProps<P>(props);
    }, [props]);
};
