import React from 'react';

import {useModalsDialogsContext} from './modals-dialogs-context';

export interface DialogOptions<P = {}> {
    /**
     * Custom children to be shown in the dialog.
     *
     * It's best to refer to the components defined in `@famly/mf_dialogs-modals_dialog`.
     *
     * In order ensure lazy loading of dialog content, you need to provide a callback function,
     * which will then be reached into the `React.lazy call` as lazyChildren
     *
     * @example `const {showDialog} = useDialog({lazyChildren: () => import('./test-dialog')});`
     * @see {@link file://./../../modals-dialogs_dialog/src/index.ts}
     * @param props props that will be passed to the component and will need
     * to be provided on `showDialog`
     */
    lazyChildren: Provider<Promise<{default: React.ComponentType<P>}>>;
}

/**
 * The hook to render a custom dialog.
 *
 * Allows passing a component in as `children`, which will be rendered when opening
 * the dialog.
 *
 * In most cases, you should go for the `useGenericDialog` version, as dialogs are supposed
 * to be only text, images and a confirm and cancel button.
 *
 * @see {@link file://./use-generic-dialog.tsx}
 * @param options see {@link DialogOptions}
 * @returns an object containing functions to show and hide the dialog
 */
export function useDialog<P = {}>(options: DialogOptions<P>) {
    const {showModalDialog, hideModalDialog} = useModalsDialogsContext();
    const id = React.useId();

    const show = React.useCallback(
        (props: Omit<P, 'onClose'>) => {
            // onClose calls the context to remove the dialog from the stack
            const handleClose = () => hideModalDialog(id);
            /* 
            // Render the dialog content passing the props and injected onClose
            const element = options.children({...props, onClose: handleClose});
            showModalDialog({id, type: 'dialog', element}); */

            const Lazy = React.lazy(options.lazyChildren);
            showModalDialog({
                id,
                type: 'dialog',
                element: (
                    <React.Suspense fallback={null}>
                        <Lazy {...(props as any)} onClose={handleClose} />
                    </React.Suspense>
                ),
            });
        },
        [hideModalDialog, id, options, showModalDialog],
    );

    return React.useMemo(
        () => ({
            showDialog: show,
            hideDialog: () => hideModalDialog(id),
        }),
        [hideModalDialog, show, id],
    );
}
