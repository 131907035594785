import React from 'react';
import {type ModalProps} from '@famly/mf_modals-dialogs_modal';

import {useModalsDialogsContext} from './modals-dialogs-context';

interface ModalOptions<P = {}> {
    /**
     * Custom children to be shown in the Modal.
     *
     * It's best to refer to the components defined in `@famly/mf_Modals-modals_Modal`.
     *
     * In order ensure lazy loading of Modal content, you need to provide a callback function,
     * which will then be reached into the `React.lazy call` as lazyChildren
     *
     * @example `const {showModal} = useModal({lazyChildren: () => import('./test-Modal')});`
     * @see {@link file://./../../modals-Modals_Modal/src/index.ts}
     * @param props props that will be passed to the component and will need
     * to be provided on `showModal`
     */
    lazyChildren: Provider<Promise<{default: React.ComponentType<P>}>>;

    /**
     * size parameter to be forwarded to the modal
     */
    size: ModalProps['size'];
}

/**
 * The hook to render a custom Modal.
 *
 * Allows passing a component in as `children`, which will be rendered when opening
 * the Modal.
 *
 * In most cases, you should go for the `useGenericModal` version, as Modals are supposed
 * to be only text, images and a confirm and cancel button.
 *
 * @see {@link file://./use-generic-Modal.tsx}
 * @param options see {@link ModalOptions}
 * @returns an object containing functions to show and hide the Modal
 */
export function useModal<P = {}>(options: ModalOptions<P>) {
    const {showModalDialog, hideModalDialog} = useModalsDialogsContext();
    const id = React.useId();

    const show = React.useCallback(
        (props: Omit<P, 'onClose'>) => {
            // onClose calls the context to remove the Modal from the stack
            const handleClose = () => hideModalDialog(id);

            const Lazy = React.lazy(options.lazyChildren);
            showModalDialog({
                id,
                type: 'modal',
                size: options.size,
                element: (
                    <React.Suspense fallback={null}>
                        <Lazy {...(props as any)} onClose={handleClose} />
                    </React.Suspense>
                ),
            });
        },
        [hideModalDialog, id, options, showModalDialog],
    );

    return React.useMemo(
        () => ({
            showModal: show,
            hideModal: () => hideModalDialog(id),
        }),
        [hideModalDialog, show, id],
    );
}
