export const ENABLE_SAFEGUARDING_PAYLOAD_KEY = 'enableSafeguarding';
export const ENABLE_MEDICATION_FORMS_PAYLOAD_KEY = 'enableMedicationForms';
export const ENABLE_IMMUNIZATION_PAYLOAD_KEY = 'enableImmunization';
export const ENABLE_HEADCOUNTS_PAYLOAD_KEY = 'enableHeadcounts';

/**
 * TODO: This list should be enhanced with more sidebar items
 */
export type SidebarItem =
    | 'GetStarted'
    | 'NewsFeed'
    | 'Home'
    | 'FeatureStore'
    | 'CustomForms'
    | 'InquiriesPage'
    | 'ShiftPlanner'
    | 'StaffHoursPage'
    | 'KinderConnect'
    | 'Reports'
    | 'StaffAttendancePage'
    | 'FinanceReports'
    | 'AttendanceReports'
    | 'LearningReports'
    | 'FinanceLandingPage'
    | 'AttendanceLandingPage'
    | 'LearningLandingPage'
    | 'ChildrenLandingPage'
    | 'StaffRatioReport'
    | 'StaffLeavePage'
    | 'StaffLeavePageV2'
    | 'Templates'
    | 'ChildrenOverview';
