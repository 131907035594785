import React from 'react';

import {Box} from '@famly/mf_layout_box';
import {getFormattedTime} from 'signin-app/helpers/time';
import {ListTimes} from 'signin-app/components/list-times/list-times';
import {useTypedSelector} from 'signin-app/components/hooks';

import * as Selectors from './selectors';
import * as Actions from './actions';

const PickupTime = () => {
    const pickupTimeOptions = useTypedSelector(Selectors.pickupTimeOptions);
    const selectedHours = useTypedSelector(Selectors.selectedHours);
    const selectedMinutes = useTypedSelector(Selectors.selectedMinutes);

    return (
        <Box>
            <ListTimes
                times={pickupTimeOptions}
                selectedHours={selectedHours}
                selectedMinutes={selectedMinutes}
                displayMinutes={getFormattedTime}
                onHoursChange={Actions.selectPickupHours.dispatch}
                onMinutesChange={Actions.selectPickupMinutes.dispatch}
                e2eHourUnit="child-pickup-hour-unit"
            />
        </Box>
    );
};

export default PickupTime;
