import React from 'react';
import {styled} from '@mui/material/styles';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {Text} from '@famly/mf_data-display_text';
import {createBox} from '@famly/mf_layout_box';
import {Stack} from '@famly/mf_layout_stack';

import {Button, type ButtonProps} from 'modern-famly/components/input/button';
import {useBreakpoints} from 'modern-famly/theming';
import {DropdownButton, type DropdownButtonProps} from 'modern-famly/components/input';
import {type DataProps, useDataProps} from 'modern-famly/components/util';

export type EmptyStateProps = {
    /**
     * Path to image explaining the feature
     */
    imageUrl: string;

    /**
     * Alt text to be provided for the visualization image
     *
     * Example: Image highlighting how the [feature] helps users [value for the customer]
     */
    imageAltText?: string;

    /**
     * Whether the image should have an elevation
     *
     * Should be applied if the image has a non-white or non-transparent background
     */
    imageElevation?: boolean;

    /**
     * Describe what the user can do
     */
    heading: string;

    /**
     * Longer description on what the user can do
     */
    body: React.ReactNode;

    /**
     * Primary action for the user to take. _Must_ be a Button or DropdownButton
     * Should result in an action that will remove the empty state (e.g. create a new observation)
     *
     * Note that `size` and `variant` are controlled by the Empty State component and will be overwritten if provided
     */
    primaryButton?: React.ReactNode;

    /**
     * Secondary action for the user to take. _Must_ be a Button or DropdownButton
     *
     * Note that `size` and `variant` are controlled by the Empty State component and will be overwritten if provided
     */
    secondaryButton?: React.ReactNode;

    /**
     * If `true`, forces the component to render with 100% width.
     *
     * Note that the component will always be rendered in fullWidth mode beneath the tabletPortrait breakpoint.
     */
    fullWidth?: boolean;

    /**
     * Height of the image
     */
    imageHeight?: string;
} & DataProps;

const Image = styled(
    createBox({
        component: 'img',
    }),
)<{height: string}>`
    height: ${props => props.height};
    width: 100%;
    border-radius: 8px;

    /* Makes sure the visualization is always fully visible */
    object-fit: contain;
    object-position: center bottom;
`;

export const EmptyState = (props: EmptyStateProps) => {
    const {isTabletPortraitAndLarger} = useBreakpoints();

    const dataProps = useDataProps(props);

    const componentWidth = React.useMemo(() => {
        switch (true) {
            case props.fullWidth:
                return '100%';
            case isTabletPortraitAndLarger:
                return '480px';
            default:
                return '100%';
        }
    }, [props.fullWidth, isTabletPortraitAndLarger]);

    const imageHeight = React.useMemo(() => {
        switch (true) {
            case hasValue(props.imageHeight):
                return props.imageHeight;
            case props.fullWidth:
                return '200px';
            case isTabletPortraitAndLarger:
                return '320px';
            default:
                return '200px';
        }
    }, [props.fullWidth, isTabletPortraitAndLarger, props.imageHeight]);

    return (
        <Stack flexDirection="column" alignItems="center" width={componentWidth} {...dataProps}>
            <Image
                src={props.imageUrl}
                alt={props.imageAltText ?? ''}
                height={imageHeight}
                elevation={props.imageElevation ? 2 : undefined}
            />
            <Stack flexDirection="column" alignItems="center" marginTop={6} paddingLeft={2} paddingRight={2}>
                <Text variant="h6" emphasized textAlign="center">
                    {props.heading}
                </Text>
                <Text variant="body" textAlign="center">
                    {props.body}
                </Text>
            </Stack>
            <Buttons primaryButton={props.primaryButton} secondaryButton={props.secondaryButton} />
        </Stack>
    );
};

const Buttons = ({primaryButton, secondaryButton}: Pick<EmptyStateProps, 'primaryButton' | 'secondaryButton'>) => {
    const {isTabletPortraitAndLarger} = useBreakpoints();

    if (!hasValue(primaryButton) && !hasValue(secondaryButton)) {
        return null;
    }

    return isTabletPortraitAndLarger ? (
        <Stack flexDirection="row" alignItems="center" gap={3} marginTop={8} paddingLeft={2} paddingRight={2}>
            {hasValue(secondaryButton) ? (
                <RenderButton node={secondaryButton} size="regular" variant="secondary" />
            ) : null}
            {hasValue(primaryButton) ? <RenderButton node={primaryButton} size="regular" variant="primary" /> : null}
        </Stack>
    ) : (
        <Stack flexDirection="column" width="100%" alignItems="stretch" marginTop={6} gap={4}>
            {hasValue(primaryButton) ? <RenderButton node={primaryButton} size="compact" variant="primary" /> : null}
            {hasValue(secondaryButton) ? (
                <RenderButton node={secondaryButton} size="compact" variant="secondary" />
            ) : null}
        </Stack>
    );
};

const RenderButton = ({node, size, variant}: {node: React.ReactNode} & Pick<ButtonProps, 'variant' | 'size'>) => {
    const nodes = React.Children.toArray(node);

    if (nodes.length !== 1) {
        throw new Error('Only exactly one node is allowed');
    }

    const element = nodes[0];

    if (React.isValidElement<ButtonProps>(element) && element.type === Button) {
        return React.cloneElement(element, {size, variant});
    }

    if (React.isValidElement<DropdownButtonProps>(element) && element.type === DropdownButton) {
        return React.cloneElement(element, {size, variant});
    }

    throw new Error('Only Button or DropdownButton is allowed as an action button on the Empty State component');
};
