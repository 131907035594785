import React from 'react';
import {Redirect, type RouteComponentProps} from 'react-router-dom';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {Icon, Button} from 'modern-famly';
import i18next from 'i18next';

import {Text} from '@famly/mf_data-display_text';
import {Stack} from '@famly/mf_layout_stack';
import {Box} from '@famly/mf_layout_box';
import {Headline, Body} from 'web-app/react/components/text/text';
import {Flex} from 'web-app/react/components/layout/layout';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import translate from 'signin-app/helpers/translate';
import {s1, s6, getSpacing, s12} from 'web-app/styleguide/spacing';
import RoutesMap from 'signin-app/routes/routes-map';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import PlatformHelper from 'web-app/helpers/platform-helper';
import LocalStorageService from 'web-app/services/local-storage';

import * as Actions from './actions';
import * as Selectors from './selectors';
import Form, {type FormData} from './form/form';

const CLOSED_ADD_TO_HOME_BANNER_COUNTER = 'HideAddToHomeBannerCounter';

const LoginContainer = styled.div`
    position: relative;
    height: 100vh;
`;

const ScreenCenter = styled(Flex).attrs(() => ({
    direction: 'column',
    align: 'center',
}))`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`;

const StyledForm = styled(Form)`
    width: 100%;
    margin-top: ${getSpacing(s12)};

    & > input {
        padding: 20px 8px;

        &::placeholder {
            font-size: 20px !important;
        }
    }
`;

const AddToHomeInstructions = ({handleClose}) => {
    const buttonText = PlatformHelper.isRunningOnIOS()
        ? translate('addToHomeDescriptionShare')
        : translate('addToHomeDescriptionMore');
    const shareMoreIcon = PlatformHelper.isRunningOnIOS() ? 'upload' : 'more_vert';

    return (
        <Stack
            flexDirection="column"
            alignItems="center"
            width="320px"
            p="16px"
            position="absolute"
            top="24px"
            right="24px"
            backgroundColor="p100"
            borderRadius="16px"
            gap="16px"
            zIndex="1000"
        >
            <Box alignSelf="flex-end">
                <Button icon="close" aria-label={i18next.t('close')} variant="transparent" onClick={handleClose} />
            </Box>
            <Text variant="body" emphasized maxWidth="200px" textAlign="center">
                {translate('addToHomeTitle')}
            </Text>
            <Stack flexDirection="column" alignItems="center">
                <Text variant="body-small" mb="8px" textAlign="center">
                    {buttonText}
                </Text>
                <Stack
                    backgroundColor="n0"
                    width="48px"
                    height="40px"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="10px"
                >
                    <Icon name={shareMoreIcon} color="n300" />
                </Stack>
            </Stack>
            <Stack flexDirection="column" alignItems="center">
                <Text variant="body-small" mb="8px" textAlign="center" maxWidth="250px">
                    {translate('addToHomeDescription2')}
                </Text>
                <Stack
                    backgroundColor="n0"
                    height="40px"
                    width="288px"
                    borderRadius="8px"
                    alignItems="center"
                    justifyContent="space-between"
                    px="12px"
                >
                    <Text variant="body-small">{translate('addToHomeScreenAdd')}</Text>
                    {PlatformHelper.isRunningOnIOS() ? <Icon name="add" color="n300" /> : null}
                </Stack>
            </Stack>
        </Stack>
    );
};

const Login: React.FC<RouteComponentProps> = () => {
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const isLoggedIn = useTypedSelector(state => Selectors.isLoggedIn(state));
    const isLoggingIn = useTypedSelector(state => Selectors.isLoggingIn(state));
    const isPinApp = useTypedSelector(state => Selectors.isPinApp(state));
    const isQrSignin = useTypedSelector(state => Selectors.isQrSignin(state));
    const dispatch = useDispatch();

    const [hideWidget, setHideWidget] = React.useState(
        () => LocalStorageService.getFromLocalStorage(CLOSED_ADD_TO_HOME_BANNER_COUNTER) > 2,
    );

    const handleSubmit = React.useCallback(
        (formData: FormData) => {
            dispatch(Actions.login.action(formData.password));
        },
        [dispatch],
    );

    const shouldShowAddToHomeInstructions = React.useMemo(() => {
        const isStandaloneApp = window.matchMedia('(display-mode: standalone)').matches;
        const isTablet = PlatformHelper.isRunningOnTablet();
        if (hideWidget || isStandaloneApp) {
            return false;
        }
        return !hideWidget && !isStandaloneApp && isTablet;
    }, [hideWidget]);

    const handleClose = React.useCallback(() => {
        setHideWidget(true);
        const currentCount = LocalStorageService.getFromLocalStorage(CLOSED_ADD_TO_HOME_BANNER_COUNTER);
        LocalStorageService.saveToLocalStorage(CLOSED_ADD_TO_HOME_BANNER_COUNTER, !currentCount ? 1 : currentCount + 1);
    }, [setHideWidget]);

    if (isLoggedIn) {
        if (isQrSignin && !isPinApp) {
            return <Redirect to={RoutesMap.qr} />;
        }
        if (isPinApp) {
            return <Redirect to={RoutesMap.pin} />;
        }
        return <Redirect to={RoutesMap.overview} />;
    }

    if (isLoggingIn) {
        return <Spinner color="white" centered />;
    }

    return (
        <>
            <LoginContainer>
                {shouldShowAddToHomeInstructions ? <AddToHomeInstructions handleClose={handleClose} /> : null}
                <ScreenCenter>
                    {React.createElement(whitelabelConfiguration.LoginLogo)}
                    <Headline emphasized marginTop={s6} color={'invertedText'} textAlign="center">
                        {translate('logInHeadline', {whitelabelName: whitelabelConfiguration.ProductName})}
                    </Headline>
                    <Body marginTop={s1} color={'invertedText'} textAlign="center">
                        {translate('loginDescription', {whitelabelName: whitelabelConfiguration.ProductName})}
                    </Body>
                    <StyledForm onSubmit={handleSubmit} />
                </ScreenCenter>
            </LoginContainer>
        </>
    );
};

export default Login;
