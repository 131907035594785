import React from 'react';
import {type AlignmentRelatedSystemProps} from '@famly/mf_system_system-props';

import {type DataProps} from 'modern-famly/components/util';

import {ButtonBase, type ButtonBaseProps, type AnchorProps} from './button-base';

type CommonProps = Pick<
    ButtonBaseProps,
    | 'fullWidth'
    | 'isDisabled'
    | 'onClick'
    | 'isLoading'
    | 'ref'
    | 'size'
    | 'type'
    | 'variant'
    | 'form'
    | AlignmentRelatedSystemProps
>;

export interface TextButtonProps extends CommonProps {
    text: string;
    icon?: ButtonBaseProps['icon'];
}

export interface IconButtonProps extends CommonProps {
    text?: undefined;
    icon: ButtonBaseProps['icon'];
    'aria-label': string;
}

export type ButtonProps = (TextButtonProps | IconButtonProps) & DataProps & AnchorProps;

export const Button: React.FC<ButtonProps> = props => {
    return <ButtonBase {...props} />;
};
