import React from 'react';

import type {Colors as LegacyColors} from 'web-app/styleguide/colors';
import {getThemeColor} from 'web-app/styleguide/color-helpers';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {currentTheme} from 'web-app/styleguide';

import {type IconFill, type SizeDescription} from './types';

type StandardSVGInputProps = React.SVGProps<SVGSVGElement> & {
    size: SizeDescription;
    className?: string;
};

/**
 * Adds some standard props to an SVG element
 */
export const StandardSVG: React.FC<React.PropsWithChildren<StandardSVGInputProps>> = props => {
    const {size, children, className, ...restProps} = props;

    const squareSize = getSizeFromSizeDescription(size);

    return (
        <svg
            width={squareSize}
            height={squareSize}
            className={className}
            viewBox={'0 0 24 24'}
            version={'1.1'}
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            {...restProps}
        >
            {children}
        </svg>
    );
};

export const getSizeFromSizeDescription = (size: SizeDescription): number | undefined => {
    switch (size) {
        case 'Small':
            return 20;
        case 'Medium':
            return 24;
        case 'Large':
            return 32;
        case 'SelfScaling':
            return undefined;
        default: {
            return size;
        }
    }
};

export const getColorOrDefault = (color?: IconFill) => {
    if (!hasValue(color)) {
        return getThemeColor('text');
    }

    if (typeof color === 'string') {
        return getThemeColor(color as LegacyColors);
    }

    return currentTheme().mf.colorPalette[color.color];
};
