import React from 'react';

import {Text} from '@famly/mf_data-display_text';
import {Stack} from '@famly/mf_layout_stack';
import {Box} from '@famly/mf_layout_box';
import {getInitials} from 'signin-app/util/get-initials';

import {PersonImage} from './person-image';

interface Props {
    image: string;
    name: string;
    subText?: string;
    subTextBold?: string | null;
}

export const UserOverview: React.FC<Props> = props => {
    const {image, name, subText, subTextBold} = props;

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            data-e2e-id="user-overview"
            color="n400"
        >
            <PersonImage image={image} initials={getInitials(name)} />
            <Box mt={2}>
                <Text variant="h5" emphasized>
                    {name}
                </Text>
            </Box>
            {Boolean(subText || subTextBold) && (
                <Stack alignItems="center">
                    <Text variant="body-small">
                        {subText} {subTextBold}
                    </Text>
                </Stack>
            )}
        </Stack>
    );
};
