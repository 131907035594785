import React from 'react';

import {Text} from '@famly/mf_data-display_text';
import {Stack} from '@famly/mf_layout_stack';
import {useBreakpoints} from '@famly/mf_util_breakpoints';
import {PersonImage} from 'signin-app/components/person-image';

export const HeaderPerson: React.FC<{
    image: string | undefined;
    initials: string;
    name: string;
}> = ({image, initials, name}) => {
    const {isTabletLandscapeAndLarger} = useBreakpoints();
    return (
        <Stack alignItems="center" flexDirection={isTabletLandscapeAndLarger ? 'row' : 'column'} gap={3}>
            <PersonImage size="sm" image={image} initials={initials} />
            <Text variant="h5" color="n400" emphasized data-e2e-id="head-person-name">
                {name}
            </Text>
        </Stack>
    );
};
