export type Visibility = 'Hidden' | 'Visible';

interface Closed {
    visibility: 'Hidden';
}

type Open<T> = {visibility: 'Visible'} & T;

export type ModalState<T> = Closed | Open<T>;

export type OpenState<ModalState> = ModalState extends Open<infer P> ? P : never;

export const open = <T = {}>(state: T) => {
    return {
        visibility: 'Visible',
        ...state,
    } as ModalState<T>;
};

export const close = <T>() => {
    return {
        visibility: 'Hidden',
    } as ModalState<T>;
};

export const isOpen = <T>(modalState: ModalState<T>): modalState is Open<T> => modalState.visibility === 'Visible';

export const isClosed = <T>(modalState: ModalState<T>): modalState is Closed => modalState.visibility === 'Hidden';

export type InferModalState<T> = T extends Open<infer InnerModalState> ? InnerModalState : never;
