import {
    type DefaultContext,
    type MutationHookOptions,
    type MutationTuple,
    type OperationVariables,
    useMutation,
} from '@apollo/client';
import {type DocumentNode} from 'graphql';
import {REST_GRAPHQL_CLIENTNAME} from '@famly/stat_apollo-client';

/**
 * Simple wrapper for apollo's `useMutation`.
 *
 * Adds the correct client name, so that the apollo client knows, that we want to
 * use the rest link for this mutation.
 *
 * Allows creating subscribable wrappers and cache updates, etc.
 *
 * @param mutation first parameter for the useMutation
 * @param options options parameter for the useMutation
 */
export function useRestMutation<TData = any, TVariables = OperationVariables>(
    mutation: DocumentNode,
    options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
    return useMutation(mutation, {...options, context: {clientName: REST_GRAPHQL_CLIENTNAME} as DefaultContext});
}
