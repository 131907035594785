import React from 'react';
import styled, {css} from 'styled-components';

import Button, {ButtonAppearance} from 'web-app/react/components/form-elements/button/button';
import TextTypes from 'web-app/styleguide/text-types';
import FormInput from 'web-app/react/components/form-elements/containers/form-input';
import {media} from 'web-app/styleguide/utils';
import AnimatedCheckmark from 'web-app/react/components/animated-checkmark/animated-checkmark';
import TextField from 'web-app/react/components/form-elements/text-field/text-field';
import type {Colors} from 'web-app/styleguide/colors';

import LoginChrome from './login-chrome';

export const LoginContainer = styled('div')<{wide?: boolean}>`
    width: 320px;
    max-width: calc(100vw - 48px);
    ${media.mobileSideways`
        width: 320px;
        ${props =>
            props.wide
                ? css`
                      width: 320px;
                  `
                : ''}
    `}
    ${media.notLandscape`
        @media (max-height: 600px) {
            width: 280px;
        }
    `}
`;

export const LoginTextFieldCSS = css<{activeColor?: Colors; passiveColor?: Colors}>`
    border: none;
    border-radius: 0;
    background-image: ${props =>
            `linear-gradient(${props.activeColor ? props.theme[props.activeColor] : props.theme.active}, ${
                props.activeColor ? props.theme[props.activeColor] : props.theme.active
            })`},
        ${props =>
            `linear-gradient(${props.passiveColor ? props.theme[props.passiveColor] : props.theme.delimiter}, ${
                props.passiveColor ? props.theme[props.passiveColor] : props.theme.delimiter
            })`};
    background-size: 0 2px, 100% 1px;
    background-position: left bottom, left calc(100% - 1px);
    background-repeat: no-repeat;
    background-color: transparent;
    padding: 16px 0;
    height: auto;
    font-size: ${props => props.theme.fontConfiguration.sizes[TextTypes.Subheader]};
    margin-bottom: 0;
    &:focus {
        border: none;
        background-size: 100% 2px, 100% 1px;
    }

    &,
    &:focus {
        transition: background-size 200ms ${props => props.theme.timings.functions.default};
    }
`;

export const LoginTextField = styled(TextField)`
    ${LoginTextFieldCSS}
`;

export const LoginFormTextField = styled(FormInput)<{activeColor?: Colors; passiveColor?: Colors; hidden?: boolean}>`
    ${LoginTextFieldCSS}

    ${props =>
        props.hidden
            ? css`
                  display: none;
              `
            : ''}
`;

export const LoginButtonStyle = css`
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontConfiguration.sizes.Subheader};
    font-weight: 600;
`;

export const SubmitButton = styled(Button).attrs(() => ({
    appearance: ButtonAppearance.confirm,
}))`
    ${LoginButtonStyle}
`;

interface SimpleLoginChromeInputProps {
    locale?: string;
    showGreenCheckMark?: boolean;
}

export const SimpleLoginChrome: React.FC<React.PropsWithChildren<SimpleLoginChromeInputProps>> = props => {
    return (
        <LoginChrome locale={props.locale}>
            <LoginContainer>
                <ContentWrapper>
                    {props.showGreenCheckMark ? <StyledAnimatedCheckmark animateCircle /> : null}
                    <InnerContentWrapper>{props.children}</InnerContentWrapper>
                </ContentWrapper>
            </LoginContainer>
        </LoginChrome>
    );
};

const ContentWrapper = styled.div`
    display: flex;
    ${media.mobile`
        flex-direction: column;
        align-items: center;
    `}
`;

const InnerContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${media.mobile`
        align-items: center;
        text-align: center;
    `}
`;

const StyledAnimatedCheckmark = styled(AnimatedCheckmark)`
    max-width: 100px;
    margin-right: 16px;
    circle,
    path {
        stroke: ${props => props.theme.accent1};
    }
    ${media.mobile`
        margin-bottom: 16px;
        margin-right: 0;
    `}
`;
