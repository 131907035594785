import {getHSLAChannels} from 'modern-famly';

const extractHslChannels = (hslColor: string) => {
    /* regex to extract the 3 channels that make the hsl*/
    const result = /^hsl\(?([0-9]{1,3}), ?([0-9]{1,3})%, ?([0-9]{1,3})% ?\)$/i.exec(hslColor);

    return result
        ? {
              h: result[1],
              s: result[2],
              l: result[3],
          }
        : null;
};

export const hslColorWithAlpha = (hslColor: string, alpha: number) => {
    const hsl = extractHslChannels(hslColor);
    if (!hsl) {
        return undefined;
    }

    return `hsla(${hsl.h}, ${hsl.s}%, ${hsl.l}%, ${alpha})`;
};

export const isHSLA = (color: string): color is HSLA => {
    return color.startsWith('hsla(');
};

// Adopted from https://stackoverflow.com/a/44134328
export const hslStringToRgb = (hsla: HSLA) => {
    // eslint-disable-next-line prefer-const
    let [h, s, l] = getHSLAChannels(hsla);

    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n: number) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
};

export const hslStringToHex = (hsla: HSLA) => {
    // eslint-disable-next-line prefer-const
    let [h, s, l] = getHSLAChannels(hsla);
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const toHex = (n: number) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    return `#${toHex(0)}${toHex(8)}${toHex(4)}`;
};
