import {currentTheme} from 'web-app/styleguide';
import {type Colors} from 'web-app/styleguide/colors';
import {type ITheme} from 'web-app/styleguide/themes/model';

export const getThemeColor = (
    color: Colors,
    secondary: boolean = false,
    disabled: boolean = false,
    theme: ITheme = currentTheme(),
) => {
    switch (color) {
        case 'text': {
            if (disabled) {
                return theme.textDisabled;
            } else if (secondary) {
                return theme.textSecondary;
            } else {
                return theme.text;
            }
        }
        case 'invertedText': {
            if (disabled) {
                return theme.invertedTextDisabled;
            } else if (secondary) {
                return theme.invertedTextSecondary;
            } else {
                return theme.invertedText;
            }
        }
        default:
            return theme[color];
    }
};
