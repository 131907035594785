import * as React from 'react';
import {styled} from '@mui/material/styles';
import {autocompleteClasses} from '@mui/material/Autocomplete';
import {Box} from '@famly/mf_layout_box';
import {Stack} from '@famly/mf_layout_stack';
import {Text} from '@famly/mf_data-display_text';

import {type SelectOption, type SelectProps} from 'modern-famly/components/input';
import {Avatar, Icon} from 'modern-famly/components/data-display';
import {type DataProps, useDataProps} from 'modern-famly/components/util';

const OPTION_HEIGHT = 40;
export const NUMBER_OF_OPTIONS_BEFORE_SCROLL = 7;

export type CommonStandaloneSelectProps = {
    /**
     * ID attribute of the input element.
     */
    id?: string;

    /**
     * The size of the component
     */
    size?: SelectProps<SelectOption>['size'];

    /**
     * List of options to display.
     */
    options: SelectOption[];
};

export type PopperComponentProps = {
    open: boolean;
    anchorEl?: any;
    disablePortal?: boolean;
};

export const PopperComponent = ({disablePortal, anchorEl, open, ...other}: PopperComponentProps) => (
    <StyledAutocompletePopper {...other} />
);

export type OptionProps = {
    props: React.HTMLAttributes<HTMLLIElement>;
    option: SelectOption;
    selected: boolean;
    size?: CommonStandaloneSelectProps['size'];
} & DataProps;

export const sxAutocomplete = {
    '& + .MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option': {
        padding: 0,
        borderRadius: 2,
    },
};

export const AutocompleteOption = ({props, option, selected, size}: OptionProps) => {
    const dataProps = useDataProps(option);

    return (
        <li {...props} key={option.value} {...dataProps}>
            <Stack
                justifyContent="space-between"
                alignItems="center"
                height={OPTION_HEIGHT}
                width="100%"
                paddingLeft={2}
                paddingRight={3}
            >
                <Stack justifyContent="space-between" alignItems="center" height={OPTION_HEIGHT} gap={2} minWidth={0}>
                    {option?.imageUrl ? <Avatar src={option.imageUrl} size={24} /> : null}
                    {option.icon ? (
                        <Icon size={20} name={option.icon.name} color={option.icon.color} filled={option.icon.filled} />
                    ) : null}
                    <Box overflow="hidden">
                        <Text variant={size === 'compact' ? 'body-small' : 'body'} ellipsis>
                            {option.label}
                        </Text>
                    </Box>
                </Stack>
                {selected ? <Icon name="check" size={24} color="p300" /> : null}
            </Stack>
        </li>
    );
};

export const componentsProps = {
    popper: {
        sx: {
            minWidth: 290,
            '::-webkit-scrollbar': {
                display: 'none',
            },
        },
    },
};

const StyledAutocompletePopper = styled('div')(({theme}) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.modernFamlyTheme.colorPalette.n0,
        padding: 0,
        maxHeight: NUMBER_OF_OPTIONS_BEFORE_SCROLL * OPTION_HEIGHT,

        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
                backgroundColor: theme.modernFamlyTheme.colorPalette.p50,
            },
        },
        '::-webkit-scrollbar': {
            display: 'none',
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));
