import React from 'react';
import i18next from 'i18next';
import {Button} from 'modern-famly';

import {Text} from '@famly/mf_data-display_text';
import {Stack} from '@famly/mf_layout_stack';
import {Box} from '@famly/mf_layout_box';
import {ModalHeader, ModalTopBar, ModalBody} from 'web-app/react/components/modals/modals';
import translate from 'signin-app/helpers/translate';

type Props = {onModalClose: VoidFunction; onConfirm: VoidFunction; siteName: string};

export const LogoutWarningModal = ({onModalClose, onConfirm, siteName}: Props) => {
    return (
        <Box pb={6} pt={6} pr={2} pl={2} data-e2e-id="logout-warning-modal">
            <ModalTopBar onCloseModal={onModalClose}>
                <ModalHeader
                    header={translate('logoutConfirmationTitle', {
                        siteName,
                    })}
                />
                <div></div>
            </ModalTopBar>
            <ModalBody>
                <Text variant="body" mt={4}>
                    {translate('logoutConfirmationDescription', {
                        siteName,
                    })}
                </Text>
            </ModalBody>
            <Stack gap={4} pr={4} mt={4} justifyContent="flex-end">
                <Button variant="tertiary" text={i18next.t('cancel')} onClick={onModalClose} />
                <Button
                    variant="primary"
                    text={translate('logoutConfirmationButton')}
                    onClick={onConfirm}
                    data-e2e-id="confirm-logout-button"
                />
            </Stack>
        </Box>
    );
};
