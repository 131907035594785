import {BBOdysseyTheme as BBOdysseyMFTheme} from 'modern-famly';

import {hexToRGBA} from 'web-app/util/hex-to-rgb';

import BaseTheme from './base';

const BBOdysseyChromeColor = '#5A003D';
const BBOdysseyPrimary = BBOdysseyChromeColor;
const BBOdysseyActive = hexToRGBA(BBOdysseyChromeColor, 0.8);
const BBOdysseyBackgroundHover = hexToRGBA(BBOdysseyChromeColor, 0.04);

const BBOdysseyTheme = BaseTheme.merge({
    title: 'Odyssey Global Preschool',
    chromeColor: BBOdysseyChromeColor,
    primary: BBOdysseyPrimary,
    active: BBOdysseyActive,
    backgroundHover: BBOdysseyBackgroundHover,
    mf: BBOdysseyMFTheme,
});

export default BBOdysseyTheme;
