/* eslint-disable no-restricted-imports */
import React from 'react';
import MuiDialogContent from '@mui/material/DialogContent';

/**
 * A simple wrapper for the content of the Dialog, which adds correct padding and
 * allows for listening to scroll actions for use with the `scrollable` and
 * `scrolledToBottom` props on the dialog buttons
 */
export const DialogContent = React.forwardRef<
    HTMLElement,
    React.PropsWithChildren<{
        isScrollable?: boolean;
        onScroll?: React.UIEventHandler<HTMLDivElement>;
    }>
>(
    (
        {
            isScrollable,
            onScroll,
            children,
        }: React.PropsWithChildren<{
            isScrollable?: boolean;
            onScroll?: React.UIEventHandler<HTMLDivElement> | undefined;
        }>,
        ref,
    ) => {
        return (
            <MuiDialogContent
                ref={ref}
                onScroll={onScroll}
                sx={{
                    paddingX: 6,
                    paddingY: isScrollable ? 6 : 0,
                }}
            >
                {children}
            </MuiDialogContent>
        );
    },
);
