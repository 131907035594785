import React from 'react';
import IconButton from '@mui/material/IconButton';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {Box} from '@famly/mf_layout_box';
import {Stack} from '@famly/mf_layout_stack';
import {Text} from '@famly/mf_data-display_text';

import {type DataProps, useDataProps} from 'modern-famly/components/util';
import {Icon, type IconName} from 'modern-famly/components/data-display/icon';
import {Button, type ButtonProps} from 'modern-famly/components/input';
import {useBreakpoints} from 'modern-famly/theming';

type NoticeSeverity = 'info' | 'warning' | 'error' | 'success';

interface CommonProps {
    /**
     * id of the Notice
     */
    id?: string;

    /**
     * Title to display in the header of the Notice
     */
    title?: string;

    /**
     * Text to display in the main section of the Notice
     *
     * Can be provided as a string or a React node.
     * The latter's use-case is to allow for more complex content, such as a Markdown component
     * which is often used to render rich formatting (e.g. inline links).
     */
    text: string | React.ReactNode;

    /**
     * Controls the icon and background color of the Notice
     *
     * @default info
     */
    severity?: NoticeSeverity;
}

/**
 * A simple notice that does not have "X" button nor a regular button
 */
interface SimpleNoticeProps extends CommonProps {
    onDismiss?: never;
    button?: never;
}

interface DismissibleNoticeProps extends CommonProps {
    /**
     * Triggered when closing the Notice
     */
    onDismiss: () => void;
    button?: never;
}

interface NoticeWithButtonProps extends CommonProps {
    onDismiss?: never;
    button: {
        /**
         * Text to display on the button
         */
        text: string;

        /**
         * Triggered when button in pressed
         */
        onClick: () => void;

        /**
         * Loader that will display instead of the button text, button is disabled if the loader is showing
         */
        isLoading?: boolean;

        /**
         * The icon name to display on the left side of the button
         */
        icon?: ButtonProps['icon'];
    };
}
export type NoticeProps = (DismissibleNoticeProps | NoticeWithButtonProps | SimpleNoticeProps) & DataProps;

export const Notice = (props: NoticeProps) => {
    const {id, text, severity, onDismiss, button, title} = props;
    const dataProps = useDataProps(props);
    const {isTabletPortraitAndLarger} = useBreakpoints();

    const config: {icon: IconName; color: keyof FixedPalette} = React.useMemo(() => {
        switch (severity) {
            case 'warning':
                return {icon: 'emergency_home' as IconName, color: 'y400'};
            case 'success':
                return {icon: 'done_all' as IconName, color: 'g400'};
            case 'error':
                return {icon: 'error' as IconName, color: 'r400'};
            default:
                return {icon: 'info' as IconName, color: 'b400'};
        }
    }, [severity]);

    return (
        <Stack
            id={id}
            borderRadius={2}
            borderStyle="solid"
            borderWidth="1px"
            padding={3}
            borderColor={config.color}
            {...dataProps}
            backgroundColor="n0"
        >
            <Stack marginRight={2} alignItems={hasValue(title) || !isTabletPortraitAndLarger ? 'flex-start' : 'center'}>
                <Icon name={config.icon} color={config.color} size={iconSize} />
            </Stack>
            <Stack
                flexDirection={isTabletPortraitAndLarger ? 'row' : 'column'}
                gap={isTabletPortraitAndLarger ? 6 : 2}
                flex={1}
                alignItems={!isTabletPortraitAndLarger && hasValue(title) ? 'flex-start' : 'center'}
            >
                <Box marginRight="auto">
                    {hasValue(title) ? (
                        <Text variant="body" emphasized marginBottom={1}>
                            {title}
                        </Text>
                    ) : null}
                    {typeof text === 'string' ? <Text variant="body-small">{text}</Text> : text}
                </Box>
                {hasValue(button) ? (
                    <Stack
                        alignSelf={isTabletPortraitAndLarger ? 'center' : 'flex-start'}
                        marginLeft={isTabletPortraitAndLarger ? 6 : 0}
                        flexShrink={0}
                    >
                        <Button
                            data-e2e-id="notice-action"
                            text={button.text}
                            variant="tertiary"
                            onClick={button.onClick}
                            size="compact"
                            isLoading={button.isLoading}
                            icon={button.icon}
                        />
                    </Stack>
                ) : null}
            </Stack>
            {hasValue(onDismiss) ? (
                <Stack alignItems="center" alignSelf="flex-start">
                    <IconButton aria-label="close" size="small" data-e2e-id="notice-close" onClick={onDismiss}>
                        <Icon name="close" color="n400" size={iconSize} />
                    </IconButton>
                </Stack>
            ) : null}
        </Stack>
    );
};

const iconSize = 20;
