import React from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment-timezone';

import {Text} from '@famly/mf_data-display_text';
import {Stack} from '@famly/mf_layout_stack';
import {Box} from '@famly/mf_layout_box';
import {useBreakpoints} from '@famly/mf_util_breakpoints';
import * as PrivateRouteSelectors from 'signin-app/components/private-route/selectors';
import * as DisplayedGroupsModalActions from 'signin-app/modals-container/displayed-groups-modal/actions';
import translate from 'signin-app/helpers/translate';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import DisplayedGroupsSettings from 'signin-app/modals-container/displayed-groups-modal/displayed-groups-modal';
import {useTypedSelector} from 'signin-app/components/hooks';
import {SettingsDropdown} from 'signin-app/components/settings-dropdown';
import {isOpen} from 'web-app/react/modals-container/types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {usePull} from 'signin-app/helpers/use-pull';
import {accessToken} from 'signin-app/login/selectors';
import * as Actions from 'signin-app/components/private-route/actions';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {HeaderLogoContainer} from 'signin-app/components/containers';

import * as Selectors from './selectors';
import {StaffSection} from './staff-section';
import {ChildrenSection} from './children-section';

export const Groups: React.FC<{}> = () => {
    const institution = useTypedSelector(Selectors.institution);
    const displayedStaffGroups = useTypedSelector(Selectors.displayedStaffGroups);
    const isFetching = useTypedSelector(PrivateRouteSelectors.isPrefetching);
    const didPrefetch = useTypedSelector(PrivateRouteSelectors.didPrefetch);
    const lastPrefetch = useTypedSelector(PrivateRouteSelectors.lastPrefetch);
    const isSettingsModalOpen = useTypedSelector((state: RootState) => isOpen(state.modals.displayedGroupsSettings));
    const token = useTypedSelector(accessToken);
    const whitelabelConfiguration = useTypedSelector(state => WhitelabelSelectors.whiteLabelConfiguration(state));
    const enableEmployeeCheckin = useTypedSelector(Selectors.enableEmployeeCheckin);

    const dispatch = useDispatch();
    const {isTabletPortraitAndLarger} = useBreakpoints();

    const [isHeaderInView, setIsHeaderInView] = React.useState(true);

    const headerRef = React.useRef<HTMLDivElement>(null);

    const handlePullDown = React.useCallback(() => dispatch(Actions.prefetch.action(token)), [dispatch, token]);
    const {onTouchStart, onTouchMove, onTouchEnd, marginTop} = usePull({
        minPullDistance: 100,
        onPullDown: handlePullDown,
        preventFromRefreshing: !isHeaderInView,
    });

    React.useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current) {
                const headerRect = headerRef.current.getBoundingClientRect();
                setIsHeaderInView(headerRect.bottom > 0);
            }
        };
        const root = document.getElementById('root');
        root?.addEventListener('scroll', handleScroll);

        return () => {
            root?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const lastUpdated = React.useMemo(() => {
        if (!lastPrefetch) {
            return null;
        }

        return `${translate('lastUpdated')} ${moment(lastPrefetch).format('HH:mm')}`;
    }, [lastPrefetch]);

    if (isFetching || !didPrefetch) {
        return <Spinner centered />;
    }

    return (
        <>
            {marginTop !== 0 && <Spinner centered />}
            <Box onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} mt={`${marginTop}`}>
                <Stack
                    flexDirection="column"
                    px={{base: 4, tabletPortrait: 10}}
                    pt={{base: 8, tabletPortrait: 10}}
                    pb={{base: 15, tabletPortrait: 30}}
                >
                    <Stack alignItems="center" justifyContent="space-between" ref={headerRef}>
                        <HeaderLogoContainer>
                            <whitelabelConfiguration.LogoForWhiteBg />
                        </HeaderLogoContainer>
                        <Stack alignItems="center">
                            {lastUpdated && (
                                <Text variant="body-small" color="n400" textAlign="center" mt={2} mr={6}>
                                    {lastUpdated}
                                </Text>
                            )}
                            <SettingsDropdown
                                settings={[
                                    {
                                        label: translate('settings'),
                                        onClick: () => dispatch(DisplayedGroupsModalActions.show.action()),
                                    },
                                ]}
                                siteName={institution?.title}
                                showWarning
                            />
                        </Stack>
                    </Stack>
                    <Text
                        variant={isTabletPortraitAndLarger ? 'h3' : 'h4'}
                        color="n400"
                        mt={10}
                        data-e2e-id="sign-in-title"
                    >
                        {translate('signInTitle')}
                    </Text>
                    <Stack
                        direction="column"
                        mt={{base: 8, tabletPortrait: 12}}
                        mb={{base: 15, mobileLandscape: 0}}
                        gap={18}
                        data-e2e-id="signin-overview"
                    >
                        <ChildrenSection />
                        {enableEmployeeCheckin && displayedStaffGroups.size > 0 && <StaffSection />}
                    </Stack>
                </Stack>
                {isSettingsModalOpen && <DisplayedGroupsSettings />}
            </Box>
        </>
    );
};

export default Groups;
