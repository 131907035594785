import React from 'react';
import {Link} from 'react-router-dom';

import {Text} from '@famly/mf_data-display_text';
import {useBreakpoints} from '@famly/mf_util_breakpoints';
import {Stack} from '@famly/mf_layout_stack';
import {SelectablePersonButton} from 'signin-app/components/selectable-person-button';
import {pinChildren} from 'signin-app/child/selectors';
import {pinEmployees} from 'signin-app/employee/selectors';
import * as CheckOutSelectors from 'signin-app/child/check-out/selectors';
import translate from 'signin-app/helpers/translate';
import {getInitials} from 'signin-app/util/get-initials';

import {useTypedSelector} from './hooks';

interface ChildSelectProps {
    activeChildId?: string;
    activeEmployeeId?: string;
    className?: string;
}

const VerticalDivider = () => {
    return <Stack width="1px" height="95px" backgroundColor="n100" mx={4} />;
};

const PersonSelect: React.FC<ChildSelectProps> = ({className, activeChildId, activeEmployeeId}) => {
    const children = useTypedSelector(pinChildren);
    const employees = useTypedSelector(pinEmployees);
    const isDataLimited = useTypedSelector(CheckOutSelectors.limitedData);
    const allPinPersons = children?.concat(employees);
    const {isMobileLandscapeAndLarger} = useBreakpoints();

    if (!children || (allPinPersons && allPinPersons.size < 2)) {
        return null;
    }

    return (
        <>
            <Text variant="h6" color="n400" emphasized>
                {translate('whoToSignIn')}
            </Text>
            <Stack className={className} mb={12} flexWrap="wrap" mt={2}>
                <Stack gap={4} flexWrap="wrap">
                    {children.map(child => {
                        const childUrl = `/child/${child.id}/${child.checkedIn ? 'checkout' : 'checkin'}`;

                        return (
                            <Link to={childUrl} key={child.id}>
                                <SelectablePersonButton
                                    key={child.id}
                                    image={child.image.large}
                                    name={child.name.firstName}
                                    initials={getInitials(isDataLimited ? child.name.firstName : child.name.fullName)}
                                    selected={child.id === activeChildId}
                                    size="md"
                                />
                            </Link>
                        );
                    })}
                </Stack>
                {employees && employees.size > 0 ? (
                    // Show divider on larger screens, margin top on mobile
                    <Stack alignItems="center" mt={{base: 6, mobileLandscape: 0}}>
                        {isMobileLandscapeAndLarger && <VerticalDivider />}
                        <Stack gap={4} flexWrap="wrap">
                            {employees.map(employee => {
                                const employeeUrl = `/employee/${employee.id}/${
                                    employee.checkedIn ? 'checkout' : 'checkin'
                                }`;

                                return (
                                    <Link to={employeeUrl} key={employee.id}>
                                        <SelectablePersonButton
                                            key={employee.id}
                                            image={employee.image.large}
                                            name={employee.name.firstName}
                                            initials={`${employee.name.firstName.charAt(
                                                0,
                                            )}${employee.name.lastName.charAt(0)}`}
                                            selected={employee.id === activeEmployeeId}
                                            size="md"
                                        />
                                    </Link>
                                );
                            })}
                        </Stack>
                    </Stack>
                ) : null}
            </Stack>
        </>
    );
};

export default PersonSelect;
