import React from 'react';
import {Icon} from 'modern-famly';

import {Stack} from '@famly/mf_layout_stack';
import {Box} from '@famly/mf_layout_box';
import {history} from 'signin-app/redux/store';

interface HeaderProps {
    rightElement?: React.ReactNode;
    centerElement?: React.ReactNode;
}

const HeaderBackButton = () => {
    const handleOnClick = React.useCallback(() => {
        history.goBack();
    }, []);

    return (
        <Stack
            borderRadius="50%"
            width="48px"
            height="48px"
            alignItems="center"
            justifyContent="center"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="n200"
            onClick={handleOnClick}
            flexShrink={0}
            cursor="pointer"
        >
            <Icon name="arrow_back" color="n400" />
        </Stack>
    );
};

const Header = ({rightElement, centerElement}: HeaderProps) => {
    return (
        <Stack justifyContent="space-between" alignItems="center" gap={3}>
            <HeaderBackButton />
            {centerElement}
            <Box>{rightElement}</Box>
        </Stack>
    );
};

export default Header;
