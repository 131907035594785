import {Map, Set, List, OrderedSet, Record} from 'immutable';

import {type ReducerExtension} from 'web-app/react/entities/factory';
import {type ActionsBundle, type EventMap} from 'web-app/react/entities/factory/actions-factory';
import {type ActionDescription, type Action} from 'web-app/util/redux/action-types';
import {makeReducer, match, type ReducerDescription} from 'web-app/util/redux';

import {type InternalAction, StoreKey} from './constants';

type ReferenceMap = Map<string, OrderedSet<string>>;

export type RecordInstance<IRecord extends {}> = InstanceType<Record.Factory<IRecord>>;

export interface IEntityState<IRecord extends {}, TRecordInstance = RecordInstance<IRecord>> {
    // Map from model.id -> model instance
    entityMap: Map<string, TRecordInstance>;

    // Map from reference.id -> List of model.ids found in the entityMap
    // Use the groupByKeys config key to populate the referenceMap
    referenceMap: ReferenceMap;

    // Keeps track of temporary loading state
    creating: boolean;
    fetchingSet: Set<string>;
    updatingSet: Set<string>;
    deletingSet: Set<string>;
}

export const entityStateFactory = <IRecord extends {}>() => {
    return Record<IEntityState<IRecord>>({
        // Map from model.id -> model instance
        entityMap: Map(),

        // Map from reference.id -> List of model.ids found in the entityMap
        // Use the groupByKeys config key to populate the referenceMap
        referenceMap: Map(),

        // Keeps track of temporary loading state
        creating: false,
        fetchingSet: Set(),
        updatingSet: Set(),
        deletingSet: Set(),
    });
};

export type EntityState<IRecord extends {[key: string]: any}> = InstanceType<Record.Factory<IEntityState<IRecord>>>;

/**
 * Helper function to keep the reference map up to date
 * @param referenceMap
 * @param groupByKeys
 * @param items
 * @returns {*}
 */
export const updateReferenceMap = <IRecord extends {[key: string]: any}>(
    referenceMap: ReferenceMap,
    items: List<Record<IRecord>>,
    groupByKeys?: string[],
) => {
    if (!groupByKeys || groupByKeys.length === 0) {
        return referenceMap;
    }

    return groupByKeys.reduce((reduction, refKey) => {
        const idsToAdd = items
            .groupBy(item => item.get<string>(refKey, List()))
            .map(group => group.map((item: any) => item.id).toOrderedSet());

        return idsToAdd.reduce((m1, ids, key) => m1.update(key, OrderedSet(), set => set.union(ids)), reduction);
    }, referenceMap);
};

/**
 * Helper function to keep the reference map up to date
 * @param referenceMap
 * @param referenceId (Key in referenceMap)
 * @param idToRemove (Id found in the value/List under the key)
 * @returns {*}
 */
const removeFromReferenceMap = ({
    referenceMap,
    referenceId,
    idToRemove,
}: {
    referenceMap: ReferenceMap;
    referenceId: string;
    idToRemove: string;
}) =>
    referenceMap.get(referenceId, List()).size === 1 && referenceMap.get(referenceId, List()).first() === idToRemove
        ? referenceMap.delete(referenceId)
        : referenceMap.update(referenceId, referenceList => referenceList.filter(id => id !== idToRemove));

const replaceInReferenceMap = ({
    referenceMap,
    referenceId,
    toReplace,
    replaceWith,
}: {
    referenceMap: ReferenceMap;
    referenceId: string;
    toReplace: string;
    replaceWith: string;
}) => referenceMap.update(referenceId, list => list.map(entityId => (entityId === toReplace ? replaceWith : entityId)));

const addToReferenceMap = ({
    referenceMap,
    referenceId,
    idToAdd,
}: {
    referenceMap: ReferenceMap;
    referenceId: string;
    idToAdd: string;
}) => referenceMap.update(referenceId, list => (list ? list.add(idToAdd) : OrderedSet([idToAdd])));

const createActionHandlers = <IRecord extends {[key: string]: any}>(
    groupByKeys?: string[],
    create?: EventMap,
): Array<ReducerDescription<EntityState<IRecord>>> => {
    if (!create) {
        return [];
    }

    return [
        match(create.start, state => state.merge({creating: true})),
        match(create.success, (state, {payload}) => {
            const response: RecordInstance<IRecord> = payload.response;
            const id: string = response.get('id', undefined);
            const newEntityMap = state.entityMap.set(id, response);
            const referenceMap = updateReferenceMap(state.referenceMap, List([response]), groupByKeys);

            return state.merge({
                creating: false,
                entityMap: newEntityMap,
                referenceMap,
            });
        }),
        match(create.failed, state => state.merge({creating: false})),
        match(create.aborted, state => state.merge({creating: false})),
    ];
};

const fetchActionHandlers = <IRecord extends {}>(
    groupByKeys: string[],
    fetch?: EventMap,
): Array<ReducerDescription<EntityState<IRecord>>> => {
    if (!fetch) {
        return [];
    }

    return [
        match(fetch.start, (state, {payload}) => state.merge({fetchingSet: state.fetchingSet.add(payload.id)})),
        match(fetch.success, (state, {payload}) => {
            const response = payload.response;
            const referenceMap = updateReferenceMap(state.referenceMap, List([response]), groupByKeys);

            return state.merge({
                fetchingSet: state.fetchingSet.remove(response.id),
                entityMap: state.entityMap.set(response.id, response),
                referenceMap,
            });
        }),
        match(fetch.failed, (state, {payload}) =>
            state.merge({fetchingSet: state.fetchingSet.remove(payload.originalPayload.id)}),
        ),
        match(fetch.aborted, (state, {payload}) =>
            state.merge({fetchingSet: state.fetchingSet.remove(payload.originalPayload.id)}),
        ),
    ];
};

const fetchAllActionHandlers = <IRecord extends {[key: string]: any}>(
    groupByKeys: string[],
    fetchAll?: EventMap,
    entityIdentifier?: string,
): Array<ReducerDescription<EntityState<IRecord>>> => {
    if (!fetchAll) {
        return [];
    }

    return [
        match(fetchAll.start, (state, {payload}) => state.merge({fetchingSet: state.fetchingSet.add(payload.id)})),
        match(fetchAll.success, (state, {payload}) => {
            type TRecordInstance = RecordInstance<IRecord>;
            const response: List<TRecordInstance> = payload.response;
            const identifier = entityIdentifier ? entityIdentifier : 'id';

            const successMap = Map<string, TRecordInstance>(
                response.map((item): [string, TRecordInstance] => [item.get(identifier, undefined), item]),
            );
            const filteredReferenceMap = payload.originalPayload.ids
                ? state.referenceMap.filterNot((_, id) => payload.originalPayload.ids.includes(id))
                : state.referenceMap.remove(payload.originalPayload.id);
            const referenceMap = updateReferenceMap(filteredReferenceMap, payload.response, groupByKeys);

            return state.merge({
                fetchingSet: state.fetchingSet.remove(payload.originalPayload.id),
                entityMap: payload.originalPayload.clearStore ? successMap : state.entityMap.merge(successMap),
                referenceMap,
            });
        }),
        match(fetchAll.failed, (state, {payload}) =>
            state.merge({fetchingSet: state.fetchingSet.remove(payload.originalPayload.id)}),
        ),
        match(fetchAll.aborted, (state, {payload}) =>
            state.merge({fetchingSet: state.fetchingSet.remove(payload.originalPayload.id)}),
        ),
    ];
};

const updateActionHandlers = <IRecord extends {[key: string]: any}>(
    groupByKeys: string[],
    update?: EventMap,
): Array<ReducerDescription<EntityState<IRecord>>> => {
    if (!update) {
        return [];
    }

    return [
        match(update.start, (state, {payload}) => state.merge({updatingSet: state.updatingSet.add(payload.id)})),
        match(update.success, (state, {payload}) => {
            const response = payload.response;
            const idHasChanged = response.id !== payload.originalPayload.id;
            const originalEntity = state.entityMap.get(payload.originalPayload.id);

            if (!originalEntity) {
                return state;
            }

            return state.merge({
                updatingSet: state.updatingSet.remove(payload.originalPayload.id),
                entityMap: idHasChanged
                    ? state.entityMap.delete(payload.originalPayload.id).set(response.id, response)
                    : state.entityMap.set(response.id, response),
                referenceMap: groupByKeys.reduce((reduction, refKey) => {
                    const originalReferenceId = originalEntity.get(refKey, undefined);
                    const newReferenceId = response.get(refKey);
                    const referenceIdHasChanged = newReferenceId !== originalReferenceId;

                    if (!newReferenceId && referenceIdHasChanged) {
                        return removeFromReferenceMap({
                            referenceMap: reduction,
                            referenceId: originalReferenceId,
                            idToRemove: payload.originalPayload.id,
                        });
                    } else if (idHasChanged) {
                        if (referenceIdHasChanged) {
                            const reducedState = removeFromReferenceMap({
                                referenceMap: reduction,
                                referenceId: originalReferenceId,
                                idToRemove: payload.originalPayload.id,
                            });
                            return addToReferenceMap({
                                referenceMap: reducedState,
                                referenceId: newReferenceId,
                                idToAdd: response.id,
                            });
                        } else {
                            return replaceInReferenceMap({
                                referenceMap: reduction,
                                referenceId: originalReferenceId,
                                toReplace: originalEntity.get('id', undefined),
                                replaceWith: response.id,
                            });
                        }
                    } else if (referenceIdHasChanged) {
                        const reducedState = removeFromReferenceMap({
                            referenceMap: reduction,
                            referenceId: originalReferenceId,
                            idToRemove: payload.originalPayload.id,
                        });
                        return addToReferenceMap({
                            referenceMap: reducedState,
                            referenceId: newReferenceId,
                            idToAdd: payload.originalPayload.id,
                        });
                    }

                    return reduction;
                }, state.referenceMap),
            });
        }),
        match(update.failed, (state, {payload}) =>
            state.merge({updatingSet: state.updatingSet.remove(payload.originalPayload.id)}),
        ),
        match(update.aborted, (state, {payload}) =>
            state.merge({updatingSet: state.updatingSet.remove(payload.originalPayload.id)}),
        ),
    ];
};

const deleteActionHandlers = <IRecord extends {[key: string]: any}>(
    groupByKeys: string[],
    deleteAction?: EventMap,
): Array<ReducerDescription<EntityState<IRecord>>> => {
    if (!deleteAction) {
        return [];
    }

    return [
        match(deleteAction.start, (state, {payload}) => state.merge({deletingSet: state.deletingSet.add(payload.id)})),
        match(deleteAction.success, (state, {payload}) =>
            state.merge({
                deletingSet: state.deletingSet.remove(payload.originalPayload.id),
                entityMap: state.entityMap.remove(payload.originalPayload.id),
                referenceMap: groupByKeys.reduce((reduction, id) => {
                    const entity = state.entityMap.get(payload.originalPayload.id);

                    if (!entity) {
                        return reduction;
                    }

                    return removeFromReferenceMap({
                        referenceMap: reduction,
                        referenceId: entity.get(id, undefined),
                        idToRemove: payload.originalPayload.id,
                    });
                }, state.referenceMap),
            }),
        ),
        match(deleteAction.failed, (state, {payload}) =>
            state.merge({deletingSet: state.deletingSet.remove(payload.originalPayload.id)}),
        ),
        match(deleteAction.aborted, (state, {payload}) =>
            state.merge({deletingSet: state.deletingSet.remove(payload.originalPayload.id)}),
        ),
    ];
};

const internalActionHandlers = <IRecord extends {[key: string]: any}>(
    groupByKeys: string[],
    internalActions: {[K in InternalAction]: ActionDescription<any>},
): Array<ReducerDescription<EntityState<IRecord>>> => {
    return [
        match(internalActions.save, (state, {payload}: {payload: List<Record<IRecord>>}) => {
            const successMap = Map(payload.map((item: any): [string, RecordInstance<IRecord>] => [item.id, item]));
            const referenceMap = updateReferenceMap<IRecord>(state.referenceMap, payload, groupByKeys);

            return state.merge({
                entityMap: state.entityMap.merge(successMap),
                referenceMap,
            });
        }),
    ];
};

const getReducerArray = <IRecord extends {[key: string]: any}>(
    actions: ActionsBundle,
    groupByKeys: string[] = [],
    entityIdentifier?: string,
): Array<ReducerDescription<EntityState<IRecord>>> => {
    return [
        ...internalActionHandlers<IRecord>(groupByKeys, actions.internalActions),
        ...createActionHandlers<IRecord>(groupByKeys, actions.create),
        ...fetchActionHandlers<IRecord>(groupByKeys, actions.fetch),
        ...fetchAllActionHandlers<IRecord>(groupByKeys, actions.fetchAll, entityIdentifier),
        ...updateActionHandlers<IRecord>(groupByKeys, actions.update),
        ...deleteActionHandlers<IRecord>(groupByKeys, actions.delete),
    ];
};

/**
 * Creates a reducer for the input actions. Allows the reducerExtension to manipulate the state
 * @param actions
 * @param reducerExtension
 * @param groupByKeys The reducers will group items that have the same value on each of these keys. The groupings are
 * stored in the referenceMap
 * @returns {function(*=, *=)}
 */
export const createReducer = <IRecord extends {}>(
    actions: ActionsBundle,
    reducerExtension?: ReducerExtension<EntityState<IRecord>, Action<any>>,
    groupByKeys?: string[],
    entityIdentifier?: string,
) => {
    const reducerMap = getReducerArray<IRecord>(actions, groupByKeys, entityIdentifier);
    const EntityStateFactory = entityStateFactory<IRecord>();
    const standardReducer = makeReducer(reducerMap, new EntityStateFactory());

    const identityReducer = (state: EntityState<IRecord>) => state;
    const guaranteedReducerExtension = reducerExtension || identityReducer;

    return (state: EntityState<IRecord>, action: Action<any>) => {
        const newState = standardReducer(state, action);
        const reducerExtensionState = guaranteedReducerExtension(newState, action);

        return newState.merge(reducerExtensionState);
    };
};

export const getReduxKeyPath = (domain: string) => {
    return `${StoreKey}.${domain}`;
};
