import React, {type ComponentType, createElement} from 'react';
import {createRoot} from 'react-dom/client';

import {hasValue} from '@famly/stat_ts-utils_has-value';

const cache = {};
/**
 * Renders the given component (icon) as static HTML, which is then optionally encoded and
 * can also be cached.
 *
 * This was introduced when a (legacy) JSX SVG icon was needed to be put into the background
 * CSS property, which can naturally not hold JSX items.
 *
 * This is a frontend rendered and reduced version of the react-dom/server's `renderToStaticMarkup`
 *
 * @example for a background-image: url("data:image/svg+xml,${svgString}"), whereas the svgString
 * is the return value of this hook
 * @see {@link https://react.dev/reference/react-dom/server/renderToStaticMarkup}
 * @param Component Icon component to be rendered to static HTML
 * @param props Props to be passed to the icon
 * @param encode true, if you want encode the result. E.g. needed for the background prop
 * @param propsToCacheKey caching function. If passed, the result will be cached in an object
 * @returns the optionally encoded and/or cached static html icon rendered from the JSX svg
 * @author Domi <dr@famly.co>
 */
export function useMarkupString<C extends ComponentType<React.PropsWithChildren<T>>, T extends {} = {}>(
    Component: C,
    props: T,
    encode?: boolean,
    propsToCacheKey?: (props: T) => string,
) {
    const [markup, setMarkup] = React.useState('');
    const key = propsToCacheKey && propsToCacheKey(props);

    const containerDiv = React.useMemo(() => document.createElement('div'), []);

    React.useEffect(() => {
        // render the icon element to the container created above
        // as this icon might be lazy loaded, we cannot just expect the
        // rendering to be fully done immediately.
        const containerRoot = createRoot(containerDiv);
        containerRoot.render(createElement<T>(Component, props));

        // instead, we create a mutation observer on the container, which will
        const observer = new MutationObserver(() =>
            // update the result markup and optionally encode it
            setMarkup(() => (encode ? encodeURIComponent(containerDiv.innerHTML) : containerDiv.innerHTML)),
        );

        // whenever the ReactDOM.render function is rendering the icon into
        // the container div
        observer.observe(containerDiv, {subtree: true, childList: true, attributes: true});
        return () => {
            observer.disconnect();
            containerRoot.unmount();
        };
    }, [Component, containerDiv, encode, props]);

    // If caching is enabled and there is a markup already cached
    if (hasValue(key) && hasValue(cache[key]) && cache[key].length > 0) {
        // return the cached markup
        return cache[key];
    }

    // cache the result if expected to
    if (hasValue(key)) {
        cache[key] = markup;
    }

    // and return the result markup, be it cached or encoded or both
    return markup;
}
