import React from 'react';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import {Text} from '@famly/mf_data-display_text';
import {Box} from '@famly/mf_layout_box';
import {Stack} from '@famly/mf_layout_stack';

import {Button, type ButtonProps} from 'modern-famly/components/input';
import {Icon, type IconName} from 'modern-famly/components/data-display/icon';
import {type DataProps, useDataProps} from 'modern-famly/components/util';
import {exhaustiveCheck, hasValue} from 'modern-famly/util';
import {useBreakpoints} from 'modern-famly/theming';

import {BannerTestAttributes as TestAttributes} from './banner-test-attributes';

type BannerSeverity = 'neutral' | 'info' | 'warning' | 'upsell' | 'error';

type LinkProps = {
    /**
     * URL to use in the link
     */
    href: string;

    /**
     * Text to display in link
     */
    text: string;

    /**
     * Target attribute for the link
     */
    target?: HTMLAnchorElement['target'];
};

export type BannerProps = {
    /**
     * Text to display in the main section of the Banner
     */
    text: string;

    /**
     * Icon to replace default icon
     */
    icon?: IconName;

    /**
     * Controls the icon and background color of the Banner
     *
     * @default standard
     */
    severity?: BannerSeverity;

    /**
     * Triggered when closing the Banner
     */
    onDismiss?: () => void;

    /**
     * Display a link in the Banner
     */
    link?: LinkProps;

    /**
     * Displays a button in the Banner
     */
    button?: {
        /**
         * Text to display on the button
         */
        text: string;

        /**
         * Triggered when button in pressed
         */
        onClick: () => void;

        /**
         * Loader that will display instead of the button text, button is disabled if the loader is showing
         */
        isLoading?: boolean;

        /**
         * The icon name to display on the left side of the button
         */
        icon?: ButtonProps['icon'];
    };
} & DataProps;

export const Banner = (props: BannerProps) => {
    const {text, severity = 'neutral', icon, button, link, onDismiss} = props;

    const {isMobileLandscapeAndLarger: shouldUseWideLayout} = useBreakpoints();
    const dataProps = useDataProps(props);

    const defaultIcon = getDefaultIcon(severity);

    const hasButton = hasValue(button);
    const hasLink = hasValue(link);
    const shouldRenderSecondRow = !shouldUseWideLayout && (hasButton || hasLink);
    const isWideButtonLayout = hasButton && shouldUseWideLayout;

    return (
        <BannerContainer
            severity={severity}
            shouldUseWideLayout={shouldUseWideLayout}
            isWideButtonLayout={isWideButtonLayout}
            {...dataProps}
        >
            <Stack direction="row" alignItems="flex-start">
                <Stack height={isWideButtonLayout ? minHeightWithButton : minHeightWithoutButton} alignItems="center">
                    <Icon name={icon ?? defaultIcon} size={iconSize} color="n500" />
                </Stack>
                <Text
                    variant="body"
                    marginLeft={2}
                    marginRight="auto"
                    marginTop={isWideButtonLayout ? 5 : 3.5}
                    marginBottom={shouldRenderSecondRow ? 2 : 3.5}
                    aria-role={severity === 'error' ? 'alert' : 'status'}
                >
                    {text}
                </Text>

                {shouldUseWideLayout && hasLink ? (
                    <Stack
                        alignItems="center"
                        height={isWideButtonLayout ? minHeightWithButton : minHeightWithoutButton}
                        marginLeft={6}
                        flexShrink={0}
                    >
                        <BannerLink href={link.href} target={link.target}>
                            <LinkContents {...link} />
                        </BannerLink>
                    </Stack>
                ) : null}

                {shouldUseWideLayout && hasButton ? (
                    <Stack alignItems="center" height={minHeightWithButton} marginLeft={6} flexShrink={0}>
                        <Button
                            data-e2e-id={TestAttributes.action}
                            text={button.text}
                            onClick={button.onClick}
                            size="compact"
                            variant="tertiary"
                            isLoading={button.isLoading}
                            icon={button.icon}
                        />
                    </Stack>
                ) : null}

                {hasValue(onDismiss) ? (
                    <Stack
                        alignItems="center"
                        height={isWideButtonLayout ? minHeightWithButton : minHeightWithoutButton}
                        marginLeft={shouldUseWideLayout ? 2 : 4}
                    >
                        <IconButton
                            aria-label="close"
                            size="small"
                            data-e2e-id={TestAttributes.close}
                            onClick={onDismiss}
                        >
                            <Icon name="close" color="n400" />
                        </IconButton>
                    </Stack>
                ) : null}
            </Stack>

            {shouldRenderSecondRow ? (
                <Box paddingBottom={4} paddingLeft={`${iconSize}px`}>
                    {hasLink ? (
                        <BannerLink href={link.href} marginLeft={2}>
                            <LinkContents {...link} />
                        </BannerLink>
                    ) : null}
                    {hasButton ? (
                        <Box marginLeft={2}>
                            <Button
                                data-e2e-id={TestAttributes.action}
                                text={button.text}
                                onClick={button.onClick}
                                size="compact"
                                variant="tertiary"
                                isLoading={button.isLoading}
                                icon={button.icon}
                            />
                        </Box>
                    ) : null}
                </Box>
            ) : null}
        </BannerContainer>
    );
};

const LinkContents = (props: LinkProps) => {
    return (
        <>
            <span>{props.text}</span>
            {props.target === '_blank' ? <Icon name="north_east" size={14} /> : null}
        </>
    );
};

const getBackgroundColor = (severity: BannerSeverity) => {
    switch (severity) {
        case 'neutral':
            return 'n50';
        case 'info':
            return 'b100';
        case 'upsell':
            return 'o100';
        case 'warning':
            return 'y100';
        case 'error':
            return 'r100';
        default:
            exhaustiveCheck(severity);
            return 'b100';
    }
};

const getDefaultIcon = (severity: BannerSeverity) => {
    switch (severity) {
        case 'neutral':
            return 'notifications_active';
        case 'info':
            return 'info';
        case 'upsell':
            return 'notifications_active';
        case 'warning':
            return 'warning';
        case 'error':
            return 'emergency_home';
        default:
            exhaustiveCheck(severity);
            return 'notifications_active';
    }
};

const iconSize = 18;
const minHeightWithButton = '60px';
const minHeightWithoutButton = '48px';

const BannerContainer = styled('div')<{
    severity: BannerSeverity;
    shouldUseWideLayout: boolean;
    isWideButtonLayout: boolean;
}>(({theme, severity, isWideButtonLayout}) => ({
    paddingRight: theme.modernFamlyTheme.spacing(3),
    paddingLeft: theme.modernFamlyTheme.spacing(4),
    minHeight: isWideButtonLayout ? minHeightWithButton : minHeightWithoutButton,
    boxShadow: theme.modernFamlyTheme.elevation[1],
    backgroundColor: theme.modernFamlyTheme.colorPalette[getBackgroundColor(severity)],
}));

const BannerLink = styled(Link)(({theme}) => ({
    textDecorationColor: 'unset',
    fontSize: theme.typography['body-small'].fontSize,
    color: theme.modernFamlyTheme.colorPalette.p400,
    display: 'flex',
    alignItems: 'center',
    gap: theme.modernFamlyTheme.spacing(0.5),
}));
