import {type TrackingEvent} from '../types';

export const StatEvents = {
    // Child profile
    CHILD_LEAVE_ADDED: {
        name: 'Child: leave added',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Employee profile contract
    EMPLOYEE_CONTRACT_SCHEDULED_HOURS: {
        name: 'Employee Contract: Scheduled update to contracted hours',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    EMPLOYEE_CONTRACT_WORK_AVAILABILITY_SAVED: {
        name: 'Employee Contract: work availability saved',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Leave & Leave subtypes
    LEAVE_ADDED: {
        name: 'Generic (staff) leave added' as const,
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    LEAVE_UPDATED: {
        name: 'Generic (staff) leave updated' as const,
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    LEAVE_SAVED: {
        name: 'Generic (staff) leave saved / saved and approved' as const,
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    LEAVE_SET_TO_PAID: {
        name: 'Leave type set to paid' as const,
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Inq (General events)
    INQUIRIES_NEW_INQUIRY_CREATED: {
        name: 'Inquiries: New enquiry created',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    INQUIRIES_ENROLLED: {
        name: 'Inquiries: Child enrolled through inquiries',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Custom Registration forms
    CUSTOM_REGISTRATION_FORMS_PAGE_VISITED: {
        name: 'Custom Registration Forms page: Visited',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    CUSTOM_REGISTRATION_FORMS_NEW_FORM_CREATED: {
        name: 'Custom Registration Forms: New form created',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2023-12-31',
    },
    CUSTOM_REGISTRATION_FORMS_FOLLOW_UP_FORM_SENT: {
        name: 'Custom Registration Forms: Follow-up form sent',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2023-12-31',
    },
    CUSTOM_REGISTRATION_FORMS_SUCCESSFUL_SAVE: {
        name: 'Custom Registration Forms: Successful save',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    CUSTOM_REGISTRATION_FORMS_ERROR_ON_SAVE: {
        name: 'Custom Registration Forms: Error on save',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    CUSTOM_REGISTRATION_FORMS_FORM_SUBMITTED: {
        name: 'Custom Registration Forms: Form submitted',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2023-12-31',
    },
    CUSTOM_REGISTRATION_FORMS_FORM_SHARED_WITH_OTHER_SITES: {
        name: 'Custom Registration Forms: Form shared with other sites',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2023-12-31',
    },
    CUSTOM_REGISTRATION_FORMS_FORM_DUPLICATE: {
        name: 'Custom Registration Forms: Form duplicated',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2023-12-31',
    },

    // Staff schedule
    SHIFT_PLANNER_PAGE_VISITED: {
        name: 'Shift planner page: visited',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SHIFT_PLANNER_SHIFT_CREATED: {
        name: 'Shift planner page: shift created',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SHIFT_PLANNER_SCHEDULE_PUBLISHED: {
        name: 'Shift planner page: schedule published',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    STAFF_SCHEDULE_SHIFTS_COPIED: {
        name: 'Staff schedule: shifts copied',
        sendTo: ['Bucket', 'Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    STAFF_SCHEDULE_RECURRING_SHIFTS_REPEAT_ON: {
        name: 'Staff schedule: Add shift repeat on',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    STAFF_SCHEDULE_BULK_DELETE_SHIFTS: {
        name: 'Staff schedule: Bulk delete shifts - Delete button',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // KinderConnect
    KINDER_CONNECT_SEND_ATTENDANCE: {
        name: 'KinderConnect',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Contract section
    CONTRACTED_HOURS_UPDATE: {
        name: 'Contracted hours update',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Staff attendance
    DETAILED_ATTENDANCE_LOG: {
        name: 'Detailed attendance log',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    STAFF_ATTENDANCE_ADD_ATTENDANCE: {
        name: 'Staff attendance records modal - Add',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    STAFF_ATTENDANCE_EDIT: {
        name: 'Staff attendance records - Edit',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    STAFF_ATTENDANCE_ADD_WORKTAG: {
        name: 'Staff attendance records - add worktag',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Staff hours
    STAFF_HOURS_EXPORT: {
        name: 'Staff hours export',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    STAFF_HOURS_TOTAL_BANK_HOURS: {
        name: 'Staff hours - total bank hours',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    STAFF_HOURS_OPEN_RECORDS: {
        name: 'Staff hours - open staff record',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Staff settings
    STAFF_SETTINGS_ADD_WORKTAG: {
        name: 'Staff settings - add worktag',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Staff profile
    STAFF_PROFILE_CONTRACT_HOURS: {
        name: 'Staff profile - contract hours',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
} satisfies Record<string, TrackingEvent>;
