import {useDataProps} from '@famly/stat_ts-utils_data-aria-props';
import {useFormControl} from '@mui/material/FormControl';
import MuiSwitch from '@mui/material/Switch';
import React from 'react';
import {type ToggleProps} from './toggle';

export type StandaloneToggleProps = Omit<ToggleProps, 'label' | 'labelPlacement'> & {ariaLabel: string};

export const StandaloneToggle = (props: StandaloneToggleProps) => {
    const dataProps = useDataProps(props);

    const {disabled: disabledFromFormControl} = useFormControl() ?? {};
    const disabled = props.disabled ?? disabledFromFormControl;

    return (
        <MuiSwitch
            checked={props.checked}
            onChange={(_, checked) => props.onChange(checked)}
            disabled={disabled}
            id={props.id}
            inputProps={{
                'aria-label': props.ariaLabel,
            }}
            {...dataProps}
        />
    );
};
