import * as React from 'react';
import Autocomplete, {type AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import {type ButtonProps as MUIButtonProps} from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {Text} from '@famly/mf_data-display_text';
import {Box} from '@famly/mf_layout_box';
import {Stack} from '@famly/mf_layout_stack';

import {Button, IconAdornment, type MultiSelectProps, type SelectOption} from 'modern-famly/components/input';
import {useTranslation} from 'modern-famly/system/use-translation';
import {type DataProps, useDataProps} from 'modern-famly/components/util';

import {AutocompleteOption, PopperComponent, componentsProps, sxAutocomplete} from './standalone-shared';
import type {CommonStandaloneSelectProps} from './standalone-shared';
import {StandaloneMultiSelectTestAttributes} from './test-attributes';

export type Props = CommonStandaloneSelectProps & {
    /**
     * Selected value.
     */
    value?: MultiSelectProps<SelectOption>['value'];

    /**
     * A function that groups the select options into categories or sections.
     */
    groupBy?: MultiSelectProps<SelectOption>['groupBy'];

    /**
     * Callback fired when the value changes.
     */
    onChange: MultiSelectProps<SelectOption>['onChange'];
} & DataProps;

export const StandaloneMultiSelect = ({
    id,
    options,
    value: valueFromProps,
    onChange,
    size,
    groupBy,
    ...rest
}: Props) => {
    const emptyResultsText = useTranslation('FilterAutocomplete.emptyResults');

    const dataProps = useDataProps(rest);

    const value = React.useMemo(() => {
        return (valueFromProps?.length ?? 0) > 0 && typeof valueFromProps?.[0] === 'string'
            ? valueFromProps?.map(value => options.find(opt => opt.value === value)).filter(hasValue) ?? []
            : ((valueFromProps ?? []) as SelectOption[]);
    }, [options, valueFromProps]);

    const handleClearAll = React.useCallback(() => {
        onChange?.([], 'clear', undefined);
    }, [onChange]);

    const handleSelectAll = React.useCallback(() => {
        onChange?.(options, 'selectOption', undefined);
    }, [onChange, options]);

    return (
        <Autocomplete
            id={id}
            open
            size={size}
            sx={sxAutocomplete}
            blurOnSelect
            componentsProps={componentsProps}
            disableCloseOnSelect
            value={value}
            onChange={(_, newValue, reason, details) => {
                onChange?.(newValue ?? null, reason, details);
            }}
            PopperComponent={PopperComponent}
            groupBy={groupBy}
            renderTags={() => null}
            noOptionsText={emptyResultsText}
            renderOption={(props, option, {selected}) => {
                return <AutocompleteOption props={props} option={option} selected={selected} size={size} />;
            }}
            options={options}
            isOptionEqualToValue={(option, value) => option.value === (value?.value ?? value)}
            getOptionLabel={option => option.label}
            renderInput={params => {
                return (
                    <AutocompleteInput
                        params={params}
                        selectedCount={value?.length ?? 0}
                        handleSelectAll={handleSelectAll}
                        handleClearAll={handleClearAll}
                    />
                );
            }}
            multiple={true}
            {...dataProps}
        />
    );
};

type AutocompleteInputProps = {
    params: AutocompleteRenderInputParams;
    selectedCount: number;
    handleClearAll: MUIButtonProps['onClick'];
    handleSelectAll: MUIButtonProps['onClick'];
};

const AutocompleteInput = ({params, selectedCount, handleClearAll, handleSelectAll}: AutocompleteInputProps) => {
    const selectedText = useTranslation('FilterAutocomplete.selected', selectedCount?.toString() ?? '');
    const clearAllText = useTranslation('FilterAutocomplete.clearAll');
    const selectAllText = useTranslation('FilterAutocomplete.selectAll');
    const placeholderText = useTranslation('FilterAutocomplete.placeholder');

    return (
        <>
            <Box marginBottom={0}>
                <TextField
                    {...params}
                    autoFocus
                    placeholder={placeholderText}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <IconAdornment icon="search" size="regular" position="start" />,
                        endAdornment: undefined,
                    }}
                    fullWidth
                />
            </Box>

            <Stack justifyContent="space-between" marginY={1} alignItems="center">
                <Text variant="micro">{selectedText}</Text>
                {hasValue(selectedCount) && selectedCount === 0 ? (
                    <Button
                        data-e2e-class={StandaloneMultiSelectTestAttributes.selectAllButtonE2eClass}
                        onClick={handleSelectAll}
                        text={selectAllText}
                        size="compact"
                        variant="transparent"
                    />
                ) : (
                    <Button
                        data-e2e-class={StandaloneMultiSelectTestAttributes.clearAllButtonE2eClass}
                        onClick={handleClearAll}
                        text={clearAllText}
                        size="compact"
                        variant="transparent"
                    />
                )}
            </Stack>
        </>
    );
};
