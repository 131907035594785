import React from 'react';
import {useDispatch} from 'react-redux';

import {Stack} from '@famly/mf_layout_stack';
import {useBreakpoints} from '@famly/mf_util_breakpoints';
import {relations} from 'signin-app/child/selectors';
import * as CheckInSelectors from 'signin-app/child/check-in/selectors';
import {selectPickupRelation} from 'signin-app/child/check-in/actions';
import {SelectablePersonButton} from 'signin-app/components/selectable-person-button';
import {useTypedSelector} from 'signin-app/components/hooks';

interface ChildRelationsProps {
    checkedIn: boolean;
    childId: string;
    selectedRelationId?: string | null;
    handleRelationClick: VoidFunction;
    children: React.ReactNode;
}

const ChildRelations = (props: ChildRelationsProps) => {
    const {handleRelationClick, children} = props;
    const childRelations = useTypedSelector(state => relations(state, props));
    const selectedPickupRelationId = useTypedSelector(CheckInSelectors.selectedPickupRelationId);

    const {isTabletPortraitAndLarger} = useBreakpoints();

    const dispatch = useDispatch();

    const handleClick = async (relationId: string | null) => {
        await dispatch(selectPickupRelation.action(relationId));
        handleRelationClick();
    };

    return (
        <Stack my={2} flexWrap="wrap">
            {childRelations.map(relation => (
                <SelectablePersonButton
                    key={relation.relationId}
                    image={relation.image.large}
                    initials={`${relation.name.firstName.charAt(0)}${relation.name.lastName.charAt(0)}`}
                    name={relation.name.firstName}
                    onClick={() => handleClick(relation.relationId)}
                    selected={selectedPickupRelationId === relation.relationId}
                    dataE2eClass="child-person-card-title"
                    size={isTabletPortraitAndLarger ? 'lg' : 'md'}
                />
            ))}
            {children}
        </Stack>
    );
};

export default ChildRelations;
