import {hasValue, exhaustiveCheck} from 'web-app/util/typescript';

import ENV from './environment';
import {WhitelabelUrl, WhitelabelMobileUrl, type WhitelabelAppId, type Whitelabel} from './white-labeling-constants';

export const OVERRIDE_WHITE_LABEL_KEY = 'famly.OVERRIDE_WHITE_LABEL_KEY';

const makeWhitelabelCheckerFunction = (whitelabelAppId: WhitelabelAppId) => () =>
    window.location.host.endsWith(WhitelabelUrl[whitelabelAppId]) || ENV.WHITELABEL_APP_ID === whitelabelAppId;

const isEspira = makeWhitelabelCheckerFunction('espira');
const isKido = makeWhitelabelCheckerFunction('kido');
const isBrightHorizons = makeWhitelabelCheckerFunction('brighthorizons');
const isNFamilyClub = makeWhitelabelCheckerFunction('nfamilyclub');
const isKindred = makeWhitelabelCheckerFunction('kindred');
const isMonkeyPuzzle = makeWhitelabelCheckerFunction('monkeypuzzle');
const isGrandirUK = makeWhitelabelCheckerFunction('grandiruk');
const isNuffieldHealth = makeWhitelabelCheckerFunction('nuffieldhealth');
const isKinderzimmerUk = makeWhitelabelCheckerFunction('kinderzimmeruk');
const isCpcTreehouse = makeWhitelabelCheckerFunction('cpctreehouse');
const isCpcOrchards = makeWhitelabelCheckerFunction('cpcorchards');
const isBusybees = makeWhitelabelCheckerFunction('busybees');
const isMontessori = makeWhitelabelCheckerFunction('bbmontessori');
const isOdyssey = makeWhitelabelCheckerFunction('bbodyssey');

let _determinedWhitelabel: Whitelabel | null | undefined;

const determineWhitelabel = () => {
    const overriddenWhitelabel = window.sessionStorage?.getItem(OVERRIDE_WHITE_LABEL_KEY);

    if (hasValue(overriddenWhitelabel) && isValidWhitelabelKey(overriddenWhitelabel)) {
        return 'overriddenWhitelabel' as unknown as Whitelabel;
    }

    if (isEspira()) {
        return 'espira';
    } else if (isKido()) {
        return 'kido';
    } else if (isBrightHorizons()) {
        return 'brighthorizons';
    } else if (isNFamilyClub()) {
        return 'nfamilyclub';
    } else if (isKindred()) {
        return 'kindred';
    } else if (isMonkeyPuzzle()) {
        return 'monkeypuzzle';
    } else if (isGrandirUK()) {
        return 'grandiruk';
    } else if (isNuffieldHealth()) {
        return 'nuffieldhealth';
    } else if (isKinderzimmerUk()) {
        return 'kinderzimmeruk';
    } else if (isCpcTreehouse()) {
        return 'cpctreehouse';
    } else if (isCpcOrchards()) {
        return 'cpcorchards';
    } else if (isBusybees()) {
        return 'busybees';
    } else if (isMontessori()) {
        return 'bbmontessori';
    } else if (isOdyssey()) {
        return 'bbodyssey';
    } else {
        return null;
    }
};

const whitelabels = [
    'espira',
    'kido',
    'brighthorizons',
    'nfamilyclub',
    'kindred',
    'monkeypuzzle',
    'grandiruk',
    'nuffieldhealth',
    'kinderzimmeruk',
    'cpctreehouse',
    'cpcorchards',
    'busybees',
    'bbmontessori',
    'bbodyssey',
] satisfies Whitelabel[];

const isValidWhitelabelKey = (whitelabel: string) => {
    return whitelabels.includes(whitelabel as any);
};

export const getWhitelabel = () => {
    if (_determinedWhitelabel !== undefined) {
        return _determinedWhitelabel;
    } else {
        const whitelabel = determineWhitelabel();
        _determinedWhitelabel = whitelabel;
        return _determinedWhitelabel;
    }
};

export const overrideWhitelabel = (whitelabelKey: string) => {
    if (!isValidWhitelabelKey(whitelabelKey)) {
        return;
    }

    window.sessionStorage.setItem(OVERRIDE_WHITE_LABEL_KEY, whitelabelKey);
};

/**
 * These ids maps to how the backend configures push notifications.
 * We send this ID along with the push notification token when we register the app installation
 * for push notifications in the backend.
 *
 * When inserting new whitelabel apps, generate a random UUID, and communicate that to the backend
 * that's responsible for configuring the push notifications.
 */
export const getWhitelabelPushAppId = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case 'espira':
            return 'eca36102-6851-4f10-959c-cfbb2663f82f';
        case 'kido':
            return '2e45692d-b3e3-4eb2-8ca2-a2f6c4c6f644';
        case 'brighthorizons':
            return 'c70d4421-c568-4b0d-a6a4-8545d318d1c0';
        case 'nfamilyclub':
            return '9eb1753f-245a-448d-a351-09c8a11b3f53';
        case 'kindred':
            return 'b20f9135-28c2-47c7-a811-03956d0105ec';
        case 'monkeypuzzle':
            return '49af27e6-b657-4449-8799-0e7a1ee52474';
        case 'grandiruk':
            return '6cf90c67-1ca8-469b-9423-5d965c39e78e';
        case 'nuffieldhealth':
            return 'dd3105da-7818-4c83-aa07-77369081b3c1';
        case 'kinderzimmeruk':
            return '83a27c8b-89f4-4140-ab89-3376ec11472c';
        case 'cpctreehouse':
            return '5a8d5aa2-1be4-440b-a18a-6ac1541984c3';
        case 'cpcorchards':
            return '9657d9f4-0620-496c-9a83-949d7b342672';
        case 'busybees':
            return '0146e4a4-5d6a-4949-8e53-b06be4dc33f1';
        case 'bbmontessori':
            return '1633421d-94a4-474e-b3a1-e0e1ffcc232b';
        case 'bbodyssey':
            return '43352584-db51-4066-b177-7a35f361b44f';
        case null:
            return FamlyPushAppId;
        default:
            exhaustiveCheck(whitelabel);
            return FamlyPushAppId;
    }
};

const FamlyPushAppId = 'b0b82a57-3922-46ea-a686-d04c28b0c9f2';

/**
 * The value returned from this is used wherever we mention the "product name".
 */
export const getWhitelabelName = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case 'espira':
            return 'Spireportalen';
        case 'kido':
            return 'Kido';
        case 'brighthorizons':
            return 'Bright Horizons';
        case 'nfamilyclub':
            return 'N Family Club';
        case 'kindred':
            return 'Kindred';
        case 'monkeypuzzle':
            return 'Monkey Puzzle';
        case 'grandiruk':
            return 'Grandir UK';
        case 'nuffieldhealth':
            return 'Nuffield Health Nursery';
        case 'kinderzimmeruk':
            return 'Kinderzimmer';
        case 'cpctreehouse':
            return 'Treehouse Children’s Academy';
        case 'cpcorchards':
            return 'Orchard Academy';
        case 'busybees':
            return 'Busy Bees';
        case 'bbmontessori':
            return 'Montessori by Busy Bees';
        case 'bbodyssey':
            return 'Odyssey Global Preschool';
        case null:
            return 'Famly';
        default:
            exhaustiveCheck(whitelabel);
            return 'Famly';
    }
};

export const getWhitelabelUrl = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case 'espira':
            return WhitelabelUrl.espira;
        case 'kido':
            return WhitelabelUrl.kido;
        case 'brighthorizons':
            return WhitelabelUrl.brighthorizons;
        case 'nfamilyclub':
            return WhitelabelUrl.nfamilyclub;
        case 'kindred':
            return WhitelabelUrl.kindred;
        case 'monkeypuzzle':
            return WhitelabelUrl.monkeypuzzle;
        case 'grandiruk':
            return WhitelabelUrl.grandiruk;
        case 'nuffieldhealth':
            return WhitelabelUrl.nuffieldhealth;
        case 'kinderzimmeruk':
            return WhitelabelUrl.kinderzimmeruk;
        case 'cpctreehouse':
            return WhitelabelUrl.cpctreehouse;
        case 'cpcorchards':
            return WhitelabelUrl.cpcorchards;
        case 'busybees':
            return WhitelabelUrl.busybees;
        case 'bbmontessori':
            return WhitelabelUrl.bbmontessori;
        case 'bbodyssey':
            return WhitelabelUrl.bbodyssey;
        case null:
            return null;
        default:
            exhaustiveCheck(whitelabel);
            return null;
    }
};

export const getWhitelabelMobileAppUrl = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case 'espira':
            return WhitelabelMobileUrl.espira;
        case 'kido':
            return WhitelabelMobileUrl.kido;
        case 'brighthorizons':
            return WhitelabelMobileUrl.brighthorizons;
        case 'nfamilyclub':
            return WhitelabelMobileUrl.nfamilyclub;
        case 'kindred':
            return WhitelabelMobileUrl.kindred;
        case 'monkeypuzzle':
            return WhitelabelMobileUrl.monkeypuzzle;
        case 'grandiruk':
            return WhitelabelMobileUrl.grandiruk;
        case 'nuffieldhealth':
            return WhitelabelMobileUrl.nuffieldhealth;
        case 'kinderzimmeruk':
            return WhitelabelMobileUrl.kinderzimmeruk;
        case 'cpctreehouse':
            return WhitelabelMobileUrl.cpctreehouse;
        case 'cpcorchards':
            return WhitelabelMobileUrl.cpcorchards;
        case 'busybees':
            return WhitelabelMobileUrl.busybees;
        case 'bbmontessori':
            return WhitelabelMobileUrl.bbmontessori;
        case 'bbodyssey':
            return WhitelabelMobileUrl.bbodyssey;
        case null:
            return 'com.famly.famlyapp';
        default:
            exhaustiveCheck(whitelabel);
            return 'com.famly.famlyapp';
    }
};

export const isWhitelabelRingfenced = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case 'brighthorizons':
        case 'busybees':
        case 'bbmontessori':
        case 'bbodyssey':
            return true;
        case 'espira':
        case 'kido':
        case 'nfamilyclub':
        case 'kindred':
        case 'monkeypuzzle':
        case 'grandiruk':
        case 'nuffieldhealth':
        case 'kinderzimmeruk':
        case 'cpctreehouse':
        case 'cpcorchards':
        case null:
            return false;
        default:
            exhaustiveCheck(whitelabel);
            return false;
    }
};
