/* eslint-disable no-restricted-imports */
import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import {useBreakpoints} from 'modern-famly';
import {getEmphasizedFontWeight} from 'modern-famly/components/util';

/**
 * Correctly styled dialog title container including an optional id,
 * which should be used for aria purpose
 *
 * @param title title of the dialog to be rendered
 * @param id optional id, which should be provided for testing and accessibility
 */
export const DialogTitle = ({title, id}: {title: string; id?: string}) => {
    const {isMobileLandscapeAndLarger} = useBreakpoints();
    return (
        <MuiDialogTitle
            id={id}
            sx={theme => ({
                padding: 0,
                margin: 6,

                color: theme.modernFamlyTheme.colorPalette.n400,
                fontWeight: getEmphasizedFontWeight('h6'),

                // ellipsis
                width: {base: '77%', mobileLandscape: '80%'},
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: isMobileLandscapeAndLarger ? 1 : 2,
            })}
        >
            {title}
        </MuiDialogTitle>
    );
};
