import {
    init,
    type Scope,
    type Event,
    type Breadcrumb,
    type BreadcrumbHint,
    type Exception,
    type EventHint,
    type BrowserOptions,
    BrowserTracing,
    Replay,
} from '@sentry/browser';
import {init as initCapacitor} from '@sentry/capacitor';
import {getCurrentHub} from '@sentry/core';
import {RewriteFrames} from '@sentry/integrations';
import {UAParser} from 'ua-parser-js';

import {checkIfFlagIsToggled, DebugLocalStorageFlags} from 'web-app/react/routes/debugger/local-storage-config';
import {redactor} from 'web-app/util/logging';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import {type RouteConfigPath} from 'web-app/react/routes/route-config';
import {findCrewFromPath, findRouteTemplateFromUncleanUrl} from 'web-app/react/routes/helpers';
import {APIErrorCode} from 'web-app/api/types';
import {RestClientNetworkError} from 'web-app/api/clients/rest-client-network-error';

import {type EnvValues} from './constants';
import {startTracing} from './sentry-tracing';
import {isNewestMobileOsVersion} from './sentry-helpers';

interface SentryConfig {
    debug: boolean;
    development: boolean;
    dsn: string;
    environment?: string;
    allowUrls?: string[];
    denyUrls?: string[];
    sentryOptions?: object;
}

interface SentryEnvironment {
    environment: keyof typeof EnvValues;
    sentry: {
        target?: 'staging' | 'production';
        staging?: SentryConfig;
        production: SentryConfig;
    };
    APP: {
        platform: string;
        version: string;
        sentryVersion: string;
        buildNumber: string;
    };
}

const FORCED_ROUTES_SAMPLE_RATE = 1;

const routesWithForcedTracking: RouteConfigPath[] = [
    '/account/institution/:institutionId/revenueReport',
    '/account/institution/:institutionId/billPayersReport',
    '/account/institution/:institutionId/debtReport',
    '/account/institution/:institutionId/publicFundingReport',
    '/account/institution/:institutionId/batchInvoice',
];

const getSentryConfig = (ENV: SentryEnvironment): SentryConfig | undefined => {
    const {staging, production, target} = ENV.sentry;

    switch (target) {
        case 'staging':
            return staging;
        case 'production':
            return production;
        default:
            return undefined;
    }
};

// Different browsers use different wording for network errors, so we need to check for all of them
// Produced by iterating through Sentry issues and their raw JSON payloads.
const badNetworkErrors = [
    // Chrome (e.g. on Android) uses this wording for failed requests
    {type: 'TypeError', value: 'Failed to fetch'},

    // Safari uses this wording for failed requests
    {type: 'TypeError', value: 'Load failed'},
    {type: 'Error', value: 'TypeError: Load failed'},

    // Safari uses this wording for cancelled requests
    {type: 'TypeError', value: 'cancelled'},
    {type: 'Error', value: 'TypeError: cancelled'},

    // Safari uses this wording for aborted operations
    {type: 'Error', value: 'AbortError: The operation was aborted.'},

    // Represents a timeout in Chrome
    {type: 'Error', value: 'AbortError: The user aborted a request.'},

    // Represents a timeout in Safari
    {type: 'AbortError', value: 'Fetch is aborted.'},
    {type: 'AbortError', value: 'Fetch is aborted'},

    // Network timeouts
    {type: 'TypeError', value: 'The request timed out.'},
    {type: 'Error', value: 'Network error: The request timed out.'},

    // No connection
    {type: 'TypeError', value: 'The Internet connection appears to be offline.'},
    {type: 'TypeError', value: 'The network connection was lost.'},
];

const isBadNetworkError = (incomingError: Exception | undefined) => {
    return badNetworkErrors.some(error => {
        return incomingError?.type === error.type && incomingError?.value === error.value;
    });
};

const setupSentry = (ENV: SentryEnvironment, enableGlobalErrorCatching?: () => any) => {
    const config = getSentryConfig(ENV);

    if (!config) {
        return;
    }

    if (config.development) {
        if (config.debug) {
            // eslint-disable-next-line no-console
            console.log('`sentry` is configured for development mode.');
        }
        return;
    }

    const {dsn, environment, debug = true, allowUrls = [], denyUrls = [], sentryOptions = {}} = config;

    const isMobileBuild = ENV.APP.platform === 'ios' || ENV.APP.platform === 'android';

    // UAParser doesn't detect desktops directly, but we can infer it from the device type being undefined
    // More info here: https://github.com/faisalman/ua-parser-js/issues/182
    const uaParser = new UAParser();
    const isDesktop = uaParser.getDevice().type === undefined;

    const osFromUserAgent = uaParser.getOS();
    const osInfo =
        osFromUserAgent.name && osFromUserAgent.version
            ? {name: osFromUserAgent.name, version: osFromUserAgent.version}
            : null;

    const replayIntegration = new Replay({
        maskAllText: true,
        blockAllMedia: true,
        unblock: [
            'svg', // We always unblock SVGs as they are only used for icons
            'img:not([src^="http"]):not([src^="data:"]):not([src^="blob:"])', // Unblock all images that aren't absolute URLs
        ],
        unmask: [
            '[data-e2e-id="contextual-sidebar"]', // Unmask the contextual side bar as it doesn't contain sensitive data
            '[data-e2e-id^="sidebar-item"]', // Unmask sidebar items as they are used for navigation
            '[id="topBar"]', // Unmask the top bar as it doesn't contain sensitive data
            'span.material-icons', // Unblock spans that are icons
        ],
    });

    let replaysOnErrorSampleRate = 0;
    let replaysSessionSampleRate = 0;

    // Replays are disabled on mobile builds by default
    if (isDesktop && !isMobileBuild) {
        replaysOnErrorSampleRate = 0.5;
        replaysSessionSampleRate = 0.005;
    } else if (osInfo && isNewestMobileOsVersion(osInfo)) {
        // Starting to roll out replays on the newest versions of the mobile apps
        // Both on browsers and in the native apps
        replaysOnErrorSampleRate = 0.001;
    } else if (checkIfFlagIsToggled('SENTRY_ENABLE_REPLAY')) {
        replaysOnErrorSampleRate = 1;
        replaysSessionSampleRate = 1;
    }

    try {
        // Keeping existing config values for allowUrls, for compatibility.
        const sentryConfig = {
            environment,
            allowUrls,
            denyUrls,
            debug,
            release: ENV.APP.sentryVersion,
            dist: isMobileBuild ? ENV.APP.buildNumber : undefined,
            attachStacktrace: true,
            integrations: [
                new BrowserTracing({
                    beforeNavigate: context => {
                        if (context.op !== 'pageload') {
                            // Returning undefined to drop the transaction since we manually trace navigations
                            return undefined;
                        }
                        return {
                            ...context,
                            // Tag all pageload events with similar name
                            name: 'PAGELOAD',
                            // Add the current location hash to the description so we can discriminate between routes in Sentry
                            description: findRouteTemplateFromUncleanUrl(window.location.href),
                        };
                    },
                    // We manually trace navigation changes
                    startTransactionOnLocationChange: false,
                }),
                replayIntegration,
            ].filter(hasValue),
            ...sentryOptions,
            dsn,
            replaysOnErrorSampleRate,
            replaysSessionSampleRate,
            /**
             * While you're testing, enable TRACE_ALL_TRANSACTIONS flag, as that ensures that every transaction will be sent to Sentry.
             * Once testing is complete, we recommend lowering this value in production or to add the route to the forced tracking list.
             */
            tracesSampler: ({transactionContext}) => {
                if (routesWithForcedTracking.includes(transactionContext.name as any)) {
                    return FORCED_ROUTES_SAMPLE_RATE;
                }

                return checkIfFlagIsToggled(DebugLocalStorageFlags.TRACE_ALL_TRANSACTIONS.flag) ? 1 : 0.01;
            },
            beforeSend: (event: Event, hint: EventHint) => {
                // Skipping event filtering when the in diagnostics mode
                if (checkIfFlagIsToggled(DebugLocalStorageFlags.SENTRY_DIAGNOSTIC_MODE.flag)) {
                    // Adding a flag to easily identify events that are sent in diagnostic mode
                    if (event.tags) {
                        event.tags.diagnosticMode = true;
                    } else {
                        event.tags = {
                            diagnosticMode: true,
                        };
                    }

                    return event;
                }

                // Attempt to map the route to a crew (to assist with auto assigning issues to the relevant crew)
                if (event.request?.url) {
                    const routeTemplate = findRouteTemplateFromUncleanUrl(event.request.url);

                    if (routeTemplate) {
                        const crew = findCrewFromPath(routeTemplate);

                        if (crew) {
                            event.tags = {
                                ...event.tags,
                                crew,
                            };
                        }
                    }
                }

                const firstException = event?.exception?.values?.[0];
                const stackTrace = firstException?.stacktrace?.frames ?? [];

                // Issues caused by extensions in Safari
                // In newer versions of the Sentry service I believe these are excluded automatically.
                // https://github.com/getsentry/sentry-javascript/discussions/5875
                if (stackTrace.some(frame => frame.filename?.startsWith('webkit-masked-url://hidden/'))) {
                    return null;
                }

                // Used for e.g. filtering Sentry events
                // https://docs.sentry.io/platforms/javascript/guides/electron/configuration/options/#before-send
                if (firstException?.value === 'TransitionAborted') {
                    return null;
                }

                // If the error is a network error we want to add the response to the event
                if (hint.originalException instanceof RestClientNetworkError) {
                    event.extra = {...(event.extra ?? {}), response: hint.originalException.response};
                }

                if (event?.extra) {
                    // We see that some events are sent to Sentry with a __serialized__ extra property.
                    // This happens when a non-Error object is passed to Sentry.captureException().
                    // Documented here: https://docs.sentry.io/platforms/javascript/troubleshooting/#events-with-non-error-exception
                    const serializedExtras = event.extra.__serialized__ as any;

                    // We don't want to send events to Sentry related to expiring sessions
                    if (serializedExtras?.responseData?.errorCode === APIErrorCode.SESSION_EXPIRED) {
                        return null;
                    }
                }

                // We're not interested in logging network connectivity errors to Sentry
                if (isBadNetworkError(firstException)) {
                    return null;
                }

                return event;
            },
            ignoreErrors: [
                // We're seeing a lot of these in Sentry after introducing ResizeObserver in Famly Home (https://github.com/famly/app/pull/20149).
                // They're flooding our Sentry infrastructure, and they're seemingly not causing any issues for end users,
                // so we're ignoring them for now.
                'ResizeObserver loop completed with undelivered notifications',
            ],
        } satisfies BrowserOptions;

        const beforeBreadcrumb = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint) => {
            if (breadcrumb.category === 'console' && breadcrumb.level === 'warning') {
                // Filter out console warnings as they are typically not useful
                return null;
            }

            // Change the breadcrumb if it's a GraphQL request so we can see which operation was called
            breadcrumb = transformGraphQLBreadcrumb(breadcrumb, hint);

            return breadcrumb;
        };

        if (isMobileBuild) {
            initCapacitor(
                {
                    dsn: sentryConfig.dsn,
                    release: sentryConfig.release,
                    debug: sentryConfig.debug,
                    allowUrls: sentryConfig.allowUrls,
                    // We don't set denyUrls here, since there's no URLs we want to deny on mobile builds
                    beforeSend: sentryConfig.beforeSend,
                    ignoreErrors: sentryConfig.ignoreErrors,
                    attachStacktrace: sentryConfig.attachStacktrace,
                    environment: sentryConfig.environment,
                    integrations: [
                        // This integration rewrites stack frames, which we need specifically for Android,
                        // where stack frames start with `/`, which Sentry doesn't like.
                        // The default integration prefixes stack frames with `app://`, which works for us.
                        new RewriteFrames(),
                        replayIntegration,
                    ].filter(hasValue),
                    replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
                    replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
                    beforeBreadcrumb,
                    // Mobile sentry specyfic options
                    enableAppHangTracking: true,
                    appHangTimeoutInterval: 10 * 1000, // in ms
                },
                init,
            );
        } else {
            init({
                ...sentryConfig,
                beforeBreadcrumb,
            });
        }
    } catch (e) {
        console.warn(`Error during 'sentry' initialization: ${e}`); // tslint:disable-line no-console
        return;
    }

    if (enableGlobalErrorCatching) {
        enableGlobalErrorCatching();
    }

    startTracing();

    configureSentryScope(scope => {
        scope.addEventProcessor((event, hint) => {
            // This attempts to filter out errors that the backend hints that we shouldn't log.
            if ((hint?.originalException as any)?.response?.log === false) {
                return null;
            }

            /**
             * This attempts to filter out errors that arises from not
             * manually catching errors when calling GraphQL mutations.
             * But only in the case where the backend, via the 'log' property,
             * has hinted not to log that error.
             */
            if ((hint?.originalException as any)?.graphQLErrors?.every?.(error => error?.extensions?.log === false)) {
                return null;
            }

            return event;
        });
    });
};

// Function to update the breadcrumb if it's determined as a GraphQL request
const transformGraphQLBreadcrumb = (breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Breadcrumb => {
    // Type checks
    if (!isGraphQLRequest(breadcrumb) || !hasValue(hint) || !hasValue(breadcrumb.data)) {
        return breadcrumb;
    }

    // Attempt to parse operation name
    const {operationName, type, variables} = getGraphQLDataFromHint(hint);

    if (!hasValue(operationName)) {
        return breadcrumb;
    }

    // Finally transform breadcrumb
    return {
        ...breadcrumb,
        type: 'query',
        category: 'GraphQL',
        message: operationName,
        data: {
            ...breadcrumb.data,
            type,
            variables: variables && redactor(variables),
        },
    };
};

// Function to determine if breadcrumb is a GraphQL request
const isGraphQLRequest = (breadcrumb: Breadcrumb) => {
    return (breadcrumb.category === 'fetch' && breadcrumb.data?.url?.includes('graphql')) ?? false;
};

// Attempts to get the operation name from the breadcrumb hint
const getGraphQLDataFromHint = (hint: BreadcrumbHint) => {
    // Type check
    if (!hasValue(hint.input)) {
        return {};
    }
    const [, requestData] = hint.input;

    // More type checking
    if (!hasValue(requestData) || !hasValue(requestData.body) || typeof requestData.body !== 'string') {
        return {};
    }

    // Attempt to parse body and read operation name from query/mutation
    try {
        const requestBody = JSON.parse(requestData.body) as {operationName: string; query: string; variables?: object};

        if (!hasValue(requestBody.operationName)) {
            return {};
        }

        return {
            operationName: requestBody.operationName,
            variables: requestBody.variables,
            type: requestBody.query.startsWith('query') ? ('query' as const) : ('mutation' as const),
        };
    } catch {
        // Return {} if JSON.parse fails
        return {};
    }
};

const configureSentryScope = (callback: (scope: Scope) => void) => {
    getCurrentHub().configureScope(callback);
};

export {configureSentryScope};

export default setupSentry;
