import React from 'react';
import MuiFormHelperText, {type FormHelperTextProps as MuiFormHelperTextProps} from '@mui/material/FormHelperText';

type Base = Pick<MuiFormHelperTextProps, 'children' | 'error' | 'disabled' | 'id'>;

export type FormErrorTextProps = Base;
export type FormHelperTextProps = Omit<Base, 'error'>;

export const FormErrorText: React.FC<FormErrorTextProps> = props => <MuiFormHelperText {...props} />;
export const FormHelperText: React.FC<FormHelperTextProps> = props => <MuiFormHelperText {...props} error={false} />;

/**
 * MUI Theming
 */
