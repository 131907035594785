import createSelector from 'web-app/util/custom-create-selector';
import {hasValue} from '@famly/stat_ts-utils_has-value';

export const isLoggedIn = createSelector(
    state => state.session,
    session => Boolean(session.accessToken),
);

export const canUseAutologout = createSelector(
    isLoggedIn,
    state => state.session && state.session.account,
    (loggedIn, account) => {
        if (!loggedIn || !hasValue(account)) {
            return false;
        }
        return account.autoLogout;
    },
);

export const isImpersonated = createSelector(
    state => state.session.account,
    account => account.impersonated,
);
