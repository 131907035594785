import React from 'react';
import moment from 'moment-timezone';
import styled, {css} from 'styled-components';
import i18next from 'i18next';

import {isNonEmpty, type NonEmptyArray, head, sort} from 'web-app/util/non-empty-array';
import {
    getFirstAvailableAdministration,
    getDailyAdministrationSchedule,
} from 'web-app/react/modals-container/medication-administration-modal/helpers';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import * as APIConstants from 'web-app/api/constants';
import {fromMoment} from 'web-app/react/components/form-elements/time/time';
import {SelfUpdatingFromNow} from 'web-app/react/components/time-renderer';
import {type MedicationFormForPeopleOverviewFragment} from 'web-app/react/institution-people/__generated__/queries.api-types';

/**
 * Component for rendering either:
 * - Prompt for missing daily updates from parents
 * - The earliest non-admininstered scheduled medication
 * - All non-scheduled medications
 */
export const MedicationFormRenderer: React.FC<
    React.PropsWithChildren<{
        medicationForms: NonEmptyArray<MedicationFormForPeopleOverviewFragment>;
        timezone: string;
    }>
> = ({medicationForms, timezone}) => {
    const medicationFormsWithMissingUpdates = React.useMemo(() => {
        return medicationForms.filter(medicationForm => {
            return getDailyAdministrationSchedule(medicationForm).type === 'missing-daily-update';
        });
    }, [medicationForms]);

    const earliestScheduledAdministration = React.useMemo(() => {
        const earliestScheduledAdministrations = medicationForms.map(getFirstAvailableAdministration).filter(hasValue);
        if (isNonEmpty(earliestScheduledAdministrations)) {
            return head(sort(earliestScheduledAdministrations));
        }
        return null;
    }, [medicationForms]);

    const isDue = React.useMemo(() => {
        // Return true if past scheduled administration
        if (!hasValue(earliestScheduledAdministration)) {
            return false;
        }
        return moment
            .tz(earliestScheduledAdministration, APIConstants.API_LOCAL_TIME_FORMAT, timezone)
            .isBefore(moment());
    }, [earliestScheduledAdministration, timezone]);

    const isCloseToDue = React.useMemo(() => {
        // Return true if "close" to scheduled administration
        if (!hasValue(earliestScheduledAdministration) || isDue) {
            return false;
        }

        const momentDate = moment.tz(earliestScheduledAdministration, APIConstants.API_LOCAL_TIME_FORMAT, timezone);

        // 15 minutes is an arbitrary value and was chosen at a whim.
        return momentDate.isBetween(moment().subtract(15, 'minutes'), moment());
    }, [earliestScheduledAdministration, timezone, isDue]);

    if (isNonEmpty(medicationFormsWithMissingUpdates)) {
        return <StyledSpan isDue>{i18next.t('medicationForms.missingDailyUpdateConcise')}</StyledSpan>;
    }

    if (hasValue(earliestScheduledAdministration)) {
        const momentDate = moment.tz(earliestScheduledAdministration, APIConstants.API_LOCAL_TIME_FORMAT, timezone);
        return (
            <SelfUpdatingFromNow time={fromMoment(momentDate)!}>
                {fromNow => {
                    return (
                        <StyledSpan isDue={isDue} isCloseToDue={isCloseToDue}>{`${momentDate.format(
                            'LT',
                        )} (${fromNow})`}</StyledSpan>
                    );
                }}
            </SelfUpdatingFromNow>
        );
    }

    // At this point no medication has a scheduled time
    return (
        <>
            {medicationForms.map(medicationForm => {
                return <div key={medicationForm.id}>{medicationForm.medicationName}</div>;
            })}
        </>
    );
};

const StyledSpan = styled.span<{isDue?: boolean; isCloseToDue?: boolean}>`
    ${props =>
        props.isDue
            ? css`
                  color: ${props.theme.accent3};
              `
            : ''}
    ${props =>
        props.isCloseToDue
            ? css`
                  font-weight: 500;
              `
            : ''}
`;
