import {ofType} from 'redux-observable';
import {of, merge} from 'rxjs';
import {mergeMap, flatMap, catchError, map} from 'rxjs/operators';

import {combineEpics} from 'web-app/util/redux-observable';
import EmployeeApi from 'signin-app/api/employee-api';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import translate from 'signin-app/helpers/translate';
import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type SignInEpic} from 'signin-app/redux/types';
import {TrackingEvents} from 'web-app/util/famlytics/events';
import {track} from 'signin-app/util/famlytics';
import {isPinApp} from 'signin-app/login/selectors';

import * as Actions from './actions';
import * as Selectors from './selectors';

const checkInEmployee: SignInEpic = (action$, state$) =>
    action$.pipe(
        ofType(Actions.checkInEmployee.type),
        mergeMap(({payload}: ReturnType<typeof Actions.checkInEmployee.action>) => {
            const {accessToken, employeeId, pin, selectedHour, selectedMinute, groupId, successMessage} = payload;

            let estimatedCheckoutTime;

            if (selectedHour !== undefined && selectedMinute !== undefined) {
                // We need to send the time formatted correctly. For example, if the user has chosen 5 minutes past 9 in the morning,
                // we need to send '09:05', rather than '9:5'.
                // It would probably be best to use a proper library to render the time in the correct ISO8601 format
                const selectedHourWithZero = `0${selectedHour}`.slice(-2);
                const selectedMinuteWithZero = `0${selectedMinute}`.slice(-2);
                estimatedCheckoutTime = `${selectedHourWithZero}:${selectedMinuteWithZero}`;
            }

            return EmployeeApi.checkIn(accessToken, employeeId, pin, groupId, estimatedCheckoutTime).pipe(
                flatMap(response => {
                    track(TrackingEvents.SIGNIN_SCREEN_STAFF_MEMBER_CHECKED_IN, {
                        openedFrom: 'Sign out screen',
                        usedPinCode: isPinApp(state$.value),
                    });
                    return [
                        Actions.checkInEmployeeSuccess.action(response),
                        GlobalEventActions.updateSuccess.action(successMessage),
                    ];
                }),
                catchError(e => {
                    const errorMessage = e.error ? e.error : translate('somethingWentWrong');
                    return merge(
                        of(GlobalEventActions.updateError.action(errorMessage)),
                        of(Actions.checkInEmployeeFailed.action(e)),
                    );
                }),
            );
        }),
    );

const onSelectedHoursChanged = (action$, state$) =>
    action$.pipe(
        ofType(Actions.selectLeaveHours.type),
        map(({payload}: ReturnType<typeof Actions.selectLeaveHours.action>) => {
            const timeOptions = Selectors.checkoutTimeOptions(state$.value);

            // Select the lowest possible minute option within the selected hour
            const minutes = Math.min(...(timeOptions.find(time => time.hour === payload.hours)?.minutes ?? []));

            return Actions.selectLeaveMinutes.action(minutes);
        }),
    );

export default combineEpics<Action<any>, Action<any>, RootState>(checkInEmployee, onSelectedHoursChanged);
