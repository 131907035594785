import React from 'react';
import {Text} from '@famly/mf_data-display_text';

import {type DataProps, useDataProps} from 'modern-famly/components/util';

import {ListItem} from './list-item';
import {useListContext} from './list-context';

export type ListItemShortTextProps = {
    text: string;
} & DataProps;

export const ListItemShortText = (props: ListItemShortTextProps) => {
    const {device} = useListContext();

    const dataProps = useDataProps(props);

    return (
        <ListItem minWidth={0} {...dataProps}>
            <Text variant={device === 'mobile' ? 'body-small' : 'body'} color="n400" ellipsis>
                {props.text}
            </Text>
        </ListItem>
    );
};
