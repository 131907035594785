import {useCallback} from 'react';
import i18next from 'i18next';

import {useGenericDialog} from '@famly/mf_modals-dialogs_context';

const useExternalLinkWarningDialog = () => {
    const {showDialog, hideDialog} = useGenericDialog();

    const show = useCallback(
        (href: string) => {
            return new Promise<true | void>(resolve => {
                showDialog({
                    title: i18next.t('react.activities.translations.json.externalLinkWarningDialog.title'),
                    description: i18next.t('react.activities.translations.json.externalLinkWarningDialog.description', {
                        linkUrl: href,
                    }),
                    confirmText: i18next.t(
                        'react.activities.translations.json.externalLinkWarningDialog.confirmButtonText',
                    ),
                    onConfirm: () => {
                        resolve(true);
                        hideDialog();
                    },
                });
            });
        },
        [showDialog, hideDialog],
    );

    return show;
};

export {useExternalLinkWarningDialog};
