import {
    type Components,
    createTheme,
    type Theme as MuiTheme,
    useTheme as useMuiTheme,
    type ThemeOptions,
} from '@mui/material/styles';
import {type Localization} from '@mui/material/locale';
import {type LinkProps} from '@mui/material/Link';
import {formLabelClasses} from '@mui/material/FormLabel';
import {SwitchThemeConfiguration} from '@famly/mf_input_toggle';

/**
 * The following makes sure that MUI's theme gets augmented with components from @mui/x-date-pickers-pro
 */

import {DrawerThemeConfiguration} from 'modern-famly/components/navigation/drawer/drawer';
import {ButtonThemeConfiguration} from 'modern-famly/components/input/button/button-base';
import {IconThemeConfiguration} from 'modern-famly/components/data-display/icon';
import {OutlinedInputThemeConfiguration} from 'modern-famly/components/input/text-field/text-field';
import {SelectThemeConfiguration} from 'modern-famly/components/input/select/base';
import {ChipThemeConfiguration} from 'modern-famly/components/data-display/pill/pill';
import {AvatarGroupThemeConfiguration} from 'modern-famly/components/data-display/avatar-group/avatar-group';
import {BadgeThemeConfiguration} from 'modern-famly/components/data-display/badge/badge';
import {DatePickerThemeConfiguration} from 'modern-famly/components/input/date-and-time/date-picker/date-picker-theme-configuration';
import {DateRangePickerThemeConfiguration} from 'modern-famly/components/input/date-and-time/date-range-picker/date-range-picker-theme-configuration';
import type {ElevationConfiguration} from 'modern-famly/theming/elevations';
import {getEmphasizedFontWeight} from 'modern-famly/components/util';

/**
 * MUI Theming
 */
const fontVariant = 'body';
export const LabelThemeConfiguration: Components<MuiTheme>['MuiFormLabel'] = {
    styleOverrides: {
        root: ({theme}) => ({
            fontWeight: getEmphasizedFontWeight(fontVariant),
            color: theme.modernFamlyTheme.colorPalette.n400,
            fontSize: theme.typography[fontVariant].fontSize,
            lineHeight: theme.typography[fontVariant].lineHeight,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',

            [`&.${formLabelClasses.disabled}`]: {
                color: theme.modernFamlyTheme.colorPalette.n200,

                [`.${formLabelClasses.asterisk}`]: {
                    color: theme.modernFamlyTheme.colorPalette.n200,
                },
            },

            [`.${formLabelClasses.asterisk}`]: {
                color: theme.modernFamlyTheme.colorPalette.r400,
            },
        }),
    },
};

export const FormHelperTextThemeConfiguration: Components<MuiTheme>['MuiFormHelperText'] = {
    styleOverrides: {
        root: ({theme}) => {
            return {
                ...theme.typography.micro,
                color: theme.modernFamlyTheme.colorPalette.n300,
                margin: 0,
            };
        },
        disabled: ({theme}) => {
            return {
                color: theme.modernFamlyTheme.colorPalette.n200,
            };
        },
    },
};

/**
 * Modern Famly theme
 */
export interface Theme {
    /**
     * The name of the theme
     */
    name: string;

    /**
     * The theme's color palette
     */
    colorPalette: ColorPalette;

    /**
     * Elevations
     */
    elevation: ElevationConfiguration;

    /**
     * Theme helper for spacing. Forwarded from Material.
     *
     * @see https://mui.com/material-ui/customization/spacing/
     */
    spacing: MuiTheme['spacing'];

    /**
     * Theme helper for breakpoints.
     *
     * @see https://mui.com/material-ui/customization/breakpoints/
     */
    breakpoints: MuiTheme['breakpoints'];

    /**
     * Theme helper for border radius.
     */
    shape: MuiTheme['shape'];
}

export const TypographyThemeConfiguration: Components<
    Omit<Theme, 'name' | 'colorPalette' | 'elevation'>
>['MuiTypography'] = {
    defaultProps: {
        variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
            body: 'p',
            'body-small': 'p',
            micro: 'p',
        },
    },
};

/**
 * Converts a Modern Famly Theme to a theme that's compatible with MUI
 *
 * @param theme A Modern Famly theme
 * @returns A MUI compatible theme
 */
export const toMuiTheme = (
    theme: Theme,
    localization: Localization,
    config?: {LinkBehavior?: React.ElementType},
): MuiTheme => {
    return createTheme(
        {
            modernFamlyTheme: theme,
            breakpoints: theme.breakpoints,
            spacing: theme.spacing,
            shape: theme.shape,
            typography: TypographyThemeOptions,
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                    -webkit-font-smoothing: antialiased;
                `,
                },
                MuiButtonBase: {
                    defaultProps: {
                        LinkComponent: config?.LinkBehavior,
                    },
                },
                MuiLink: {
                    // The `as LinkProps` cast is necessary. This is what MUI does in their own documentation: https://mui.com/material-ui/guides/routing/#global-theme-link
                    defaultProps: {
                        component: config?.LinkBehavior,
                    } as LinkProps,
                },
                MuiButton: ButtonThemeConfiguration,
                MuiChip: ChipThemeConfiguration,
                MuiDrawer: DrawerThemeConfiguration,
                MuiFormLabel: LabelThemeConfiguration,
                MuiIcon: IconThemeConfiguration,
                MuiFormHelperText: FormHelperTextThemeConfiguration,
                MuiTypography: TypographyThemeConfiguration,
                MuiOutlinedInput: OutlinedInputThemeConfiguration,
                MuiAutocomplete: SelectThemeConfiguration,
                MuiUseMediaQuery: {
                    defaultProps: {
                        /**
                         * Tells MUI that we're not doing server-side rendering and as such
                         * don't need to cater for it when using the useMediaQuery hook.
                         *
                         * For more details, see https://mui.com/material-ui/react-use-media-query/#client-side-only-rendering
                         */
                        noSsr: true,
                    },
                },
                MuiAvatarGroup: AvatarGroupThemeConfiguration,
                MuiBadge: BadgeThemeConfiguration,
                MuiSwitch: SwitchThemeConfiguration,
                ...DatePickerThemeConfiguration,
                ...DateRangePickerThemeConfiguration,
            },
        },
        localization,
    );
};

/**
 * Get the current Modern Famly Theme
 *
 * @returns The current theme
 */
export const useTheme = (): Theme => {
    const muiTheme = useMuiTheme();

    return muiTheme.modernFamlyTheme;
};

/*
|------------------------------------------------------------------------------
| Module augmentation
|------------------------------------------------------------------------------
|
| Extends MUI's theme definition to include Modern Famly theme
|
*/
type ModernFamlyTheme = Theme;

declare module '@mui/material/styles' {
    interface Theme {
        modernFamlyTheme: ModernFamlyTheme;
    }

    interface ThemeOptions {
        modernFamlyTheme: ModernFamlyTheme;
    }

    interface BreakpointOverrides {
        xs: false;
        sm: false;
        md: false;
        lg: false;
        xl: false;

        base: true;
        mobileLandscape: true;
        tabletPortrait: true;
        tabletLandscape: true;
        laptop: true;
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        poster: React.CSSProperties;
        body: React.CSSProperties;
        'body-small': React.CSSProperties;
        micro: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        poster?: React.CSSProperties;
        body?: React.CSSProperties;
        'body-small'?: React.CSSProperties;
        micro?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body: true;
        'body-small': true;
        micro: true;
        subtitle1: false;
        subtitle2: false;
        body1: false;
        body2: false;
        button: false;
        caption: false;
        overline: false;
        inherit: false;
    }
}

export const TypographyThemeOptions: ThemeOptions['typography'] = {
    fontFamily: 'Matter, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
    h1: {
        fontSize: '64px',
        fontWeight: 400,
        lineHeight: '72px',
        letterSpacing: '-0.01em',
    },
    h2: {
        fontSize: '48px',
        fontWeight: 400,
        lineHeight: '56px',
        letterSpacing: '-0.01em',
    },
    h3: {
        fontSize: '40px',
        fontWeight: 400,
        lineHeight: '48px',
        letterSpacing: '-0.01em',
    },
    h4: {
        fontSize: '32px',
        fontWeight: 400,
        lineHeight: '40px',
        letterSpacing: '-0.01em',
    },
    h5: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '32px',
        letterSpacing: '-0.01em',
    },
    h6: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '28px',
        letterSpacing: '-0.01em',
    },
    body: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
    },
    'body-small': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
    },
    micro: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '-0.02em',
    },
};
