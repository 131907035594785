import {
    type Theme as ModernFamlyTheme,
    FamlyTheme as FamlyMFTheme,
    NuffieldHealthTheme as NuffieldHealthMFTheme,
    EspiraTheme as EspiraMFTheme,
    KidoTheme as KidoMFTheme,
    BrightHorizonsTheme as BrightHorizonsMFTheme,
    MonkeyPuzzleTheme as MonkeyPuzzleMFTheme,
    NFamilyClubTheme as NFamilyClubMFTheme,
    GrandirUKTheme as GrandirUKMFTheme,
    KindredTheme as KindredMFTheme,
    KinderzimmerUKTheme as KinderzimmerUKMFTheme,
    CpcTreehouseTheme as CpcTreehouseMFTheme,
    CpcOrchardsTheme as CpcOrchardsMFTheme,
    BusybeesTheme as BusybeesMFTheme,
    BBMontessoriTheme as BBMontessoriMFTheme,
    BBOdysseyTheme as BBOdysseyMFTheme,
} from 'modern-famly';

import {getWhitelabel} from 'web-app/shared/white-labeling-helpers';
import {exhaustiveCheck, hasValue} from 'web-app/util/typescript';

import NewLookTheme from './new-look';
import EspiraTheme from './espira';
import KidoTheme from './kido';
import GrandirUKTheme from './grandiruk';
import NuffieldHealthTheme from './nuffieldhealth';
import BrightHorizonsTheme from './bright-horizons';
import KindredTheme from './kindred';
import MonkeyPuzzleTheme from './monkeypuzzle';
import NFamilyClubTheme from './nfamilyclub';
import KinderzimmerUKTheme from './kinderzimmeruk';
import CpcTreehouseTheme from './cpc-treehouse';
import CpcOrchardsTheme from './cpc-orchards';
import BusybeesTheme from './busybees';
import BBMontessoriTheme from './bbmontessori';
import BBOdysseyTheme from './bbodyssey';

/**
 * @deprecated Use color enum
 */

export const colorKeys = [
    'primary',
    'secondary',
    'active',
    'accent1',
    'accent2',
    'accent3',
    'analogue1',
    'analogue2',
    'analogue3',
    'text',
    'blackScrim',
    'textSecondary',
    'textDisabled',
    'invertedText',
    'invertedTextSecondary',
    'invertedTextDisabled',
    'whiteSecondary',
    'whiteDisabled',
    'delimiter',
    'backgroundHover',
];

export const currentTheme = () => {
    const whitelabel = getWhitelabel();

    switch (whitelabel) {
        case 'espira':
            return EspiraTheme;
        case 'kido':
            return KidoTheme;
        case 'brighthorizons':
            return BrightHorizonsTheme;
        case 'kindred':
            return KindredTheme;
        case 'monkeypuzzle':
            return MonkeyPuzzleTheme;
        case 'nfamilyclub':
            return NFamilyClubTheme;
        case 'grandiruk':
            return GrandirUKTheme;
        case 'nuffieldhealth':
            return NuffieldHealthTheme;
        case 'kinderzimmeruk':
            return KinderzimmerUKTheme;
        case 'cpctreehouse':
            return CpcTreehouseTheme;
        case 'cpcorchards':
            return CpcOrchardsTheme;
        case 'busybees':
            return BusybeesTheme;
        case 'bbmontessori':
            return BBMontessoriTheme;
        case 'bbodyssey':
            return BBOdysseyTheme;
        case null:
            return NewLookTheme;
        default:
            exhaustiveCheck(whitelabel);
            return NewLookTheme;
    }
};

export const getCurrentModernFamlyTheme = (): ModernFamlyTheme => {
    const whitelabel = getWhitelabel();

    if (!hasValue(whitelabel)) {
        return FamlyMFTheme;
    }

    switch (whitelabel) {
        case 'espira':
            return EspiraMFTheme;
        case 'kido':
            return KidoMFTheme;
        case 'brighthorizons':
            return BrightHorizonsMFTheme;
        case 'kindred':
            return KindredMFTheme;
        case 'monkeypuzzle':
            return MonkeyPuzzleMFTheme;
        case 'nfamilyclub':
            return NFamilyClubMFTheme;
        case 'grandiruk':
            return GrandirUKMFTheme;
        case 'nuffieldhealth':
            return NuffieldHealthMFTheme;
        case 'kinderzimmeruk':
            return KinderzimmerUKMFTheme;
        case 'cpctreehouse':
            return CpcTreehouseMFTheme;
        case 'cpcorchards':
            return CpcOrchardsMFTheme;
        case 'busybees':
            return BusybeesMFTheme;
        case 'bbmontessori':
            return BBMontessoriMFTheme;
        case 'bbodyssey':
            return BBOdysseyMFTheme;
        default:
            exhaustiveCheck(whitelabel);
            return FamlyMFTheme;
    }
};

export default currentTheme;

export {NewLookTheme};
