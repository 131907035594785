import React from 'react';
import MuiStack, {type StackProps as MuiStackProps} from '@mui/material/Stack';
import {extractSystemStyles, type WithSystemProps} from '@famly/mf_system_system-props';
import {type ResponsiveValue} from '@famly/mf_system_system-props';
import {useDataProps} from '@famly/stat_ts-utils_data-aria-props';

type Props = {
    children?: MuiStackProps['children'];
    direction?: ResponsiveValue<MuiStackProps['direction']>;
    spacing?: ResponsiveValue<MuiStackProps['spacing']>;
};

export type StackProps<T extends keyof JSX.IntrinsicElements = 'div'> = WithSystemProps<Props & DataProps> & {
    component?: T;
} & React.ComponentPropsWithoutRef<T>;

export const Stack = React.forwardRef(
    <T extends keyof JSX.IntrinsicElements = 'div'>(props: StackProps<T>, ref: React.Ref<React.ElementRef<T>>) => {
        const {component, children, direction = 'row', spacing, ...rest} = props;

        const dataProps = useDataProps(rest);
        const {sx, nonSystemProps} = extractSystemStyles(rest);

        const _props = {...dataProps, sx, component, direction, spacing, useFlexGap: true, ...nonSystemProps};

        return (
            <MuiStack {...(_props as any)} ref={ref}>
                {children}
            </MuiStack>
        );
    },
);

export const createStack = <T extends keyof JSX.IntrinsicElements = 'div'>(outerProps: StackProps<T>) => {
    return React.forwardRef((innerProps: StackProps<T>, ref: React.Ref<React.ElementRef<T>>) => {
        const props = {...outerProps, ...innerProps};
        return <Stack {...(props as any)} ref={ref} />;
    });
};
