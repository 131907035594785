import styled from 'styled-components';
import {Icon} from 'modern-famly';
import React from 'react';

import {createBox} from '@famly/mf_layout_box';
import {hasValue} from '@famly/stat_ts-utils_has-value';

import {type LeaveType} from './leave-type';
import {getLeaveTypeIconProps} from '../staffing-attendance/staff-leave-v2/get-leave-type-icon-props';

export type LeaveTypeIconSize = 'tiny' | 'small' | 'medium' | 'large' | 'huge';

const iconSizeMap: Record<LeaveTypeIconSize, number> = {
    tiny: 16,
    small: 24,
    medium: 32,
    large: 40,
    huge: 64,
};

interface LeaveTypeIconColorOptions {
    disabled?: boolean;
}

interface LeaveTypeBackgroundInverted extends LeaveTypeIconColorOptions {
    invertedBackground: true;
    padding: number;
}

interface LeaveTypeBackgroundNormal extends LeaveTypeIconColorOptions {
    invertedBackground: false;
}

type LeaveTypeIconOptions = LeaveTypeBackgroundInverted | LeaveTypeBackgroundNormal;

const IconBackgroundWrapper = styled(createBox({}))`
    border-radius: 4px;

    > svg {
        /* This is needed for the icon to be centered */
        display: block;
    }

    span.material-icons {
        vertical-align: middle;
    }
`;

export function getLeaveTypeIcon(
    leaveType: LeaveType,
    size?: LeaveTypeIconSize,
    options?: LeaveTypeIconOptions,
): JSX.Element | null {
    const iconSize = size ?? defaultLeaveTypeIconSize[leaveType];
    const leaveTypeIconProps = getLeaveTypeIconProps(leaveType, options?.disabled);

    // This is just a safe check in case an invalid leave type is provided
    if (!hasValue(leaveTypeIconProps)) {
        return null;
    }

    const {name, color} = leaveTypeIconProps;

    if (options?.invertedBackground) {
        return (
            <IconBackgroundWrapper padding={`${options.padding}px`} backgroundColor={color}>
                <Icon
                    data-e2e-leave-type={leaveType}
                    name={name}
                    size={iconSizeMap[iconSize] - 2 * options.padding}
                    color="n0"
                />
            </IconBackgroundWrapper>
        );
    }

    return <Icon data-e2e-leave-type={leaveType} name={name} size={iconSizeMap[iconSize]} color={color} />;
}

const defaultLeaveTypeIconSize: {[key in LeaveType]: LeaveTypeIconSize} = {
    ['CHILD_SICK']: 'small',
    ['SICK']: 'small',
    ['VACATION']: 'small',
    ['ABSENT']: 'small',
};
