import React from 'react';

import {hasValue} from '@famly/stat_ts-utils_has-value';
import {type RecordInstance} from 'web-app/react/entities/factory/reducer-factory';
import {type IChild} from 'signin-app/entities/children/model';
import {type PinChild} from 'signin-app/pin/models';
import translate from 'signin-app/helpers/translate';
import * as GoHomeWithActions from 'signin-app/go-home-with/actions';
import * as GoHomeWithSelectors from 'signin-app/go-home-with/selectors';
import * as PinSelectors from 'signin-app/login/selectors';
import {UserOverview} from 'signin-app/components/user-overview';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {useTypedSelector} from 'signin-app/components/hooks';

import * as Selectors from './selectors';

interface ChildOverviewProps {
    limitedData: boolean;
    child: RecordInstance<IChild> | PinChild;
}

const composePickupNameAndTime = (name: string | null, time: string | null) => {
    if (hasValue(name) && hasValue(time)) {
        return `${name}, ${time}`;
    } else if (hasValue(name)) {
        return name;
    }
    return null;
};

export const ChildOverview: React.FC<ChildOverviewProps> = ({child, limitedData}) => {
    const childName = limitedData ? child.name.firstName : child.name.fullName;
    const subText = child.goHomeWithChildId || child.pickupRelationId ? translate('goingHomeWithAnotherChild') : '';
    const isPinApp = useTypedSelector(PinSelectors.isPinApp);
    const isFetching = useTypedSelector(state => GoHomeWithSelectors.isFetching(state));

    const pickupPersonName = useTypedSelector(state =>
        Selectors.pickupFullName(state, {
            child,
            childId: child.id,
        }),
    );

    const pickupTime = useTypedSelector(state =>
        Selectors.pickupTime(state, {
            childId: child.id,
        }),
    );

    React.useEffect(() => {
        if (child && isPinApp) {
            GoHomeWithActions.fetchGoHomeWithChildren.dispatch(child.id);
        }
    }, [child, isPinApp]);

    if (!child) {
        return null;
    }

    if (isFetching) {
        return <Spinner margined centered />;
    }

    return (
        <UserOverview
            image={child.image.large}
            name={childName}
            subText={subText}
            subTextBold={composePickupNameAndTime(pickupPersonName, pickupTime)}
        />
    );
};
