/* eslint-disable no-restricted-imports */
import React from 'react';
import {extractAriaProps, extractDataProps} from 'modern-famly/components/util';

import {useModalsDialogsContext} from './modals-dialogs-context';
import {GenericDialog, type GenericDialogProps} from './generic-dialog';

/**
 * The hook to render a generic dialog.
 *
 * Allows easily showing a generic dialog with title, text, confirmation and cancel buttons.
 *
 * Allows specifying a `variant` prop which will change the confirm button's appearance.
 *
 * @see {@link file://./generic-dialog.tsx}
 * @returns an object containing functions to show and hide the dialog
 */
export function useGenericDialog() {
    const {showModalDialog, hideModalDialog} = useModalsDialogsContext();
    const id = React.useId();
    const handleClose = React.useCallback(() => hideModalDialog(id), [hideModalDialog, id]);

    const show = React.useCallback(
        (props: Omit<GenericDialogProps, 'onClose'>) => {
            showModalDialog({
                id,
                type: 'dialog',
                element: <GenericDialog onClose={handleClose} {...props} />,
                ...extractDataProps(props),
                ...extractAriaProps(props),
            });
        },
        [handleClose, id, showModalDialog],
    );

    return React.useMemo(
        () => ({
            showDialog: show,
            hideDialog: handleClose,
        }),
        [handleClose, show],
    );
}
