import React from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import moment from 'moment-timezone';

import {Text} from '@famly/mf_data-display_text';
import {Box} from '@famly/mf_layout_box';
import {useBreakpoints} from '@famly/mf_util_breakpoints';
import * as LoginSelectors from 'signin-app/login/selectors';
import translate from 'signin-app/helpers/translate';
import {isPrefetching} from 'signin-app/components/private-route/selectors';
import * as GroupSelectors from 'signin-app/groups/selectors';
import {checkInEmployee} from 'signin-app/employee/check-in/actions';
import Header from 'signin-app/components/header';
import {PinHeader} from 'signin-app/child/pin-header';
import {
    ConfirmButton,
    ActionButton,
    BottomBarContainer,
    PageBodyContainer,
    ActionOnClickButton,
} from 'signin-app/components/action-buttons';
import {UserOverview} from 'signin-app/components/user-overview';
import Spinner from 'web-app/react/components/loading/spinner/spinner';
import {getFormattedTime, type Time} from 'signin-app/helpers/time';
import {Card, CardsContainer} from 'signin-app/groups/group-item';
import {useTypedSelector} from 'signin-app/components/hooks';
import PersonSelect from 'signin-app/components/person-select';
import * as WhitelabelSelectors from 'signin-app/whitelabel/selectors';
import {isPinApp} from 'signin-app/login/selectors';
import {pinCanOpenDoor, pinHolder, pinLoginId, pinOpenDoorExpiration, pinOpenDoorHmac} from 'signin-app/pin/selectors';
import {PageContainer} from 'signin-app/components/containers';
import {useDisplayName} from 'signin-app/helpers/use-display-name';
import {useRemoteOpenDoor} from 'signin-app/door-locking/hooks';

import {checkOutEmployee} from './actions';
import * as Selectors from './selectors';

type CheckOutRouteProps = RouteComponentProps<{employeeId: string}>;

export const EmployeeCheckOut: React.FC<CheckOutRouteProps> = props => {
    const accessToken = useTypedSelector(LoginSelectors.accessToken);
    const isFetching = useTypedSelector(isPrefetching);
    const employee = useTypedSelector(state => Selectors.employee(state, props));
    const isCheckingOut = useTypedSelector(Selectors.isCheckingOut);
    const groups = useTypedSelector(GroupSelectors.groups);
    const timezone = useTypedSelector(GroupSelectors.timezone);
    const employeeEstimatedCheckoutTimeEnabled = useTypedSelector(GroupSelectors.employeeEstimatedCheckoutTimeEnabled);
    const locale = useTypedSelector(GroupSelectors.institutionLocale);
    const canSwitchRoom = useTypedSelector(GroupSelectors.canSwitchRoom);
    const isPin = useTypedSelector(isPinApp);
    const pin = useTypedSelector(pinHolder);
    const loginId = useTypedSelector(pinLoginId);
    const siteId = useTypedSelector(LoginSelectors.getSiteId);
    const canOpenDoor = useTypedSelector(pinCanOpenDoor) ?? false;
    const openDoorExpiration = useTypedSelector(pinOpenDoorExpiration);
    const openDoorHmac = useTypedSelector(pinOpenDoorHmac);

    const whitelabelConfiguration = useTypedSelector(WhitelabelSelectors.whiteLabelConfiguration);

    const {isTabletPortraitAndLarger} = useBreakpoints();
    const dispatch = useDispatch();
    const displayName = useDisplayName(employee?.name.firstName, employee?.name.lastName);

    const formattedEstimatedCheckoutTime: Time = React.useMemo(() => {
        if (employee && employee.estimatedCheckoutTime && employeeEstimatedCheckoutTimeEnabled && timezone) {
            const timezonedCheckoutTime = moment(employee.estimatedCheckoutTime).tz(timezone);

            const formattedEstimatedCheckoutTime = getFormattedTime(
                timezonedCheckoutTime.hours(),
                timezonedCheckoutTime.minutes(),
                locale,
                false,
            );
            return formattedEstimatedCheckoutTime;
        }
        return {time: '', fullTime: '', amPm: '', minutes: ''};
    }, [employee, employeeEstimatedCheckoutTimeEnabled, timezone, locale]);

    const handleRoomSwitch = React.useCallback(
        (roomId: string) => {
            if (!employee || !timezone) {
                return;
            }
            const successMessage = translate('hasMovedRoom', {personName: displayName});

            const parsedPickupTime =
                employeeEstimatedCheckoutTimeEnabled && employee.estimatedCheckoutTime
                    ? moment(employee.estimatedCheckoutTime).tz(timezone)
                    : undefined;

            dispatch(
                checkInEmployee.action(
                    accessToken,
                    employee.id,
                    pin,
                    parsedPickupTime?.hours(),
                    parsedPickupTime?.minutes(),
                    roomId,
                    successMessage,
                ),
            );
        },
        [employee, timezone, accessToken, dispatch, employeeEstimatedCheckoutTimeEnabled, displayName, pin],
    );

    const handleOpenDoor = useRemoteOpenDoor(siteId, loginId, openDoorExpiration, openDoorHmac);

    return (
        <PageContainer data-e2e-id="employee-checkout">
            {isPin ? <PinHeader whitelabelConfiguration={whitelabelConfiguration} /> : <Header />}
            {employee && !isFetching ? (
                <>
                    <PageBodyContainer mt={16}>
                        <PersonSelect activeEmployeeId={employee.id} />
                        <UserOverview
                            image={employee.image.large}
                            name={displayName}
                            subText={employeeEstimatedCheckoutTimeEnabled ? translate('leavingToday') : ''}
                            subTextBold={
                                employeeEstimatedCheckoutTimeEnabled ? formattedEstimatedCheckoutTime.fullTime : ''
                            }
                        />
                        {canSwitchRoom && (
                            <Box my={3}>
                                <Text variant="h6" color="n400" emphasized>
                                    {translate('switchRoom')}
                                </Text>
                                <CardsContainer mt={2}>
                                    {groups.map(group => (
                                        <Card
                                            alignment={isTabletPortraitAndLarger ? 'vertical' : 'horizontal'}
                                            key={group.groupId}
                                            image={group.image.large}
                                            displayTitle={group.title}
                                            onClick={() => handleRoomSwitch(group.groupId)}
                                            selected={
                                                employee.tempGroupId
                                                    ? group.groupId === employee.tempGroupId
                                                    : group.groupId === employee.groupId
                                            }
                                            dataE2eCardContainerClass={`group-title-${group.title}`}
                                        />
                                    ))}
                                </CardsContainer>
                            </Box>
                        )}
                    </PageBodyContainer>
                    <BottomBarContainer>
                        {employeeEstimatedCheckoutTimeEnabled ? (
                            <ActionButton
                                text={translate('changeLeavingTime')}
                                link={`/employee/${employee.id}/checkin?update`}
                            />
                        ) : null}
                        {loginId && canOpenDoor ? (
                            <ActionOnClickButton
                                text={translate('openDoor')}
                                onClick={() => handleOpenDoor()}
                                dataE2EId="employee-check-out-open-door-button"
                            />
                        ) : null}
                        <ConfirmButton
                            type={'signOut'}
                            text={translate('signOut', {personName: displayName})}
                            isLoading={isCheckingOut}
                            onClick={() => dispatch(checkOutEmployee.action(accessToken, employee.id))}
                            dataE2EId="employee-check-out-confirm-button"
                        />
                    </BottomBarContainer>
                </>
            ) : (
                <Spinner centered />
            )}
        </PageContainer>
    );
};
