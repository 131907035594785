import {type QueryHookOptions, type QueryResult} from '@apollo/client';
import {type DocumentNode} from 'graphql';

import {useResilientQuery} from '@famly/stat_apollo-query';

type Transform<T, P, K> = (props: QueryResult<T, P>) => K;

/**
 * Extends the useResilientQuery hook with a transform function, similar to the `props`
 * argument for the equivalent `graphql` react-apolle HoC
 *
 * Usage
 * ```
 * const [{foo}, {data, loading}] = useResilientQuery<MyQuery>()(MyQuery, {}, props => ({
 *   foo: props.data?.bar + 2 ?? 'wizbiz',
 * }))
 * ```
 */
export const useTransformQuery =
    <T, P>() =>
    <K>(
        query: DocumentNode,
        options: QueryHookOptions<T, P> = {},
        transform: Transform<T, P, K>,
    ): [K, QueryResult<T, P>] => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const props = useResilientQuery<T, P>(query, options);
        return [transform(props), props];
    };
