import {adjustAlpha, fixedPalette} from '@famly/mf_colors';

/** @deprecated this is globally available in types/mf.d.ts */
export type ElevationOption = 0 | 1 | 2 | 3 | 4 | 5 | 6;

/** @deprecated this is globally available in types/mf.d.ts */
export type ElevationConfiguration = {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
};

const elevationColorDark = adjustAlpha(0.08, fixedPalette.n500);
const elevationColorLight = adjustAlpha(0.04, fixedPalette.n500);

export const elevationConfiguration: ElevationConfiguration = {
    0: 'none',
    1: `0.0px 3.0px 2.0px 0px ${elevationColorDark},0.0px 0.0px 2.0px 0px ${elevationColorLight}`,
    2: `0.0px 5.0px 3.0px 0px ${elevationColorDark},0.0px 3.0px 2.0px 0px ${elevationColorLight}`,
    3: `0.0px 8.0px 5.0px 0px ${elevationColorDark},0.0px 5.0px 3.0px 0px ${elevationColorLight}`,
    4: `0.0px 15.0px 12.0px 0px ${elevationColorDark},0.0px 8.0px 5.0px 0px ${elevationColorLight}`,
    5: `0.0px 24.0px 18.0px 0px ${elevationColorDark},0.0px 18.0px 12.0px 0px ${elevationColorLight}`,
    6: `0.0px 35.0px 24.0px 0px ${elevationColorDark},0.0px 24.0px 18.0px 0px ${elevationColorLight}`,
};
