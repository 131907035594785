import React from 'react';
import styled, {css} from 'styled-components';

import {hasValue} from '@famly/stat_ts-utils_has-value';
import {PortalModal, type ModalProps as PortalModalProps} from 'web-app/react/components/modals/modals';
import generateUUID from 'web-app/util/uuid';
import {useOnClickOutside} from 'web-app/react/hooks/use-on-click-outside';
import {ModalDialogProvider} from '@famly/mf_modals-dialogs_context';

import {dayPickerInputPortalContentClassName} from '../components/form-elements/daypicker/day-picker-input-portal-class-name';

const modalContext = React.createContext<ModalContextValue<any> | undefined>(undefined);

interface ModalContextValue<P extends object> {
    registerModal: (props: ModalProps<P>) => void;
    unregisterModal: (id: string) => void;
    showModal: (key: string, props: any) => void;
    hideModal: (key: string) => void;
}

interface ModalContextProps {
    children: React.ReactNode;
}

// eslint-disable-next-line no-restricted-syntax
export enum StyledModalPortalWidth {
    Small = '300px',
    Medium = '450px',
    Big = '600px',
}

interface StyledModalPortalProps {
    width?: StyledModalPortalWidth;
    maxWidth?: string;
    height?: string;
    margin?: string;
    borderRadius?: string;
    backgroundColor?: ColorKey;
}

export const StyledModalPortal = styled(PortalModal)<StyledModalPortalProps>`
    ${props =>
        props.width
            ? css`
                  width: ${props.width};
              `
            : ''}
    ${props =>
        props.maxWidth
            ? css`
                  max-width: ${props.maxWidth};
              `
            : ''}
    ${props =>
        props.height
            ? css`
                  height: ${props.height};
              `
            : ''}
    ${props =>
        props.margin
            ? css`
                  margin: ${props.margin};
              `
            : ''}
    ${props =>
        props.borderRadius
            ? css`
                  border-radius: ${props.borderRadius};
              `
            : ''}

    ${props =>
        props.backgroundColor
            ? css`
                  background-color: ${props.theme.mf.colorPalette[props.backgroundColor]};
              `
            : ''}
`;

const StyledModalContent = styled.span<{fullHeight?: boolean}>`
    ${props => (props.fullHeight ? 'height: 100%;' : '')}
`;

type Combine<A, B> = A & B;

/**
 * This is the context, which allows using the `useModal` hook.
 *
 * It should not be accessed directly, as the useModal hook is wrapping some of the important, e.g. memory-leak
 * preventing functionality, that is crucial for modals to not mess around with the whole app.
 *
 * Internally, the context adds a portal modal for each registered modal. This allows rendering any number of modals
 * at any place in the app.
 *
 * @param props Only contains the react children.
 * @returns The children wrapped in the modal context, having a portal modal sibling for each registered modal.
 */
export function ModalContext<P extends object>(props: ModalContextProps): JSX.Element {
    const modalRegistry = React.useRef<Array<ModalProps<P>>>([]);

    const registerModal = React.useCallback((modalProps: ModalProps<P>) => {
        modalRegistry.current = [...modalRegistry.current, modalProps];
    }, []);

    const unregisterModal = React.useCallback((id: string) => {
        modalRegistry.current = modalRegistry.current.filter(modal => modal.id !== id);
    }, []);

    const [currentModalProps, setCurrentModalProps] = React.useState<Array<Combine<{props: P}, ModalProps<P>>>>([]);

    const showModal = React.useCallback((id: string, modalProps: P) => {
        setCurrentModalProps(state => {
            const modalFromRegister = modalRegistry.current.find(modal => modal.id === id);
            if (!hasValue(modalFromRegister)) {
                return state;
            }

            const alreadyRenderedModalIndex = state.findIndex(modal => modal.id === modalFromRegister.id);
            if (alreadyRenderedModalIndex !== -1) {
                const newState = [...state];
                newState[alreadyRenderedModalIndex].props = modalProps;
                return newState;
            } else {
                return [
                    ...state,
                    {
                        ...modalFromRegister,
                        props: modalProps,
                    },
                ];
            }
        });
    }, []);

    const hideModal = React.useCallback((id: string) => {
        setCurrentModalProps(state => {
            return state.filter(modal => modal.id !== id);
        });
    }, []);

    return (
        <modalContext.Provider
            value={React.useMemo(
                () => ({
                    registerModal,
                    unregisterModal,
                    showModal,
                    hideModal,
                }),
                [hideModal, registerModal, showModal, unregisterModal],
            )}
        >
            {currentModalProps.map((props, index) => (
                <ModalWrapper
                    key={props.id}
                    {...props}
                    isTopMostModal={currentModalProps.length - 1 === index}
                    handleHide={() => hideModal(props.id)}
                />
            ))}
            <React.Fragment key="unchanged">{props.children}</React.Fragment>
        </modalContext.Provider>
    );
}

const defaultIgnoreOutsideClickSelector = [
    `.${dayPickerInputPortalContentClassName}`,
    '.MuiPickersPopper-root',
    '.MuiInputBase-input',
];

function ModalWrapper<P extends object>(
    modal: Combine<
        {
            props: P;
        },
        ModalProps<P>
    > & {isTopMostModal: boolean; handleHide: () => void},
): JSX.Element {
    const modalRef = React.useRef(null);
    const handleClickOutside = React.useCallback(() => {
        if (modal.isTopMostModal) {
            modal.handleHide();
        }
    }, [modal]);

    const combinedIgnoreOutsideClickSelectors = React.useMemo(() => {
        return [...defaultIgnoreOutsideClickSelector, ...(modal.ignoreOutsideClickSelectors ?? [])];
    }, [modal.ignoreOutsideClickSelectors]);

    useOnClickOutside(modalRef, handleClickOutside, combinedIgnoreOutsideClickSelectors);
    return (
        <StyledModalPortal {...modal.modalProps} {...modal.modalStyle}>
            <ModalDialogProvider>
                <StyledModalContent fullHeight={modal.contentFullHeight} ref={modalRef}>
                    {React.createElement(modal.component, modal.props)}
                </StyledModalContent>
            </ModalDialogProvider>
        </StyledModalPortal>
    );
}

function useModalContext<P extends object>(): ModalContextValue<P> {
    return React.useContext(modalContext) as ModalContextValue<P>;
}

interface ModalProps<P extends object> {
    id: string;
    component: React.ComponentType<React.PropsWithChildren<P>>;

    modalProps?: Omit<PortalModalProps, 'children'>;
    modalStyle?: StyledModalPortalProps;
    contentFullHeight?: boolean;

    ignoreOutsideClickSelectors?: string[];
}

/**
 * Hook to render a Modal.
 *
 * Internally uses the modal context to register and unregister modals.
 *
 * You should provide any static props to the modal via the `useModal` hook props, while contextual props, that
 * depend on the context, in which the `show` function returned by this hook is called, via the props put into that
 * function call.
 *
 * The generic parameter `<P>` refers to the dynamic props.
 *
 * @example
 *
 * ```typescript
 * // Some component to render in a modal
 * const HelloWorldComponent: React.FC<{ hello: 'world'; bar: Array<number> }> = props => {
 *   return ...
 * }
 *
 * const {show, hide} = useModal({
 *      component: HelloWorldComponent
 * })
 *
 * // Types for `show` are inferred from the prop of HelloWorldComponent 🙌
 * show({hello: 'world', bar: [1, 2, 3]});
 * ```
 *
 * @param props props to register the modal. That includes the component to show, the portal modal props and some
 *      trimmed down styling options.
 * @returns an object containing the `show` function to render the modal and the `hide` function to hide it again
 * @deprecated use the new `useModal` hook from '@famly/mf_modals-dialogs_context
 *
 * @author Domi <dr@famly.co>
 * @author Emil <elh@famly.co>
 */
export function useModal<P extends object>(
    props: UseModalProps<P>,
): {
    show: (props: P) => void;
    hide: () => void;
} {
    const {hideModal, registerModal, showModal, unregisterModal} = useModalContext<P>();

    const persistedId = React.useRef(generateUUID());

    React.useEffect(() => {
        const id = persistedId.current;
        registerModal({
            ...props,
            id,
        });
        return () => unregisterModal(id);
    }, [props, registerModal, unregisterModal]);

    const show = React.useCallback((modalProps: P) => showModal(persistedId.current, modalProps), [showModal]);
    const hide = React.useCallback(() => hideModal(persistedId.current), [hideModal]);

    return {show, hide};
}

export type UseModalProps<P extends object> = Omit<ModalProps<P>, 'id'>;
