import {Button} from 'modern-famly';
import React from 'react';

import {DialogTestAttributes} from './dialog-test-attributes';

/**
 * The close button element to be used on modern famly dialogs.
 *
 * @param onClose callback called when the close button is clicked
 */
export const DialogCloseButton = ({onClose}: {onClose: Callback<void>}) => {
    return (
        <Button
            data-e2e-id={DialogTestAttributes.xButtonE2eId}
            icon="close"
            aria-label="close"
            onClick={() => onClose()}
            variant="transparent"
            alignSelf="center"
        />
    );
};
