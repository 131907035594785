import {OrderedMap, Range, type List} from 'immutable';

import {hasValue} from '@famly/stat_ts-utils_has-value';

import * as NonEmptyArrayUtils from './non-empty-array';
import {type Dictionary, mapAndReduce} from './typescript';

export function partition<T, S extends T>(array: T[], predicate: (value: T) => value is S): {passed: S[]; failed: T[]} {
    return array.reduce(
        ({passed, failed}, value) => {
            // eslint-disable-next-line no-unused-expressions
            predicate(value) ? passed.push(value) : failed.push(value);
            return {passed, failed};
        },
        {
            // eslint-disable-next-line no-array-constructor
            passed: new Array<S>(),
            // eslint-disable-next-line no-array-constructor
            failed: new Array<T>(),
        },
    );
}

const arrayToOrderedMap = <T, K = any>(array: T[], getIdentifier: (value: T) => K): OrderedMap<K, T> => {
    return OrderedMap(array.map((val): [K, T] => [getIdentifier(val), val]));
};

export function mergeUnique<T, K = any>(array1: T[], array2: T[], getIdentifier: (value: T) => K): T[] {
    const map1 = arrayToOrderedMap(array1, getIdentifier);
    const map2 = arrayToOrderedMap(array2, getIdentifier);

    return map1.merge(map2).valueSeq().toArray();
}

export const chunkArray = <T>(array: T[], chunkSize: number): T[][] =>
    Range(0, array.length, chunkSize)
        .map(chunkStart => array.slice(chunkStart, chunkStart + chunkSize))
        .toArray();

export const chunkList = <T>(array: List<T>, chunkSize: number): List<List<T>> =>
    Range(0, array.size, chunkSize)
        .map(chunkStart => array.slice(chunkStart, chunkStart + chunkSize))
        .toList();

export const transposeArray = <T>(array: T[][]): T[][] => {
    const newA: T[][] = [];
    for (let i = 0; i < array[0].length; ++i) {
        newA[i] = [];
        for (let j = 0; j < array.length; j++) {
            newA[i][j] = array[j][i];
        }
    }
    return newA;
};

/**
 * Creates an array of numbers from `start` to `end` (both inclusive)
 *
 * @param start The start of the range
 * @param end The end of the range
 * @returns An array of numbers from `start` to `end`
 */
export const range = (start: number, end: number): number[] => {
    const array: Array<number> = [];

    for (let i = 0; start + i <= end; i++) {
        array.push(start + i);
    }

    return array;
};

/**
 * Utility to force the return type of an array indexing to potentially be `undefined`
 *
 * This is useful as TypeScript will infer `number` to be of type number:
 *
 *      const myNumbers = [1, 2, 3, 4];
 *      const number = myNumbers[1000];
 *
 * But it is `undefined`. This will force the user to handle the potential undefined value.
 *
 * @param index
 * @param arr
 */
export const get = <T>(index: number, arr: T[]): T | undefined => {
    return arr[index];
};

export const groupByObj = <T extends string, P>(keygen: (elem: P) => T, arr: P[]): Dictionary<T, P[]> => {
    const initial: Dictionary<T, P[]> = {};

    return arr.reduce((acc, curr) => {
        const key = keygen(curr);

        if (Object.prototype.hasOwnProperty.call(acc, key)) {
            acc[key] = [...acc[key]!, curr] as P[];
        } else {
            acc[key] = [curr];
        }

        return acc;
    }, initial);
};

/**
 * String a => (b -> a) -> [b] -> [(a, [b])]
 *
 * Utility that groups elements of an array into tuples based on a key generated from a single array element.
 * Array items will be inserted into the same tuple if the generate the same key. Example usage:
 *
 * ```ts
 *  groupBy(num => num % 2, [0,1,2,3,4,5])
 *  // returns: [["0", [0,2,4]], ["1", [1,3,5]]]
 * ```
 *
 * Really usefull in cases where you need to group elements of an object based on a property i.e. `__typename`:
 * ```ts
 *  groupBy(lib => lib.library.__typename, librariesToRender)
 * ```
 */
export const groupBy = <T extends string, P>(keygen: (elem: P) => T, arr: P[]): ArrayGroups<T, P> => {
    const groupedObj = groupByObj(keygen, arr);
    return Object.keys(groupedObj).map(key => [key as T, groupedObj[key] ?? []]);
};

export type ArrayGroups<T, P> = Array<[T, P[]]>;
export type ArrayGroup<T, P> = InferArrayType<ArrayGroups<T, P>>;
type ArrayGroupIdentifier<T, P> = ArrayGroup<T, P>[0];
type ArrayGroupMembers<T, P> = ArrayGroup<T, P>[1];
export const getArrayGroupIdentifier = <T, P>(arrayGroup: ArrayGroup<T, P>): ArrayGroupIdentifier<T, P> =>
    arrayGroup[0];
export const getArrayGroupMembers = <T, P>(arrayGroup: ArrayGroup<T, P>): ArrayGroupMembers<T, P> => arrayGroup[1];

type ValuesOf<T extends any[]> = T[number];
const isNonPartial = <K extends NonEmptyArrayUtils.NonEmptyArray<string>, U, P extends {[key in ValuesOf<K>]: U}>(
    candidate: Partial<P>,
    keys: K,
): candidate is P => {
    const candidateKeys = Object.keys(candidate);
    return keys.every(key => candidateKeys.includes(key));
};

/**
 * Provides a type-safe approach group/transform an array into a _non-nullable_ dictionary structure.
 */
export const groupAndTransform = <P, K extends string, U>(
    array: NonEmptyArrayUtils.NonEmptyArray<P>,
    keygen: (item: P) => K,
    transform: (items: P[]) => U,
): {[key in K]: U} => {
    const foo = mapAndReduce(
        groupBy(keygen, array),
        ([key, items]) => [key, transform(items)] as const,
        (reduction, [key, transformedItems]) => {
            reduction[key] = transformedItems;
            return reduction;
        },
        {} as Partial<{[key in K]: U}>,
    );

    if (!isNonPartial(foo, NonEmptyArrayUtils.map(array, keygen))) {
        /**
         * This should never happen as long as this is only used in TS.
         * The reason for throwing an error is to keep a clean return type.
         */
        throw new Error('Unable to construct grouped structure in `groupAndTransform`');
    }

    return foo;
};

export const indexIsInBounds = (arr: any[], i: number) => {
    return i >= 0 && i <= arr.length - 1;
};

export const swapIndicies = <T>(arr: T[], idxA: number, idxB: number): T[] => {
    if (!indexIsInBounds(arr, idxA) || !indexIsInBounds(arr, idxB)) {
        return arr;
    }
    return Object.assign([...arr], {[idxA]: arr[idxB], [idxB]: arr[idxA]});
};

export const shiftIndex = <T>(arr: T[], i: number, direction: 'prev' | 'next'): T[] => {
    const indexToShift = direction === 'prev' ? i - 1 : i + 1;
    if (!indexIsInBounds(arr, i) || !indexIsInBounds(arr, indexToShift)) {
        return arr;
    }
    return swapIndicies(arr, i, indexToShift);
};

export const isLast = <T>(arr: T[], candidate: T): boolean => {
    return candidate === arr[arr.length - 1];
};

export const last = <T>(arr: T[]): T | undefined => {
    return arr[arr.length - 1];
};

export const withoutLast = <T>(arr: T[]): T[] => {
    return arr.slice(0, -1);
};

/**
 * Use this to filter unique complex arrays
 *
 * @example
 * ```
 * myArray.filter(uniquenessFilter(item => item.uniqueProp))
 * ```
 */
export const uniquenessFilter =
    <T, K>(getUniqueProp: (item: T) => K) =>
    (item: T, index: number, array: T[]) =>
        array.findIndex(candidate => getUniqueProp(candidate) === getUniqueProp(item)) === index;

/**
 * Use this to find intersection between two arrays
 */
export const intersect = <T, K>([a, b]: [T[], T[]], keygen: (item: T) => K) =>
    a.filter(itemA => b.some(itemB => keygen(itemB) === keygen(itemA))).filter(uniquenessFilter(keygen));

/**
 * Use this to find intersection by at least two arrays
 */
export const intersectAll = <T, K>(arrays: T[][], keygen: (item: T) => K) => {
    const [first, ...rest] = arrays;
    if (!hasValue(first)) {
        return [];
    }
    if (!NonEmptyArrayUtils.isNonEmpty(rest)) {
        return first;
    }
    return rest.reduce((reduction, array) => {
        return intersect([reduction, array], keygen);
    }, first);
};

export type InferArrayType<A> = A extends Array<infer T> ? T : never;

export const distinct =
    <T, K>(keygen: (element: T) => K) =>
    (arr: T[]) =>
        arr.filter(uniquenessFilter(keygen));

export const isDistinct = <T, K>(arr: T[], keygen: (element: T) => K) => {
    return arr.filter(uniquenessFilter(keygen)).length === arr.length;
};

export const sortBy = <T, P>(arr: T[], transform: (o: T) => P, compare: (o1: P, o2: P) => 1 | -1 | 0) => {
    return [...arr].sort((a, b) => compare(transform(a), transform(b)));
};

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 * @author Nico Timmerman (Stack overflow https://stackoverflow.com/questions/40929260/find-last-index-of-element-inside-array-by-certain-condition)
 */
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array)) {
            return l;
        }
    }
    return -1;
}

type UnboxPotentialArray<T> = T extends Array<infer B> ? UnboxPotentialArray<B> : T;

/**
 * Returns a tuple based on the filtering (test) function
 *
 * passing type arguments explicitly allows you to use this function as a typeguard as well.
 *
 * @example `bifurcate([1, 2, 3, 4, 5, 6], element => element % 2 === 0)` returns `[[2, 4, 6], [1, 3, 5]];`
 * @example `bifurcate<number | string, number, string>([1, 2, 'hello', 4, 'world'], (element: number | string): element is number => typeof element ==== 'number')` returns `[[1, 2, 4], ["hello", "world"]];`
 * @param array The array to split
 * @param test The filtering (test) function
 * @returns A tuple of elements that satisfy/doesn't satisfy the test function
 */
export const bifurcate = <T, P extends T = T, N extends T = T>(
    array: Array<UnboxPotentialArray<T>>,
    test: (candidate: UnboxPotentialArray<T>) => boolean,
): [Array<UnboxPotentialArray<P>>, Array<UnboxPotentialArray<N>>] =>
    array.reduce(
        (itemsArray: [Array<UnboxPotentialArray<P>>, Array<UnboxPotentialArray<N>>], item) => {
            itemsArray[test(item) ? 0 : 1].push(item as any);
            return itemsArray;
        },
        [[], []],
    );

export const isEmpty = <T>(array: Array<T>): boolean => array.length === 0;

/**
 *  Curried wrapper over `Array.prototype.flatMap` for chaining of flatMap calls in pipe/compose.
 *
 * @example `flatMap((x) => [x, x])([1, 2, 3])` returns `[1, 1, 2, 2, 3, 3];`
 * @param fn The function to apply to each element of the array
 * @param arr The array to apply the function to
 * @returns A curried function that takes an array and flatMaps with the provided function
 */
export const flatMap =
    <T, U>(fn: (value: T, index: number, array: T[]) => U[]) =>
    (arr: T[]): U[] =>
        arr.flatMap(fn);

/**
 *  Curried wrapper over `Array.prototype.map` for chaining of map calls in pipe/compose.
 *
 * @example `map((x) => x * x)([1, 2, 3])` returns `[1, 4, 9];`
 * @param fn The function to apply to each element of the array
 * @param arr The array to apply the function to
 * @returns A curried function that takes an array and maps with the provided function
 */
export const map =
    <T, U>(fn: (value: T, index: number, array: T[]) => U) =>
    (arr: T[]): U[] =>
        arr.map(fn);

/**
 *  Curried wrapper over `Array.prototype.filter` for chaining of filter calls in pipe/compose.
 *
 * @example `filter((x) => x % 2 === 0)([1, 2, 3])` returns `[2];`
 * @param predicate The function to apply to each element of the array
 * @param arr The array to apply the function to
 * @returns A curried function that takes an array and filters with the provided predicate
 */
export function filter<T, U extends T>(
    predicate: (value: T, index: number, array: T[]) => value is U,
): (arr: T[]) => U[];
export function filter<T>(predicate: (value: T, index: number, array: T[]) => boolean): (arr: T[]) => T[];
export function filter<T>(predicate: (value: T, index: number, array: T[]) => boolean) {
    return function (arr: T[]): T[] {
        return arr.filter(predicate);
    };
}

/**
 * Indexes the elements of an array by a string key extracted from the array
 * elements.
 *
 * Assumes keys are unique.
 *
 * @param xs The array of elements to be indexed.
 * @param getKey Function to get the key from an element.
 * @returns A record mapping keys to the corresponding elements.
 * @example
 *   const philosophers = indexBy([
 *      {name: 'Aristotle', id: 'a'},
 *      {name: 'Plato', id: 'b'}
 *   ], p=>p.id);
 *   assert(philosophers['a'].name === 'Aristotle');
 */
export const indexBy = <T>(xs: T[], getKey: (x: T) => string): {[key: string]: T} =>
    toRecord(xs, getKey, value => value);

/**
 * Converts an array to a record by extracting key, value pairs from each
 * array element and indexing the values by the keys.
 *
 * Assumes keys are unique.
 *
 * @param xs The array of elements to be indexed.
 * @param getKey Function to get the key from an element.
 * @param getValue Function to get the value from an element
 * @returns A record mapping keys to the corresponding values.
 * @example
 *   const philosophers = toRecord([
 *      {name: 'Aristotle', id: 'a'},
 *      {name: 'Plato', id: 'b'}
 *   ], p=>p.id, p=>p.name);
 *   assert(philosophers['a'] === 'Aristotle');
 */
export const toRecord = <T, TValue>(
    xs: T[],
    getKey: (x: T) => string,
    getValue: (x: T) => TValue,
): {[key: string]: TValue} =>
    xs.reduce((map: {[key: string]: TValue}, x: T) => {
        map[getKey(x)] = getValue(x);
        return map;
    }, {});

/**
 * Builds an array from a generating function and an initial state for the
 * generator. A functional way of building arrays of hard to predict length.
 *
 * This is an anamorphism over arrays which is the dual concept of
 * catamorphisms of which `fold` is an example.
 *
 * TODO: This could be optimized by rewriting it as a dirty while loop.
 *
 * @param initialState An initial state to generate array elements from.
 * @param gen A generating function that takes a state and produces the
 * next element and an updated state. Should return `null` if no more
 * elements should be generated.
 * @return An array of elements generated by `gen`.
 *
 * @example
 *   const numberNames = ['nolla', 'yksi', 'kaksi', 'kolme'];
 *   const numbers = generate(1, state =>
 *     state < numberNames.length ?
 *       {element: numberNames[state], state: state + 2} :
 *       null,
 *   );
 *   assert(numbers === ['yksi', 'kolme']);
 */
export const generate = <TState, T>(
    initialState: TState,
    gen: (state: TState) => {element: T; state: TState} | null,
): T[] => {
    const pair = gen(initialState);
    if (!hasValue(pair)) {
        return [];
    }
    // Don't bother with tail calls. Only Safari optimizes tail calls.
    return [pair.element, ...generate(pair.state, gen)];
};
