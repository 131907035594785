import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {Icon, type IconName} from 'modern-famly';
import moment from 'moment-timezone';

import {Text} from '@famly/mf_data-display_text';
import {Stack} from '@famly/mf_layout_stack';
import {Box} from '@famly/mf_layout_box';
import {createStack} from '@famly/mf_layout_stack';
import {useBreakpoints} from '@famly/mf_util_breakpoints';
import {type IEmployee} from 'signin-app/entities/staff/model';
import type ChildModel from 'signin-app/entities/children/model';
import {type PinChild, type PinEmployee} from 'signin-app/pin/models';
import {limitedData} from 'signin-app/child/check-out/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import {showSignedIn} from 'signin-app/group/selectors';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import {PersonImage} from 'signin-app/components/person-image';
import {getWentHome} from 'web-app/react/components/person-button/person-button-base';
import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

import {Badge} from './badge';

interface PersonButtonProps {
    person: PersonType;
    onClick?: (personId: string) => void;
    linkTo?: string;
    className?: string;
    selected?: boolean;
    hide?: boolean;
    compact?: boolean;
}

type PersonType = typeof ChildModel.default | IEmployee | PinChild | PinEmployee;

const StyledPersonContainer = styled(
    createStack({
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        maxWidth: {base: '100px', tabletPortrait: 'initial'},
        mx: 'auto',
    }),
)<{selected?: boolean; hide?: boolean; compact?: boolean; customBackgroundColor?: string; customBorderColor?: string}>`
    border-radius: ${props => props.theme.mf.spacing(4)};
    ${props => css`
        color: ${props.theme.mf.colorPalette.n400};
    `}

    ${props =>
        props.compact
            ? css`
                  width: 120px;
              `
            : ''}

    ${props =>
        props.selected
            ? css`
                  background-color: ${props.customBackgroundColor};
                  border: 1px solid ${props.customBorderColor};
              `
            : ''}

    ${props =>
        props.hide
            ? css`
                  display: none !important;
              `
            : ''}
          
    box-sizing: border-box;
`;

// eslint-disable-next-line no-restricted-syntax
enum StatusState {
    SignedIn = 'SignedIn',
    SignedOut = 'SignedOut',
    Sick = 'Sick',
    Absent = 'Absent',
    OnVacation = 'OnVacation',
    None = 'None',
    HasBirthday = 'HasBirthday',
}

const hasProperty = (obj: any, property): obj is typeof ChildModel.default => {
    return property in obj;
};

const employeeHasProperty = (obj: any, property): obj is IEmployee => {
    return property in obj;
};

const StyledTextContainer = styled(createStack({}))`
    max-width: 100%;
    * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const PersonButton: React.FC<PersonButtonProps> = props => {
    const {linkTo, person, hide, selected, compact, onClick} = props;
    const isDataLimited = useTypedSelector(state => limitedData(state));
    const shouldShowSignedIn = useTypedSelector(state => showSignedIn(state));
    const timezone = useTypedSelector(GroupsSelectors.timezone);
    const p300 = useCustomWhitelabelColor('p300');
    const p200 = useCustomWhitelabelColor('p200');

    const hasBirthdayToday = React.useMemo(() => {
        if (hasProperty(person, 'birthday')) {
            const birthday = person.birthday;
            const today = moment();
            const birthdate = moment(birthday);
            const hasBirthday = today.month() === birthdate.month() && today.date() === birthdate.date();
            return hasBirthday;
        }
        return false;
    }, [person]);

    const handleButtonClick = React.useCallback(() => {
        if (onClick) {
            onClick(person.id);
        }
    }, [person, onClick]);

    const displayName = React.useMemo(() => {
        if (compact || !person.name.lastName) {
            return person.name.firstName;
        }
        return `${person.name.firstName} ${person.name.lastName.charAt(0)}.`;
    }, [person, compact]);

    const pickupDetails = React.useMemo(() => {
        if (
            hasProperty(person, 'pickupTime') &&
            hasProperty(person, 'pickupName') &&
            person.pickupTime &&
            person.pickupName &&
            timezone
        ) {
            /** Following the timezone logic from signin-app/src/child/check-in/pickup-time.tsx */
            const pickupTime = moment(person.pickupTime).tz(timezone);
            const hours = pickupTime.hour().toString().padStart(2, '0');
            const minutes = pickupTime.minute().toString().padStart(2, '0');
            const formattedTime = `${hours}:${minutes}`;
            return `${formattedTime} • ${person.pickupName}`;
        }
        return '';
    }, [person, timezone]);

    const personButtonInner = React.useMemo(
        () => (
            <StyledPersonContainer
                hide={hide}
                selected={selected}
                compact={compact}
                customBackgroundColor={p200}
                customBorderColor={p300}
            >
                <PersonAvatar person={person} isDataLimited={isDataLimited} hasBirthdayToday={hasBirthdayToday} />
                <StyledTextContainer mt={compact ? 3 : 2}>
                    <Text variant={compact ? 'body' : 'h5'} emphasized>
                        {displayName}
                    </Text>
                </StyledTextContainer>
                {shouldShowSignedIn && <Text variant="body-small">{pickupDetails}</Text>}
            </StyledPersonContainer>
        ),
        [
            person,
            displayName,
            hide,
            selected,
            hasBirthdayToday,
            isDataLimited,
            shouldShowSignedIn,
            pickupDetails,
            compact,
            p300,
            p200,
        ],
    );

    return (
        <>
            {linkTo ? (
                <Link to={linkTo} onClick={handleButtonClick}>
                    <Box>{personButtonInner}</Box>
                </Link>
            ) : (
                <Box onClick={handleButtonClick}>{personButtonInner}</Box>
            )}
        </>
    );
};

const PersonAvatar: React.FC<{person: PersonType; isDataLimited: boolean; hasBirthdayToday: boolean}> = ({
    person,
    isDataLimited,
    hasBirthdayToday,
}) => {
    const {isTabletPortraitAndLarger} = useBreakpoints();
    const initials = React.useMemo(() => {
        return `${person.name.firstName.charAt(0)}${person.name.lastName.charAt(0)}`;
    }, [person]);

    const wentHome = React.useMemo(() => {
        if (hasProperty(person, 'checkins')) {
            return getWentHome(person.checkins);
        }
        if (employeeHasProperty(person, 'checkoutTime')) {
            return !person.checkedIn && person.checkoutTime;
        }
        return false;
    }, [person]);

    const statuses = React.useMemo(() => {
        const statuses: StatusState[] = [];
        if (wentHome) {
            statuses.push(StatusState.SignedOut);
        }
        if (person.checkedIn) {
            statuses.push(StatusState.SignedIn);
        }
        if (hasProperty(person, 'hasVacation') && person.hasVacation) {
            statuses.push(StatusState.OnVacation);
        }
        if (hasProperty(person, 'isSick') && person.isSick) {
            statuses.push(StatusState.Sick);
        }
        if (hasProperty(person, 'isAbsent') && person.isAbsent) {
            statuses.push(StatusState.Absent);
        }
        if (hasBirthdayToday) {
            statuses.push(StatusState.HasBirthday);
        }
        return statuses;
    }, [person, wentHome, hasBirthdayToday]);

    return (
        // Bottom margin is handled by the element below the image
        <Stack position="relative" mx={{base: 0, tabletPortrait: 4}} mt={{base: 0, tabletPortrait: 2}}>
            <PersonImage
                image={person.image.small}
                initials={initials}
                hasOverlay={!isDataLimited ? !statuses.includes(StatusState.SignedIn) : false}
                size={isTabletPortraitAndLarger ? 'xl' : 'lg'}
            />
            {!isDataLimited && <StatusBadges statuses={statuses} hasBirthdayToday={hasBirthdayToday} />}
        </Stack>
    );
};

const BirthdayBadge = ({index}: {index: number}) => {
    return (
        <Badge zIndex={1} index={index} backgroundColor="n0" borderColor="n75">
            <Text variant="h5">🎂</Text>
        </Badge>
    );
};

interface BadgeProps {
    backgroundColor?: keyof ColorPalette;
    foregroundColor?: keyof ColorPalette;
    borderColor?: keyof ColorPalette;
    icon?: IconName;
}

const StatusBadges: React.FC<{statuses: StatusState[]; hasBirthdayToday: boolean}> = ({statuses, hasBirthdayToday}) => {
    const getProperties = React.useCallback((status): BadgeProps | undefined => {
        switch (status) {
            case StatusState.SignedIn:
                return {backgroundColor: 'g400', foregroundColor: 'n0', borderColor: 'n0', icon: 'done'};
            case StatusState.SignedOut:
                return {backgroundColor: 'n50', foregroundColor: 'n300', borderColor: 'n100', icon: 'logout'};
            case StatusState.OnVacation:
                return {backgroundColor: 'y400', foregroundColor: 'n0', borderColor: 'n0', icon: 'light_mode'};
            case StatusState.Sick:
                return {backgroundColor: 'r400', foregroundColor: 'n0', borderColor: 'n0', icon: 'device_thermostat'};
            case StatusState.Absent:
                return {backgroundColor: 'b400', foregroundColor: 'n0', borderColor: 'n0', icon: 'event_busy'};
            default:
                return undefined;
        }
    }, []);

    if ((!statuses || statuses.length === 0) && !hasBirthdayToday) {
        return null;
    }

    return (
        <>
            {statuses?.map((status, index) => {
                const properties = getProperties(status);
                if (
                    !properties?.backgroundColor ||
                    !properties.borderColor ||
                    !properties.icon ||
                    !properties.foregroundColor
                ) {
                    return null;
                }
                return (
                    <Badge
                        backgroundColor={properties.backgroundColor}
                        borderColor={properties.borderColor}
                        index={index}
                        key={properties.icon}
                    >
                        <Icon name={properties.icon} size={32} color={properties.foregroundColor} />
                    </Badge>
                );
            })}
            {hasBirthdayToday ? <BirthdayBadge index={statuses.length >= 0 ? statuses.length - 1 : 0} /> : null}
        </>
    );
};

export default PersonButton;
