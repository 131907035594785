/* eslint-disable no-restricted-imports */
import React from 'react';
import MuiDialogActions from '@mui/material/DialogActions';
import {Button, type ButtonProps} from 'modern-famly';
import {useTranslation} from 'modern-famly/system/use-translation';

import {DialogTestAttributes} from './dialog-test-attributes';

/**
 * A combined element that will show the dialog's actionable buttons on the bottom.
 *
 * Shows either a primary or delete button, depending on the given variant.
 */
export const DialogButtons = ({
    isScrollable,
    isScrolledToBottom,
    cancelText,
    confirmText,
    variant = 'primary',
    onClose,
    onConfirm,
    isConfirmDisabled = false,
}: {
    /**
     * A boolean telling if the dialog content is scrollable.
     */
    isScrollable?: boolean;

    /**
     * A boolean telling if the dialog content was scrolled to the bottom.
     *
     * Will add an elevation prop dropping a shadow onto the dialog content,
     * if the dialog is scrollable and not scrolled to the bottom
     */
    isScrolledToBottom?: boolean;

    /**
     * Translated cancel button label of the dialog. Defaults to 'Cancel'.
     */
    cancelText?: string;

    /**
     * Translated confirm button label of the dialog. Defaults to 'Confirm'.
     */
    confirmText?: string;

    /**
     * Variant of the Dialog to be shown. The variant is passed the CTA button. Defaults to 'primary'
     */
    variant?: Extract<ButtonProps['variant'], 'primary' | 'critical'>;

    /**
     * Callback to handle when the user clicks either 'Cancel' or 'x' button. Use this to control
     * the `open` state
     */
    onClose: Provider<void>;

    /**
     * Callback to handle when the user clicks 'Confirm' button. Use this to handle confirmation.
     *
     * If a Promise is returned, the `loading` flag on the submit button will show a spinner
     * while the Promise is running.
     */
    onConfirm: Provider<void> | Provider<Promise<void>>;

    /**
     * Optional disabled prop to disable the confirm button
     */
    isConfirmDisabled?: boolean;
}) => {
    const cancelTranslation = useTranslation('Dialog.cancel');
    const confirmTranslation = useTranslation('Dialog.confirm');

    const [loading, setLoading] = React.useState(false);
    const handleClose = React.useCallback(() => onClose(), [onClose]);
    const handleConfirm = React.useCallback(() => {
        const result = onConfirm();
        if (result && typeof result.then === 'function') {
            setLoading(() => true);
            result.then(() => setLoading(() => false));
        }
    }, [onConfirm]);
    return (
        <MuiDialogActions
            sx={{
                marginX: 6,
                marginBottom: 6,
                padding: 0,
                paddingTop: 6,
                gap: {
                    base: 4,
                    mobileLandscape: 2,
                },
                flexDirection: {
                    base: 'column-reverse',
                    mobileLandscape: 'row',
                },
                boxShadow:
                    isScrollable && !isScrolledToBottom ? ' 0px -3px 2px 0px rgba(104, 111, 125, 0.04)' : undefined,
            }}
        >
            <Button
                data-e2e-id={DialogTestAttributes.cancelButtonE2eId}
                onClick={handleClose}
                text={cancelText ?? cancelTranslation}
                variant="tertiary"
                fullWidth
            />
            <Button
                data-e2e-id={DialogTestAttributes.confirmButtonE2eId}
                onClick={handleConfirm}
                text={confirmText ?? confirmTranslation}
                fullWidth
                variant={variant}
                isLoading={loading}
                icon={variant === 'critical' ? 'delete' : undefined}
                isDisabled={isConfirmDisabled}
            />
        </MuiDialogActions>
    );
};
