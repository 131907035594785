// eslint-disable-next-line no-restricted-syntax
export enum TARGET_IDS {
    OBSERVATION_LINK = 'Observation link',
    PERSON_BUTTON = 'Person button',
    TAB_LINK = 'Tab link',
    EYFS_BUTTON = 'EYFS button',
    MENU_ITEM = 'Menu item',
    ADD_EYFS_BUTTON = 'Add EYFS links button',
    APPROVE_AND_SEND_BUTTON = 'Approve and send button',
    OBSERVATION_MODAL = 'Observation Modal',
    ROOM_OVERVIEW_ADD_CHILD = 'Add child',
    ROOM_OVERVIEW_ADD_STAFF = 'Add employee',
    SWITCH_ROOM_DROPDOWN = 'Switch room dropdown',
    ADD_ACTIVITIES_BUTTON = 'Add activities button',
    CREATE_FAMLY_LIVE_MEETING_BUTTON = 'Create Famly Live Meeting Button',
    SETUP_EMPLOYEE_ADD_EMPLOYEE_BUTTON = 'Employee setup: Add employee button',
    SETUP_PRICING_GROUPS_BUTTON = 'Pricing setup: Pricing group button',
    SETUP_ROOMS_NEW_ROOM_NAME = 'Room setup: New room name',
    SETUP_ROOMS_NEW_ROOM_BUTTON = 'Room setup: New room button',
    NEWS_FEED_SEARCH_FOR_RECIPIENTS = 'News feed: Search for recipients',
    PLANS_MONDAY_BUTTON = 'Plans monday button',
    PLANS_PRICING_GROUP = 'Plans pricing group',
    DAY_PICKER_FROM = 'Day picker from',
    DAY_PICKER_TO = 'Day picker to',
    SCHEDULE_FIRST_DAY_SMILEY = 'Schedule First Day Smiley',
    SCHEDULE_FIRST_STAFF = 'Schedule First Staff',
    STAFF_ATTENDANCE_EXPORT = 'Staff Attendance Export',
    STAFF_ATTENDANCE_CONTRACT_HOURS = 'Staff Attendance Contract Hours',
    STAFF_ATTENDANCE_DAY_CELL = 'Staff Attendance Details',
    STAFF_ATTENDANCE_WEEKLY_TOTAL = 'Staff Attendance Weekly Total',
    STAFF_HOURS_PAID_LEAVE = 'Staff Hours Paid Leave',
    STAFF_HOURS_BREAK = 'Staff Hours Break',
    STAFF_HOURS_DIFFERENCE = 'Staff Hours Difference',
    STAFF_HOURS_BANK_HOURS = 'Staff Hours Bank Hours',
    STAFF_HOURS_WEEKLY_OR_CUSTOM_PERIOD = 'Staff Hours Weekly Or Custom Period',
    NEWSFEED_LIVE_TRANSLATIONS_BUTTON = 'Newsfeed: Live translations button',
    NEWSFEED_ADD_POLL_BUTTON = 'Newsfeed: Add poll button',
    // New Observation
    OBSERVATION_RECIPIENT_SELECT = 'Observation recipient select',
    SEND_FOR_REVIEW_BUTTON = 'Send for review button',
    NEW_OBSERVATION_BUTTON = 'New observation button',
    EYFS_AREA_DROPDOWN = 'EYFS area dropdown',
    ADD_WHATS_NEXT_BUTTON = 'Add whats next button',
    PAST_OBSERVATIONS_SUBTITLE = 'Past observations subtitle',
    DEVELOPMENT_MATTERS_SUBTITLE = 'Development matters subtitle',
    SEND_OBSERVATION_BUTTON = 'Save observation button',
    SAVE_OBSERVATION_DRAFT_BUTTON = 'Save observation draft button',
    TALLY_OBSERVATION_TABLE = 'Tally observation table',
    // App Buttons
    CHILD_DEVELOPMENT = 'Child Development',
    // Activity Planner.
    NEW_ACTIVITY_BUTTON = 'New Activity Button',
    ACTIVITY_PLANNER_APP_TITLE = 'Activity Planner app title',
    SELECT_TARGET_FOR_ACTIVITY_PLAN = 'Select target for activity plan',
    SELECT_WEEK_FOR_ACTIVITY_PLAN = 'Select week for activity plan',
    DESCRIBE_ACITIVITY_PLAN = 'Describe activity plan',
    ACTIVITY_PLAN_SECTION_NAME = 'Activity plan section name',
    ADD_ACTIVITY_TO_PLAN_BUTTON = 'Add activity to plan button',
    ADD_NOTE_TO_ACTIVITY_PLAN_BUTTON = 'Add note to activity plan button',
    OPEN_ACTIVITY_PLAN_MENU = 'Open activity plan menu',
    PUBLISH_ACTIVITY_PLAN_WEEK = 'Publish activity plan week',
    // Debt report
    DEBT_REPORT_V2_ENABLE_BUTTON = 'Debt Report V2: Enable new debt report',
    DEBT_REPORT_V2_GRAPH = 'Debt Report V2: Balance graph',
    DEBT_REPORT_V2_FILTERS = 'Debt Report V2: Filters',
    DEBT_REPORT_V2_SEND_REMINDERS_BUTTON = 'Debt Report V2: Send reminders button',
    // Settings
    SETTINGS_INSTITUTION_DROPDOWN = 'Settings: Institution Dropdown',
    CHILD_DEVELOPMENT_SETUP_CONTAINER = 'Child development setup container',
    ACTIVATE_FOR_ME_TOGGLE = 'Activate for me toggle',
    ACTIVATE_FOR_ENTIRE_SETTING_TOGGLE = 'Activate for entire setting toggle',
    AUTOLOGOUT_ROLE_ITEM = 'Auto-logout: Indicates the role',
    AUTOLOGOUT_TIMEOUT_ITEM = 'Auto-logout: Indicates the timeouts dropdown',
    AUTOLOGOUT_SAVE = 'Auto-logout: Indicates the save button',
    REFERENCE_MATERIAL_TOGGLE = 'Reference material toggle',
    LEARNING_FRAMEWORK_TOGGLE = 'Learning framework toggle',
    QR_SIGN_IN_TOGGLE = 'QR sign in toggle',
    ROOMS_CUSTOM_STATUSES_ADD_NEW_STATUS = 'Rooms: Custom statuses - add new status area',
    FINANCES_ACCOUNTING_TITLE_ID_CONTAINER = 'Finances: Accounting - title and account ID container',
    FINANCES_ACCOUNTING_INVOICE_LINES_BLOCK = 'Finances: Accounting - invoice lines block',
    FINANCES_ACCOUNTING_ADD_ACCOUNT_BUTTON = 'Finances: Accounting - add account button',
    // Famly Pay
    FAMLY_PAY_SETTINGS_BUTTON = 'Famly Pay app: button routing to settings',
    FINANCES_FAMLY_PAY_SET_UP_PAYMENTS_BUTTON = 'Finances : Famly Pay - set up payments button',
    FINANCES_REVENUE_GRAPH = 'Finances : Revenue - revenue graph',
    FINANCES_REVENUE_TABLE = 'Finances : Revenue - revenue table',
    FINANCES_REVENUE_SET_UP_TARGET_BUTTON = 'Finances : Revenue - set up target button',
    // Free Package Product Tours
    SIDEBAR_LINK = 'Sidebar Link',
    SIDEBAR_GROUP = 'Sidebar Group',
    NEW_POST_ATTACHMENT_BUTTONS = 'New post attachment buttons',
    NEWS_FEED = 'News feed',
    FAMLY_REPORT_PARENTS = 'Famly report parents',
    CALENDAR_NEW_EVENT = 'Calendar new event',
    ENTITY_SELECTOR = 'Entity selector',
    EMPLOYEE_ROW = 'Employee row',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    CHILDREN_ADD_CHILD = 'Add child',
    CD_OBSERVATION_TYPE_SELECT = 'Observation type',
    // Navigation
    WHATS_THIS_BUTTON = "What's this button",
    COLLAPSE_SECONDARY_SIDEBAR_BUTTON = 'Collapse secondary sidebar button',
    OLD_NAVIGATION_HELP_AND_SUPPORT_BUTTON = 'Old navigation: help and support button',
    // My Settings
    ENABLE_OUT_OF_OFFICE = 'OOO: Enable out of office status',
    OUT_OF_OFFICE_FROM_DATE = 'OOO: Out of office from date',
    OUT_OF_OFFICE_TO_DATE = 'OOO: Out of office until date',
    OUT_OF_OFFICE_STATUS = 'OOO: Out of office status message',
    // Medication Forms
    ADD_MEDICATION_BUTTON_IN_SAFEGUARDING = 'Add medication button in the Safeguarding tab',
    // Payments
    PAYMENT_IMPORT_WIZARD = 'Payment import wizard',
    ADD_PAYMENT_BUTTON = 'Add payment button',
    // Curriculum builder
    CREATE_NEW_CURRICULUM_BUTTON = 'Create new curriculum button',
    CURRICULUM_LEARNING_AREA_LINK_ALL = 'Link all learning areas',
    CURRICULUM_LEARNING_AREA_DELETE_AREA = 'Delete a learning area',
    CURRICULUM_LEARNING_AREA_ADD_ANOTHER = 'Add another leaning area',
    CURRICULUM_LEARNING_AREA_CREATE_PAGE = 'Create a page',
    CURRICULUM_LEARNING_AREA_NEXT_BUTTON = 'Next step button',
    CURRICULUM_CONTENT_EDIT = 'Edit curriculum content',
    CURRICULUM_CONTENT_LEARNING_AREA_ROW = 'Edit learning area content',
    CURRICULUM_CONTENT_SAVE_AS_DRAFT = 'Save as draft',
    CURRICULUM_CONTENT_SAVE_AND_UPLOAD = 'Save and upload',
    CURRICULUM_CONTENT_DELETE = 'Delete curriculum content',
    CURRICULUM_CONTENT_DROPDOWN_BUTTON = 'Curriculum content dropdown button',
    CURRICULUM_CONTENT_SEE_PREVIEW = 'Curriculum content preview',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    CURRICULUM_CONTENT_CREATE_PAGE = 'Create a page',
    CURRICULUM_CONTENT_CREATE_SUBAREA = 'Create a subarea',
    CURRICULUM_CONTENT_CREATE_SUBAREA_NAME = 'Add subarea name',
    CURRICULUM_PAGE_TITLE = 'Add page title',
    CURRICULUM_PAGE_TEXT = 'Add page content',
    CURRICULUM_PAGE_SAVE = 'Save page',
    // Children Overview
    CHILDREN_OVERVIEW_KITA_PLANER_IMPORT = 'Kita-Planer import button',
    CHILDREN_OVERVIEW_KIVAN_IMPORT = 'Kivan import button',
    CHILDREN_OVERVIEW_TEST_CHILD = 'Children overview test child',
    // Famly Home
    FAMLY_HOME = 'Famly Home',
    FAMLY_HOME_CUSTOMISATION_BUTTON = 'Famly home customisation button',
    FAMLY_HOME_RATIO_HEADER = 'Famly Home Room Ratio',
    FAMLY_HOME_ADD_TAG_BUTTON = 'Famly Home - Add tag button',
    FAMLY_HOME_LATEST_OBSERVATION_DRAFTS_WIDGET = 'Famly home - latest observation draft widget',
    FAMLY_HOME_SETUP_GUIDE_WIDGET = 'Famly Home - setup guide widget',
    FAMLY_HOME_SETUP_GUIDE_EXPANDED = 'Famly Home - setup guide expanded',
    FAMLY_HOME_SITE_OVERVIEW_WIDGET = 'Famly Home - site overview widget',
    FAMLY_HOME_ALL_WIDGETS_OVERVIEW = 'Famly Home - all widgets overview',
    FAMLY_HOME_UPCOMING_ROOM_MOVES_WIDGET = 'Famly Home - upcoming room moves widget',
    // Employee profile
    STAFF_LEAVE_WIDGET = 'Staff leave widget',
    // CRF
    CRF_NEW_FORM = 'New form',
    CRF_INTEREST_FORM = 'Interest form',
    CRF_REGISTRATION_FORM = 'Registration form',
    CRF_ENROLLMENT_FORM = 'Enrollment form',
    CRF_START_BUILDING = 'Start building',
    CRF_FORM_FIELD_OPTIONS_BUILDER = 'Field Options Box Form Builder',
    CRF_FORM_SECTION_DRAG_FORM_BUILDER = 'section drag form builder',
    CRF_FORM_NAME_FORM_BUILDER = 'Name Elipsis drag form builder',
    CRF_SAVE_FORM = 'save form',
    // Bill payer profile
    BILL_PAYER_WIDGET = 'Bill payer widget',
    // Employee contract
    CONTRACTED_HOURS = 'Contracted weekly hours',
    WORK_AVAILABILITY = 'Contracted hours work availability',
    // Sidekick
    SIDEKICK_TERMS_AND_CONDITION = 'Sidekick terms and conditions',
    SIDEKICK_FEATURE_TOGGLE = 'Sidekick feature toggle',
    SIDEKICK_CTA = 'Sidekick CTA',
    // Sidekick learning
    SIDEKICK_WHATS_NEXT = 'Sidekick learning: whats next',
    SIDEKICK_JOURNEY_SUMMARY_CHILD_PROFILE = 'Sidekick learning: journey summary on child profile',
    SIDEKICK_JOURNEY_SUMMARY_CUSTOM_PROMPT = 'Sidekick learning: journey summary custom prompt',
    SIDEKICK_JOURNEY_SUMMARY_BUTTON_OBSERVATION_EDITOR = 'Sidekick learning: journey summary button in observation editor',
    SIDEKICK_ACTIVITIES_BUTTON_LIBRARY = 'Sidekick learning: activities button in library',
    SIDEKICK_CHILD_PROFILE_BUTTON = 'Sidekick learning: child profile button',
    // Famly store
    MULTIPLE_BOOSTERPACK_PURCHASE = 'Multiple boosterpack purchase',
    FEATURE_STORE_START_PROFESSIONAL_TRIAL = 'Feature store: Start professional trial',
    FEATURE_STORE_BROWSE_FEATURES_TAB = 'Feature store: Browse features tab',
    // Test child actions
    TEST_CHILD_CTA_BUTTON_IN_TOP_NAVIGATION = 'Test child CTA button in top navigation',
    // Sign in test child
    TEST_CHILD_SITE_ORG_OVERVIEW = 'Test child site in organisation overview',
    TEST_CHILD_ROOM_FAMLY_HOME = 'Test child room in Famly Home',
    TEST_CHILD_BUTTON = 'Test child button',
    TEST_CHILD_STATUS_BUTTON = 'Test child status button',
    TEST_CHILD_CHECK_IN_BUTTON = 'Test child check in button',
    // Add a nap
    TEST_CHILD_SLEEP_BUTTON = 'Test child sleep button',
    TEST_CHILD_SLEEP_NOW_BUTTON_IN_MODAL = 'Test child Sleep now button in modal',
    // Finish assessment
    TEST_CHILD_ASSESSMENT_FAMLY_HOME = 'Test child assessment in Famly Home',
    // Finish news feed post draft
    TEST_CHILD_NEW_POST_EDITOR_COLLAPSED = 'Test child - New post editor collapsed',
    TEST_CHILD_OPEN_DRAFTS_BUTTON = 'Test child - Open drafts button',
    TEST_CHILD_SELECT_DRAFT = 'Test child - Select draft',
    TEST_CHILD_POST_BUTTON = 'Test child - Post button',
    // Review and send an observation
    TEST_CHILD_OBSERVATION_PENDING_REVIEW = 'Test child - Observation pending review',
    OBSERVATION_APPROVE_AND_SEND_BUTTON = 'Observation Approve and send button',
    // Accident report
    TEST_CHILD_ACCIDENT_REPORT_ROW_IN_TABLE = 'Test child - Accident report row in table',
    TEST_CHILD_ACCIDENT_REPORT_UPDATE_AND_NOTIFY_CTA = 'Accident report - Update and notify CTA',
    TEST_CHILD_ACCIDENT_REPORT_BODY_MAPS_CTA = 'Accident report - Body maps CTA',
    TEST_CHILD_ACCIDENT_REPORT_MODAL_CTA = 'Accident report modal - CTA',
    // Top bar
    TOP_BAR_MESSAGE_NOTIFICATIONS = 'Top bar: Message notifications',
    TOP_BAR_PROFILE_MENU_BUTTON = 'Top bar: Profile menu button',
    TOP_BAR_SWITCH_CONTEXT_BUTTON = 'Top bar: Switch context button',
    // New child modal
    NEW_CHILD_MODAL_SIBLING_FIELD = 'New child modal: Sibling field',
    // Staff schedule
    SCHEDULE_NEW_SHIFT_BUTTON = 'Schedule_New_Shift_Button',
    SCHEDULE_GROUP_BY_DROPDOWN = 'Schedule_Group_By_Dropdown',
    SCHEDULE_PUBLISH_SHIFT_BUTTON = 'Schedule_Publish_Button',
    STAFF_RATIO_ROOMS = 'Staff Ratio_Rooms',
    STAFF_RATIO_ROOMS_SETTINGS = 'Staff Ratio_Rooms_Settings',
    STAFF_PROFILE_CONTRACT = 'Staff Profile_Contract',
    STAFF_PROFILE_CONTRACT_HEADER = 'Staff Profile - Contract header',
    // Meal Plans V3
    MEAL_PLANS_RECURRING_SETTINGS = 'Meal plans v3: Recurring settings',
    MEAL_PLANS_SCHEDULE_SETTINGS = 'Meal plans v3: Schedule settings',
    MEAL_PLANS_ASSIGN_TO_SETTINGS = 'Meal plans v3: Assign to settings',
    MEAL_PLANS_SAVE_DRAFT_BUTTON = 'Meal plans v3: Save draft button',
    MEAL_PLANS_PUBLISH_BUTTON = 'Meal plans v3: Publish button',
    MEAL_PLANS_MEAL_TYPE_ROW = 'Meal plans v3: Meal type',
    MEAL_PLANS_CREATE_MEAL_BUTTON = 'Meal plans: Create meal plan button',
    // Learning
    LEARNING_CHILD_DEVELOPMENT_OVERVIEW_HEADER = 'Child development overview header',
    CHILD_DEVELOPMENT_OBSERVATION_OVERVIEW = 'Child development - observations overview block',
    LEARNING_CHILD_DEVELOPMENT_PROGRESS_REPORT_BLOCK = 'Child development - progress report block',
    LEARNING_ACTIVITY_PLANNER_BOX = 'Learning - activity planner box',
    LEARNING_ACTIVITY_PLANNER_PUBLISH_WEEK_BUTTON = 'Learning - activity planner publish week button',
    LEARNING_ACTIVITY_GET_STARTED_BUTTON = 'Learning - activity planner get started button',
    // Child profile
    CHILD_PROFILE_GO_TO_FULL_PAGE_BUTTON = 'Child profile - go to full profile button',
    CHILD_PROFILE_PRINT_JOURNEY_AREA = 'Child profile - print journey area',
    CHILD_PROFILE_ACTIVITY_LOGS_BLOCK = 'Child profile - activity logs block',
    CHILD_PROFILE_TABS_OVERVIEW = 'Child profile - tabs overview',
    CHILD_PROFILE_JOURNEY_BLOCK_JOURNEY_TAB = 'Child profile - journey block in journey tab',
    CHILD_PROFILE_BOOKINGS_TAB_PURCHASES_SECTION = 'Child profile - bookings tab purchases section',
    // Invoicing
    INVOICING_INVOICES_BLOCK = 'Invoicing - invoices block',
    INVOICING_BILLPAYER_NAME = 'Invoicing - bill payer name',
    INVOICING_BILLPAYER_PROFILE_ENABLE_AUTOMATIC_INVOICING_BLOCK = 'Invoicing : bill payer profile - enable automatic invoicing block',
    PARENTAL_PERMISSIONS_HOW_IT_WORKS_BUTTON = 'Parental permissions - how it works button',
    // Attendance
    ATTENDANCE_ROOM_PLANNER_UPCOMING_ROOM_MOVES = 'Attendance - upcoming room moves',
    ATTENDANCE_ROOM_PLANNER_FILTERS = 'Attendance - room planner filters',
    ATTENDANCE_ROOM_PLANNER_TABLE = 'Attendance - room planner table',
    // Staff leave
    STAFF_LEAVE_SETTINGS = 'Staff leave settings',
    // Enquiries
    ADD_ENQUIRY_BUTTON = 'Add enquiry button',
    // Holiday RVSP
    ADD_VACATION_BUTTON = 'Add vacation button',
    ADD_VACATION_BUTTON_CREATE_SEND = 'Add vacation button create and send',
    // Simplified US billing plan
    NEW_BILLING_PLAN_HEADER = 'New billing plan - header',
    ADD_PLAN_AS_NEW_TEMPLATE_CHECKBOX = 'Add plan as new template checkbox',
    // Billing Hub
    BILLING_HUB_NEXT_INVOICE_COLUMN_HEADER = 'Billing hub next invoice column header',
}

export const DYNAMIC_TARGET_IDS = {
    // Setup / Settings.
    SETTINGS_GROUP_ITEM_LINK: (id: string) => `Go to settings for ${id}`,
    COMPARE_PERMISSIONS_TABLE_ROW_ITEM: (permissionId: string) => `${permissionId} row in the permissions table`,
};
