import {type ThemedCssFunction} from 'styled-components';
import {css} from 'styled-components';

import {type ITheme} from './themes/model';
import * as Breakpoints from './breakpoints';

export const mobileLandscapeAndLarger = (...args: Parameters<typeof css>) => css`
    @media ${Breakpoints.mobileLandscapeAndLarger} {
        ${css(...args)}
    }
`;

export const isMobileLandscapeAndLarger = () => {
    return window.matchMedia(Breakpoints.mobileLandscapeAndLarger).matches;
};

export const tabletPortraitAndLarger = (...args: Parameters<typeof css>) => css`
    @media ${Breakpoints.tabletPortraitAndLarger} {
        ${css(...args)}
    }
`;

export const isTabletPortraitAndLarger = () => {
    return window.matchMedia(Breakpoints.tabletPortraitAndLarger).matches;
};

export const tabletLandscapeAndLarger = (...args: Parameters<typeof css>) => css`
    @media ${Breakpoints.tabletLandscapeAndLarger} {
        ${css(...args)}
    }
`;

export const isTabletLandscapeAndLarger = () => {
    return window.matchMedia(Breakpoints.tabletLandscapeAndLarger).matches;
};

export const laptopAndLarger = (...args: Parameters<typeof css>) => css`
    @media ${Breakpoints.laptopAndLarger} {
        ${css(...args)}
    }
`;

export const isLaptopAndLarger = () => {
    return window.matchMedia(Breakpoints.laptopAndLarger).matches;
};

export const customAtLeastOneLargerThanLandscape = (...args: Parameters<typeof css>) => css`
    @media ${Breakpoints.customAtLeastOneLargerThanLandscape} {
        ${css(...args)}
    }
`;

export const isCustomAtLeastOneLargerThanLandscape = () => {
    return window.matchMedia(Breakpoints.customAtLeastOneLargerThanLandscape).matches;
};

export const makeBreakpoint =
    (minWidthInPixels: number) =>
    (...args: Parameters<typeof css>) =>
        css`
            @media ${`(min-width: ${minWidthInPixels}px)`} {
                ${css(...args)}
            }
        `;

export const breakPointToCSSFunction = (
    breakpoint: Breakpoints.Breakpoint,
): typeof laptopAndLarger | ThemedCssFunction<ITheme> => {
    switch (breakpoint) {
        case 'laptopAndLarger':
            return laptopAndLarger;
        case 'tabletLandscapeAndLarger':
            return tabletLandscapeAndLarger;
        case 'tabletPortraitAndLarger':
            return tabletPortraitAndLarger;
        case 'mobileLandscapeAndLarger':
            return mobileLandscapeAndLarger;
        case 'customAtLeastOneLargerThanLandscape':
            return customAtLeastOneLargerThanLandscape;
        default:
            return css;
    }
};
