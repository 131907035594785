import {useDialog} from '@famly/mf_modals-dialogs_context';
import type {DialogOptions} from 'famly/mf/modals-dialogs_context/src/use-dialog';

/**
 * usePromiseDialog
 *
 * A hook that wraps useDialog to provide a Promise-based API for dialogs.
 * This allows you to await the result of a dialog (confirm/cancel) in an async function.
 *
 * @example
 * // Import the hook
 * import { usePromiseDialog } from '@famly/mf_modals-dialogs_context';
 *
 * // In your component:
 * const { show: showConfirmDialog } = usePromiseDialog({
 *   lazyChildren: () => import('./confirm-dialog'),
 * });
 *
 * // Use it in an async function:
 * const handleDeleteItem = async () => {
 *   // The dialog will return true if confirmed, false if canceled
 *   const confirmed = await showConfirmDialog({
 *     title: 'Delete Item',
 *     message: 'Are you sure you want to delete this item?',
 *     // Any other props your dialog component needs
 *   });
 *
 *   if (confirmed) {
 *     // User clicked confirm
 *     deleteItem();
 *   } else {
 *     // User clicked cancel or closed the dialog
 *     console.log('Deletion canceled');
 *   }
 * };
 *
 * // Your dialog component should accept onConfirm and onCancel props
 * // which will be automatically provided by usePromiseDialog
 */

// Type for the props passed to the show function
export type DialogShowProps<T = Record<string, unknown>> = Omit<T, 'onConfirm' | 'onCancel' | 'onClose'>;

// Return type of the hook
export type PromiseDialogResult<T = Record<string, unknown>> = {
    show: (dialogProps: DialogShowProps<T>) => Promise<boolean>;
};

export const usePromiseDialog = <T extends Record<string, unknown>>(
    props: DialogOptions<T>,
): PromiseDialogResult<T> => {
    const {lazyChildren} = props;
    const {showDialog, hideDialog} = useDialog<T>({lazyChildren});

    return {
        show: (dialogProps: DialogShowProps<T>): Promise<boolean> =>
            new Promise(resolve => {
                showDialog({
                    ...(dialogProps as any), // Cast needed due to TypeScript limitations with generics
                    onConfirm: () => {
                        resolve(true);
                        hideDialog();
                    },
                    onCancel: () => {
                        resolve(false);
                        hideDialog();
                    },
                });
            }),
    };
};
