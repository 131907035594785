import React from 'react';

import {Text} from '@famly/mf_data-display_text';
import {useBreakpoints} from '@famly/mf_util_breakpoints';
import {Stack} from '@famly/mf_layout_stack';
import translate from 'signin-app/helpers/translate';
import {useTypedSelector} from 'signin-app/components/hooks';

import {GroupItem, CardsContainer} from './group-item';
import * as Selectors from './selectors';

export const ChildrenSection = () => {
    const displayedChildGroups = useTypedSelector(Selectors.displayedChildGroups);
    const checkedInCounts = useTypedSelector(Selectors.checkedInCounts);

    const {isTabletPortraitAndLarger} = useBreakpoints();

    return (
        <Stack gap={2} flexDirection="column">
            <Text variant="h6" color="n400" emphasized>
                {translate('childrenRooms')}
            </Text>
            <CardsContainer>
                {displayedChildGroups.map(group => (
                    <GroupItem
                        key={group.groupId}
                        title={group.title}
                        image={group.image.large}
                        href={`/groups/${group.groupId}`}
                        checkedInCount={checkedInCounts[group.groupId]}
                        groupType={'Children'}
                        groupId={group.groupId}
                        alignment={isTabletPortraitAndLarger ? 'vertical' : 'horizontal'}
                    />
                ))}
            </CardsContainer>
        </Stack>
    );
};
