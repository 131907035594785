import createSpacing from '@mui/system/createTheme/createSpacing';
import createBreakpoints from '@mui/system/createTheme/createBreakpoints';
import {fixedPalette} from '@famly/mf_colors';

import {type Theme} from '../theme';
import {elevationConfiguration} from '../elevations';

const BaseThemePrimaryColors: PrimaryColor = {
    p50: fixedPalette.n50,
    p75: fixedPalette.n75,
    p100: fixedPalette.n100,
    p200: fixedPalette.n200,
    p300: fixedPalette.n300,
    p400: fixedPalette.n400,
    p500: fixedPalette.n500,
};

const BaseThemeBrandColor = fixedPalette.n400;

/**
 * The base theme that all other themes inherit from
 */
export const BaseTheme: Theme = {
    name: 'base',
    colorPalette: {
        brandColor: BaseThemeBrandColor,
        ...BaseThemePrimaryColors,
        ...fixedPalette,
    },
    elevation: elevationConfiguration,

    // This  will create a `spacing` function that is based on a 4px spacing scale.
    // `theme.spacing(2)` will return the string '8px'.
    spacing: createSpacing(4),

    // If a number is passed as the value to the `borderRadius` system prop, that value will be
    // multiplied with the value of `shape.borderRadius`.
    shape: {
        borderRadius: 4,
    },

    // Ported from: app/styleguide/breakpoints.ts
    // Inspired by: https://ricostacruz.com/til/css-media-query-breakpoints;
    breakpoints: createBreakpoints({
        values: {
            base: 0,
            mobileLandscape: 480,
            tabletPortrait: 768,
            tabletLandscape: 992,
            laptop: 1200,
        },
    }),
};

interface ThemeOptions {
    /**
     * Name theme's name
     */
    name: string;

    /**
     * The theme's primary colors. Can be omitted to use the BaseTheme's primary colors
     */
    primaryColors?: PrimaryColor;

    /**
     * The theme's brand color.
     */
    brandColor: HSLA;
}

/**
 * Creates a variation of the BaseTheme based on brand color and primary colors
 *
 * @param themeOptions
 * @returns A new theme which extends the BaseTheme with the given `themeOptions`
 */
export const createBaseThemeVariation = (themeOptions: ThemeOptions): Theme => {
    const {name, primaryColors = BaseThemePrimaryColors, brandColor} = themeOptions;

    return {
        ...BaseTheme,
        name,
        colorPalette: {
            ...BaseTheme.colorPalette,
            ...primaryColors,
            brandColor,
        },
    };
};
