import {type RefObject, useEffect} from 'react';

import PlatformHelper from 'web-app/helpers/platform-helper';
import {hasValue} from '@famly/stat_ts-utils_has-value';

/**
 * Handle clicks outside of your component! Use this to close popups, modals, etc
 *
 * @example
 * ```
 * const Foo = props => {
 *
 *   const ref = React.useRef();
 *
 *   useOnClickOutside(ref, props.bar, ['.ignore-this-modal', '#ignoreThisButton']);
 *
 *   return <MyComponent {...} ref={ref}/>
 * }
 * ```
 */
export const useOnClickOutside = (
    node: RefObject<Element>,
    callback: (e: Event) => void,
    ignoreSelectors?: string[],
    capture?: boolean,
    event?: keyof GlobalEventHandlersEventMap,
) => {
    const eventName = getEventNameOrDefault(event);
    useEffect(() => {
        const listener: EventListener = event => {
            /**
             * This implementation uses event delegation on the document to catch any and
             * all events. These events carry a "target" node, which can be used
             * to determine if the click event was targeted at a node
             * inside or outside of the attached element.
             *
             * Checking for value of event.target and node.current is just a fallback
             * that shouldn't really happen. The real logic is in the `contains` call.
             */
            if (!hasValue(event.target) || !hasValue(node.current)) {
                return;
            }
            if (node.current.contains(event.target as Node)) {
                return;
            }

            /**
             * If "ignore selectors" are passed to it, we have to traverse upwards in the DOM tree, from
             * the event target to see if any parent element matches one of the selectors.
             */
            if (
                hasValue(ignoreSelectors) &&
                ignoreSelectors.some(selector => isElementContainedInSelector(event.target as HTMLElement, selector))
            ) {
                return;
            }

            callback(event);
        };

        document.addEventListener(eventName, listener, capture);

        return function () {
            document.removeEventListener(eventName, listener, capture);
        };
    }, [node, callback, ignoreSelectors, eventName, capture]);
};

// Use traversal function to check if an Element is rendered inside an element with a target selector
const isElementContainedInSelector = (element: HTMLElement, selector: string) => {
    return hasValue(traverseElementUpwards(element, element => element.querySelector(selector)));
};

// Traverse upwards a DOM tree starting with any Element.
// Return if `cb` returns a value or if no parents exist.
export const traverseElementUpwards = <K>(element: HTMLElement, cb: (parent: HTMLElement) => K | null): K | null => {
    const returnValue = cb(element);
    if (hasValue(returnValue)) {
        return returnValue;
    }
    const parentElement = element.parentElement;
    if (hasValue(parentElement)) {
        return traverseElementUpwards(parentElement, cb);
    }
    return null;
};

/**
 * `touchstart` and `mouseup` are the default events used in this hook. These events were chosen as we wanted
 * a hook that behaved similar to `react-onclickoutside`.
 *
 * However, it is possible to choose other events if so desired.
 */
const getEventNameOrDefault = (eventName?: keyof GlobalEventHandlersEventMap): keyof GlobalEventHandlersEventMap => {
    if (hasValue(eventName)) {
        return eventName;
    }
    if (PlatformHelper.isTouch()) {
        return 'touchstart';
    }
    return 'mouseup';
};
