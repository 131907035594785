import React from 'react';
import MuiSwitch from '@mui/material/Switch';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import {useDataProps} from '@famly/stat_ts-utils_data-aria-props';

export type ToggleProps = {
    /**
     * The value for the toggle
     */
    checked: boolean;

    /**
     * Callback for when the toggle is toggled
     */
    onChange: (value: boolean) => void;

    /**
     * The label for the toggle
     */
    label: React.ReactNode;

    /**
     * The position of the label
     *
     * @default 'start'
     */
    labelPlacement?: 'start' | 'end';

    // /**
    //  * The size of the toggle
    //  *
    //  * @default 'regular'
    //  */
    // size?: 'regular' | 'compact';

    /**
     * Whether the toggle is disabled
     */
    disabled?: boolean;

    /**
     * ID attribute of the underlying input element.
     */
    id?: string;
} & DataProps;

export const Toggle = (props: ToggleProps) => {
    const dataProps = useDataProps(props);
    return (
        <MuiFormControlLabel
            control={
                <MuiSwitch
                    checked={props.checked}
                    onChange={(_, checked) => props.onChange(checked)}
                    disabled={props.disabled}
                    id={props.id}
                />
            }
            label={props.label}
            labelPlacement={props.labelPlacement ?? 'start'}
            sx={() => ({
                // By default, MUI sets this to 16px
                marginLeft: '0',
            })}
            {...dataProps}
        />
    );
};
