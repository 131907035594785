import React from 'react';
import {Set, Map} from 'immutable';
import {type Epic} from 'redux-observable';
import {tap, filter} from 'rxjs/operators';

import generateUUID from 'web-app/util/uuid';
import {hasValue} from '@famly/stat_ts-utils_has-value';

type ActionSet = Set<string>;

type Callback = (action: any, state: any) => any;
let actionMap = Map<
    string,
    {
        actionsSet: ActionSet;
        callback: Callback;
    }
>();

export type ActionTypes = string[];
export type UpdateFunc<TProps> = (props: TProps, action: any) => any;

export const updateOnActionsEpic: Epic = (action$, state$) =>
    action$.pipe(
        tap(action => {
            const filteredMap = actionMap.filter(({actionsSet}) => actionsSet.includes(action.type));
            filteredMap.forEach(({callback}) => callback(action, state$.value));
        }),
        // Filter so we don't dispatch anything,
        // but can still subscribe to it in the root epic
        filter(() => false),
    );

export const subscribe = (
    actionTypes: ActionTypes,
    callback: Callback,
    idPrefix?: string,
    idToUse?: string,
): string => {
    if (!Array.isArray(actionTypes) || actionTypes.length === 0) {
        throw Error('updateOnActions must be given at least one action type to reload on');
    }

    if (!callback || typeof callback !== 'function') {
        throw Error('updateOnActions must be given at a function to call on actions');
    }
    const id = idToUse || `${idPrefix}${generateUUID()}`;

    const actionsSet = Set<string>(actionTypes) as ActionSet;
    actionMap = actionMap.set(id, {
        actionsSet,
        callback,
    });

    return id;
};

type UnsubscribeFunc = () => void;

export const subscribeToStore = (
    actionTypes: ActionTypes,
    callback: Callback,
    idPrefix?: string,
    idToUse?: string,
): UnsubscribeFunc => {
    const id = subscribe(actionTypes, callback, idPrefix, idToUse);
    return () => unsubscribe(id);
};

export const unsubscribe = (id?: string) => {
    if (id) {
        actionMap = actionMap.remove(id);
    }
};

/**
 * This higher-order component can be added after the react-apollo graphql higher-order component in the compose method.
 * It takes an array of action types and calls refetch on the inner component every time an action type of those sorts
 * is seen.
 * @param actionTypes
 * @param updateFunc
 */
export interface UpdateOnActionOptions<TProps = any> {
    actionTypes: ActionTypes;
    updateFunc: UpdateFunc<TProps>;
}

const updateOnActions =
    <TOriginalProps extends UpdateOnActionOptions>() =>
    (Component: React.ComponentType<React.PropsWithChildren<TOriginalProps>>) => {
        return class UpdateOnActions extends React.Component<TOriginalProps> {
            public id?: string;

            public componentDidMount() {
                const {actionTypes, updateFunc} = this.props;

                if (!updateFunc || typeof updateFunc !== 'function') {
                    throw Error('updateOnActions must be given at a function to call on actions');
                }

                this.id = hasValue<ActionTypes>(actionTypes)
                    ? subscribe(actionTypes, action => updateFunc(this.props, action))
                    : undefined;
            }

            public componentWillUnmount() {
                if (this.id) {
                    unsubscribe(this.id);
                }
            }

            public render() {
                return <Component {...this.props} />;
            }
        };
    };

export default updateOnActions;
