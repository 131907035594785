import {type Components} from '@mui/material/styles';
import {type Theme} from '@mui/material/styles';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

import {DIALOG_PADDING, NextIconButton, PreviousIconButton} from '../shared';

export const DatePickerThemeConfiguration: Components<Theme> = {
    MuiDateCalendar: {
        styleOverrides: {
            // The container holding the month picker and the calendar
            root: {
                width: '320px',
                // Sets the max-height of the container. Setting this explicitly prevents
                // the UI from "glitch jumping" when changing between the calendar, months
                // and years views.
                maxHeight: '392px',
            },
        },
    },
    MuiDayCalendar: {
        styleOverrides: {
            // The "cells" showing the weekday abbreviations
            weekDayLabel: {
                width: '32px',
                height: '32px',
                margin: 0,
            },
            // The container holding the "pickable" days in the calendar (excluding the header cells)
            // Looks like the min-height must be set manually.
            slideTransition: {
                minHeight: '248px',
            },
            // Contains a row of days in the calendar
            weekContainer: ({theme}) => ({
                justifyContent: 'space-between',
                margin: theme.modernFamlyTheme.spacing(0, DIALOG_PADDING),
            }),
            // Contains the week day abbreviations in the calendar's header
            header: ({theme}) => ({
                justifyContent: 'space-between',
                margin: theme.modernFamlyTheme.spacing(0, DIALOG_PADDING, 2),
            }),
            // Contains week rows (this wraps a list of `weekContainer`s)
            monthContainer: ({theme}) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.modernFamlyTheme.spacing(2),
            }),
        },
    },
    MuiPickersCalendarHeader: {
        styleOverrides: {
            // The header showing the month picker and the arrows to browse back and fourth between months
            root: ({theme}) => ({
                paddingLeft: theme.modernFamlyTheme.spacing(1),
                paddingRight: 0,
                margin: theme.modernFamlyTheme.spacing(DIALOG_PADDING, DIALOG_PADDING, 8),
            }),
            // The label containing the month and year
            label: ({theme}) => ({
                ...theme.typography.body,
            }),
        },
    },
    MuiPickersPopper: {
        styleOverrides: {
            // The outermost container of the dialog
            paper: ({theme}) => ({
                border: '1px solid',
                borderColor: theme.modernFamlyTheme.colorPalette.n75,
                borderRadius: 3 * theme.modernFamlyTheme.shape.borderRadius,
                boxShadow: theme.modernFamlyTheme.elevation[2],
            }),
        },
    },
    MuiPickersArrowSwitcher: {
        defaultProps: {
            slots: {
                previousIconButton: PreviousIconButton,
                nextIconButton: NextIconButton,
            },
        },
        styleOverrides: {
            // The space between the arrows to browse back and fourth between months
            spacer: {
                width: '0',
            },
        },
    },
    MuiPickersMonth: {
        styleOverrides: {
            // The month buttons when switching to the "months" view
            monthButton: ({theme}) => ({
                color: theme.modernFamlyTheme.colorPalette.n400,
                '&:hover, &:focus': {
                    backgroundColor: theme.modernFamlyTheme.colorPalette.p100,
                },
                width: '64px',
            }),
            selected: ({theme}) => ({
                color: theme.modernFamlyTheme.colorPalette.n0,
                backgroundColor: theme.modernFamlyTheme.colorPalette.p400,
                '&:hover, &:focus': {
                    backgroundColor: theme.modernFamlyTheme.colorPalette.p400,
                },
            }),
        },
    },
    MuiPickersYear: {
        styleOverrides: {
            // The year buttons when switching to the "years" view
            yearButton: ({theme}) => ({
                color: theme.modernFamlyTheme.colorPalette.n400,
                '&:hover, &:focus': {
                    backgroundColor: theme.modernFamlyTheme.colorPalette.p100,
                },
                width: '64px',
            }),
            selected: ({theme}) => ({
                color: theme.modernFamlyTheme.colorPalette.n0,
                backgroundColor: theme.modernFamlyTheme.colorPalette.p400,
                '&:hover, &:focus': {
                    backgroundColor: theme.modernFamlyTheme.colorPalette.p400,
                },
            }),
        },
    },
};
