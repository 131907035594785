import React from 'react';
import {Text} from '@famly/mf_data-display_text';
import {Stack} from '@famly/mf_layout_stack';

import {type DataProps, useDataProps} from 'modern-famly/components/util';

import {NumericPillTestAttributes as TestAttributes} from './numeric-pill-test-attributes';

const maxUpperBound = 999;

export type NumericPillProps = {
    /**
     * Count to display in the pill
     */
    count: number;

    /**
     * Max number from a range 1–999 to display before showing a plus sign
     * @default 999
     */
    max?: number;

    /**
     * Controls the color of the text in the pill
     * @defalut n400
     */
    color?: ColorKey;

    /**
     * Controls the background color of the pill
     * @default n100
     */
    backgroundColor?: ColorKey;

    /**
     * Size of the pill
     * @default regular
     */
    size?: 'regular' | 'compact';
} & DataProps;

const minimumSizeRegular = '24px';
const minimumSizeCompact = '16px';

export const NumericPill = (props: NumericPillProps) => {
    const {count, color = 'n400', backgroundColor = 'n100', max = 999} = props;

    const dataProps = useDataProps(props);

    const clampedMax = max < 1 ? maxUpperBound : Math.min(max, maxUpperBound);

    const displayCount = count > clampedMax ? `${clampedMax}+` : count;

    const minimumSize = props.size === 'compact' ? minimumSizeCompact : minimumSizeRegular;

    return (
        <Stack
            display="inline-flex"
            direction="row"
            alignItems="center"
            justifyContent="center"
            height={minimumSize}
            minWidth={minimumSize}
            boxSizing="border-box"
            paddingX={2}
            borderRadius={6}
            backgroundColor={backgroundColor}
            {...dataProps}
        >
            <Text
                variant={props.size === 'compact' ? 'micro' : 'body-small'}
                color={color}
                data-e2e-class={TestAttributes.text}
            >
                {displayCount}
            </Text>
        </Stack>
    );
};
