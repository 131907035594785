import {type Theme} from '../theme';
import {createBaseThemeVariation} from './base';

export const BBMontessoriTheme: Theme = createBaseThemeVariation({
    name: 'bbmontessori',
    brandColor: 'hsla(203, 28%, 42%, 1)',
    primaryColors: {
        p50: 'hsla(203, 28%, 98%, 1)',
        p75: 'hsla(203, 28%, 97%, 1)',
        p100: 'hsla(203, 28%, 96%, 1)',
        p200: 'hsla(203, 28%, 90%, 1)',
        p300: 'hsla(203, 28%, 70%, 1)',
        p400: 'hsla(203, 28%, 42%, 1)',
        p500: 'hsla(203, 28%, 15%, 1)',
    },
});
