export function extractProps<T, E extends ExtractedProp>(
    props: Dictionary<string, any>,
    type: E,
): ExtractedProps<T, E> {
    return Object.keys(props).reduce((acc, key) => {
        if (key.startsWith(type)) {
            acc[key as keyof ExtractedProps<T, E>] = props[key];
        }
        return acc;
    }, {} as ExtractedProps<T, E>);
}
