import React from 'react';
import styled, {css} from 'styled-components';

import {type Colors} from 'web-app/styleguide/colors';
import {getThemeColor} from 'web-app/styleguide/color-helpers';
import TextTypes from 'web-app/styleguide/text-types';
import {Base, type BaseProps} from 'web-app/react/components/layout/layout';
import {type SingleSpacingDescription, getSpacingStringFromDescription} from 'web-app/styleguide/spacing';
import {type ITheme} from 'web-app/styleguide/themes/model';

type asType = React.ElementType | keyof JSX.IntrinsicElements;

export interface TextProps extends BaseProps {
    // Default props
    color?: Colors;
    inlinespacing?: SingleSpacingDescription;
    emphasized?: boolean;
    pill?: boolean;
    circular?: boolean;
    type?: TextTypes;

    // Other props
    lineHeight?: string;
    decoration?: string;
    vertical?: string;
    secondary?: boolean;
    disabled?: boolean;
    italic?: boolean;
    inline?: boolean;
    inlineBlock?: boolean;
    capitalizeFirst?: boolean;
    overrideTag?: asType;
    ellipsis?: boolean;

    // Print styling
    keepColorOnPrint?: boolean;
}

const getMarginCSS = props => {
    if (props.margin) {
        return '';
    }
    if (!props.margin && !props.marginBottom && !props.marginTop && !props.marginRight && !props.marginLeft) {
        return css`
            margin: 0;
        `;
    } else {
        return css<TextProps>`
            ${props => (props.marginBottom ? '' : 'margin-bottom: 0;')}
            ${props => (props.marginTop ? '' : 'margin-top: 0;')}
            ${props => (props.marginRight ? '' : 'margin-right: 0;')}
            ${props => (props.marginLeft ? '' : 'margin-left: 0;')}
        `;
    }
};

const getDisplayValue = props => {
    if (props.inlineBlock) {
        return 'inline-block';
    } else if (props.inline || props.pill) {
        return 'inline';
    }
    return 'block';
};

const getLetterSpacing = (textType: TextTypes | undefined) => {
    switch (textType) {
        case TextTypes.Display:
        case TextTypes.Headline:
        case TextTypes.Title:
        case TextTypes.Subheader:
            return '-0.01em';
        default:
            return 'normal';
    }
};

const getFontFamily = (textType: TextTypes | undefined, theme: ITheme) => {
    switch (textType) {
        case TextTypes.Small:
            return `Inter, ${theme.fontConfiguration.family}`;
        default:
            return theme.fontConfiguration.family;
    }
};

// We've had to use the non-null assertion operator (!) for the default props here. Default props and styled components are
// quite difficult to use together as of November 2018. This was the cleanest way I found
export const TextBaseCss = css<TextProps>`
    // Color
    color: ${props =>
        props.pill
            ? props.theme.invertedText
            : getThemeColor(props.color!, props.secondary, props.disabled, props.theme)};

    // Font
    font-family: ${props => getFontFamily(props.type, props.theme)};
    font-size: ${props => props.theme.fontConfiguration.sizes.get(props.type!, 0)};
    font-weight: normal;
    line-height: ${props => props.lineHeight || props.theme.fontConfiguration.lineHeights.get(props.type!, 0)};
    font-weight: ${props => props.theme.fontConfiguration.fontWeight};
    ${props => (props.emphasized ? props.theme.fontConfiguration.emphasized : '')};
    ${props => (props.italic ? props.theme.fontConfiguration.italic : '')};
    letter-spacing: ${props => getLetterSpacing(props.type)};

    display: ${props => getDisplayValue(props)};
    ${props => (props.vertical ? `vertical-align: ${props.vertical}` : '')};
    ${props => (props.decoration ? `text-decoration: ${props.decoration}` : '')};
    ${props => (props.textAlign ? `text-align: ${props.textAlign}` : '')};

    // Spacing
    ${getMarginCSS}

    ${props =>
        (props.inline || props.inlineBlock) && props.inlinespacing
            ? css`
                  & + [data-inline='true'] {
                      margin-left: ${getSpacingStringFromDescription(props.inlinespacing, props.theme)};
                  }
              `
            : ''}

    // Pill
    ${props =>
        props.pill &&
        css`
            padding: 2px 6px;
            background-color: ${getThemeColor(props.color!, props.secondary, props.disabled, props.theme)};
            border-radius: 6px;
        `}

    // Circular
    ${props =>
        props.circular &&
        css`
            padding: ${props.theme.fontConfiguration.circularPadding.get(props.type as any, 0)};
            background-color: ${getThemeColor(props.color!, props.secondary, props.disabled, props.theme)};
            border-radius: 999px;
        `}

    // Word appearance
    ${props =>
        props.capitalizeFirst &&
        css`
            text-transform: capitalize;
        `}

    ${props =>
        props.keepColorOnPrint
            ? css`
                  @media print {
                      color: ${props.pill
                          ? props.theme.invertedText
                          : getThemeColor(props.color!, props.secondary, props.disabled, props.theme)} !important;
                  }
              `
            : ''}

    ${props =>
        props.ellipsis
            ? css`
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
              `
            : ''}
`;

const getTagForTextType = (textType?: TextTypes, overrideTag?: asType): asType => {
    if (overrideTag) {
        return overrideTag;
    }
    switch (textType) {
        case TextTypes.Display:
        case TextTypes.Headline:
            return 'h1';
        case TextTypes.Title:
            return 'h2';
        case TextTypes.Subheader:
            return 'h3';
        case TextTypes.Body:
            return 'p';
        case TextTypes.Caption:
            return 'small';
        case TextTypes.Small:
            return 'small';
        default:
            return 'span';
    }
};

const TextBaseBeforeStyling: React.FC<React.PropsWithChildren<TextProps>> = props => (
    <Base
        as={getTagForTextType(props.type, props.overrideTag)}
        data-inline={Boolean(props.inline || props.inlineBlock)}
        {...props}
    />
);

export const TextBase = styled(TextBaseBeforeStyling)<TextProps>`
    ${TextBaseCss}
`;
TextBase.defaultProps = {
    color: 'text',
    emphasized: false,
    inlinespacing: '3px',
    pill: false,
};

/**
 * @deprecated Use the `Text` component from Modern Famly
 */
export const Display = styled(TextBase).attrs<TextProps>(() => ({
    type: TextTypes.Display,
    inlinespacing: '5px',
}))``;

/**
 * @deprecated Use the `Text` component from Modern Famly
 */
export const Headline = styled(TextBase).attrs<TextProps>(() => ({
    type: TextTypes.Headline,
    inlinespacing: '5px',
}))``;

/**
 * @deprecated Use the `Text` component from Modern Famly
 */
export const Title = styled(TextBase).attrs<TextProps>(() => ({
    type: TextTypes.Title,
    inlinespacing: '5px',
}))``;

/**
 * @deprecated Use the `Text` component from Modern Famly
 */
export const Subheader = styled(TextBase).attrs<TextProps>(() => ({
    type: TextTypes.Subheader,
    inlinespacing: '5px',
}))``;

/**
 * @deprecated Use the `Text` component from Modern Famly
 */
export const Body = styled(TextBase).attrs<TextProps>(() => ({
    type: TextTypes.Body,
}))``;

/**
 * @deprecated Use the `Text` component from Modern Famly
 */
export const Caption = styled(TextBase).attrs<TextProps>(() => ({
    type: TextTypes.Caption,
}))``;

/**
 * @deprecated Use the `Text` component from Modern Famly
 */
export const Small = styled(TextBase).attrs<TextProps>(() => ({
    type: TextTypes.Small,
}))``;

export type TextVariant = Lowercase<`${TextTypes}`>;

/**
 * @deprecated Use the `Text` component from Modern Famly
 */
export const Text = (props: TextProps & {variant?: TextVariant; children: React.ReactNode}) => {
    const {variant, ...textProps} = props;

    switch (variant) {
        case 'display':
            return <Display {...textProps} />;
        case 'headline':
            return <Headline {...textProps} />;
        case 'title':
            return <Title {...textProps} />;
        case 'subheader':
            return <Subheader {...textProps} />;
        case 'body':
            return <Body {...textProps} />;
        case 'caption':
            return <Caption {...textProps} />;
        case 'small':
            return <Small {...textProps} />;
        default:
            return <Body {...textProps} />;
    }
};
