import React from 'react';
import styled from 'styled-components';

import {Stack} from '@famly/mf_layout_stack';
import {Base} from 'web-app/react/components/layout/layout';
import {Subheader, Title} from 'web-app/react/components/text/text';
import Container from 'web-app/react/components/container/container';
import Button, {ButtonAppearance} from 'web-app/react/components/form-elements/button/button';
import Close from 'web-app/react/components/icons/legacy/lazy-icons/close';
import {s2} from 'web-app/styleguide/spacing';
import {media} from 'web-app/styleguide/utils';

interface ModalHeaderProps {
    header?: React.ReactNode;
    mobileHeader?: React.ReactNode;
    subHeader?: React.ReactNode;
    onCloseModal?: () => any;
    rightTitleElement?: React.ReactNode;
    className?: string;
}

const StyledMobileTitle = styled(Title)<{hasMobileHeader: boolean}>`
    display: none;

    ${media.mobile`
        display: ${props => (props.hasMobileHeader ? 'initial' : 'none')};
    `}
`;

const StyledTitle = styled(Title)<{hasMobileHeader: boolean}>`
    display: initial;

    ${media.mobile`
        display: ${props => (props.hasMobileHeader ? 'none' : 'initial')};
    `}
`;

const NewModalHeader: React.FC<React.PropsWithChildren<ModalHeaderProps>> = props => {
    const {header, mobileHeader, subHeader, onCloseModal, rightTitleElement, className} = props;

    return (
        <Container className={className} noMargin>
            <Base paddingTop={s2} paddingBottom={s2}>
                <Stack alignItems="center">
                    <StyledTitle minWidth={0} ellipsis grow={1} hasMobileHeader={Boolean(mobileHeader)}>
                        {header}
                    </StyledTitle>
                    <StyledMobileTitle minWidth={0} ellipsis grow={1} hasMobileHeader={Boolean(mobileHeader)}>
                        {mobileHeader}
                    </StyledMobileTitle>
                    {rightTitleElement}
                    {onCloseModal ? (
                        <Button
                            buttonSize={'mini'}
                            appearance={ButtonAppearance.icon}
                            onClick={onCloseModal}
                            data-e2e-class="modalCloseButton"
                        >
                            <Close size={18} />
                        </Button>
                    ) : null}
                </Stack>
                {subHeader ? (
                    <Subheader data-e2e-class="modal-subheader" secondary>
                        {subHeader}
                    </Subheader>
                ) : null}
            </Base>
        </Container>
    );
};

export default NewModalHeader;
