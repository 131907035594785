import Typography, {type TypographyProps} from '@mui/material/Typography';
import {type SxProps, type Theme} from '@mui/material/styles';
import {extractSystemStyles, type WithSystemProps} from '@famly/mf_system_system-props';
import React from 'react';
import {useDataProps} from '@famly/stat_ts-utils_data-aria-props';
import {getEmphasizedFontWeight} from '@famly/mf_util_font';

export type TextProps = {
    children: TypographyProps['children'];
    ellipsis?: boolean;
    emphasized?: boolean;
    whiteSpace?: TypographyProps['whiteSpace'];
    variant: TypographyProps['variant'];

    /**
     * When set, the text will be displayed with a maximum number of lines.
     * If the text exceeds the number of lines, it will be truncated and an ellipsis will be displayed.
     */
    lines?: number;

    /**
     * When set, the text will be displayed as a number.
     * Note that this will not apply any formatting — only the font itself and some font-attributes will be changed.
     */
    numeric?: boolean;
};

export const Text = (props: WithSystemProps<TextProps>) => {
    const {variant, children, emphasized, ellipsis, numeric, lines, ...rest} = props;

    const dataProps = useDataProps(rest);
    const {sx: systemSx} = extractSystemStyles(rest);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const sx = React.useCallback<Extract<SxProps<Theme>, Function>>(
        theme => {
            return {
                ...systemSx(theme),
                fontWeight: emphasized ? getEmphasizedFontWeight(variant) : undefined,
                ...getNumericStyle(numeric),
                ...(!props.ellipsis &&
                    lines && {
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: lines,
                    }),
            };
        },
        [systemSx, emphasized, variant, numeric, props.ellipsis, lines],
    );

    return (
        <Typography sx={sx} variant={variant} noWrap={props.ellipsis} {...dataProps}>
            {props.children}
        </Typography>
    );
};

const getNumericStyle = (isNumeric?: boolean) => {
    return isNumeric
        ? {
              fontFamily: 'Inter',
              fontVariantNumeric: 'lining-nums tabular-nums stacked-fractions slashed-zero',
              fontFeatureSettings: "'ss02' on, 'ss04' on, 'cv11' on, 'ss01' on",
          }
        : {};
};
