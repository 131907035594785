import React from 'react';
import Stack from '@mui/material/Stack';
import {Text} from '@famly/mf_data-display_text';

import {ListItem} from './list-item';
import {Icon, type IconProps, type IconName} from '../icon';
import {getAnchorProps, type AnchorProps} from './anchor-props';
import {useListContext} from './list-context';

export type ListItemTextAndIconProps = {
    text: string;
    description?: string;
    icon?: IconName | Pick<IconProps, 'color' | 'name' | 'filled'>;
} & AnchorProps;

function isIconName(icon: unknown): icon is IconName {
    return typeof icon === 'string';
}

export const ListItemTextAndIcon = (props: ListItemTextAndIconProps) => {
    const {text, description, icon} = props;

    const {device} = useListContext();

    const anchorProps = device === 'non-mobile' ? getAnchorProps(props) : null;

    return (
        // The padding is added to the Stack component instead of the ListItem component
        // to make a potential hover effect not touch the edges of the ListItem component.
        <ListItem px={0}>
            <Stack
                {...anchorProps}
                height="100%"
                direction="row"
                gap={2}
                px={device === 'mobile' ? 0 : 4}
                alignItems="center"
                width="100%"
                overflow="hidden"
                sx={theme => ({
                    textDecoration: 'unset',
                    ':hover': anchorProps?.href
                        ? {
                              cursor: 'pointer',
                              backgroundColor: theme.modernFamlyTheme.colorPalette.p50,
                          }
                        : undefined,
                    ':focus-visible': {
                        outlineOffset: '-1px',
                        outlineColor: theme.modernFamlyTheme.colorPalette.p400,
                    },
                })}
            >
                {icon ? (
                    <>
                        {isIconName(icon) ? (
                            <Icon name={icon} size={24} color="n300" />
                        ) : (
                            <Icon name={icon.name} size={24} color={icon.color ?? 'n300'} filled={icon.filled} />
                        )}
                    </>
                ) : null}
                <Stack direction="column" minWidth={0}>
                    <Text variant="body" textDecoration="none" color="n400" ellipsis>
                        {text}
                    </Text>
                    {description ? (
                        <Text variant="body-small" color="n300" ellipsis>
                            {description}
                        </Text>
                    ) : null}
                </Stack>
            </Stack>
        </ListItem>
    );
};
