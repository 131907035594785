import React from 'react';

import {extractDataProps} from './extract-data-props';

/**
 * Utility hook to extract all data-attributes that are added to components
 */
export const useDataProps = (props: Dictionary<string, any>): DataProps => {
    return React.useMemo(() => {
        return extractDataProps(props);
    }, [props]);
};
