import React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer, {type SwipeableDrawerProps} from '@mui/material/SwipeableDrawer';
import MuiDrawer, {type DrawerProps as MuiDrawerProps} from '@mui/material/Drawer';
import {type Theme, type Components} from '@mui/material/styles';
import type {PaperProps} from '@mui/material/Paper';
import {Stack, type StackProps} from '@famly/mf_layout_stack';
import {Text} from '@famly/mf_data-display_text';

import {useDataProps} from 'modern-famly/components/util';
import {useBreakpoints} from 'modern-famly/theming';
import {Avatar, Icon, type AvatarProps, type IconProps} from 'modern-famly/components/data-display';
import {Button} from 'modern-famly/components/input';
import {hasValue} from 'modern-famly/util';

import {DrawerTestAttributes} from './drawer-test-attributes';

type InputProps = {
    /**
     * Height value for the Drawer container (Defaults to '90vh')
     */
    height?: string;
    disablePortal?: boolean;
};

/**
 * A type defining all possible borderRadius options (All default to '0px')
 */
type BorderRadiusProps = {
    borderTopLeftRadius?: string;
    borderTopRightRadius?: string;
    borderBottomLeftRadius?: string;
    borderBottomRightRadius?: string;
};

/**
 * Props shared for all the Drawers
 */
type SharedDrawerProps = InputProps & Pick<SwipeableDrawerProps, 'open' | 'onOpen' | 'onClose'>;

/**
 * Props for Drawer only
 */
type InnerDrawerProps = SharedDrawerProps & BorderRadiusProps & Pick<SwipeableDrawerProps, 'anchor'>;

const InnerDrawer: React.FC<React.PropsWithChildren<InnerDrawerProps>> = ({
    children,
    onOpen,
    onClose,
    anchor = 'bottom',
    open,
    height,
    borderBottomLeftRadius = 0,
    borderBottomRightRadius = 0,
    borderTopLeftRadius = 0,
    borderTopRightRadius = 0,
    disablePortal,
    ...rest
}) => {
    const dataProps = useDataProps(rest);

    const {isTabletPortraitAndLarger, isTabletLandscapeAndLarger} = useBreakpoints();

    /**
     * Ensures that the drawer doesn't take up full width on tablets.
     * Note that the order of the if statements matter!
     */
    const maxWidth = React.useMemo(() => {
        if (isTabletLandscapeAndLarger) {
            return '70%';
        }

        if (isTabletPortraitAndLarger) {
            return '80%';
        }

        return '100%';
    }, [isTabletPortraitAndLarger, isTabletLandscapeAndLarger]);

    const PaperProps: PaperProps = React.useMemo(
        () => ({
            sx: {
                height: height || '100vh',
                borderBottomLeftRadius,
                borderBottomRightRadius,
                borderTopLeftRadius,
                borderTopRightRadius,
                paddingBottom: 'env(safe-area-inset-bottom)',
                maxWidth,
                margin: '0 auto',
            },
        }),
        [height, borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius, maxWidth],
    );

    return (
        <SwipeableDrawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            onOpen={onOpen}
            disablePortal={disablePortal}
            PaperProps={PaperProps}
            {...dataProps}
        >
            {children}
        </SwipeableDrawer>
    );
};

const Puller = () => {
    return (
        <Box
            sx={theme => {
                return {
                    width: 60,
                    height: 6,
                    backgroundColor: theme.modernFamlyTheme.colorPalette.n300,
                    borderRadius: 3,
                    position: 'absolute',
                    top: 8,
                    left: 'calc(50% - 30px)',
                };
            }}
        />
    );
};

/**
 * Props for the BottomDrawer only
 */
export type SheetProps = Omit<SharedDrawerProps, 'anchor'> & {
    /**
     * A flag indicating whether to show a 'Puller' visual or not (Defaults to true).
     * This might be useful when we want to show a Drawer on desktop but hide the puller (as it does not make sense to have it).
     */
    showPuller?: boolean;

    /**
     * Border radius for the drawer (Defaults to 16px)
     */
    borderRadius?: string;

    /**
     * Height of the drawer. Defaults to 90vh
     */
    height?: string;
    disablePortal?: boolean;
};

export const Sheet: React.FC<React.PropsWithChildren<SheetProps>> = ({
    showPuller = true,
    children,
    height = '90vh',
    borderRadius = '16px',
    disablePortal = false,
    ...rest
}) => {
    return (
        <InnerDrawer
            height={height}
            {...rest}
            anchor="bottom"
            borderTopLeftRadius={borderRadius}
            borderTopRightRadius={borderRadius}
            disablePortal={disablePortal}
        >
            {showPuller ? <Puller /> : null}
            {children}
        </InnerDrawer>
    );
};

/**
 * MUI Theming
 */
export const DrawerThemeConfiguration: Components<Theme>['MuiDrawer'] = {
    defaultProps: {
        anchor: 'bottom',
        open: false,
    },
};

export type IconTitle = {text: string; icon?: IconProps};
export type AvatarTitle = {text: string; avatar: AvatarProps & {description?: string}};

export type DrawerProps = Pick<MuiDrawerProps, 'open' | 'onClose' | 'variant'> & {
    title?: (IconTitle | AvatarTitle) & {onClick?: () => void};
    /**
     * Max width of the drawer. Use this if you need to e.g. limit the title space
     */
    maxWidth?: StackProps['maxWidth'];
};

function isAvatarTitle(candidate: DrawerProps['title']): candidate is AvatarTitle {
    return hasValue((candidate as AvatarTitle).avatar);
}

/**
 * This component renders the Drawer's title section with either only text, text and icon,
 * or an avatar component.
 *
 * Only exposed for testing, do not use directly.
 *
 * @param props containing the title to be rendered
 * @returns the title area or null, if title is not given
 */
export const DrawerTitle = (props: Pick<DrawerProps, 'title'>) => {
    const dataProps = useDataProps(props);
    const {title} = props;
    if (!hasValue(title)) {
        return null;
    } else if (isAvatarTitle(title)) {
        const {
            text,
            onClick,
            avatar: {description, ...avatarProps},
        } = title;

        return (
            <Stack
                {...dataProps}
                gap={2}
                alignItems="center"
                cursor={onClick ? 'pointer' : undefined}
                onClick={onClick ? onClick : undefined}
            >
                <Avatar {...avatarProps} data-e2e-id={DrawerTestAttributes.titleAvatar} />
                <Stack flexDirection="column">
                    <Text variant="body" data-e2e-id={DrawerTestAttributes.titleText}>
                        {text}
                    </Text>
                    <Text variant="body" color="n300" data-e2e-id={DrawerTestAttributes.titleDescription}>
                        {description}
                    </Text>
                </Stack>
            </Stack>
        );
    }

    const {text, onClick, icon} = title;
    return (
        <Stack
            {...dataProps}
            gap={2}
            alignItems="center"
            overflow="hidden"
            cursor={onClick ? 'pointer' : undefined}
            onClick={onClick ? onClick : undefined}
        >
            {icon ? <Icon {...icon} data-e2e-id={DrawerTestAttributes.titleIcon} /> : null}
            <Text variant="h5" data-e2e-id={DrawerTestAttributes.titleText} ellipsis>
                {text}
            </Text>
        </Stack>
    );
};

const titleAreaPadding: StackProps['padding'] = {
    base: 3,
    tabletPortrait: 6,
};

export const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({open, title, maxWidth, children, ...rest}) => {
    const dataProps = useDataProps(rest);
    const onCloseClick = React.useCallback(() => rest.onClose?.({}, 'backdropClick'), [rest]);

    const paperProps = React.useMemo(() => {
        return {
            sx: {
                paddingTop: `env(safe-area-inset-top)`,
                paddingBottom: 'env(safe-area-inset-bottom)',
                boxSizing: 'border-box',
            },
        } satisfies Partial<PaperProps>;
    }, []);

    return (
        <MuiDrawer open={open} {...rest} {...dataProps} anchor="right" PaperProps={paperProps}>
            <Stack
                justifyContent="space-between"
                alignItems="center"
                flexDirection="row-reverse"
                padding={titleAreaPadding}
                gap={2}
                maxWidth={maxWidth}
                boxSizing="border-box"
            >
                <Button icon="close" onClick={onCloseClick} aria-label="close" size="compact" variant="tertiary" />
                <DrawerTitle title={title} data-e2e-id={DrawerTestAttributes.title} />
            </Stack>
            {children}
        </MuiDrawer>
    );
};
