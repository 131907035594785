import React from 'react';

/**
 * A function that will determine if the given HTML element, that the returned ref is assigned to,
 * is scrollable or not.
 *
 * @returns {
 *  ref: The ref you need to assign the element you want to know the scrollable state of
 *  isScrollable: The boolean telling you if the element is scrollable or not
 * }
 */
export function useIsScrollable(): {ref: React.MutableRefObject<HTMLElement | null>; isScrollable: boolean} {
    const [isScrollable, setIsScrollable] = React.useState(false);
    const contentRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        const checkScrollable = () => {
            if (contentRef.current) {
                const {scrollHeight, clientHeight} = contentRef.current;
                setIsScrollable(scrollHeight > clientHeight);
            }
        };

        checkScrollable();
        window.addEventListener('resize', checkScrollable);

        return () => {
            window.removeEventListener('resize', checkScrollable);
        };
    }, []);

    return React.useMemo(() => ({ref: contentRef, isScrollable}), [isScrollable]);
}
