import {ObservationVariant} from 'web-app/util/graphql/api-types';

import {type TrackingEvent} from '../types';

export const PtpEvents = {
    // Child Development form
    CD_OLD_OBSERVATION_SUBMITTED: {
        name: 'CD_OLD_OBSERVATION_SUBMITTED',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Famly Live
    FAMLY_LIVE_MEETING_CREATED: {
        name: 'FAMLY_LIVE_MEETING_CREATED',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FAMLY_LIVE_MEETING_POSTED: {
        name: 'FAMLY_LIVE_MEETING_POSTED',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Messaging
    MESSAGING_NEW_MESSAGE_SELECTED: {
        name: 'Messaging: New message selected',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MESSAGING_RECIPIENT_TREE_CLICKED: {
        name: 'Messaging: Recipient tree clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MESSAGING_WRITING_BEGUN: {
        name: 'Messaging: Message writing begun',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MESSAGING_MESSAGE_SENT: {
        name: 'Messaging: Message sent',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    MESSAGING_MESSAGE_MODULE_OPENED: {
        name: 'Messaging: Message module opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MESSAGING_CONVERSATION_OPENED: {
        name: 'Messaging: Conversation opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MESSAGING_INBOX_CLOSED: {
        name: 'Messaging: Inbox closed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Meal registrations
    MEAL_REGISTRATIONS_MEAL_REGISTRATIONS_MODAL_OPENED: {
        name: 'Meal registrations: Meal registration modal opened',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MEAL_REGISTRATIONS_MEAL_TYPE_SELECTED: {
        name: 'Meal registrations: Meal type selected',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MEAL_REGISTRATIONS_FOOD_ITEM_SELECTED: {
        name: 'Meal registrations: Food item selected',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MEAL_REGISTRATIONS_NEW_FOOD_ITEM_CREATED: {
        name: 'Meal registrations: New food item created',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MEAL_REGISTRATIONS_FOOD_AMOUNT_CHANGED_IN_SLIDER: {
        name: 'Meal registrations: Food amount changed in slider',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MEAL_REGISTRATIONS_FOOD_AMOUNT_SELECTOR_CHANGED: {
        name: 'Meal registrations: Food amount selector changed',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MEAL_REGISTRATIONS_FOOD_ITEM_DELETED: {
        name: 'Meal registrations: Food item deleted',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MEAL_REGISTRATIONS_MEAL_REGISTRATION_SAVED: {
        name: 'Meal registrations: Meal registration saved',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    MEAL_REGISTRATIONS_MEAL_REGISTRATION_UPDATE_OPENED: {
        name: 'Meal registrations: Meal registration update opened',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MEAL_REGISTRATIONS_MEAL_REGISTRATION_UPDATED: {
        name: 'Meal registrations: Meal registration updated',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Meal plans v1
    MEAL_PLANS_MEAL_PLAN_OPENED: {
        name: 'Meal plans: meal plan opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2024-01-01',
    },
    MEAL_PLANS_MEAL_SAVED: {
        name: 'Meal plans: meal plan saved',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2024-01-01',
    },

    // Meal plans v3
    MEAL_PLANS_SITE_OPENED: {
        name: 'Meal plans: site opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MEAL_PLANS_NEW_PLAN_STARTED: {
        name: 'Meal plans: New plan started',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    MEAL_PLANS_EXISTING_PLAN_EDIT_CLICKED: {
        name: 'Meal plans: Existing plan edit clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MEAL_PLANS_PLAN_DELETED: {
        name: 'Meal plans: Meal plan deleted',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MEAL_PLANS_PLAN_ARCHIVED: {
        name: 'Meal plans: Meal plan archived',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MEAL_PLANS_PLAN_SAVED: {
        name: 'Meal plans: Meal plan saved',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MEAL_PLANS_PLAN_PUBLISHED: {
        name: 'Meal plans: Meal plan published',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Sign-In/Out
    SIGNIN_SCREEN_V2_APP_LOADED: {
        name: 'Signin-screen v2: App loaded',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2024-11-19',
    },
    SIGN_IN_OUT_CHILD_CHECKED_IN: {
        name: 'Sign-in/out: Child checked in',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SIGNIN_SCREEN_CHILD_CHECKED_IN: {
        name: 'Signin-screen: Child checked in',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2024-11-19',
    },
    SIGN_IN_OUT_CHILD_CHECKED_OUT: {
        name: 'Sign-in/out: Child checked out',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: false,
        expirationDate: 'none',
    },
    SIGNIN_SCREEN_CHILD_CHECKED_OUT: {
        name: 'Signin-screen: Child checked out',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2024-11-19',
    },
    SIGN_IN_OUT_UPDATE_CHILD_PICK_UP: {
        name: 'Sign-in/out: update child pick up',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SIGN_IN_OUT_STAFF_MEMBER_CHECKED_IN: {
        name: 'Sign-in/out: Staff member checked in',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SIGNIN_SCREEN_STAFF_MEMBER_CHECKED_IN: {
        name: 'Signin-screen: Staff member checked in',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SIGN_IN_OUT_STAFF_MEMBER_CHECKED_OUT: {
        name: 'Sign-in/out: Staff member checked out',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIGNIN_SCREEN_STAFF_MEMBER_CHECKED_OUT: {
        name: 'Signin-screen: Staff member checked out',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2024-11-19',
    },
    SIGNIN_SCREEN_CHILD_STATUS_APPLIED: {
        name: 'Signin-screen: Custom status applied',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2024-11-19',
    },

    // Activity library
    ACTIVITY_LIBRARY_OPENED: {
        name: 'Activity library: opened',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_LIBRARY_ACTIVITY_OPENED: {
        name: 'Activity library: activity opened',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Activity generator
    ACTIVITY_GENERATOR_OPENED: {
        name: 'Activity generator: Opened',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_GENERATOR_CLOSED: {
        name: 'Activity generator: Closed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_GENERATOR_GENERATE_PREVIEWS: {
        name: 'Activity generator: Generate previews',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_GENERATOR_CLICK_PREVIEW: {
        name: 'Activity generator: Click preview',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_GENERATOR_ACTIVITY_SAVED: {
        name: 'Activity generator: Activity saved',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_GENERATOR_ENGAGEMENT_SUGGESTIONS: {
        name: 'Activity generator: Child engagement suggestions',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Activity planer
    ACTIVITY_PLANNER_APP_OPENED: {
        name: 'Activity planner: App opened',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_DRAFT_CREATED: {
        name: 'Activity planner: Draft created',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_ADD_ACTIVITY_CLICKED: {
        name: 'Activity planner: Add activaty clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_ACTIVITY_ADDED: {
        name: 'Activity planner: Activity added',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_NOTE_CREATED: {
        name: 'Activity planner: Note created',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_PLAN_COPIED: {
        name: 'Activity planner: Plan copied',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_PLAN_PUBLISHED: {
        name: 'Activity planner: Plan published',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_PLAN_EDITED: {
        name: 'Activity planner: Plan edited',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_PLAN_REPUBLISHED: {
        name: 'Activity planner: Plan republished',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_PLAN_UNPUBLISHED: {
        name: 'Activity planner: Plan unpublished',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_PLAN_DELETED: {
        name: 'Activity planner: Plan deleted',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_DATE_PICKER_LEFT_ARROW_BUTTON_CLICKED: {
        name: 'Activity planner: Date picker left arrow button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_DATE_PICKER_RIGHT_ARROW_BUTTON_CLICKED: {
        name: 'Activity planner: Date picker right arrow button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACTIVITY_PLANNER_PRINT_BUTTON: {
        name: 'Activity planner: Print button clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Settings page
    SETTINGS_CURRICULUM_ENABLED: {
        name: `Settings: Curriculum enabled`,
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SETTINGS_CURRICULUML_DISBLED: {
        name: `Settings: Curriculum disabled`,
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SETTINGS_QR_SIGNIN_ENABLED: {
        name: 'Settings: QR signin enabled',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETTINGS_QR_SIGNIN_DISABLED: {
        name: 'Settings: QR signin disabled',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETTINGS_EXPECTED_PICK_UP_TIME_REQUIRED: {
        name: 'Expected pick up time required',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Accident/ Incident reports
    ACCIDENT_INCIDENT_REPORTS_OPENED: {
        name: 'Accident incident reports: Opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2025-12-01',
    },
    ACCIDENT_INCIDENT_REPORTS_NEW_REPORT_STARTED: {
        name: 'Accident incident reports: New report started',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACCIDENT_INCIDENT_REPORTS_KIND_SELECTED: {
        name: 'Accident incident reports: Kind selected' as const,
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2025-12-01',
    },
    ACCIDENT_INCIDENT_REPORTS_OTHER_NOTES: {
        name: 'Accident incident reports: Other notes first letter typed' as const,
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2025-12-01',
    },
    ACCIDENT_INCIDENT_REPORTS_REPORT_CREATED: {
        name: 'Accident incident reports: Report saved',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    ACCIDENT_INCIDENT_REPORTS_REPORT_EDIT_CANCELLED: {
        name: 'Accident incident reports: Report edit/creation cancelled',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2025-12-01',
    },
    ACCIDENT_INCIDENT_REPORTS_REPORT_EXIT_PREVIEW: {
        name: 'Accident incident reports: Exit preview',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2025-12-01',
    },
    ACCIDENT_INCIDENT_REPORTS_REPORT_PRINTED: {
        name: 'Accident incident reports: Report printed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACCIDENT_INCIDENT_REPORTS_REPORT_SUBMITTED_FOR_REVIEW: {
        name: 'Accident incident reports: Report submitted for review',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    ACCIDENT_INCIDENT_REPORTS_REPORT_SENT_TO_PARENTS_UPDATED: {
        name: 'Accident incident reports: Report sent to parents updated',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACCIDENT_INCIDENT_REPORTS_APPROVE_REPORT: {
        name: 'Accident incident reports: Report approved and sent to parents',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    ACCIDENT_INCIDENT_REPORTS_SEND_NEW_REPORT_TO_PARENTS: {
        name: 'Accident incident reports: Send new report to parents',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ACCIDENT_INCIDENT_REPORTS_OPEN_REPORT_IN_REVIEW: {
        name: 'Accident incident reports: Open report in review',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Accident/ Incident reports - permissions
    ACCIDENT_INCIDENT_REPORTS_PERMISSION_TOGGLED: {
        name: 'Accident incident reports: Permission toggled',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Accident/ Incident reports - body maps
    ACCIDENT_INCIDENT_REPORTS_BODY_MAP_ENTER: {
        name: 'Accident incident reports: Enter body map step',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Accident/ Incident reports - overview
    ACCIDENT_INCIDENT_REPORTS_FILTERS: {
        name: 'Accident incident reports: filters used',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2024-10-31',
    },

    ACCIDENT_TYPE_SELECTED: {
        name: 'Accident type selected',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Medication forms
    MEDICATION_FORMS_NEW_MEDICATION_FORM_OPENED: {
        name: 'Medication Forms: New medication form opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    MEDICATION_FORMS_MEDICATION_FORM_SAVED: {
        name: 'Medication Forms: Medication form saved',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    MEDICATION_FORMS_MEDICATION_ADMINISTERED: {
        name: 'Medication Forms: Medication administered',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: false,
        expirationDate: 'none',
    },

    // Immunizations
    IMMUNIZATION_RECORDS_FORM_SAVE_BUTTON_CLICKED: {
        name: 'Immunizations: Immunization form save button clicked',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    IMMUNIZATION_RECORDS_FORM_SAVED: {
        name: 'Immunizations: Immunization form completed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    IMMUNIZATION_RECORDS_FILTER_SELECTED_MONTH_PERIOD: {
        name: 'Immunizations: filter selected: Month/period',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Progress report & Tally
    CD_REPORT_FILTER_SELECTED: {
        name: 'CD report filter selected',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Kita-Planer child import
    KITA_PLANER_OPENED: {name: 'Kita-Planer: opened', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},
    KITA_PLANER_CONNECTION: {
        name: 'Kita-Planer: connection',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    KITA_PLANER_CHILDREN_IMPORTED: {
        name: 'Kita-Planer: children imported',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    KITA_PLANER_CHILDREN_DISMISSED: {
        name: 'Kita-Planer: children dismissed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    KITA_PLANER_KITAEXPORT_ERROR: {
        name: 'Kita-Planer: error getting data from Kita-Planer',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // News feed
    NEWSFEED_POST_ADDED: {
        name: 'News feed: post added',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_POST_LIKED: {name: 'News feed: post liked', sendTo: ['Mixpanel'], isActive: false, expirationDate: 'none'},
    NEWSFEED_POST_UNLIKED: {
        name: 'News feed: post unliked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_COMMENT_ADDED: {
        name: 'News feed: comment added',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    NEWSFEED_COMMENT_LIKED: {
        name: 'News feed: comment liked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    NEWSFEED_COMMENT_UNLIKED: {
        name: 'News feed: comment unliked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_TRANSLATION_LANGUAGE_SELECTED: {
        name: 'News feed: translation language selected',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_TRANSLATE_POST_BANNER_DISMISSED: {
        name: 'News feed: translate post banner dismissed',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_TRANSLATE_POST_BUTTON_CLICKED: {
        name: 'News feed: translate post button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_DRAFT_MENU_CLICKED: {
        name: 'News feed: draft menu clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    NEWSFEED_DRAFT_OPENED: {
        name: 'News feed: draft opened',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    NEWSFEED_CLEAR_CLICKED: {
        name: 'News feed: clear button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_UPLOAD_CLICKED: {
        name: 'News feed: upload clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    NEWSFEED_ACTIVITY_SHARING_CLICKED: {
        name: 'News feed: activity sharing clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_POST_DELETE_CLICKED: {
        name: 'News feed: post delete clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_POST_VIEW_EDIT_CLICKED: {
        name: 'News feed: post view/edit clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    NEWSFEED_POST_BOOKMARK_CLICKED: {
        name: 'News feed: post bookmark clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NEWSFEED_POST_RECIPIENTS_SEEN_BY_CLICKED: {
        name: 'News feed: post recipients/seen by clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    NEWSFEED_DISABLE_ENABLE_COMMENTS_CLICKED: {
        name: 'News feed: disable/enable comments clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Child notes
    CHILD_NOTES_DOCUMENT_SCANNED: {
        name: 'Child Notes: Document scanned',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CHILD_NOTES_SCANNER_MODAL_OPEN: {
        name: 'Child Notes: Document scanner modal opened',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Image tagging permission
    IMAGE_TAGGING_PERMISSION_ENABLED: {
        name: 'Image tagging permission: enabled',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    IMAGE_TAGGING_PERMISSION_DISABLED: {
        name: 'Image tagging permission: disabled',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    IMAGE_TAGGING_PERMISSION_NEW_PERMISSION_CREATED: {
        name: 'Image tagging permission: new permission created',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    IMAGE_TAGGING_PERMISSION_TAGGING_STARTED: {
        name: 'Image tagging permission: tagging started',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    IMAGE_TAGGING_PERMISSION_TAGGING_FINISHED: {
        name: 'Image tagging permission: tagging finished',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    IMAGE_TAGGING_PERMISSION_DISCARDED_IMAGE: {
        name: 'Image tagging permission: image discarded',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    IMAGE_TAGGING_PERMISSION_TAGGING_FLOW_ABANDONED: {
        name: 'Image tagging permission: abandoned tagging flow',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Observation review
    OBSERVATION_REVIEW_COMMENT_SENT: {
        name: 'Observation review: comment sent',
        isActive: true,
        sendTo: ['Mixpanel'],
        expirationDate: 'none',
    },
    OBSERVATION_REVIEW_REQUEST_CHANGES_BUTTON_CLICKED: {
        name: 'Observation review: request changes button clicked',
        isActive: true,
        sendTo: ['Mixpanel'],
        expirationDate: 'none',
    },
    OBSERVATION_REVIEW_APPROVE_AND_SEND_CLICKED: {
        name: 'Observation review: approve and send clicked',
        isActive: false,
        sendTo: ['Mixpanel'],
        expirationDate: '2024-11-27',
    },
    OBSERVATION_REVIEW_UPDATE_BUTTON_CLICKED: {
        name: 'Observation review: update button clicked',
        isActive: false,
        sendTo: ['Mixpanel'],
        expirationDate: '2024-11-27',
    },

    // Curriculum builder
    CURRICULUM_BUILDER_CREATE_CURRICULUM_CLICKED: {
        name: 'Curriculum builder create curriculum clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_DESIGN_MY_OWN_CLICKED: {
        name: 'Curriculum builder design my own clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_USE_TEMPLATE_CLICKED: {
        name: 'Curriculum builder use a template clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_DRAFT_SAVED: {
        name: 'Curriculum builder draft saved',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_CURRICULUM_PUBLISHED: {
        name: 'Curriculum builder curriculum published',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_PUBLISHED_MESSAGE_TAKE_ME_TO_SETTINGS_CLICKED: {
        name: 'Curriculum builder published message Take me to settings clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_PUBLISHED_MESSAGE_WILL_DO_IT_LATER_CLICKED: {
        name: 'Curriculum builder published message I’ll do it later clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_CURRICULUM_DELETED: {
        name: 'Curriculum builder curriculum deleted',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_ADD_CURRICULUM_INFO: {
        name: 'Curriculum builder add curriculum info',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_ADD_SUBAREAS_PAGES_INSIDE_LEARNING_AREA: {
        name: 'Curriculum builder add subareas/pages inside learning area',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    CURRICULUM_BUILDER_PREVIEW: {
        name: 'Curriculum builder preview clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Immunization records
    IMMUNIZATIONS_OPENED: {name: 'Immunizations: opened', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},
    IMMUNIZATIONS_GET_STARTED_CLICKED: {
        name: 'Immunizations onboarding: Get started clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    IMMUNIZATIONS_NEXT_CLICKED: {
        name: 'Immunizations onboarding: How it works Next clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    IMMUNIZATIONS_SAVE_CLICKED: {
        name: 'Immunizations onboarding: Save and Next clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    IMMUNIZATIONS_UP_TO_SPEED_CHECKED: {
        name: 'Immunizations onboarding: Up to speed checked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    IMMUNIZATIONS_UP_TO_SPEED_UNCHECKED: {
        name: 'Immunizations onboarding: Up to speed un-checked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Media share
    MEDIA_SHARE_FEATURE_TOGGLE: {
        name: 'Media share: feature toggle setting changed',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    MEDIA_SHARE_TAPPED: {
        name: 'Media share: tapped',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Sidekick
    SIDEKICK_SETTINGS_TOGGLED: {
        name: 'Sidekick: settings toggled',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SIDEKICK_BUTTON_CLICKED: {
        name: 'Sidekick: button clicked',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SIDEKICK_PROMPT_SELECTED: {
        name: 'Sidekick: prompt selected',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2024-11-27',
    },
    SIDEKICK_CANCEL_CLICKED: {
        name: 'Sidekick: cancel button clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRY_AGAIN_CLICKED: {
        name: 'Sidekick: try again button clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_APPLY_CLICKED: {
        name: 'Sidekick: apply button clicked',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SIDEKICK_COMPLETION_ENDED: {
        name: 'Sidekick: completion',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2024-11-27',
    },

    // Sidekick trial
    SIDEKICK_TRIAL_PUSH_ELEMENT_VISIBLE: {
        name: 'Sidekick trial: push element is visible',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_BUTTON_CLICKED: {
        name: 'Sidekick trial: button clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_STARTED: {
        name: 'Sidekick trial: started',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_LEARN_MORE: {
        name: 'Sidekick trial: learn more clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_MAYBE_LATER: {
        name: 'Sidekick trial: maybe later clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_PUSH_DISMISSED: {
        name: 'Sidekick trial: push element dismissed',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_BUY_NOW: {
        name: 'Sidekick trial: buy now clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_MANAGE_SETTINGS: {
        name: 'Sidekick trial: manage settings clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_START_PUSH_CLOSED: {
        name: 'Sidekick trial: push element closed after trial start',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_SETTINGS_TOGGLED: {
        name: 'Sidekick trial: settings toggled',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_SHOW_CONTROLS_TOGGLED: {
        name: 'Sidekick trial: show controls toggled',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_TRIAL_REMINDER_SEEN: {
        name: 'Sidekick trial: usage reminder seen',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_ADDON_PURCHASED: {
        name: 'Sidekick: addon purchased',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SIDEKICK_STORE_ELEMENT_CLICKED: {
        name: 'Sidekick: store element clicked',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_STORE_ELEMENT_SUMMARY_VIEW: {
        name: 'Sidekick: store element summary step',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_LEARNING_JOURNEY_NEW_SUMMARY: {
        name: 'Sidekick learning: new journey summary',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SIDEKICK_LEARNING_JOURNEY_NEW_WHATS_NEXT: {
        name: 'Sidekick learning: new whats next',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Calendar
    CALENDAR_EVENT_CREATED: {
        name: 'Calendar: event created',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Daily logging
    DAILY_LOGGING_DIAPER_CHANGE: {
        name: 'Daily logging: nappy/toilet',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    DAILY_LOGGING_SLEEP_LOGGED: {
        name: 'Daily logging: sleep logged',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    DAILY_LOGGING_CUSTOM_STATUS_ADDED: {
        name: 'Daily logging: custom status added',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    // Events that are triggered by multiple actions (e.g., diaper change, sleep, child sign in/out etc...)
    DAILY_LOGS: {
        name: 'Daily logs',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SAFEGUARDING: {
        name: 'Safeguarding',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
} satisfies Record<string, TrackingEvent>;

export const DynamicTrackingEvents = {
    // New observations
    NEW_OBSERVATION_PUBLISHED_DIRECTLY: (variant: ObservationVariant) => ({
        name: `${variant} published directly`,
        sendTo: ['Bucket'],
        // Deactivate for parent observations and two year checks
        isActive: ![ObservationVariant.PARENT_OBSERVATION, ObservationVariant.TWO_YEAR_PROGRESS].includes(variant),
        expirationDate: 'none',
    }),
    NEW_OBSERVATION_SENT_FOR_REVIEW: (variant: ObservationVariant) => ({
        name: `${variant} sent for review`,
        sendTo: ['Bucket'],
        // Deactivate for parent observations and two year checks
        isActive: ![ObservationVariant.PARENT_OBSERVATION, ObservationVariant.TWO_YEAR_PROGRESS].includes(variant),
        expirationDate: 'none',
    }),
    NEW_OBSERVATION_DRAFT_SAVED: (variant: ObservationVariant) => ({
        name: `${variant} draft saved`,
        sendTo: ['Mixpanel'],
        // Deactivate for assessments, regular observations and two year checks
        isActive: ![
            ObservationVariant.ASSESSMENT,
            ObservationVariant.REGULAR_OBSERVATION,
            ObservationVariant.TWO_YEAR_PROGRESS,
        ].includes(variant),
        expirationDate: 'none',
    }),
    NEW_OBSERVATION_DISCARDED: (variant: ObservationVariant) => ({
        name: `${variant} discarded`,
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    }),
    NEW_OBSERVATION_DRAFT_OPENED: (variant: ObservationVariant) => ({
        name: `${variant} draft opened`,
        sendTo: ['Mixpanel'],
        // Deactivate for assessments, regular observations and two year checks
        isActive: ![
            ObservationVariant.ASSESSMENT,
            ObservationVariant.REGULAR_OBSERVATION,
            ObservationVariant.TWO_YEAR_PROGRESS,
        ].includes(variant),
        expirationDate: 'none',
    }),
    NEW_CHILD_DEVELOPMENT: () => ({
        name: `Child development`,
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: '2024-11-27',
    }),

    // Reference material
    REFERENCE_MATERIAL_FRAMEWORK_CLICKED: (variant: ObservationVariant) => ({
        name: `${variant}  framework clicked`,
        sendTo: ['Mixpanel'],
        // Deactivate for regular observations
        isActive: variant !== ObservationVariant.REGULAR_OBSERVATION,
        expirationDate: 'none',
    }),
    REFERENCE_MATERIAL_PAST_OBSERVATIONS_CLICKED: (variant: ObservationVariant) => ({
        name: `${variant} past observations clicked`,
        sendTo: ['Mixpanel'],
        // Deactivate for assessments, regular observations and two year checks
        isActive: ![
            ObservationVariant.ASSESSMENT,
            ObservationVariant.REGULAR_OBSERVATION,
            ObservationVariant.TWO_YEAR_PROGRESS,
        ].includes(variant),
        expirationDate: 'none',
    }),
    REFERENCE_MATERIAL_PAST_ASSESSMENTS_CLICKED: (variant: ObservationVariant) => ({
        name: `${variant} past assessments clicked`,
        sendTo: ['Mixpanel'],
        // Deactivate for assessments, regular observations and two year checks
        isActive: ![
            ObservationVariant.ASSESSMENT,
            ObservationVariant.REGULAR_OBSERVATION,
            ObservationVariant.TWO_YEAR_PROGRESS,
        ].includes(variant),
        expirationDate: 'none',
    }),
} satisfies Record<string, (variant: ObservationVariant) => TrackingEvent>;
