const externalMatchRegex = /^[a-zA-Z]+:/g;

export const openWindow = (href: string | undefined, windowName: string = '_system', windowFeatures?: string) => {
    const newWindow = window.open(href, windowName, windowFeatures);
    if (newWindow) {
        newWindow.opener = null;
    }
};

export const isLinkExternal = (href?: string): boolean => {
    if (!href) {
        return false;
    }

    return Boolean(href.match(externalMatchRegex)) || href.startsWith('/api');
};
