import {ofType} from 'redux-observable';
import {of, merge, type Observable, zip} from 'rxjs';
import {mergeMap, flatMap, catchError} from 'rxjs/operators';
import {push} from 'connected-react-router';
import i18next from 'i18next';

import {combineEpics} from 'web-app/util/redux-observable';
import ChildApi from 'signin-app/api/child-api';
import * as GlobalEventActions from 'signin-app/global-event/actions';
import translate from 'signin-app/helpers/translate';
import {childName as getChildName, childRelations} from 'signin-app/child/selectors';
import {type Action} from 'web-app/util/redux/action-types';
import {type RootState} from 'signin-app/redux/main-reducer';
import {type SignInEpic} from 'signin-app/redux/types';
import ChildrenEntity from 'signin-app/entities/children';
import {TrackingEvents} from 'web-app/util/famlytics/events';
import {track} from 'signin-app/util/famlytics';
import {hasValue} from '@famly/stat_ts-utils_has-value';

import * as Actions from './actions';
import {checkingInOutPersonType} from '../check-in/epics';

const checkOutChild: SignInEpic = (action$, state$) =>
    action$.pipe(
        ofType(Actions.checkOutChild.type),
        mergeMap(({payload}: ReturnType<typeof Actions.checkOutChild.action>) => {
            const {childId, pin} = payload;

            return ChildApi.checkOut(childId, pin).pipe(
                flatMap(response => {
                    const state = state$.value;
                    const childName = getChildName(state, {id: childId, childId});

                    const relationsIds = childRelations(state).map(relation => relation.loginId);

                    track(TrackingEvents.SIGNIN_SCREEN_CHILD_CHECKED_OUT, {
                        openedFrom: 'Sign out screen',
                        checkingInOutPersonType: checkingInOutPersonType(response.checkinLoginId, relationsIds),
                        usedPinCode: hasValue(pin) && pin.length > 0,
                    });

                    return [
                        Actions.checkOutChildSuccess.action(response),
                        GlobalEventActions.updateSuccess.action(translate('signOutSuccess', {personName: childName})),
                    ];
                }),
                catchError(e => {
                    const errorMessage = e.error ? e.error : i18next.t('somethingWentWrong');
                    return merge(
                        of(GlobalEventActions.updateError.action(errorMessage)),
                        of(Actions.checkOutChildFailed.action(e)),
                    );
                }),
            );
        }),
    );

const applyChildStatus: SignInEpic = (action$, state$) =>
    action$.pipe(
        ofType(Actions.applyChildStatus.type),
        mergeMap(({payload}: ReturnType<typeof Actions.applyChildStatus.action>) => {
            const {childId, status} = payload;

            track(TrackingEvents.SIGNIN_SCREEN_CHILD_STATUS_APPLIED);
            const child = ChildrenEntity.selectors.getById(state$.value, {id: childId});
            let removeAllAppliedStatusesObservable: Observable<any>;

            if (child && child.statusRegistrations.size > 0) {
                const observables = child.statusRegistrations.map(registration => {
                    return ChildApi.endStatus(childId, registration.statusConfig.statusConfigId).pipe(
                        mergeMap(response => of(Actions.removeChildStatusSuccess.action(response, status))),
                    );
                });

                removeAllAppliedStatusesObservable = zip(...observables);
            }

            return ChildApi.applyStatus(childId, status.statusConfigId).pipe(
                mergeMap(response => {
                    if (removeAllAppliedStatusesObservable) {
                        return removeAllAppliedStatusesObservable.pipe(
                            flatMap(removeStatusActions => {
                                return removeStatusActions.concat([
                                    Actions.applyChildStatusSuccess.action(response, status),
                                    GlobalEventActions.updateSuccess.action(translate('statusUpdated')),
                                    push('/overview'),
                                ]);
                            }),
                        );
                    } else {
                        return [
                            Actions.applyChildStatusSuccess.action(response, status),
                            GlobalEventActions.updateSuccess.action(translate('statusUpdated')),
                            push('/overview'),
                        ];
                    }
                }),
                catchError(e => {
                    const errorMessage = e.error ? e.error : i18next.t('somethingWentWrong');
                    return merge(
                        of(GlobalEventActions.updateError.action(errorMessage)),
                        of(Actions.applyChildStatusFailed.action(e)),
                    );
                }),
            );
        }),
    );

const removeChildStatus: SignInEpic = action$ =>
    action$.pipe(
        ofType(Actions.removeChildStatus.type),
        mergeMap(({payload}: ReturnType<typeof Actions.removeChildStatus.action>) => {
            const {childId, status} = payload;

            return ChildApi.endStatus(childId, status.statusConfigId).pipe(
                flatMap(response => {
                    return [
                        Actions.removeChildStatusSuccess.action(response, status),
                        GlobalEventActions.updateSuccess.action(translate('statusUpdated')),
                        push('/overview'),
                    ];
                }),
                catchError(e => {
                    const errorMessage = e.error ? e.error : i18next.t('somethingWentWrong');
                    return merge(
                        of(GlobalEventActions.updateError.action(errorMessage)),
                        of(Actions.removeChildStatusFailed.action(e)),
                    );
                }),
            );
        }),
    );

export default combineEpics<Action<any>, Action<any>, RootState>(checkOutChild, applyChildStatus, removeChildStatus);
