import {useState, useEffect} from 'react';

/**
 * Updates the value after a delay
 *
 * @deprecated import from '@famly/stat_ts-utils_debounce instead
 * @param value The value to debounce
 * @param delay The delay before the debounced value is updated
 * @returns The debounced value
 */
export const useDebounce = <T>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [value, delay]);

    return debouncedValue;
};
