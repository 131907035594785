import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import {Text} from '@famly/mf_data-display_text';
import {Stack} from '@famly/mf_layout_stack';
import {Box} from '@famly/mf_layout_box';
import {createBox} from '@famly/mf_layout_box';
import {type Time} from 'signin-app/helpers/time';
import {getDisplayHourValue} from 'signin-app/components/list-times/helpers';
import * as GroupsSelectors from 'signin-app/groups/selectors';
import * as ChildSelectors from 'signin-app/child/selectors';
import translate from 'signin-app/helpers/translate';
import TimeBox from 'signin-app/components/time-box';
import {useTypedSelector} from 'signin-app/components/hooks';

import {type Times} from './types';

const StyledBox = styled(createBox({}))`
    p {
        font-family: ${props => props.theme.fontConfiguration.family};
    }
`;

interface ListTimesProps {
    times: Times[];
    selectedHours?: number;
    selectedMinutes?: number;
    displayMinutes?: (hours: number, minutes: number, locale: string) => Time;
    onHoursChange: (hour: number) => void;
    onMinutesChange: (minutes: number) => void;
    e2eHourUnit: string;
    isEmployee?: boolean;
}

export const ListTimes: React.FC<ListTimesProps> = ({
    times,
    selectedHours,
    selectedMinutes,
    onHoursChange,
    onMinutesChange,
    displayMinutes,
    e2eHourUnit,
    isEmployee,
}) => {
    const locale = useTypedSelector(GroupsSelectors.institutionLocale);
    const institutionTitle = useTypedSelector(GroupsSelectors.institutionTitle);
    const minutesWrapperRef = React.useRef<HTMLDivElement | null>(null);
    const {childId: childIdFromParams} = useParams<{childId: string}>();
    const requirePickupInfo = useTypedSelector(state =>
        ChildSelectors.requirePickupInfo(state, {childId: childIdFromParams}),
    );

    const minutesForSelectedHour = React.useMemo(() => {
        const hiddenPlaceholderMinutes = times.length ? times[0].minutes : [];
        if (selectedHours) {
            const selectedTime = times.find(time => time.hour === selectedHours);
            return selectedTime ? selectedTime.minutes : hiddenPlaceholderMinutes;
        }
        return hiddenPlaceholderMinutes;
    }, [selectedHours, times]);

    const handleHoursClick = React.useCallback(
        async hours => {
            await onHoursChange(hours);
            if (!selectedHours && minutesWrapperRef.current) {
                minutesWrapperRef.current.scrollIntoView({behavior: 'smooth'});
            }
        },
        [selectedHours, onHoursChange],
    );

    const text = React.useMemo(() => {
        if (isEmployee) {
            return translate('whenWillLeave');
        }
        if (requirePickupInfo) {
            return translate('hour');
        }
        return translate('optionalHour');
    }, [isEmployee, requirePickupInfo]);

    if (times.length === 0) {
        return (
            <Stack flexWrap="wrap">
                <Text variant="body" color="n400">
                    {translate('emptyOpeningHours', {institutionTitle})}
                </Text>
            </Stack>
        );
    }

    return (
        <StyledBox>
            <Text variant="h6" emphasized color="n400">
                {text}
            </Text>
            <Stack mt={1} flexWrap="wrap" gap={3}>
                {times.map(time => {
                    const displayHourValue = getDisplayHourValue(locale, time.hour);
                    return (
                        <TimeBox
                            key={time.hour}
                            value={time.hour}
                            displayValue={displayHourValue && displayHourValue.hour}
                            subText={displayHourValue && displayHourValue.amPm}
                            selected={time.hour === selectedHours}
                            handleOnClick={handleHoursClick}
                            dataE2eTimeHour={e2eHourUnit}
                        />
                    );
                })}
            </Stack>
            {selectedHours ? (
                <div ref={minutesWrapperRef}>
                    <Box mt={6}>
                        <Text variant="h6" emphasized color="n400">
                            {translate('minutes')}
                        </Text>
                        <Stack mt={1} flexWrap="wrap" gap={3}>
                            {minutesForSelectedHour.map(minutes => {
                                const displayMinuteValue =
                                    displayMinutes && selectedHours
                                        ? displayMinutes(selectedHours, minutes, locale)
                                        : '';
                                return (
                                    <TimeBox
                                        key={minutes}
                                        value={minutes}
                                        selected={minutes === selectedMinutes}
                                        handleOnClick={onMinutesChange}
                                        displayValue={displayMinuteValue && displayMinuteValue.minutes}
                                    />
                                );
                            })}
                        </Stack>
                    </Box>
                </div>
            ) : null}
        </StyledBox>
    );
};
