import React from 'react';
import {hot} from 'react-hot-loader';
import {useDispatch} from 'react-redux';
import {ModernFamlyProvider, type SupportedLanguage as MFSupportedLanguage} from 'modern-famly';
import {ThemeProvider} from 'styled-components';

import setupI18next from 'signin-app/setup/i18next-setup';
import setupLocale from 'signin-app/setup/locale-setup';
import PlatformHelper from 'web-app/helpers/platform-helper';
import {hasValue} from '@famly/stat_ts-utils_has-value';
import LegacyModalStyle from 'web-app/react/global-styles/legacy-modal-style';
import {ModalDialogProvider} from '@famly/mf_modals-dialogs_context';
import GlobalCSS from 'shared/components/global-css';
import GlobalEvent from 'signin-app/global-event/global-event';
import GlobalStyleTooltip from 'web-app/react/global-styles/tooltip-style';
import Routes from 'signin-app/routes/routes';
import {AppContainer} from 'signin-app/components/app-container';
import * as PrivateRouteActions from 'signin-app/components/private-route/actions';
import {useTypedSelector} from 'signin-app/components/hooks';
import * as LoginSelectors from 'signin-app/login/selectors';
import {useInterval} from 'web-app/react/hooks/use-interval';
import {AuthStateProvider} from 'web-app/react/contexts/auth-state';
import currentTheme, {getCurrentModernFamlyTheme} from 'web-app/styleguide/themes';
import {MFFonts} from 'shared/components/mf-fonts';
import {getModernFamlyLanguage} from 'web-app/setup/locale-setup';
import {ModalContext} from 'web-app/react/modal-context/modal-context';
import {PortalRootId} from 'web-app/react/components/portals/constants';

/*
|---------------------------------------------------------------------------------
| App
|---------------------------------------------------------------------------------
*/

// TouchEvent interface is missing the `scale` prop
// https://developer.apple.com/documentation/webkitjs/touchevent/1632169-scale
interface ExtendedTouchEvent extends TouchEvent {
    scale: number;
}

const MINUTE = 60 * 1000;

export const App: React.FC = () => {
    const [setupReady, setSetupReady] = React.useState(false);
    const [latestAppRelease, setLatestAppRelease] = React.useState<string | null>(null);
    const [locale, setLocale] = React.useState<MFSupportedLanguage | undefined>(undefined);
    const releaseCheckIntervalRef = React.useRef<ReturnType<typeof setInterval>>();
    const dispatch = useDispatch();
    const accessToken = useTypedSelector(LoginSelectors.accessToken);
    const isPinApp = useTypedSelector(LoginSelectors.isPinApp);
    const isLoggedIn = useTypedSelector(LoginSelectors.isLoggedIn);
    const modernFamlyTheme = getCurrentModernFamlyTheme();

    const theme = React.useMemo(() => {
        return currentTheme().toObject();
    }, []);

    React.useEffect(() => {
        // Apple release note:
        // To improve accessibility on websites in Safari,
        // users can now pinch-to-zoom even when a website sets user-scalable=no in the viewport.
        if (PlatformHelper.isSafariMobile()) {
            disableTouchZoom();
            disableDoubleClickZoom();
        }

        // First we setup i18n, then the locale, which will select the
        // locale for i18next as well as moment etc.
        setupI18next()
            .then(setupLocale)
            .then(locale => {
                const modernFamlyLocale = getModernFamlyLanguage(locale);
                setLocale(modernFamlyLocale);
                setSetupReady(true);
            });

        return () => {
            if (PlatformHelper.isSafariMobile()) {
                document.removeEventListener('touchend', () => null);
                document.removeEventListener('touchmove', () => null);
            }
        };
    }, []);

    // Reloads the page if a new version of the app has been deployed
    React.useEffect(() => {
        if (!hasValue(latestAppRelease)) {
            getAppRelease().then(appRelease => {
                setLatestAppRelease(appRelease);

                releaseCheckIntervalRef.current = setInterval(() => {
                    getAppRelease().then(appRelease => {
                        if (latestAppRelease !== appRelease) {
                            window.location.reload();
                        }
                    });
                }, 10 * MINUTE);
            });
        }

        return () => {
            if (releaseCheckIntervalRef.current) {
                clearInterval(releaseCheckIntervalRef.current);
            }
        };
    }, [latestAppRelease]);

    // Refreshes the data regularly to stay synced with the main app.
    useInterval(() => {
        if (accessToken && !isPinApp) {
            dispatch(PrivateRouteActions.prefetch.action(accessToken));
        }
    }, 5 * MINUTE);

    if (!setupReady) {
        return null;
    }

    return (
        <ModernFamlyProvider theme={modernFamlyTheme} isMobileApp={PlatformHelper.isMobileApp()} language={locale}>
            <ThemeProvider theme={theme}>
                <AuthStateProvider value={{isLoggedIn}}>
                    <ModalDialogProvider>
                        <ModalContext>
                            <AppContainer>
                                <GlobalCSS />
                                <GlobalStyleTooltip />
                                <GlobalEvent />
                                <MFFonts />
                                <LegacyModalStyle />
                                <Routes />
                                <div id={PortalRootId.modalRoot} />
                            </AppContainer>
                        </ModalContext>
                    </ModalDialogProvider>
                </AuthStateProvider>
            </ThemeProvider>
        </ModernFamlyProvider>
    );
};

/*
|---------------------------------------------------------------------------------
| Helpers
|---------------------------------------------------------------------------------
*/

const disableDoubleClickZoom = () => {
    let lastTouchEnd = 0;

    document.addEventListener(
        'touchend',
        event => {
            const now = new Date().getTime();
            if (now - lastTouchEnd <= 300) {
                event.preventDefault();
            }
            lastTouchEnd = now;
        },
        false,
    );
};

const disableTouchZoom = () => {
    document.addEventListener(
        'touchmove',
        event => {
            if ((event as ExtendedTouchEvent).scale !== 1) {
                event.preventDefault();
            }
        },
        {passive: false},
    );
};

const getAppRelease = () => {
    const releaseURL = `${window.location.origin}/release.txt`;

    return new Promise<string>(resolve => {
        fetch(releaseURL)
            .then(response => {
                if (!response.ok) {
                    return 'dev';
                }

                return response.text();
            })
            .then(resolve);
    });
};

export default hot(module)(App);
