import {type Breakpoints, type Theme as MuiTheme} from '@mui/material/styles';
import useMuiMediaQuery from '@mui/material/useMediaQuery';

/**
 * @deprecated use @famly/mf_util_breakpoints instead
 */
export const useMediaQuery = (fn: (breakpoints: Breakpoints) => string) => {
    return useMuiMediaQuery<MuiTheme>(theme => fn(theme.breakpoints));
};

/**
 * Returns a dictionary of breakpoint names and booleans indicating whether that breakpoint is currently in use.
 *
 * @example
 * ```
 * const {
 *     isBase,
 *     isMobileLandscapeAndLarger,
 *     isTabletPortraitAndLarger,
 *     isTabletLandscapeAndLarger,
 *     isLaptopAndLarger
 * } = useBreakpoints();
 * ```
 * @deprecated use @famly/mf_util_breakpoints instead
 */
export const useBreakpoints = () => {
    const isBase = useMediaQuery(breakpoints => breakpoints.up('base'));
    const isMobileLandscapeAndLarger = useMediaQuery(breakpoints => breakpoints.up('mobileLandscape'));
    const isTabletPortraitAndLarger = useMediaQuery(breakpoints => breakpoints.up('tabletPortrait'));
    const isTabletLandscapeAndLarger = useMediaQuery(breakpoints => breakpoints.up('tabletLandscape'));
    const isLaptopAndLarger = useMediaQuery(breakpoints => breakpoints.up('laptop'));

    return {
        isBase,
        isMobileLandscapeAndLarger,
        isTabletPortraitAndLarger,
        isTabletLandscapeAndLarger,
        isLaptopAndLarger,
    };
};
