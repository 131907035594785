import {List} from 'immutable';

import {type DispatchableActionDescription} from 'web-app/util/redux/action-types';
import {makeCreateAction} from 'web-app/util/redux/action-helpers';
import {type APIDescription} from 'web-app/react/entities/factory/index';

import {type InternalAction, type RequestEvent, type RequestType} from './constants';

export type EventMap = {[E in RequestEvent]: DispatchableActionDescription<any>};
export interface ActionsBundle {
    internalActions: {[E in InternalAction]: DispatchableActionDescription<any>};
    fetch?: EventMap;
    fetchAll?: EventMap;
    create?: EventMap;
    update?: EventMap;
    delete?: EventMap;
}

export const createActions = (
    domain: string,
    apiDescription: APIDescription,
    dispatch: (arg: any) => void,
    isDevelopment: boolean,
) => {
    const createAction = makeCreateAction(dispatch, isDevelopment);

    const internalActions = {
        save: createAction(`${domain}/save`, obj => obj).action,
    };

    if (!apiDescription) {
        return {internalActions};
    }

    const wronglyTypedActionsBundle = List(Object.keys(apiDescription)).map(key => {
        const actions = createAction(
            `${domain}/${key.toUpperCase()}`,
            obj => obj,
            (response, originalPayload) => ({response, originalPayload}),
            (error, originalPayload) => ({error, originalPayload}),
            originalPayload => ({originalPayload}),
        );

        return {
            [key]: {
                start: actions.action,
                success: actions.success,
                failed: actions.failed,
                aborted: actions.aborted,
            },
        };
    });

    type ActionsBundleDeep = Array<{[K in RequestType]?: EventMap}>;

    // This type cast is needed until we find a proper way of iterating the keys of the apiDescription, which throws away type info
    const externalActionsBundleDeep = wronglyTypedActionsBundle as any as ActionsBundleDeep;

    return externalActionsBundleDeep.reduce<ActionsBundle>(
        (actions, actionsMap) => {
            return {
                ...actionsMap,
                ...actions,
            };
        },
        {internalActions},
    );
};
