import {type OperationVariables, type QueryHookOptions, useLazyQuery} from '@apollo/client';
import {type DocumentNode} from 'graphql';
import {REST_GRAPHQL_CLIENTNAME} from '@famly/stat_apollo-client';

/**
 * Simple wrapper for apollo's `useLazyQuery`.
 *
 * This wrapper adds the `REST_GRAPHQL_CLIENTNAME` to the context of the query.
 *
 * @param query first parameter for the useLazyQuery
 * @param options options parameter for the useLazyQuery
 * @returns a more error prone and enhanced version of the return values
 */
export function useRestLazyQuery<TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>,
) {
    return useLazyQuery(query, {...options, context: {...options?.context, clientName: REST_GRAPHQL_CLIENTNAME}});
}
